import Box from '@components/Box';
import Button from '@components/Button';
import Center from '@components/Center';
import Flex from '@components/Flex';
import Spinner from '@components/Spinner';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import {
	ArrowUpRightThin16,
	ChevronDownLarge16,
	ChevronLeftSmall,
	ChevronRightLarge16,
	ChevronRightSmall,
	GenAILogoColored,
} from '@icons/index';
import { default as classNames, default as classnames } from 'classnames';
import { useMemo, useRef } from 'react';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import classes from './AICard.module.scss';

type AICardProps = {
	isLoading: boolean;
	isDataOverviewOpen: boolean;
	currentMessage: {
		position: number;
		text?: string;
	};
	messagesLength: number;
	onExpandCollapseClick: VoidFunction;
	onPrevMessage: VoidFunction;
	onNextMessage: VoidFunction;
	toggleGenAIPopover: VoidFunction;
};

type CardHeaderProps = {
	isDataOverviewOpen: boolean;
	isLoading: boolean;
	onExpandCollapseClick: VoidFunction;
};

type CardBodyProps = {
	text?: string;
	isLoading: boolean;
	currentMessage?: {
		position: number;
		text?: string;
	};
	messagesLength?: number;
	onPrevMessage?: VoidFunction;
	onNextMessage?: VoidFunction;
	toggleGenAIPopover?: VoidFunction;
};

type ToggleAIChatButtonProps = {
	toggleGenAIPopover: VoidFunction;
};

function AILogo() {
	return (
		<Center height={'24px'}>
			<GenAILogoColored />
		</Center>
	);
}

function CardHeader({ isDataOverviewOpen, isLoading, onExpandCollapseClick }: CardHeaderProps) {
	const headerRightItem = useMemo(() => {
		if (isLoading) {
			return (
				<Center height={'24px'}>
					<Spinner size={'16px'} />
				</Center>
			);
		}

		return (
			<Tooltip hasArrow label={isDataOverviewOpen ? 'Close' : 'Expand'} size={'sm'} placement={'top'} gutter={14}>
				<Button
					isIconOnly
					size="xxs"
					variant="outline"
					border="none"
					borderRadius={'4px'}
					colorScheme="lightGray"
					mixBlendMode={'multiply'}
				>
					{isDataOverviewOpen ? <ChevronDownLarge16 /> : <ChevronRightLarge16 />}
				</Button>
			</Tooltip>
		);
	}, [isDataOverviewOpen, isLoading]);

	return (
		<Flex
			flexDirection={'row'}
			justifyContent={'space-between'}
			alignItems={'flex-start'}
			alignSelf={'stretch'}
			height={'48px'}
			className={classNames(classes.cardHeader, {
				[classes.isCollapsed]: !isDataOverviewOpen,
				[classes.isLoading]: isLoading,
			})}
			onClick={onExpandCollapseClick}
		>
			<AILogo />
			{headerRightItem}
		</Flex>
	);
}

function ToggleAIChatButton({ toggleGenAIPopover }: ToggleAIChatButtonProps) {
	const { wrapWithReport } = useReportEvent();
	const { metricNameWithoutFlavor, flavor, breakdowns, filters } = useMetricDerivedState();

	const toggleGenAIPopoverEventMetadata = useMemo(
		() => ({
			metric: metricNameWithoutFlavor,
			flavor: flavor?.selectedValue,
			breakdowns: breakdowns.values.map((e) => e.key),
			filterKeys: filters.map((e) => e.key),
			feature: 'Generative Virtual Assistant',
		}),
		[metricNameWithoutFlavor, flavor?.selectedValue, breakdowns.values, filters]
	);

	return (
		<Button
			isIconOnly
			size="xxs"
			variant="outline"
			border="none"
			colorScheme="black"
			mixBlendMode={'multiply'}
			onClick={wrapWithReport(toggleGenAIPopover, 'genai-chat-state-changed', {
				...toggleGenAIPopoverEventMetadata,
				action: 'open',
			})}
		>
			<ArrowUpRightThin16 />
		</Button>
	);
}

function CardBody({
	isLoading,
	text,
	messagesLength,
	currentMessage,
	onPrevMessage,
	onNextMessage,
	toggleGenAIPopover,
}: CardBodyProps) {
	const typographyRef = useRef<HTMLDivElement | null>(null);
	const isOverflowing = useOnOverflow(typographyRef, [text]);
	const numberContainerWidth = messagesLength && messagesLength < 10 ? 30 : 40;
	const numberItemWidth = messagesLength && messagesLength < 10 ? 10 : 20;

	const isPrevNextDisabled = (
		currentMessagePosition: number,
		messagesLength: number,
		direction: 'prev' | 'next'
	): boolean => {
		if (direction === 'prev') {
			return currentMessagePosition <= 1;
		} else if (direction === 'next') {
			return currentMessagePosition >= messagesLength;
		}
		return false;
	};

	const isPrevDisabled = isPrevNextDisabled(currentMessage?.position || 0, messagesLength || 0, 'prev');
	const isNextDisabled = isPrevNextDisabled(currentMessage?.position || 0, messagesLength || 0, 'next');

	return (
		<Flex
			padding="0 16px 16px 16px"
			flexDirection={'column'}
			alignItems={'flex-start'}
			gap="8px"
			width={'100%'}
			className={classes.cardBody}
		>
			<Tooltip size={'md'} label={isOverflowing ? text : ''} placement={'bottom'} maxWidth={'500px'}>
				<Typography variant={'Paragraph12R'} color={colors.gray[900]}>
					<div ref={typographyRef}>{text}</div>
				</Typography>
			</Tooltip>
			{!isLoading && messagesLength && messagesLength > 1 && (
				<Flex justifyContent={'space-between'} alignItems={'flex-end'} width={'100%'}>
					<Flex alignItems={'center'} gap={'4px'}>
						<Center
							height={'16px'}
							width={'16px'}
							className={classnames(classes.chevronButton, { [classes.isDisabled]: isPrevDisabled })}
							onClick={() => {
								if (!isPrevDisabled) {
									onPrevMessage?.();
								}
							}}
						>
							<ChevronLeftSmall />
						</Center>
						<Typography
							variant={'DesktopH10Regular'}
							color={colors.gray[1000]}
							width={numberContainerWidth}
							display={'inline-flex'}
							textAlign={'center'}
						>
							<Box width={numberItemWidth} textAlign={'center'}>
								{currentMessage?.position}
							</Box>
							/
							<Box width={numberItemWidth} textAlign={'center'}>
								{messagesLength}
							</Box>
						</Typography>
						<Center
							height={'16px'}
							width={'16px'}
							className={classnames(classes.chevronButton, { [classes.isDisabled]: isNextDisabled })}
							onClick={() => {
								if (!isNextDisabled) {
									onNextMessage?.();
								}
							}}
						>
							<ChevronRightSmall />
						</Center>
					</Flex>

					<Tooltip hasArrow label={'Generate in AI'} size={'sm'} gutter={14}>
						<ToggleAIChatButton toggleGenAIPopover={toggleGenAIPopover as VoidFunction} />
					</Tooltip>
				</Flex>
			)}
		</Flex>
	);
}

export default function AICard({
	isLoading: rawIsLoading,
	isDataOverviewOpen,
	onExpandCollapseClick,
	currentMessage,
	messagesLength,
	onPrevMessage,
	onNextMessage,
	toggleGenAIPopover,
}: AICardProps) {
	const isLoading = rawIsLoading || !currentMessage.text;

	return (
		<Flex flexDirection={'column'} alignItems={'flex-start'} className={classes.AICard}>
			<CardHeader
				isLoading={isLoading}
				isDataOverviewOpen={isDataOverviewOpen}
				onExpandCollapseClick={onExpandCollapseClick}
			/>
			{isDataOverviewOpen && !isLoading && (
				<CardBody
					isLoading={isLoading}
					text={currentMessage.text}
					messagesLength={messagesLength}
					currentMessage={currentMessage}
					onPrevMessage={onPrevMessage}
					onNextMessage={onNextMessage}
					toggleGenAIPopover={toggleGenAIPopover}
				/>
			)}
		</Flex>
	);
}

export function AIErrorCard() {
	const errorMessage = 'Something went wrong, please try again later.';

	return (
		<Flex flexDirection={'column'} alignItems={'flex-start'} className={classes.AICard}>
			<Flex
				flexDirection={'row'}
				p="12px 16px"
				justifyContent={'space-between'}
				alignItems={'center'}
				alignSelf={'stretch'}
				minHeight={'40px'}
			>
				<AILogo />
			</Flex>
			<CardBody text={errorMessage} isLoading={false} />
		</Flex>
	);
}
