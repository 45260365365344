import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Flex from 'src/common/components/Flex';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import {
	LabelWithIcon,
	PeriodBasicTooltip,
	PeriodFormulaTooltip,
	SelectLabel,
	TooltipIcon,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { BigSelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/SelectLabel';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

import { SuggestionsContextSettings } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import {
	addMacroPeriodIn,
	isMacroPeriodIn,
	removeMacroPeriodIn,
	addMacroPeriodInIfNeeded,
} from 'src/lib/metricRules/builder/periodMacroUtils';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import BuilderTextInput from '../../../../../../common/components/BuilderTextInput';
import { ConfirmationModal } from '../../../../../../common/components/ConfirmationModal';
import { SelectOption } from '../../../../../../common/components/Select/types';
import { TinySwitch } from '../../../../../../common/components/TinySwitch/TinySwitch';
import Typography from '../../../../../../common/components/Typography';
import { EditorFeature } from '../../EditPanel';
import { useMetricBuilderAIAgent } from '../../../../hooks/useMetricBuilderAIAgent';
import { AskAIFieldSuggestor } from '../../../../../../common/components/AskAI/AskAIFieldSuggestor';
import { PeriodXAxis } from '../../../../../../lib/completions/semanticTypes/metrics.schema';
import { PendingAISuggestion } from '../../../../../../common/components/AskAI/hooks/useAIPendingSuggestions';

function periodXAxisToPeriodStrings(isFormulaType: boolean, period?: PeriodXAxis[]): string[] | undefined {
	const periods = period?.map((p) => p.sql);
	if (!isFormulaType && periods && periods.length === 1 && isMacroPeriodIn(periods[0])) {
		return [removeMacroPeriodIn(periods[0])];
	}
	return periods;
}

function periodStringsToPeriodXAxis(periodStrings: string[]): PeriodXAxis[] {
	if (periodStrings.length === 1) {
		return periodStrings.map((sql) => ({ sql: addMacroPeriodInIfNeeded(sql) }));
	}
	return periodStrings.map((sql) => ({ sql }));
}

type PeriodFilterProps = {
	isDisabled?: boolean;
	selectedEntity?: SelectOption;
	selectedFeature: EditorFeature;
};

export const PeriodFilter = ({ isDisabled = false, selectedEntity, selectedFeature }: PeriodFilterProps) => {
	const [isFormulaType, setIsFormulaType] = useState(true);
	const { metricNameWithoutFlavor } = useMetricDerivedState();
	const { metricBuilderState: builderState, upsertYAMLProperty } = useBuilderDerivedState();
	const { isOpen, onOpen, onClose } = useModal();

	const { reportEvent } = useReportEvent({
		metricName: metricNameWithoutFlavor,
		editMode: formatBuilderFeatureEditMode(selectedFeature),
		feature: 'Metric Builder',
	});
	const { objectsTypes } = useMetricDerivedState();

	const isAggreagate = builderState && builderState.type === 'aggregate';
	const contextSettings: SuggestionsContextSettings = useMemo(() => {
		return {
			entity: selectedEntity?.value || objectsTypes[0],
			metric: '',
			includeTypes: ['dimension_date', 'relationship', 'parameter', 'parameter_store'],
		};
	}, [objectsTypes, selectedEntity?.value]);

	const formulaPeriodContextSettings: SuggestionsContextSettings = {
		entity: selectedEntity?.value || objectsTypes[0],
		metric: metricNameWithoutFlavor,
		includeTypes: [
			'dimension',
			'dimension_boolean',
			'function',
			'dimension_date',
			'dimension_numeric',
			'metric',
			'formula_metric',
			'relationship',
			'join',
			'parameter',
			'parameter_store',
		],
	};

	const canBeMacroType = useMemo(() => {
		if (builderState?.type !== 'aggregate') return false;
		const periodsCount = builderState?.x_axis?.period?.length ?? 0;
		const hasNoPeriods = !periodsCount;
		const hasMultiplePeriods = periodsCount > 1;
		const canBeMacro =
			hasNoPeriods ||
			(!hasMultiplePeriods &&
				(isMacroPeriodIn(builderState.x_axis?.period[0].sql ?? '') || builderState.x_axis?.period[0].sql == ''));
		return canBeMacro;
	}, [builderState]);

	useEffect(() => {
		setIsFormulaType(!canBeMacroType);
	}, [canBeMacroType, selectedEntity]);

	const xAxisPeriod = useMemo(() => {
		if (!isAggreagate) return [];
		return builderState?.x_axis?.period?.length ? builderState?.x_axis.period : [];
	}, [builderState, isAggreagate]);

	const { latestMetricBuilderSuggetion } = useMetricBuilderAIAgent();

	const periodSuggestion = useMemo((): PendingAISuggestion => {
		const suggestedValue = periodXAxisToPeriodStrings(
			isFormulaType,
			latestMetricBuilderSuggetion?.suggestedMetric?.aggregate?.x_axis?.period
		);
		const existingValue = periodXAxisToPeriodStrings(isFormulaType, xAxisPeriod);
		return {
			field: 'period',
			suggestedValue,
			existingValue,
		};
	}, [isFormulaType, latestMetricBuilderSuggetion?.suggestedMetric?.aggregate?.x_axis?.period, xAxisPeriod]);

	const onAcceptPeriodFieldSuggestion = useCallback(
		(values?: string[]) => {
			if (values?.length) {
				const periods = periodStringsToPeriodXAxis(values);
				upsertYAMLProperty('x_axis', { period: periods }, { shouldPreviewAfter: true });
			}
		},
		[upsertYAMLProperty]
	);

	const toggleFormulaType = () => {
		reportEvent({
			event: 'metric-edit-period-toggle',
			metaData: {
				feature: 'Metric Builder',
				entity: selectedEntity?.value,
				newState: isFormulaType ? 'basic' : 'formula',
				metric: builderState?.name,
			},
		});
		const canSwitchWithoutClearing = !isFormulaType || canBeMacroType;
		if (!canSwitchWithoutClearing) {
			onOpen();
			return;
		}
		setIsFormulaType(!isFormulaType);
	};

	const clearXAxisAndSwitch = () => {
		reportEvent({
			event: 'metric-edit-leave-period-modal',
			metaData: { feature: 'Create New Metric', action: 'confirm' },
		});
		upsertYAMLProperty('x_axis', undefined, { shouldRequestAISuggestions: true, shouldPreviewAfter: true });
		setIsFormulaType(!isFormulaType);
		onClose();
	};

	if (!isAggreagate) return null;

	const Toggler = () => {
		return (
			<Flex paddingY="1px">
				<TinySwitch isEnabled={isFormulaType} onClick={() => !isDisabled && toggleFormulaType()} text="Formula" />
			</Flex>
		);
	};

	const FormulaTypeContent = (
		<Flex flexDirection={'column'} width={'100%'}>
			{xAxisPeriod?.map((el, index) => (
				<Flex key={index} alignItems={'start'} marginBottom={'8px'} width="100%">
					{index > 0 && (
						<Box marginTop={'6px'}>
							<Typography marginRight={'8px'} color={'gray.700'} variant="DesktopH10Medium">
								and
							</Typography>
						</Box>
					)}
					<BuilderTextInput
						fieldName={'formulaPeriod'}
						onBlur={(value) => {
							const shouldRequestAISuggestions = xAxisPeriod[index]?.sql !== value;
							if (value === '') {
								upsertYAMLProperty(
									'x_axis',
									{ period: xAxisPeriod.filter((_, i) => i !== index) },
									{ shouldRequestAISuggestions, shouldPreviewAfter: true }
								);
								return;
							}
							upsertYAMLProperty(
								'x_axis',
								{
									period: xAxisPeriod.map((el, i) => (i === index ? { sql: value } : el)),
								},
								{ shouldRequestAISuggestions, shouldPreviewAfter: true }
							);
						}}
						placeholder={'Add value'}
						validation={'None'}
						validationText={''}
						readOnly={isDisabled}
						contextSettings={formulaPeriodContextSettings}
						text={el?.sql}
						id={`period-${index}`}
					/>
				</Flex>
			))}
			{!xAxisPeriod.length && (
				<BuilderTextInput
					fieldName={'formulaPeriod'}
					onBlur={(value) => {
						if (value === '') {
							upsertYAMLProperty('x_axis', undefined, { shouldPreviewAfter: true });
							return;
						}
						upsertYAMLProperty(
							'x_axis',
							{ period: [{ sql: value }] },
							{ shouldRequestAISuggestions: true, shouldPreviewAfter: true }
						);
					}}
					placeholder={'Add value'}
					validation={'None'}
					validationText={''}
					readOnly={isDisabled}
					contextSettings={formulaPeriodContextSettings}
					text={''}
					id={`period-first`}
				/>
			)}
			{!!xAxisPeriod.length && !xAxisPeriod.some((val) => val.sql === '') && (
				<Box marginTop={'8px'}>
					<LabelWithIcon
						isFieldsDisabled={isDisabled}
						onClick={() => {
							reportEvent({
								event: 'metric-edit-period-added',
								metaData: {
									feature: 'Metric Builder',
									entity: selectedEntity,
									metric: builderState.name,
								},
							});
							upsertYAMLProperty('x_axis', { period: xAxisPeriod.concat({ sql: '' }) });
						}}
						text="Add period"
					/>
				</Box>
			)}
		</Flex>
	);

	const MacroTypeContent = (
		<BuilderTextInput
			fieldName={'inPeriod'}
			onBlur={(val) => {
				const shouldRequestAISuggestions = xAxisPeriod?.[0]?.sql !== val?.trim();
				if (val == undefined || val === '') {
					upsertYAMLProperty('x_axis', undefined, { shouldRequestAISuggestions, shouldPreviewAfter: true });
					return;
				}
				upsertYAMLProperty(
					'x_axis',
					{ period: [{ sql: addMacroPeriodIn(val.trim()) }] },
					{ shouldRequestAISuggestions, shouldPreviewAfter: true }
				);
			}}
			placeholder={'Add value'}
			validation={'None'}
			validationText={''}
			readOnly={isDisabled}
			contextSettings={contextSettings}
			text={removeMacroPeriodIn(xAxisPeriod?.[0]?.sql ?? '')}
			id={'period_macro'}
			rightComponent={<Typography variant="DesktopH8Regular">In period</Typography>}
		/>
	);

	return (
		<Box>
			<Flex marginBottom={'28px'} justifyContent={'space-between'} alignItems={'center'}>
				<BigSelectLabel text="Period (X Axis)" />
				<Toggler />
			</Flex>
			<SelectLabel
				text="Date dimension"
				isOptional
				paddingBottom={'12px'}
				trailingIcon={
					<TooltipIcon
						tooltipBody={
							isFormulaType ? (
								<PeriodFormulaTooltip
									onHelpClicked={() => {
										reportEvent({ event: 'metric-edit-help-clicked', metaData: { component: 'Period formula' } });
									}}
								/>
							) : (
								<PeriodBasicTooltip
									onHelpClicked={() => {
										reportEvent({ event: 'metric-edit-help-clicked', metaData: { component: 'Period basic' } });
									}}
								/>
							)
						}
					/>
				}
			/>
			<AskAIFieldSuggestor
				suggestion={periodSuggestion}
				onSuggestionAccepted={onAcceptPeriodFieldSuggestion}
				reportMetadata={{
					metric: metricNameWithoutFlavor,
					metricType: latestMetricBuilderSuggetion?.metricType,
					triggerType: latestMetricBuilderSuggetion?.triggerType,
				}}
			>
				<Flex flexDirection={'column'} width={'100%'}>
					{isFormulaType ? FormulaTypeContent : MacroTypeContent}
				</Flex>
			</AskAIFieldSuggestor>
			<ConfirmationModal
				submitColorScheme="blue"
				isOpen={isOpen}
				onSubmit={clearXAxisAndSwitch}
				onClose={() => {
					reportEvent({
						event: 'metric-edit-leave-period-modal',
						metaData: { feature: 'Create New Metric', action: 'cancel' },
					});
					onClose();
				}}
				modalTitle="Leave formula mode."
				modalText="Leaving formula mode will clear Period input."
				primaryButtonLabel="Leave"
			/>
		</Box>
	);
};
