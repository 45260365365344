import { Box, Flex } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import { CloseMedium16, SparksFullWhite } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import ResizableIcon from '../../../../common/components/ResizableIcon';
import { useCallback, useEffect, useState } from 'react';
import { useReportAIEvent } from '../../../../common/components/AskAI/hooks/useReportAIEvent';
type ErrorProps = {
	onClose?: () => void;
	errorMessage?: string;
	isFixWithAIAvailable?: boolean;
	isAIBusy?: boolean;
	onFixWithAI?: (errorMessage: string) => void;
	reportMetadata?: Record<string, any>;
};

export function KeySensitiveError({
	onClose,
	errorMessage = '',
	isFixWithAIAvailable,
	isAIBusy,
	onFixWithAI,
	reportMetadata,
}: ErrorProps) {
	const [isBusyFixing, setIsBusyFixing] = useState(false);
	const { reportAICopilotEvent } = useReportAIEvent();

	const onFixWithAIClicked = useCallback(() => {
		onFixWithAI?.(errorMessage);
		setIsBusyFixing(true);
		reportAICopilotEvent({
			event: 'ai-copilot-click-fix-error-with-ai',
			metaData: { ...reportMetadata, errorMessage },
		});
	}, [errorMessage, onFixWithAI, reportAICopilotEvent, reportMetadata]);

	useEffect(() => {
		if (!isAIBusy) {
			setIsBusyFixing(false);
		}
	}, [isAIBusy]);

	const isFixLoading = isAIBusy && isBusyFixing;

	return (
		<Box
			borderRadius={'8px'}
			wordBreak={'break-all'}
			padding={'16px'}
			paddingTop={'10px'}
			maxHeight={'525px'}
			maxWidth={'272px'}
			backgroundColor={'gray.1000'}
			whiteSpace={'break-spaces'}
		>
			<Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={'8px'}>
				<Typography variant={'DesktopH10Bold'} color={'white'}>
					Error details
				</Typography>
				<Button onClick={onClose} isIconOnly size="xs" variant="outline" colorScheme="darkGray">
					<CloseMedium16 />
				</Button>
			</Flex>

			<Typography variant={'DesktopH10Regular'} color={'white'}>
				{errorMessage}
			</Typography>

			{isFixWithAIAvailable && (
				<Button
					isDisabled={isAIBusy}
					isLoading={isFixLoading}
					marginTop={'16px'}
					onClick={onFixWithAIClicked}
					size="xs"
					variant="solid"
					colorScheme="blue"
					leftIcon={<ResizableIcon icon={SparksFullWhite} size={'12px'} />}
				>
					Fix with AI
				</Button>
			)}
		</Box>
	);
}
