import Box from '@components/Box';
import Checkbox from '@components/Checkbox';
import { useCallback } from 'react';
import Flex from 'src/common/components/Flex';
import { EditableValueContainer } from './EditableValueContainer';

export type BooleanOption = { title: string; value: string };

export type BooleanEditorProps = {
	firstOption: BooleanOption;
	secondOption: BooleanOption;
	value: string;
	onApply: (newValue: string) => void;
	prefixComponent?: React.ReactNode;
};

function BooleanCheckbox({
	label,
	value,
	isChecked,
	onChecked,
}: {
	label: string;
	value: string;
	isChecked: boolean;
	onChecked: (checked: boolean, value: string) => void;
}) {
	return (
		<Box padding={'8px'}>
			<Checkbox
				isFluid={true}
				variant={'rounded'}
				checkboxColor={'blue.600'}
				size={'xs'}
				isChecked={isChecked}
				label={label}
				value={value}
				onChange={onChecked}
			/>
		</Box>
	);
}

export function BooleanEditor({ firstOption, secondOption, value, onApply, prefixComponent }: BooleanEditorProps) {
	const onChecked = useCallback(
		(checked: boolean, value: string) => {
			if (checked) {
				onApply(value);
			}
		},
		[onApply]
	);

	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<Flex height="32px">
				<BooleanCheckbox
					isChecked={value === firstOption.value}
					label={firstOption.title}
					value={firstOption.value}
					onChecked={onChecked}
				/>
				<BooleanCheckbox
					isChecked={value === secondOption.value}
					label={secondOption.title}
					value={secondOption.value}
					onChecked={onChecked}
				/>
			</Flex>
		</EditableValueContainer>
	);
}
