import { AbilityBuilder, AnyAbility, createMongoAbility, subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import { createContext, ReactNode, useMemo } from 'react';
import { Entitlement, ResourceType } from 'src/common/components/Can/types';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useGetUserRawEntitlementsRulesQuery } from 'src/generated/graphql';

export const AbilityContext = createContext<AnyAbility>(createMongoAbility());

export function PulseAbilityProviderGuard({ children }: { children: ReactNode }) {
	const isEntitlementsEnabled = useIsEntitlementsEnabled({ resourceType: 'any' });

	if (!isEntitlementsEnabled) return <>{children}</>;
	return <PulseAbilityProvider>{children}</PulseAbilityProvider>;
}

function PulseAbilityProvider({ children }: { children: ReactNode }) {
	const { data } = useGetUserRawEntitlementsRulesQuery({ fetchPolicy: 'network-only' });

	const ability = useMemo(() => {
		if (!data) return createMongoAbility();
		const builder = new AbilityBuilder(createMongoAbility);
		for (const rule of data.getUserRawEntitlementsRules.userRawEntitlementsRules) {
			builder.can(rule.action, rule.subject, rule.conditions);
		}
		return builder.build();
	}, [data]);

	return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
}

export function useIsEntitlementsEnabled({ resourceType }: { resourceType: ResourceType | 'any' }) {
	const isMetricEntitlementsEnabled = useFeatureFlag('pulse.sightfull2.entitlements.metrics.enable');
	if (resourceType === 'metric') return isMetricEntitlementsEnabled;

	return isMetricEntitlementsEnabled;
}

export function useEntitlementsCheck(entitlement: Entitlement): boolean {
	const ability = useAbility(AbilityContext);
	const isEntitlementsEnabled = useIsEntitlementsEnabled({ resourceType: entitlement.resourceType });
	return (
		!isEntitlementsEnabled ||
		ability.can(entitlement.action, subject(entitlement.resourceType, { resourceId: entitlement.resourceId }))
	);
}
