import { useAtomValue, useSetAtom } from 'jotai';
import pluralize from 'pluralize';
import { useCallback, useMemo, useRef, useState } from 'react';
import Image from '@components/Image';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import { TagIcon24 } from 'src/common/components/Icons';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { EntityCatalogQuery } from 'src/generated/graphql';
import { useSharedMonacoTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { OntologyStateAtomDerived, upsertPropertyOntologyState } from 'src/pages/OntologyPage/atoms/OntologyState';
import { KeysType, usePropertyBuilderState } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import {
	CreateNewDimensionPropertyName,
	CreateNewRelationshipPropertyName,
	usePropertyNameParam,
	useSetOntologyLocation,
} from 'src/pages/OntologyPage/hooks/useOntologySearchParams';
import { PropertyUpdate } from 'src/pages/OntologyPage/utils/updateYaml';
import { findKeyValue, isJoinKeysFormat, splitJoinKeysByAnd } from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import colors from 'src/style/colors';
import YAML from 'yaml';
import CreatePropertyPanel from './CreatePropertyPanel';
import EditPropertyPanel from './EditPropertyPanel';
import { connectorsInfos } from 'src/common/components/TopNavigation/DataConnectorsModal/connectors';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { toLower } from 'lodash';
import { OnAcceptAIChatPropertyFieldSuggestionsProps } from '../../../utils/types';

export function PropertyPanel({
	onSubmit,
	onMoveBack,
	isEditable = true,
	entities,
	isAdvancedMode = false,
	setIsAdvancedMode,
}: {
	onMoveBack?: VoidFunction;
	onSubmit: (value?: string[], additionalTableColumns?: string[]) => void;
	isEditable?: boolean;
	entities: EntityCatalogQuery['entityCatalog'];
	isAdvancedMode: boolean;
	setIsAdvancedMode: (val: boolean) => void;
}) {
	const [propertyUpdate, setPropertyUpdate] = useState<PropertyUpdate | null>();
	const selectedPropertyName = usePropertyNameParam();

	const { reportEvent } = useReportEvent({
		editMode: isAdvancedMode ? 'YAML' : 'UI',
	});

	const isNewDimension = selectedPropertyName === CreateNewDimensionPropertyName;
	const isNewRelationship = selectedPropertyName === CreateNewRelationshipPropertyName;
	const isEditProperty = !isNewDimension && !isNewRelationship;

	const { propertyType: editPropertyType } = usePropertyBuilderState({ name: selectedPropertyName || '' });
	const propertyType = editPropertyType || (isNewDimension ? 'dimensions' : 'relationships');

	const toast = useToast();
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const upsertProperty = useSetAtom(upsertPropertyOntologyState);
	const updateOntologyLocation = useSetOntologyLocation();
	const inputRef = useRef<HTMLInputElement>(null);

	const { setIsNavigationBlocked } = useNavigationBlock();

	const { isOpen: isRenameModalOpen, onOpen: onRenameModalOpen, onClose: onRenameModalClose } = useModal();

	useSharedMonacoTextFieldProviders({ entity: ontologyState.loading ? '' : ontologyState?.entityName, metric: '' });

	const entitiesOptions = useMemo(
		() =>
			entities.map((entity) => {
				const sourceInfo = entity?.schema?.sourceDisplayName
					? connectorsInfos.find((connector) => connector.id === toLower(entity.schema?.sourceDisplayName))
					: null;
				return {
					value: entity.entityName,
					label: entity.displayName || removeUnderscoresAndCapitalize(entity.entityName),
					icon: <Image src={sourceInfo?.icon || PLACEHOLDER_ICON} maxWidth={'16px'} height={'16px'} width={'100%'} />,
				};
			}),

		[entities]
	);

	const onAcceptAISuggestions = useCallback(
		({
			propertyDefinitionValue,
			upsertYAMLProperties,
			selectedFields,
		}: OnAcceptAIChatPropertyFieldSuggestionsProps) => {
			const properties: KeysType = [];
			const meta: {
				display_name?: string;
				description?: string;
			} = {};
			for (const field of selectedFields) {
				if (field.propertyName === 'display_name' || field.propertyName === 'description') {
					meta[field.propertyName] = field.value;
				} else {
					properties.push({ key: field.propertyName, value: field.value });
				}
			}
			if (meta) {
				properties.push({
					key: 'meta',
					value: {
						display_name: meta.display_name ?? propertyDefinitionValue?.meta?.display_name,
						description: meta.description ?? propertyDefinitionValue?.meta?.description,
					},
				});
			}
			upsertYAMLProperties(properties);
		},
		[]
	);

	if (!selectedPropertyName || ontologyState.loading) return false;

	const onRenameModalSubmit = () => {
		closeEntityMetricModal();
		if (!isEditProperty) setTimeout(() => inputRef?.current?.focus(), 200);
	};

	const closeEntityMetricModal = () => {
		reportEvent({
			event: 'ontology-edit-unique-name-modal',
			metaData: {
				feature: 'YAML Editor',
				newName: propertyUpdate?.currentPropertyName,
				action: 'confirm',
				objectName: ontologyState.entityName,
			},
		});
		onRenameModalClose();
		setPropertyUpdate(null);
	};

	const onSubmitPropertyUpdate = (propertyUpdate: PropertyUpdate) => {
		reportEvent({
			event: 'ontology-object-apply-changes-clicked',
			metaData: {
				objectType: propertyUpdate.ontologyPropertyType,
				parentEntity: ontologyState.entityName,
				objectName: ontologyState.entityName,
				flow: isEditProperty ? 'edit' : 'create',
				editMode: isAdvancedMode ? 'YAML' : 'UI',
			},
		});
		setPropertyUpdate(propertyUpdate);
		if (!isEditProperty) onConfirmSubmit();
	};

	const onConfirmSubmit = (submittedProp?: PropertyUpdate) => {
		const propertyToUpdate = submittedProp || propertyUpdate;
		if (!propertyToUpdate) return;
		if (!propertyToUpdate?.propertyDefinition) return;

		const parsedEntity = YAML.parse(propertyToUpdate.propertyDefinition);

		reportEvent({
			event: 'ontology-object-apply-changes-clicked',
			metaData: {
				objectName: parsedEntity.name,
				objectType: propertyToUpdate?.ontologyPropertyType,
				parentEntity: ontologyState.entityName,
				flow: isEditProperty ? 'edit' : 'create',
				editMode: isAdvancedMode ? 'YAML' : 'UI',
			},
		});

		try {
			upsertProperty({
				...propertyToUpdate,
				result: (result) => {
					let additionalTableColumns: string[] = [];
					if (propertyType === 'dimensions') additionalTableColumns = [result.newName];
					if (
						propertyType === 'relationships' &&
						parsedEntity.type === 'many_to_one' &&
						isJoinKeysFormat(parsedEntity.on)
					) {
						const toKeyValues: string[] = splitJoinKeysByAnd(parsedEntity?.on)?.map((el: string) =>
							findKeyValue(el, true)
						);
						if (toKeyValues.length > 0) {
							additionalTableColumns = toKeyValues.map((el) => `$${result.newName}.${el?.split('.')[1]}`);
						}
					}
					onSubmit(result.yaml.split('\n'), additionalTableColumns);
					setIsNavigationBlocked({ isBlocked: false });
					setTimeout(() => {
						updateOntologyLocation({
							ontologyType: 'entity',
							ontologyName: ontologyState.entityName,
							propertyName: result.newName,
						});
					}, 0);
					propertyToUpdate.result?.(result);
				},
			});
			setPropertyUpdate(null);
		} catch (e) {
			if (e instanceof Error) {
				if (e.message.includes('already exists')) {
					reportEvent({
						event: 'ontology-edit-unique-name-modal',
						metaData: {
							objectType: propertyToUpdate.ontologyPropertyType,
							parentEntity: ontologyState.entityName,
							objectName: propertyToUpdate.currentPropertyName,
							error: e.message,
						},
					});
					onRenameModalOpen();
				} else {
					toast({ variant: 'error', message: e.message });
					setPropertyUpdate(null);
				}
			}
		}
	};

	return (
		<>
			{isEditProperty ? (
				<EditPropertyPanel
					entitiesOptions={entitiesOptions}
					isAdvancedMode={isAdvancedMode}
					setIsAdvancedMode={setIsAdvancedMode}
					isEditable={isEditable}
					name={selectedPropertyName}
					onMoveBack={onMoveBack}
					onSubmit={onSubmitPropertyUpdate}
					onAcceptAISuggestions={onAcceptAISuggestions}
				/>
			) : (
				<CreatePropertyPanel
					entitiesOptions={entitiesOptions}
					isAdvancedMode={isAdvancedMode}
					setIsAdvancedMode={setIsAdvancedMode}
					isEditable={isEditable}
					inputRef={inputRef}
					onMoveBack={onMoveBack}
					onSubmit={(propertyUpdate) => {
						setPropertyUpdate(propertyUpdate);
						onConfirmSubmit(propertyUpdate);
					}}
					onAcceptAISuggestions={onAcceptAISuggestions}
					propertyType={propertyType}
				/>
			)}
			<ConfirmationModal
				modalIcon={<TagIcon24 color={colors.blue[600]} />}
				isOpen={!!propertyUpdate && isEditProperty}
				onSubmit={() => onConfirmSubmit()}
				onClose={() => {
					reportEvent({
						event: 'save-and-run-ontology-canceled',
						metaData: { objectType: propertyUpdate?.ontologyPropertyType },
					});
					setPropertyUpdate(null);
				}}
				modalTitle="Save changes."
				modalText="Making changes to this entity will affect other entities, metrics, and dashboards within the platform."
			/>
			<ConfirmationModal
				isWithoutCancel
				submitColorScheme="blue"
				isOpen={isRenameModalOpen}
				onSubmit={onRenameModalSubmit}
				modalTitle={`Change ${
					propertyUpdate?.ontologyPropertyType && pluralize(propertyUpdate?.ontologyPropertyType, 1)
				} name.`}
				modalText={`Name has to be unique.`}
				primaryButtonLabel="Edit name"
				onClose={closeEntityMetricModal}
			/>
		</>
	);
}
