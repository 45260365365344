import { useCallback, useMemo, useState } from 'react';
import { useMetricCatalogV2LazyQuery } from 'src/generated/graphql';
import { normalizeCategoriesV2 } from 'src/normalize';
import { CategoryV2 } from 'src/types/metric';

export const useMetricCategoriesV2 = (clearMetricCardsCache?: VoidFunction): [CategoryV2[], () => void, boolean] => {
	const [shouldDropUncategorized, setShouldDropUncategorized] = useState(true);
	const toggleShouldDropUncategorized = useCallback(
		() => setShouldDropUncategorized(!shouldDropUncategorized),
		[shouldDropUncategorized]
	);

	const [fetchCategories, { data: rawMetricsV2, error: didMetricV2LoadFailed, loading: isLoadingCategories }] =
		useMetricCatalogV2LazyQuery();

	const getMetricCategories = useCallback(() => {
		const categories = rawMetricsV2?.metricCatalogV2.categories;

		if (categories) {
			return normalizeCategoriesV2(categories, { shouldDropUncategorized });
		}
		if (!didMetricV2LoadFailed && !isLoadingCategories) {
			fetchCategories();
		}

		return [];
	}, [fetchCategories, didMetricV2LoadFailed, isLoadingCategories, rawMetricsV2, shouldDropUncategorized]);

	const metricCategories = useMemo(() => getMetricCategories(), [getMetricCategories]);

	clearMetricCardsCache?.();

	return [metricCategories, toggleShouldDropUncategorized, isLoadingCategories];
};
