import { PrimitiveAtom, atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { AskAIChatMessage } from '../types';
import { useAIAgents } from './useAIAgents';

const globalScope = Symbol();
export const ChatMessagesAtom = atom<PrimitiveAtom<AskAIChatMessage>[]>([]);

export function useAskAIChatMessages() {
	const [chatMessages, setChatMessages] = useAtom(ChatMessagesAtom, globalScope);

	const { clearAIAgentsContext } = useAIAgents();

	const addChatMessage = useCallback(
		(message: Omit<AskAIChatMessage, 'messageKey'>) => {
			if (message.userPrompt.length === 0) return;
			const chatMessageAtom = atom({ ...message, messageKey: `${Date.now()}-${Math.random()}` });
			setChatMessages((prev) => [...prev, chatMessageAtom]);
			return chatMessageAtom;
		},
		[setChatMessages]
	);

	const resetChatMessages = useCallback(() => {
		setChatMessages(() => []);
		clearAIAgentsContext();
	}, [setChatMessages, clearAIAgentsContext]);

	return { chatMessages, addChatMessage, resetChatMessages };
}
