import Flex from '@components/Flex';
import { Key24B, Office16B, ShieldCheck16B } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import classes from './ConnectorsBanner.module.scss';
import { TestIDs } from 'src/common/types/test-ids';

export const ConnectorsBanner = () => {
	const { bannerWrapper, contentWrapper, contentDescriptionWrapper, contentDescription, contentList } = classes;

	return (
		<Flex className={bannerWrapper} data-testid={TestIDs.CONNECTORS_PAGE_BANNER}>
			<Flex className={contentWrapper}>
				<Typography marginBottom={'12px'} color={'gray.1000'} variant={'DesktopH3M'} textAlign="start">
					Connect your data source with confidence.
				</Typography>
				<Flex className={contentDescriptionWrapper}>
					<Typography className={contentDescription} variant={'Paragraph14R'}>
						Connect your data from hundreds of integrations to keep your metrics, and dashboards always up-to-date. Your
						data’s security is our top priority.
					</Typography>
					<Flex className={contentList}>
						<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
							<Key24B color={colors.gray['1000']} />
							<Typography variant={'DesktopH8Medium'} color={colors.gray['1000']} textAlign={'center'}>
								Data encryption
							</Typography>
						</Flex>
						<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
							<Office16B color={colors.gray['1000']} />
							<Typography variant={'DesktopH8Medium'} color={colors.gray['1000']} textAlign={'center'}>
								Comply with industry standards
							</Typography>
						</Flex>
						<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
							<ShieldCheck16B color={colors.gray['1000']} />
							<Typography variant={'DesktopH8Medium'} color={colors.gray['1000']} textAlign={'center'}>
								Strict access control
							</Typography>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
