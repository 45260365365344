import { useCallback } from 'react';
import { useReportEvent } from 'src/services/analytics';

export type AnyFunc = (args?: any) => unknown;

export type AskAIEventProps = {
	event: string;
	feature: string;
	metaData?: Record<string, any>;
};

export function useReportAIEvent() {
	const { reportEvent } = useReportEvent();

	const reportAIEvent = useCallback(
		({ event, metaData, feature }: AskAIEventProps) => {
			delete metaData?.result;
			const props = { event, metaData: { feature, ...metaData } };
			reportEvent(props);
		},
		[reportEvent]
	);

	const reportAskAIEvent = useCallback(
		({ event, metaData }: Pick<AskAIEventProps, 'event' | 'metaData'>) => {
			delete metaData?.result;
			const props = { event, metaData: { feature: 'Ask AI', ...metaData } };
			reportEvent(props);
		},
		[reportEvent]
	);

	const reportAICopilotEvent = useCallback(
		({ event, metaData }: Pick<AskAIEventProps, 'event' | 'metaData'>) => {
			const props = { event, metaData: { feature: 'AI Copilot', ...metaData } };
			reportEvent(props);
		},
		[reportEvent]
	);

	return { reportAskAIEvent, reportAICopilotEvent, reportAIEvent };
}
