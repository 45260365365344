import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
// https://sightfull.atlassian.net/wiki/spaces/HANDBOOK/pages/1298563074/Scrape+Fivetran+connectors
export const connectorsInfos: DataConnectorInfo[] = [
	{
		name: '15Five',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/15five/resources/15five.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: '15five',
		connectorClass: 'lite',
	},
	{
		name: '360Learning',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/360learning/resources/360learning.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: '360learning',
		connectorClass: 'lite',
	},
	{
		name: '6sense',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/6sense/resources/6sense.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: '6sense',
		connectorClass: 'lite',
	},
	{
		name: '7shifts',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/7shifts/resources/7shifts.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: '7shifts',
		connectorClass: 'lite',
	},
	{
		name: 'ADP Workforce Now',
		icon: 'https://fivetran.com/integrations/adp_workforce_now/resources/adp_logo.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'adp_workforce_now',
		connectorClass: 'standard',
	},
	{
		name: 'AVEVA PI',
		icon: 'https://fivetran.com/integrations/osi_pi/metadata/resources/aveva_pi.png',
		status: 'private_preview',
		type: 'Finance',
		id: 'aveva_pi',
		connectorClass: 'standard',
	},
	{
		name: 'AWS CloudTrail',
		icon: 'https://fivetran.com/integrations/aws_cloudtrail/resources/aws_cloudtrail.svg',
		status: 'general_availability',
		type: 'File',
		id: 'aws_cloudtrail',
		connectorClass: 'standard',
	},
	{
		name: 'AWS Cost and Usage Reports',
		icon: 'https://fivetran.com/integrations/aws_cost_report/resources/aws_cost_report.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'aws_cost_report',
		connectorClass: 'standard',
	},
	{
		name: 'AWS Inventory',
		icon: 'https://fivetran.com/integrations/aws_inventory/resources/aws_inventory.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'aws_inventory',
		connectorClass: 'standard',
	},
	{
		name: 'AWS Lambda',
		icon: 'https://fivetran.com/integrations/functions/aws_lambda/resources/logo.png',
		status: 'general_availability',
		type: 'Function',
		id: 'aws_lambda',
		connectorClass: 'standard',
	},
	{
		name: 'AWS MSK',
		icon: 'https://fivetran.com/integrations/kafka/resources/aws_msk.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'aws_msk',
		connectorClass: 'standard',
	},
	{
		name: 'Absorb LMS',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/absorb_lms/resources/absorb_lms.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'absorb_lms',
		connectorClass: 'lite',
	},
	{
		name: 'AccuLynx',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/acculynx/resources/acculynx.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'acculynx',
		connectorClass: 'lite',
	},
	{
		name: 'ActiveCampaign',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/activecampaign/resources/activecampaign.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'activecampaign',
		connectorClass: 'lite',
	},
	{
		name: 'Acumatica',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/acumatica/resources/acumatica.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'acumatica',
		connectorClass: 'lite',
	},
	{
		name: 'AdRoll',
		icon: 'https://fivetran.com/integrations/adroll/resources/adroll.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'adroll',
		connectorClass: 'standard',
	},
	{
		name: 'Ada',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ada/resources/ada.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'ada',
		connectorClass: 'lite',
	},
	{
		name: 'Adjust',
		icon: 'https://fivetran.com/integrations/adjust/resources/adjust-logo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'adjust',
		connectorClass: 'standard',
	},
	{
		name: 'Adobe Analytics',
		icon: 'https://fivetran.com/integrations/adobe_analytics/resources/adobe-analytics-symbol.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'adobe_analytics',
		connectorClass: 'standard',
	},
	{
		name: 'Adobe Analytics Data Feed',
		icon: 'https://fivetran.com/integrations/adobe_analytics_data_feed/resources/adobe-analytics-data-feed-symbol.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'adobe_analytics_data_feed',
		connectorClass: 'standard',
	},
	{
		name: 'Adobe Commerce',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/adobe_commerce/resources/adobe_commerce.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'adobe_commerce',
		connectorClass: 'standard',
	},
	{
		name: 'Adobe Workfront',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/adobe_workfront/resources/adobe_workfront.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'adobe_workfront',
		connectorClass: 'lite',
	},
	{
		name: 'Adyen',
		icon: 'https://fivetran.com/integrations/adyen/resources/adyen.svg',
		status: 'beta',
		type: 'Finance',
		id: 'adyen',
		connectorClass: 'standard',
	},
	{
		name: 'Affinity',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/affinity/resources/affinity.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'affinity',
		connectorClass: 'lite',
	},
	{
		name: 'Afterpay',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/afterpay/resources/afterpay.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'afterpay',
		connectorClass: 'lite',
	},
	{
		name: 'Aha!',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/aha/resources/aha.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'aha',
		connectorClass: 'lite',
	},
	{
		name: 'Aircall',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/aircall/resources/aircall.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'aircall',
		connectorClass: 'lite',
	},
	{
		name: 'Airtable',
		icon: 'https://fivetran.com/integrations/airtable/resources/logo.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'airtable',
		connectorClass: 'standard',
	},
	{
		name: 'Akamai',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/akamai/resources/akamai.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'akamai',
		connectorClass: 'lite',
	},
	{
		name: 'Akeneo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/akeneo/resources/akeneo.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'akeneo',
		connectorClass: 'lite',
	},
	{
		name: 'Alchemer',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/alchemer/resources/alchemer.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'alchemer',
		connectorClass: 'standard',
	},
	{
		name: 'Algolia',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/algolia/resources/algolia.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'algolia',
		connectorClass: 'lite',
	},
	{
		name: 'Amazon Ads',
		icon: 'https://fivetran.com/integrations/amazon_ads/resources/amazon-ads-logo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'amazon_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon Attribution',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/amazon_attribution/resources/amazon_attribution.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'amazon_attribution',
		connectorClass: 'lite',
	},
	{
		name: 'Amazon Aurora MySQL',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/amazon-aurora.png',
		status: 'general_availability',
		type: 'Database',
		id: 'aurora',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon Aurora PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/aurora.png',
		status: 'general_availability',
		type: 'Database',
		id: 'aurora_postgres',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon CloudFront',
		icon: 'https://fivetran.com/integrations/cloudfront/resources/cloudfront.svg',
		status: 'general_availability',
		type: 'File',
		id: 'cloudfront',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon DSP',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/amazon_dsp/resources/amazon_dsp.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'amazon_dsp',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon DocumentDB',
		icon: 'https://fivetran.com/integrations/document/metadata/resources/documentDB.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'documentdb',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon DynamoDB',
		icon: 'https://fivetran.com/integrations/dynamodb/resources/dynamodb.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'dynamodb',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon Kinesis Firehose',
		icon: 'https://fivetran.com/integrations/kinesis/resources/kinesis.svg',
		status: 'general_availability',
		type: 'File',
		id: 'kinesis',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon RDS for MariaDB',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_rds.png',
		status: 'general_availability',
		type: 'Database',
		id: 'maria_rds',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon RDS for PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/aurora.png',
		status: 'general_availability',
		type: 'Database',
		id: 'postgres_rds',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon RDS for SQL Server',
		icon: 'https://fivetran.com/integrations/sql_server/resources/aurora.png',
		status: 'general_availability',
		type: 'Database',
		id: 'sql_server_rds',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon S3',
		icon: 'https://fivetran.com/integrations/s3/resources/s3.svg',
		status: 'general_availability',
		type: 'File',
		id: 's3',
		connectorClass: 'standard',
	},
	{
		name: 'Amazon Selling Partner',
		icon: 'https://fivetran.com/integrations/amazon_selling_partner/resources/logo.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'amazon_selling_partner',
		connectorClass: 'standard',
	},
	{
		name: 'Amplitude',
		icon: 'https://fivetran.com/integrations/amplitude/resources/logo.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'amplitude',
		connectorClass: 'standard',
	},
	{
		name: 'Anaplan',
		icon: 'https://fivetran.com/integrations/anaplan/resources/anaplan.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'anaplan',
		connectorClass: 'standard',
	},
	{
		name: 'Anvyl',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/anvyl/resources/anvyl.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'anvyl',
		connectorClass: 'lite',
	},
	{
		name: 'Apache Kafka',
		icon: 'https://fivetran.com/integrations/kafka/resources/apache_kafka.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'apache_kafka',
		connectorClass: 'standard',
	},
	{
		name: 'Appcues',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/appcues/resources/appcues.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'appcues',
		connectorClass: 'lite',
	},
	{
		name: 'Appfigures',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/appfigures/resources/appfigures.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'appfigures',
		connectorClass: 'lite',
	},
	{
		name: 'Apple App Store',
		icon: 'https://fivetran.com/integrations/itunes_connect/resources/itunes_connect_logo.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'itunes_connect',
		backwardsCompatibleName: 'AppleStore',
		connectorClass: 'standard',
	},
	{
		name: 'Apple Search Ads',
		icon: 'https://fivetran.com/integrations/apple_search_ads/resources/apple_search_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'apple_search_ads',
		connectorClass: 'standard',
	},
	{
		name: 'AppsFlyer',
		icon: 'https://fivetran.com/integrations/appsflyer/resources/appsflyer.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'appsflyer',
		connectorClass: 'standard',
	},
	{
		name: 'Asana',
		icon: 'https://fivetran.com/integrations/asana/resources/asana.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'asana',
		connectorClass: 'standard',
	},
	{
		name: 'Assembled',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/assembled/resources/assembled.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'assembled',
		connectorClass: 'standard',
	},
	{
		name: 'Attentive',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/attentive/resources/attentive.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'attentive',
		connectorClass: 'standard',
	},
	{
		name: 'Attio',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/attio/resources/attio.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'attio',
		connectorClass: 'lite',
	},
	{
		name: 'Aumni',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/aumni/resources/aumni.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'aumni',
		connectorClass: 'lite',
	},
	{
		name: 'Auth0',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/auth0/resources/auth0.svg',
		status: 'general_availability',
		type: 'Security',
		id: 'auth0',
		connectorClass: 'lite',
	},
	{
		name: 'Autodesk BIM 360',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/autodesk_bim_360/resources/autodesk_bim_360.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'autodesk_bim_360',
		connectorClass: 'lite',
	},
	{
		name: 'AvantLink',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/avantlink/resources/avantlink.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'avantlink',
		connectorClass: 'lite',
	},
	{
		name: 'Awin',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/awin/resources/awin.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'awin',
		connectorClass: 'lite',
	},
	{
		name: 'Azure Blob Storage',
		icon: 'https://fivetran.com/integrations/azure_blob_storage/resources/azure_blob_storage.svg',
		status: 'general_availability',
		type: 'File',
		id: 'azure_blob_storage',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Boards',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/azure_boards/resources/azure_boards.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'azure_boards',
		connectorClass: 'lite',
	},
	{
		name: 'Azure Cosmos DB for MongoDB',
		icon: 'https://fivetran.com/integrations/mongo/resources/cosmos_for_mongo-logo.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'azure_cosmos_for_mongo',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Cosmos DB for NoSQL',
		icon: 'https://fivetran.com/integrations/cosmos/resources/cosmos.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'cosmos',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Database for MariaDB',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/maria_azure.png',
		status: 'general_availability',
		type: 'Database',
		id: 'maria_azure',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Database for MySQL',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/azure.png',
		status: 'general_availability',
		type: 'Database',
		id: 'mysql_azure',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Database for PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/azure.png',
		status: 'general_availability',
		type: 'Database',
		id: 'azure_postgres',
		connectorClass: 'standard',
	},
	{
		name: 'Azure DevOps',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/azure_devops/resources/azure_devops.svg',
		status: 'beta',
		type: 'Productivity',
		id: 'azure_devops',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Event Hubs',
		icon: 'https://fivetran.com/integrations/kafka/resources/event_hub.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'azure_event_hub',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Functions',
		icon: 'https://fivetran.com/integrations/functions/azure_function/resources/logo.svg',
		status: 'general_availability',
		type: 'Function',
		id: 'azure_function',
		connectorClass: 'standard',
	},
	{
		name: 'Azure SQL Database',
		icon: 'https://fivetran.com/integrations/sql_server/resources/azure.png',
		status: 'general_availability',
		type: 'Database',
		id: 'azure_sql_db',
		connectorClass: 'standard',
	},
	{
		name: 'Azure SQL Managed Instance',
		icon: 'https://fivetran.com/integrations/sql_server/resources/azure.png',
		status: 'general_availability',
		type: 'Database',
		id: 'azure_sql_managed_db',
		connectorClass: 'standard',
	},
	{
		name: 'Azure Service Bus',
		icon: 'https://fivetran.com/integrations/azure_service_bus/resources/azure_service_bus.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'azure_service_bus',
		connectorClass: 'standard',
	},
	{
		name: 'Backbone PLM',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/backbone_plm/resources/backbone_plm.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'backbone_plm',
		connectorClass: 'lite',
	},
	{
		name: 'BallotReady',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ballotready/resources/ballotready.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ballotready',
		connectorClass: 'lite',
	},
	{
		name: 'Bamboo HR',
		icon: 'https://fivetran.com/integrations/bamboohr/resources/bamboohr.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'bamboohr',
		connectorClass: 'standard',
	},
	{
		name: 'Bazaarvoice',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bazaarvoice/resources/bazaarvoice.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'bazaarvoice',
		connectorClass: 'lite',
	},
	{
		name: 'Betterworks',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/betterworks/resources/betterworks.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'betterworks',
		connectorClass: 'lite',
	},
	{
		name: 'BigCommerce',
		icon: 'https://fivetran.com/integrations/big_commerce/resources/big_commerce.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'big_commerce',
		connectorClass: 'standard',
	},
	{
		name: 'BigMarker',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bigmarker/resources/bigmarker.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'bigmarker',
		connectorClass: 'lite',
	},
	{
		name: 'BigQuery',
		icon: 'https://fivetran.com/integrations/bigquery/resources/logo.png',
		status: 'general_availability',
		type: 'Database',
		id: 'bigquery_db',
		backwardsCompatibleName: 'BigQuery',
		connectorClass: 'standard',
	},
	{
		name: 'Bigin by Zoho CRM',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bigin_by_zoho_crm/resources/bigin_by_zoho_crm.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'bigin_by_zoho_crm',
		connectorClass: 'lite',
	},
	{
		name: 'BillingPlatform',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/billing_platform/resources/billing_platform.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'billing_platform',
		connectorClass: 'lite',
	},
	{
		name: 'Birdeye',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/birdeye/resources/birdeye.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'birdeye',
		connectorClass: 'lite',
	},
	{
		name: 'Bitly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bitly/resources/bitly.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'bitly',
		connectorClass: 'lite',
	},
	{
		name: 'Bizzabo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bizzabo/resources/bizzabo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'bizzabo',
		connectorClass: 'lite',
	},
	{
		name: 'Boostr',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/boostr/resources/boostr.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'boostr',
		connectorClass: 'lite',
	},
	{
		name: 'Box',
		icon: 'https://fivetran.com/integrations/box/resources/box-icon.svg',
		status: 'general_availability',
		type: 'File',
		id: 'box',
		connectorClass: 'standard',
	},
	{
		name: 'Braintree',
		icon: 'https://fivetran.com/integrations/braintree/resources/braintree.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'braintree',
		connectorClass: 'standard',
	},
	{
		name: 'Braintree Sandbox',
		icon: 'https://fivetran.com/integrations/braintree/resources/braintree.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'braintree_sandbox',
		connectorClass: 'standard',
	},
	{
		name: 'Branch',
		icon: 'https://fivetran.com/integrations/branch/resources/branch.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'branch',
		connectorClass: 'standard',
	},
	{
		name: 'Brave Ads',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/brave_ads/resources/brave_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'brave_ads',
		connectorClass: 'lite',
	},
	{
		name: 'Braze',
		icon: 'https://fivetran.com/integrations/braze/resources/braze.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'braze',
		connectorClass: 'standard',
	},
	{
		name: 'Brevo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/brevo/resources/brevo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'brevo',
		connectorClass: 'lite',
	},
	{
		name: 'Brex',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/brex/resources/brex.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'brex',
		connectorClass: 'lite',
	},
	{
		name: 'Brightcove',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/brightcove/resources/brightcove.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'brightcove',
		connectorClass: 'lite',
	},
	{
		name: 'Brightpearl',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/brightpearl/resources/brightpearl.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'brightpearl',
		connectorClass: 'lite',
	},
	{
		name: 'Bubble',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/bubble/resources/bubble.svg',
		status: 'sunset',
		type: 'Productivity',
		id: 'bubble',
		connectorClass: 'lite',
	},
	{
		name: 'Buildium',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/buildium/resources/buildium.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'buildium',
		connectorClass: 'lite',
	},
	{
		name: 'Buildkite',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/buildkite/resources/buildkite.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'buildkite',
		connectorClass: 'lite',
	},
	{
		name: 'Buzzsprout',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/buzzsprout/resources/buzzsprout.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'buzzsprout',
		connectorClass: 'lite',
	},
	{
		name: 'CJ Commission Detail',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cj_commission_detail/resources/cj_commission_detail.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'cj_commission_detail',
		connectorClass: 'lite',
	},
	{
		name: 'Calabrio',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/calabrio/resources/calabrio.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'calabrio',
		connectorClass: 'lite',
	},
	{
		name: 'Calendly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/calendly/resources/calendly.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'calendly',
		connectorClass: 'lite',
	},
	{
		name: 'CallRail',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/callrail/resources/callrail.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'callrail',
		connectorClass: 'lite',
	},
	{
		name: 'Campaign Monitor',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/campaignmonitor/resources/campaignmonitor.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'campaignmonitor',
		connectorClass: 'lite',
	},
	{
		name: 'Canny',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/canny/resources/canny.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'canny',
		connectorClass: 'lite',
	},
	{
		name: 'Canvas by Instructure',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/canvas_by_instructure/resources/canvas_by_instructure.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'canvas_by_instructure',
		connectorClass: 'lite',
	},
	{
		name: 'Care Quality Commission',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/care_quality_commission/resources/care_quality_commission.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'care_quality_commission',
		connectorClass: 'lite',
	},
	{
		name: 'Castor EDC',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/castor_edc/resources/castor_edc.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'castor_edc',
		connectorClass: 'lite',
	},
	{
		name: 'Centra',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/centra/resources/centra.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'centra',
		connectorClass: 'lite',
	},
	{
		name: 'Ceridian Dayforce',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ceridian_dayforce/resources/ceridian_dayforce.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'ceridian_dayforce',
		connectorClass: 'lite',
	},
	{
		name: 'Chameleon',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chameleon/resources/chameleon.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'chameleon',
		connectorClass: 'lite',
	},
	{
		name: 'ChargeDesk',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chargedesk/resources/chargedesk.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'chargedesk',
		connectorClass: 'lite',
	},
	{
		name: 'Chargebee Product Catalog 1.0',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_1/resources/chargebee_product_catalog_1.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'chargebee_product_catalog_1',
		connectorClass: 'lite',
	},
	{
		name: 'Chargebee Product Catalog 2.0',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_2/resources/chargebee_product_catalog_2.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'chargebee_product_catalog_2',
		connectorClass: 'lite',
	},
	{
		name: 'ChartMogul',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chartmogul/resources/chartmogul.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'chartmogul',
		connectorClass: 'lite',
	},
	{
		name: 'Checkout.com',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/checkout/resources/checkout.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'checkout',
		connectorClass: 'lite',
	},
	{
		name: 'Checkr',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/checkr/resources/checkr.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'checkr',
		connectorClass: 'lite',
	},
	{
		name: 'Chorus',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/chorusai/resources/chorusai.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'chorusai',
		connectorClass: 'lite',
	},
	{
		name: 'ChurnZero',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/churnzero/resources/churnzero.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'churnzero',
		connectorClass: 'lite',
	},
	{
		name: 'Churnkey',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/churnkey/resources/churnkey.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'churnkey',
		connectorClass: 'lite',
	},
	{
		name: 'Cimis',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cimis/resources/cimis.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'cimis',
		connectorClass: 'lite',
	},
	{
		name: 'Cin7',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cin7/resources/cin7.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'cin7',
		connectorClass: 'lite',
	},
	{
		name: 'Cin7 Core',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cin7core/resources/cin7core.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'cin7core',
		connectorClass: 'lite',
	},
	{
		name: 'CircleCI',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/circleci/resources/circleci.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'circleci',
		connectorClass: 'lite',
	},
	{
		name: 'Clari',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clari/resources/clari.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'clari',
		connectorClass: 'lite',
	},
	{
		name: 'Clazar',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clazar/resources/clazar.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'clazar',
		connectorClass: 'lite',
	},
	{
		name: 'ClickUp',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clickup/resources/clickup.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'clickup',
		connectorClass: 'lite',
	},
	{
		name: 'Clockify',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clockify/resources/clockify.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'clockify',
		connectorClass: 'lite',
	},
	{
		name: 'Clockodo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clockodo/resources/clockodo.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'clockodo',
		connectorClass: 'lite',
	},
	{
		name: 'Close',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/close/resources/close.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'close',
		connectorClass: 'lite',
	},
	{
		name: 'Cloudbeds',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cloudbeds/resources/cloudbeds.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'cloudbeds',
		connectorClass: 'lite',
	},
	{
		name: 'Clubspeed',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/clubspeed/resources/clubspeed.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'clubspeed',
		connectorClass: 'lite',
	},
	{
		name: 'Coassemble',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/coassemble/resources/coassemble.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'coassemble',
		connectorClass: 'lite',
	},
	{
		name: 'CockroachDB',
		icon: 'https://fivetran.com/integrations/cockroachdb/impl/resources/cockroachdb.svg',
		status: 'private_preview',
		type: 'Database',
		id: 'cockroachdb',
		connectorClass: 'standard',
	},
	{
		name: 'Codefresh',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/codefresh/resources/codefresh.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'codefresh',
		connectorClass: 'lite',
	},
	{
		name: 'Collibra',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/collibra/resources/collibra.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'collibra',
		connectorClass: 'lite',
	},
	{
		name: 'Column',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/column/resources/column.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'column',
		connectorClass: 'lite',
	},
	{
		name: 'Compliance Checkpoint',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/compliance_checkpoint/resources/compliance_checkpoint.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'compliance_checkpoint',
		connectorClass: 'lite',
	},
	{
		name: 'Concord',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/concord/resources/concord.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'concord',
		connectorClass: 'lite',
	},
	{
		name: 'Confluence',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/confluence/resources/confluence.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'confluence',
		connectorClass: 'lite',
	},
	{
		name: 'Confluent Cloud',
		icon: 'https://fivetran.com/integrations/kafka/resources/confluent_cloud.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'confluent_cloud',
		connectorClass: 'standard',
	},
	{
		name: 'Connector SDK',
		icon: 'https://fivetran.com/integrations/sdk_connectors/resources/connector_sdk.svg',
		status: 'beta',
		type: 'Function',
		id: 'connector_sdk',
		connectorClass: 'standard',
	},
	{
		name: 'Constant Contact',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/constant_contact/resources/constant_contact.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'constant_contact',
		connectorClass: 'lite',
	},
	{
		name: 'Contrast Security',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/contrast_security/resources/contrast_security.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'contrast_security',
		connectorClass: 'lite',
	},
	{
		name: 'Convex',
		icon: 'https://fivetran.com/integrations/sdk_connectors/resources/convex.svg',
		status: 'beta',
		type: 'Database',
		id: 'convex',
		connectorClass: 'partner-built',
	},
	{
		name: 'Copper',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/copper/resources/copper.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'copper',
		connectorClass: 'lite',
	},
	{
		name: 'Coupa',
		icon: 'https://fivetran.com/integrations/coupa/resources/coupa_logo.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'coupa',
		connectorClass: 'standard',
	},
	{
		name: 'Criteo',
		icon: 'https://fivetran.com/integrations/criteo/resources/criteo.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'criteo',
		connectorClass: 'standard',
	},
	{
		name: 'Criteo Retail Media',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/criteo_retail_media/resources/criteo_retail_media.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'criteo_retail_media',
		connectorClass: 'lite',
	},
	{
		name: 'Crossbeam',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/crossbeam/resources/crossbeam.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'crossbeam',
		connectorClass: 'lite',
	},
	{
		name: 'Culture Amp',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/culture_amp/resources/culture_amp.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'culture_amp',
		connectorClass: 'lite',
	},
	{
		name: 'Customer.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/customerio/resources/customerio.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'customerio',
		connectorClass: 'standard',
	},
	{
		name: 'Cvent',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/cvent/resources/cvent.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'cvent',
		connectorClass: 'lite',
	},
	{
		name: 'D2L Brightspace',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/d2l_brightspace/resources/d2l_brightspace.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'd2l_brightspace',
		connectorClass: 'lite',
	},
	{
		name: 'DCL Logistics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dcl_logistics/resources/dcl_logistics.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'dcl_logistics',
		connectorClass: 'lite',
	},
	{
		name: 'DSV',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dsv/resources/dsv.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'dsv',
		connectorClass: 'lite',
	},
	{
		name: 'Databricks',
		icon: 'https://fivetran.com/integrations/databricks/resources/databricks.png',
		status: 'beta',
		type: 'Database',
		id: 'databricks_db',
		connectorClass: 'standard',
	},
	{
		name: 'Datadog',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/datadog/resources/datadog.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'datadog',
		connectorClass: 'lite',
	},
	{
		name: 'Db2 for LUW',
		icon: 'https://fivetran.com/integrations/db2/resources/logos/db2.png',
		status: 'private_preview',
		type: 'Database',
		id: 'db2',
		connectorClass: 'standard',
	},
	{
		name: 'Delighted',
		icon: 'https://fivetran.com/integrations/delighted/resources/delighted.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'delighted',
		connectorClass: 'standard',
	},
	{
		name: 'Deposco',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/deposco/resources/deposco.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'deposco',
		connectorClass: 'lite',
	},
	{
		name: 'Deputy',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/deputy/resources/deputy.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'deputy',
		connectorClass: 'lite',
	},
	{
		name: 'Destini',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/destini/resources/destini.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'destini',
		connectorClass: 'lite',
	},
	{
		name: 'Dialpad',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dialpad/resources/dialpad.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'dialpad',
		connectorClass: 'lite',
	},
	{
		name: 'Dixa',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dixa/resources/dixa.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'dixa',
		connectorClass: 'lite',
	},
	{
		name: 'Docebo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/docebo/resources/docebo.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'docebo',
		connectorClass: 'lite',
	},
	{
		name: 'Donus',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/donus/resources/donus.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'donus',
		connectorClass: 'lite',
	},
	{
		name: 'DoorLoop',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/doorloop/resources/doorloop.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'doorloop',
		connectorClass: 'lite',
	},
	{
		name: 'Drata',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/drata/resources/drata.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'drata',
		connectorClass: 'lite',
	},
	{
		name: 'Drift',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/drift/resources/drift.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'drift',
		connectorClass: 'lite',
	},
	{
		name: 'Drip',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/drip/resources/drip.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'drip',
		connectorClass: 'lite',
	},
	{
		name: 'Dropbox',
		icon: 'https://fivetran.com/integrations/dropbox/resources/dropbox.svg',
		status: 'general_availability',
		type: 'File',
		id: 'dropbox',
		connectorClass: 'standard',
	},
	{
		name: 'Dropbox Sign',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dropbox_sign/resources/dropbox_sign_logo.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'dropbox_sign',
		connectorClass: 'lite',
	},
	{
		name: 'Duoplane',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/duoplane/resources/duoplane.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'duoplane',
		connectorClass: 'lite',
	},
	{
		name: 'EHR',
		icon: 'https://fivetran.com/integrations/clarity/resources/ehr.svg',
		status: 'private_preview',
		type: 'Database',
		id: 'ehr',
		connectorClass: 'standard',
	},
	{
		name: 'EZOfficeInventory',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ezofficeinventory/resources/ezofficeinventory.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'ezofficeinventory',
		connectorClass: 'lite',
	},
	{
		name: 'EasyPost',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/easypost/resources/easypost.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'easypost',
		connectorClass: 'lite',
	},
	{
		name: 'Elastic Cloud',
		icon: 'https://fivetran.com/integrations/elasticsearch/resources/logos/elastic_cloud.png',
		status: 'beta',
		type: 'Database',
		id: 'elastic_cloud',
		connectorClass: 'standard',
	},
	{
		name: 'Electronic Tenant Solutions',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/electronic_tenant_solutions/resources/electronic_tenant_solutions.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'electronic_tenant_solutions',
		connectorClass: 'lite',
	},
	{
		name: 'Eloqua',
		icon: 'https://fivetran.com/integrations/eloqua/resources/eloqua.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'eloqua',
		connectorClass: 'standard',
	},
	{
		name: 'Email',
		icon: 'https://fivetran.com/integrations/email/resources/email.svg',
		status: 'general_availability',
		type: 'File',
		id: 'email',
		connectorClass: 'standard',
	},
	{
		name: 'Employment Hero',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/employment_hero/resources/employment_hero.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'employment_hero',
		connectorClass: 'lite',
	},
	{
		name: 'Eventbrite',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/eventbrite/resources/eventbrite.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'eventbrite',
		connectorClass: 'lite',
	},
	{
		name: 'Eventsforce',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/eventsforce/resources/eventsforce.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'eventsforce',
		connectorClass: 'lite',
	},
	{
		name: 'Everflow',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/everflow/resources/everflow.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'everflow',
		connectorClass: 'lite',
	},
	{
		name: 'Everhour',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/everhour/resources/everhour.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'everhour',
		connectorClass: 'lite',
	},
	{
		name: 'Exact Online',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/exact_online/resources/exact_online.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'exact_online',
		connectorClass: 'lite',
	},
	{
		name: 'ExpenseIn',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/expensein/resources/expensein.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'expensein',
		connectorClass: 'lite',
	},
	{
		name: 'Expensify',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/expensify/resources/expensify.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'expensify',
		connectorClass: 'lite',
	},
	{
		name: 'FTP',
		icon: 'https://fivetran.com/integrations/ftp/resources/ftp.svg',
		status: 'general_availability',
		type: 'File',
		id: 'ftp',
		connectorClass: 'standard',
	},
	{
		name: 'Facebook Ads',
		icon: 'https://fivetran.com/integrations/facebook/resources/facebook.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'facebook_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Facebook Pages',
		icon: 'https://fivetran.com/integrations/facebook/resources/facebook.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'facebook_pages',
		connectorClass: 'standard',
	},
	{
		name: 'Factbird',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/factbird/resources/factbird.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'factbird',
		connectorClass: 'lite',
	},
	{
		name: 'Factorial',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/factorial/resources/factorial.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'factorial',
		connectorClass: 'lite',
	},
	{
		name: 'Fillout',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/fillout/resources/fillout.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'fillout',
		connectorClass: 'lite',
	},
	{
		name: 'FinancialForce',
		icon: 'https://fivetran.com/integrations/salesforce/resources/financial_force.svg',
		status: 'sunset',
		type: 'API',
		id: 'financial_force',
		connectorClass: 'standard',
	},
	{
		name: 'FinancialForce sandbox',
		icon: 'https://fivetran.com/integrations/salesforce/resources/financial_force.svg',
		status: 'private_preview',
		type: 'API',
		id: 'financial_force_sandbox',
		connectorClass: 'standard',
	},
	{
		name: 'FireHydrant',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/firehydrant/resources/firehydrant.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'firehydrant',
		connectorClass: 'lite',
	},
	{
		name: 'Firebase',
		icon: 'https://fivetran.com/integrations/firebase/metadata/resources/firebase.svg',
		status: 'beta',
		type: 'Database',
		id: 'firebase',
		connectorClass: 'standard',
	},
	{
		name: 'Fivetran Platform',
		icon: 'https://fivetran.com/integrations/fivetran_log/core/resources/fivetran.svg',
		status: 'general_availability',
		type: 'Free',
		id: 'fivetran_log',
		connectorClass: 'standard',
	},
	{
		name: 'Flexport',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/flexport/resources/flexport.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'flexport',
		connectorClass: 'lite',
	},
	{
		name: 'Float',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/float/resources/float.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'float',
		connectorClass: 'lite',
	},
	{
		name: 'Fone Dynamics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/fone_dynamics/resources/fone_dynamics.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'fone_dynamics',
		connectorClass: 'lite',
	},
	{
		name: 'Forj Community',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/forj_community/resources/forj_community.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'forj_community',
		connectorClass: 'lite',
	},
	{
		name: 'Formstack',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/formstack/resources/formstack.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'formstack',
		connectorClass: 'lite',
	},
	{
		name: 'Fountain',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/fountain/resources/fountain.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'fountain',
		connectorClass: 'lite',
	},
	{
		name: 'FourKites',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/fourkites/resources/fourkites.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'fourkites',
		connectorClass: 'lite',
	},
	{
		name: 'Freightview',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freightview/resources/freightview.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'freightview',
		connectorClass: 'lite',
	},
	{
		name: 'FreshBooks',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshbooks/resources/freshbooks.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'freshbooks',
		connectorClass: 'lite',
	},
	{
		name: 'FreshTeam',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshteam/resources/freshteam.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'freshteam',
		connectorClass: 'lite',
	},
	{
		name: 'Freshchat',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshchat/resources/freshchat.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'freshchat',
		connectorClass: 'lite',
	},
	{
		name: 'Freshdesk',
		icon: 'https://fivetran.com/integrations/freshdesk/resources/freshdesk.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'freshdesk',
		connectorClass: 'standard',
	},
	{
		name: 'Freshdesk Contact Center',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshdesk_contact_center/resources/freshdesk_contact_center.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'freshdesk_contact_center',
		connectorClass: 'lite',
	},
	{
		name: 'Freshsales',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshsales/resources/freshsales.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'freshsales',
		connectorClass: 'lite',
	},
	{
		name: 'Freshservice',
		icon: 'https://fivetran.com/integrations/freshservice/resources/freshservice.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'freshservice',
		connectorClass: 'standard',
	},
	{
		name: 'Freshsuccess',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/freshsuccess/resources/freshsuccess.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'freshsuccess',
		connectorClass: 'lite',
	},
	{
		name: 'Friendbuy',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/friendbuy/resources/friendbuy.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'friendbuy',
		connectorClass: 'lite',
	},
	{
		name: 'Front',
		icon: 'https://fivetran.com/integrations/front/resources/front.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'front',
		connectorClass: 'standard',
	},
	{
		name: 'FullStory',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/fullstory/resources/fullstory.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'fullstory',
		connectorClass: 'lite',
	},
	{
		name: 'G2',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/g2/resources/g2.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'g2',
		connectorClass: 'lite',
	},
	{
		name: 'GRIN',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/grin/resources/grin.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'grin',
		connectorClass: 'lite',
	},
	{
		name: 'Gainsight Customer Success',
		icon: 'https://fivetran.com/integrations/gainsight_customer_success/resources/gainsight_customer_success.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'gainsight_customer_success',
		connectorClass: 'standard',
	},
	{
		name: 'Gainsight Product Experience',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gainsight_product_experience/resources/gainsight_product_experience.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'gainsight_product_experience',
		connectorClass: 'lite',
	},
	{
		name: 'Gem',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gem/resources/gem.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'gem',
		connectorClass: 'lite',
	},
	{
		name: 'Genesys',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/genesys/resources/genesys.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'genesys',
		connectorClass: 'lite',
	},
	{
		name: 'GetFeedback',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/getfeedback/resources/getfeedback.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'getfeedback',
		connectorClass: 'lite',
	},
	{
		name: 'GitHub',
		icon: 'https://fivetran.com/integrations/github/resources/github.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'github',
		connectorClass: 'standard',
	},
	{
		name: 'GitLab',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gitlab/resources/gitlab.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'gitlab',
		connectorClass: 'lite',
	},
	{
		name: 'Gladly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gladly/resources/gladly.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'gladly',
		connectorClass: 'lite',
	},
	{
		name: 'GlobalMeet',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/globalmeet/resources/globalmeet.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'globalmeet',
		connectorClass: 'lite',
	},
	{
		name: 'Gmail',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gmail/resources/gmail.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'gmail',
		connectorClass: 'lite',
	},
	{
		name: 'GoCardless',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gocardless/resources/gocardless_logo.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'gocardless',
		connectorClass: 'lite',
	},
	{
		name: 'Gong',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gongio/resources/gongio.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'gongio',
		backwardsCompatibleName: 'Gong',
		connectorClass: 'standard',
	},
	{
		name: 'Google Ad Manager',
		icon: 'https://fivetran.com/integrations/google_ad_manager/resources/google_ad_manager.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'double_click_publishers',
		connectorClass: 'standard',
	},
	{
		name: 'Google Ads',
		icon: 'https://fivetran.com/integrations/google_ads/resources/google-ads.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Google Analytics 360',
		icon: 'https://fivetran.com/integrations/google_analytics_360/resources/google-analytics-360.svg',
		status: 'sunset',
		type: 'Marketing',
		id: 'google_analytics_360',
		connectorClass: 'standard',
	},
	{
		name: 'Google Analytics 4',
		icon: 'https://fivetran.com/integrations/google_analytics_4/resources/google_analytics_4.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_analytics_4',
		backwardsCompatibleName: 'GoogleAnalytics',
		connectorClass: 'standard',
	},
	{
		name: 'Google Analytics 4 Export',
		icon: 'https://fivetran.com/integrations/google_analytics_4_export/resources/google-analytics-4-export.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_analytics_4_export',
		connectorClass: 'standard',
	},
	{
		name: 'Google Business Profile',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/google_business_profile/resources/google_business_profile.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_business_profile',
		connectorClass: 'lite',
	},
	{
		name: 'Google Calendar',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/google_calendar/resources/google_calendar.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'google_calendar',
		connectorClass: 'lite',
	},
	{
		name: 'Google Campaign Manager 360',
		icon: 'https://fivetran.com/integrations/double_click_campaign_manager/resources/campaign_manager.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'double_click_campaign_manager',
		connectorClass: 'standard',
	},
	{
		name: 'Google Classroom',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/google_classroom/resources/google_classroom.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'google_classroom',
		connectorClass: 'lite',
	},
	{
		name: 'Google Cloud Functions',
		icon: 'https://fivetran.com/integrations/functions/google_cloud_function/resources/logo.svg',
		status: 'general_availability',
		type: 'Function',
		id: 'google_cloud_function',
		connectorClass: 'standard',
	},
	{
		name: 'Google Cloud SQL for MySQL',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/cloudsql.png',
		status: 'general_availability',
		type: 'Database',
		id: 'google_cloud_mysql',
		connectorClass: 'standard',
	},
	{
		name: 'Google Cloud SQL for PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/cloudsql.png',
		status: 'general_availability',
		type: 'Database',
		id: 'google_cloud_postgresql',
		connectorClass: 'standard',
	},
	{
		name: 'Google Cloud SQL for SQL Server',
		icon: 'https://fivetran.com/integrations/sql_server/resources/cloudsql.png',
		status: 'general_availability',
		type: 'Database',
		id: 'google_cloud_sqlserver',
		connectorClass: 'standard',
	},
	{
		name: 'Google Cloud Storage',
		icon: 'https://fivetran.com/integrations/gcs/resources/gcs.svg',
		status: 'general_availability',
		type: 'File',
		id: 'gcs',
		connectorClass: 'standard',
	},
	{
		name: 'Google Display & Video 360',
		icon: 'https://fivetran.com/integrations/google_display_and_video_360/resources/google-display-and-video-360.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_display_and_video_360',
		connectorClass: 'standard',
	},
	{
		name: 'Google Drive',
		icon: 'https://fivetran.com/integrations/gdrive/resources/google-drive-icon.svg',
		status: 'general_availability',
		type: 'File',
		id: 'google_drive',
		connectorClass: 'standard',
	},
	{
		name: 'Google Play',
		icon: 'https://fivetran.com/integrations/google_play/resources/google_play.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_play',
		backwardsCompatibleName: 'GooglePlay',
		connectorClass: 'standard',
	},
	{
		name: 'Google Search Ads 360',
		icon: 'https://fivetran.com/integrations/google_search_ads_360/resources/google_search_ads_360.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_search_ads_360',
		connectorClass: 'standard',
	},
	{
		name: 'Google Search Console',
		icon: 'https://fivetran.com/integrations/google_search_console/resources/google-search-console-logo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'google_search_console',
		connectorClass: 'standard',
	},
	{
		name: 'Google Sheets',
		icon: 'https://fivetran.com/integrations/gsheets/resources/gsheets.svg',
		status: 'general_availability',
		type: 'File',
		id: 'google_sheets',
		backwardsCompatibleName: 'GoogleSheets',
		connectorClass: 'standard',
	},
	{
		name: 'Google Tasks',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/google_tasks/resources/google_tasks.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'google_tasks',
		connectorClass: 'lite',
	},
	{
		name: 'Gorgias',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/gorgias/resources/gorgias.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'gorgias',
		connectorClass: 'lite',
	},
	{
		name: 'GreenPowerMonitor',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/green_power_monitor/resources/green_power_monitor.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'green_power_monitor',
		connectorClass: 'lite',
	},
	{
		name: 'Greenhouse',
		icon: 'https://fivetran.com/integrations/greenhouse/resources/greenhouse.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'greenhouse',
		connectorClass: 'standard',
	},
	{
		name: 'Grepsr',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/grepsr/resources/grepsr.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'grepsr',
		connectorClass: 'lite',
	},
	{
		name: 'Guru',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/guru/resources/guru.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'guru',
		connectorClass: 'lite',
	},
	{
		name: 'HVA SAP ECC on SQL Server',
		icon: 'https://fivetran.com/integrations/sql_server_hva/resources/sql_server_sap_ecc_hva.svg',
		status: 'beta',
		type: 'Database',
		id: 'sql_server_sap_ecc_hva',
		connectorClass: 'standard',
	},
	{
		name: 'HappyFox',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/happyfox/resources/happyfox.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'happyfox',
		connectorClass: 'lite',
	},
	{
		name: 'Harvest',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/harvest/resources/harvest.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'harvest',
		connectorClass: 'lite',
	},
	{
		name: 'Healthie',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/healthie/resources/healthie.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'healthie',
		connectorClass: 'lite',
	},
	{
		name: 'Heap',
		icon: 'https://fivetran.com/integrations/heap/resources/heap.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'heap',
		connectorClass: 'standard',
	},
	{
		name: 'Height',
		icon: 'https://fivetran.com/integrations/height/resources/height.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'height',
		connectorClass: 'standard',
	},
	{
		name: 'Help Scout',
		icon: 'https://fivetran.com/integrations/helpscout/resources/helpscout.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'helpscout',
		connectorClass: 'standard',
	},
	{
		name: 'Helpshift',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/helpshift/resources/helpshift.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'helpshift',
		connectorClass: 'lite',
	},
	{
		name: 'Heroku Kafka',
		icon: 'https://fivetran.com/integrations/kafka/resources/heroku_kafka.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'heroku_kafka',
		connectorClass: 'standard',
	},
	{
		name: 'Heroku PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/heroku.png',
		status: 'general_availability',
		type: 'Database',
		id: 'heroku_postgres',
		connectorClass: 'standard',
	},
	{
		name: 'HiBob',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/hibob/resources/hibob.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'hibob',
		connectorClass: 'lite',
	},
	{
		name: 'High Volume Agent Db2 for i',
		icon: 'https://fivetran.com/integrations/db2i_hva/resources/icon-db2i.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'db2i_hva',
		connectorClass: 'standard',
	},
	{
		name: 'High-Volume Agent SAP ECC on Db2 for i',
		icon: 'https://fivetran.com/integrations/db2i_hva/resources/icon-db2i-sap.svg',
		status: 'sunset',
		type: 'Database',
		id: 'db2i_sap_hva',
		connectorClass: 'standard',
	},
	{
		name: 'High-Volume Agent SQL Server',
		icon: 'https://fivetran.com/integrations/sql_server_hva/resources/sql_server.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'sql_server_hva',
		connectorClass: 'standard',
	},
	{
		name: 'Hopin',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/hopin/resources/hopin.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'hopin',
		connectorClass: 'lite',
	},
	{
		name: 'HubSpot',
		icon: 'https://fivetran.com/integrations/hubspot/resources/hubspot.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'hubspot',
		connectorClass: 'standard',
	},
	{
		name: 'Impact',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/impact/resources/impact.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'impact',
		connectorClass: 'standard',
	},
	{
		name: 'Incident.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/incidentio/resources/incidentio.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'incidentio',
		connectorClass: 'lite',
	},
	{
		name: 'Infobip',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/infobip/resources/infobip.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'infobip',
		connectorClass: 'lite',
	},
	{
		name: 'Insightly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/insightly/resources/insightly.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'insightly',
		connectorClass: 'lite',
	},
	{
		name: 'Instagram Business',
		icon: 'https://fivetran.com/integrations/instagram/resources/instagram.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'instagram_business',
		connectorClass: 'standard',
	},
	{
		name: 'Integral Ad Science',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/integral_ad_science/resources/integral_ad_science.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'integral_ad_science',
		connectorClass: 'lite',
	},
	{
		name: 'Integrate',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/integrate/resources/integrate.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'integrate',
		connectorClass: 'lite',
	},
	{
		name: 'Intercom',
		icon: 'https://fivetran.com/integrations/intercom/resources/intercom.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'intercom',
		connectorClass: 'standard',
	},
	{
		name: 'Invoiced',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/invoiced/resources/invoiced.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'invoiced',
		connectorClass: 'lite',
	},
	{
		name: 'Ironclad',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ironclad/resources/ironclad.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'ironclad',
		connectorClass: 'lite',
	},
	{
		name: 'Iterable',
		icon: 'https://fivetran.com/integrations/iterable/resources/iterable.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'iterable',
		connectorClass: 'standard',
	},
	{
		name: 'Ivanti',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ivanti/resources/ivanti.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'ivanti',
		connectorClass: 'lite',
	},
	{
		name: 'Jama Software',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/jama_software/resources/jama_software.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'jama_software',
		connectorClass: 'lite',
	},
	{
		name: 'Jamf',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/jamf/resources/jamf.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'jamf',
		connectorClass: 'lite',
	},
	{
		name: 'Jibble',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/jibble/resources/jibble.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'jibble',
		connectorClass: 'lite',
	},
	{
		name: 'Jira',
		icon: 'https://fivetran.com/integrations/jira/resources/jira.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'jira',
		connectorClass: 'standard',
	},
	{
		name: 'Jira Align',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/atlassian_jira_align/resources/atlassian_jira_align.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'atlassian_jira_align',
		connectorClass: 'lite',
	},
	{
		name: 'JobNimbus',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/jobnimbus/resources/jobnimbus.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'jobnimbus',
		connectorClass: 'lite',
	},
	{
		name: 'Jotform',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/jotform/resources/jotform.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'jotform',
		connectorClass: 'lite',
	},
	{
		name: 'JustCall',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/justcall/resources/justcall.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'justcall',
		connectorClass: 'lite',
	},
	{
		name: 'Kandji',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/kandji/resources/kandji.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'kandji',
		connectorClass: 'lite',
	},
	{
		name: 'Kantata',
		icon: 'https://fivetran.com/integrations/mavenlink/resources/kantata.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'mavenlink',
		connectorClass: 'standard',
	},
	{
		name: 'Katana',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/katana/resources/katana.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'katana',
		connectorClass: 'lite',
	},
	{
		name: 'Kevel',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/kevel/resources/kevel.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'kevel',
		connectorClass: 'lite',
	},
	{
		name: 'KeyPay',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/keypay/resources/keypay.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'keypay',
		connectorClass: 'lite',
	},
	{
		name: 'Khoros Care',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/khoros_care/resources/khoros_care.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'khoros_care',
		connectorClass: 'lite',
	},
	{
		name: 'Khoros Communities',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/khoros_communities/resources/khoros_communities.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'khoros_communities',
		connectorClass: 'lite',
	},
	{
		name: 'Kisi',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/kisi/resources/kisi.svg',
		status: 'general_availability',
		type: 'Security',
		id: 'kisi',
		connectorClass: 'lite',
	},
	{
		name: 'Kissmetrics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/kissmetrics/resources/kissmetrics.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'kissmetrics',
		connectorClass: 'lite',
	},
	{
		name: 'Klarna',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/klarna/resources/klarna.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'klarna',
		connectorClass: 'lite',
	},
	{
		name: 'Klaviyo',
		icon: 'https://fivetran.com/integrations/klaviyo/resources/klaviyo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'klaviyo',
		connectorClass: 'standard',
	},
	{
		name: 'Konnect Insights',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/konnect_insights/resources/konnect_insights.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'konnect_insights',
		connectorClass: 'lite',
	},
	{
		name: 'Kustomer',
		icon: 'https://fivetran.com/integrations/kustomer/resources/kustomer.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'kustomer',
		connectorClass: 'standard',
	},
	{
		name: 'LINE Ads',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/line_ads/resources/line_ads.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'line_ads',
		connectorClass: 'lite',
	},
	{
		name: 'Lattice',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/lattice/resources/lattice.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'lattice',
		connectorClass: 'lite',
	},
	{
		name: 'LaunchDarkly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/launchdarkly/resources/launchdarkly.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'launchdarkly',
		connectorClass: 'lite',
	},
	{
		name: 'LearnUpon',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/learnupon/resources/learnupon.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'learnupon',
		connectorClass: 'lite',
	},
	{
		name: 'Lemlist',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/lemlist/resources/lemlist.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'lemlist',
		connectorClass: 'lite',
	},
	{
		name: 'Lessonly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/lessonly/resources/lessonly.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'lessonly',
		connectorClass: 'lite',
	},
	{
		name: 'Lever',
		icon: 'https://fivetran.com/integrations/lever/resources/lever.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'lever',
		connectorClass: 'standard',
	},
	{
		name: 'Liftoff',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/liftoff/resources/liftoff.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'liftoff',
		connectorClass: 'lite',
	},
	{
		name: 'Lightspeed Retail',
		icon: 'https://fivetran.com/integrations/lightspeed_retail/resources/lightspeed.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'light_speed_retail',
		connectorClass: 'standard',
	},
	{
		name: 'Linear',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/linear/resources/linear.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'linear',
		connectorClass: 'lite',
	},
	{
		name: 'LinkSquares',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/linksquares/resources/linksquares.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'linksquares',
		connectorClass: 'lite',
	},
	{
		name: 'LinkedIn Ad Analytics',
		icon: 'https://fivetran.com/integrations/linkedin/metadata/resources/linkedin_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'linkedin_ads',
		backwardsCompatibleName: 'LinkedIn',
		connectorClass: 'standard',
	},
	{
		name: 'LinkedIn Company Pages',
		icon: 'https://fivetran.com/integrations/linkedin/metadata/resources/linkedin_company_pages.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'linkedin_company_pages',
		connectorClass: 'standard',
	},
	{
		name: 'LiveChat Partner',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/livechat_partner/resources/livechat_partner.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'livechat_partner',
		connectorClass: 'lite',
	},
	{
		name: 'Lob',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/lob/resources/lob.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'lob',
		connectorClass: 'lite',
	},
	{
		name: 'Looker',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/looker_source/resources/looker_source.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'looker_source',
		connectorClass: 'lite',
	},
	{
		name: 'Loop',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/loop/resources/loop.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'loop',
		connectorClass: 'lite',
	},
	{
		name: 'Loopio',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/loopio/resources/loopio.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'loopio',
		connectorClass: 'lite',
	},
	{
		name: 'Lucca',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/lucca/resources/lucca.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'lucca',
		connectorClass: 'lite',
	},
	{
		name: 'Luma',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/luma/resources/luma.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'luma',
		connectorClass: 'lite',
	},
	{
		name: 'MNTN',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mountain/resources/mountain.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mountain',
		connectorClass: 'lite',
	},
	{
		name: 'Magento MySQL',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/magento.png',
		status: 'general_availability',
		type: 'Database',
		id: 'magento_mysql',
		connectorClass: 'standard',
	},
	{
		name: 'Magento MySQL RDS',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/magento.png',
		status: 'general_availability',
		type: 'Database',
		id: 'magento_mysql_rds',
		connectorClass: 'standard',
	},
	{
		name: 'Mailchimp',
		icon: 'https://fivetran.com/integrations/mailchimp/resources/mailchimp.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mailchimp',
		connectorClass: 'standard',
	},
	{
		name: 'Mailgun',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mailgun/resources/mailgun.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mailgun',
		connectorClass: 'lite',
	},
	{
		name: 'Mailjet',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mailjet/resources/mailjet.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'mailjet',
		connectorClass: 'lite',
	},
	{
		name: 'Malomo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/malomo/resources/malomo.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'malomo',
		connectorClass: 'lite',
	},
	{
		name: 'Mambu',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mambu/resources/mambu.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'mambu',
		connectorClass: 'lite',
	},
	{
		name: 'Mandrill',
		icon: 'https://fivetran.com/integrations/mandrill/resources/mandrill.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mandrill',
		connectorClass: 'standard',
	},
	{
		name: 'MariaDB',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/maria.png',
		status: 'general_availability',
		type: 'Database',
		id: 'maria',
		connectorClass: 'standard',
	},
	{
		name: 'Marin Software',
		icon: 'https://fivetran.com/integrations/marin/resources/logo.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'marin',
		connectorClass: 'standard',
	},
	{
		name: 'Marketo',
		icon: 'https://fivetran.com/integrations/marketo/resources/marketo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'marketo',
		connectorClass: 'standard',
	},
	{
		name: 'Matomo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/matomo/resources/matomo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'matomo',
		connectorClass: 'lite',
	},
	{
		name: 'Maxio Chargify',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/maxio_chargify/resources/maxio_chargify.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'maxio_chargify',
		connectorClass: 'lite',
	},
	{
		name: 'Maxio SaaSOptics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/maxio_saasoptics/resources/maxio_saasoptics.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'maxio_saasoptics',
		connectorClass: 'lite',
	},
	{
		name: 'Medallia',
		icon: 'https://fivetran.com/integrations/medallia/resources/medallia.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'medallia',
		connectorClass: 'standard',
	},
	{
		name: 'Medallia Agile Research',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/medallia_agile_research/resources/medallia_agile_research.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'medallia_agile_research',
		connectorClass: 'lite',
	},
	{
		name: 'Megaphone',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/megaphone/resources/megaphone.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'megaphone',
		connectorClass: 'lite',
	},
	{
		name: 'Mention',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mention/resources/mention.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mention',
		connectorClass: 'lite',
	},
	{
		name: 'Microsoft Advertising',
		icon: 'https://fivetran.com/integrations/bingads/resources/bingads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'bingads',
		connectorClass: 'standard',
	},
	{
		name: 'Microsoft Dynamics 365 Business Central',
		icon: 'https://fivetran.com/integrations/business_central/resources/business_central.svg',
		status: 'beta',
		type: 'Finance',
		id: 'business_central',
		connectorClass: 'standard',
	},
	{
		name: 'Microsoft Dynamics 365 CRM',
		icon: 'https://fivetran.com/integrations/dynamics365/resources/logo.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'dynamics_365',
		connectorClass: 'standard',
	},
	{
		name: 'Microsoft Dynamics 365 Finance',
		icon: 'https://fivetran.com/integrations/dynamics_365_fo/resources/logo.svg',
		status: 'sunset',
		type: 'Sales',
		id: 'microsoft_dynamics_365_fo',
		connectorClass: 'standard',
	},
	{
		name: 'Microsoft Dynamics 365 Finance and Operations',
		icon: 'https://fivetran.com/integrations/azure_synapse_link/resources/logo.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'microsoft_dynamics_365_fno',
		connectorClass: 'standard',
	},
	{
		name: 'Microsoft Entra ID',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/microsoft_entra_id/resources/microsoft_entra_id.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'microsoft_entra_id',
		connectorClass: 'lite',
	},
	{
		name: 'Microsoft Lists',
		icon: 'https://fivetran.com/integrations/microsoft_lists/resources/microsoft-lists-icon.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'microsoft_lists',
		connectorClass: 'standard',
	},
	{
		name: 'Missive',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/missive/resources/missive.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'missive',
		connectorClass: 'lite',
	},
	{
		name: 'Mixmax',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mixmax/resources/mixmax.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mixmax',
		connectorClass: 'lite',
	},
	{
		name: 'Mixpanel',
		icon: 'https://fivetran.com/integrations/mixpanel/resources/mixpanel.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'mixpanel',
		connectorClass: 'standard',
	},
	{
		name: 'Mode',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mode/resources/mode.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'mode',
		connectorClass: 'lite',
	},
	{
		name: 'Moloco',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/moloco/resources/moloco.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'moloco',
		connectorClass: 'lite',
	},
	{
		name: 'Monday.com',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/monday/resources/monday.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'monday',
		connectorClass: 'lite',
	},
	{
		name: 'MongoDB',
		icon: 'https://fivetran.com/integrations/mongo/resources/mongo.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'mongo',
		connectorClass: 'standard',
	},
	{
		name: 'MongoDB Sharded',
		icon: 'https://fivetran.com/integrations/mongo/resources/mongo.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'mongo_sharded',
		connectorClass: 'standard',
	},
	{
		name: 'Mux',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/mux/resources/mux.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'mux',
		connectorClass: 'lite',
	},
	{
		name: 'MySQL',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/mysql.png',
		status: 'general_availability',
		type: 'Database',
		id: 'mysql',
		connectorClass: 'standard',
	},
	{
		name: 'MySQL RDS',
		icon: 'https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_rds.png',
		status: 'general_availability',
		type: 'Database',
		id: 'mysql_rds',
		connectorClass: 'standard',
	},
	{
		name: 'Namely',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/namely/resources/namely.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'namely',
		connectorClass: 'lite',
	},
	{
		name: 'Navan',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/navan/resources/navan.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'navan',
		connectorClass: 'lite',
	},
	{
		name: 'NetSuite SuiteAnalytics',
		icon: 'https://fivetran.com/integrations/netsuite/resources/netsuite.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'netsuite_suiteanalytics',
		connectorClass: 'standard',
	},
	{
		name: 'Nice',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/nice/resources/nice.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'nice',
		connectorClass: 'lite',
	},
	{
		name: 'Notion',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/notion/resources/notion.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'notion',
		connectorClass: 'lite',
	},
	{
		name: 'Nylas',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/nylas/resources/nylas.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'nylas',
		connectorClass: 'lite',
	},
	{
		name: 'ON24',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/on24/resources/on24.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'on24',
		connectorClass: 'lite',
	},
	{
		name: 'Okendo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/okendo/resources/okendo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'okendo',
		connectorClass: 'lite',
	},
	{
		name: 'Okta',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/okta/resources/okta.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'okta',
		connectorClass: 'standard',
	},
	{
		name: 'Ometria',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ometria/resources/ometria.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ometria',
		connectorClass: 'lite',
	},
	{
		name: 'OnceHub',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/oncehub/resources/oncehub.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'oncehub',
		connectorClass: 'lite',
	},
	{
		name: 'OneDrive',
		icon: 'https://fivetran.com/integrations/azure_consumer_file/resources/one_drive.svg',
		status: 'general_availability',
		type: 'File',
		id: 'one_drive',
		connectorClass: 'standard',
	},
	{
		name: 'Open Data DC',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/open_data_dc/resources/open_data_dc.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'open_data_dc',
		connectorClass: 'lite',
	},
	{
		name: 'Open Distro',
		icon: 'https://fivetran.com/integrations/elasticsearch/resources/logos/opendistro.png',
		status: 'beta',
		type: 'Database',
		id: 'opendistro',
		connectorClass: 'standard',
	},
	{
		name: 'Open Exchange Rates',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/open_exchange_rates/resources/open_exchange_rates.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'open_exchange_rates',
		connectorClass: 'lite',
	},
	{
		name: 'Opensearch',
		icon: 'https://fivetran.com/integrations/elasticsearch/resources/logos/opensearch.png',
		status: 'beta',
		type: 'Database',
		id: 'opensearch',
		connectorClass: 'standard',
	},
	{
		name: 'Opsgenie',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/atlassian_ops_genie/resources/atlassian_ops_genie.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'atlassian_ops_genie',
		connectorClass: 'lite',
	},
	{
		name: 'Optimizely',
		icon: 'https://fivetran.com/integrations/optimizely/resources/optimizely.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'optimizely',
		connectorClass: 'standard',
	},
	{
		name: 'Oracle Business Intelligence Publisher',
		icon: 'https://fivetran.com/integrations/oracle_business_intelligence_publisher/resources/oracle.svg',
		status: 'beta',
		type: 'Sales',
		id: 'oracle_business_intelligence_publisher',
		connectorClass: 'standard',
	},
	{
		name: 'Oracle Fusion Cloud Applications - CRM',
		icon: 'https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'oracle_fusion_cloud_apps_crm',
		connectorClass: 'standard',
	},
	{
		name: 'Oracle Fusion Cloud Applications - FSCM(ERP & SCM)',
		icon: 'https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'oracle_fusion_cloud_apps_fscm',
		connectorClass: 'standard',
	},
	{
		name: 'Oracle Fusion Cloud Applications - HCM',
		icon: 'https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'oracle_fusion_cloud_apps_hcm',
		connectorClass: 'standard',
	},
	{
		name: 'Oracle Moat Analytics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/oracle_moat_analytics/resources/oracle_moat_analytics.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'oracle_moat_analytics',
		connectorClass: 'lite',
	},
	{
		name: 'Orbit',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/orbit/resources/orbit.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'orbit',
		connectorClass: 'lite',
	},
	{
		name: 'Ordway',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ordway/resources/ordway.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'ordway',
		connectorClass: 'lite',
	},
	{
		name: 'Ortto',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ortto/resources/ortto.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ortto',
		connectorClass: 'lite',
	},
	{
		name: 'Outbrain',
		icon: 'https://fivetran.com/integrations/outbrain/resources/outbrain_logo.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'outbrain',
		connectorClass: 'standard',
	},
	{
		name: 'Outreach',
		icon: 'https://fivetran.com/integrations/outreach/resources/outreach.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'outreach',
		connectorClass: 'standard',
	},
	{
		name: 'Packiyo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/packiyo/resources/packiyo.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'packiyo',
		connectorClass: 'lite',
	},
	{
		name: 'PagerDuty',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pagerduty/resources/pagerduty.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'pagerduty',
		connectorClass: 'lite',
	},
	{
		name: 'PandaDoc',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pandadoc/resources/pandadoc_icon.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'pandadoc',
		connectorClass: 'lite',
	},
	{
		name: 'Papershift',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/papershift/resources/papershift.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'papershift',
		connectorClass: 'lite',
	},
	{
		name: 'Pardot',
		icon: 'https://fivetran.com/integrations/pardot/resources/pardot.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'pardot',
		connectorClass: 'standard',
	},
	{
		name: 'PartnerStack Vendor',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/partnerstack_vendor/resources/partnerstack_vendor.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'partnerstack_vendor',
		connectorClass: 'lite',
	},
	{
		name: 'Partnerize',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/partnerize/resources/partnerize.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'partnerize',
		connectorClass: 'lite',
	},
	{
		name: 'Paychex',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/paychex/resources/paychex.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'paychex',
		connectorClass: 'lite',
	},
	{
		name: 'Paypal',
		icon: 'https://fivetran.com/integrations/paypal/resources/paypal.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'paypal',
		connectorClass: 'standard',
	},
	{
		name: 'Paypal Sandbox',
		icon: 'https://fivetran.com/integrations/paypal/resources/paypal.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'paypal_sandbox',
		connectorClass: 'standard',
	},
	{
		name: 'Pendo',
		icon: 'https://fivetran.com/integrations/pendo/resources/pendo.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'pendo',
		connectorClass: 'standard',
	},
	{
		name: 'Persona',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/persona/resources/persona.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'persona',
		connectorClass: 'lite',
	},
	{
		name: 'Personio',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/personio/resources/personio.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'personio',
		connectorClass: 'lite',
	},
	{
		name: 'Phoenix Ads',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/phoenix_ads/resources/phoenix_ads.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'phoenix_ads',
		connectorClass: 'lite',
	},
	{
		name: 'Picqer',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/picqer/resources/picqer.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'picqer',
		connectorClass: 'lite',
	},
	{
		name: 'Pigment',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pigment/resources/pigment.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'pigment',
		connectorClass: 'lite',
	},
	{
		name: 'Pingdom',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pingdom/resources/pingdom.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'pingdom',
		connectorClass: 'lite',
	},
	{
		name: 'Pinpoint',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pinpoint/resources/pinpoint.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'pinpoint',
		connectorClass: 'lite',
	},
	{
		name: 'Pinterest Ads',
		icon: 'https://fivetran.com/integrations/pinterest/resources/pinterest-ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'pinterest_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Pinterest Organic',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pinterest_organic/resources/pinterest_organic.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'pinterest_organic',
		connectorClass: 'lite',
	},
	{
		name: 'Pipe17',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pipe17/resources/pipe17.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'pipe17',
		connectorClass: 'lite',
	},
	{
		name: 'Pipedrive',
		icon: 'https://fivetran.com/integrations/pipedrive/resources/pipedrive.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'pipedrive',
		connectorClass: 'standard',
	},
	{
		name: 'Pivotal Tracker',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/pivotal_tracker/resources/pivotal_tracker.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'pivotal_tracker',
		connectorClass: 'lite',
	},
	{
		name: 'Piwik PRO',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/piwik_pro/resources/piwik_pro.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'piwik_pro',
		connectorClass: 'lite',
	},
	{
		name: 'Placer.ai',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/placerai/resources/placerai.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'placerai',
		connectorClass: 'lite',
	},
	{
		name: 'PlanetScale',
		icon: 'https://fivetran.com/integrations/sdk_connectors/resources/planetscale.svg',
		status: 'beta',
		type: 'Database',
		id: 'planetscale',
		connectorClass: 'partner-built',
	},
	{
		name: 'Planful',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/planful/resources/planful.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'planful',
		connectorClass: 'lite',
	},
	{
		name: 'Planhat',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/planhat/resources/planhat.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'planhat',
		connectorClass: 'lite',
	},
	{
		name: 'Playvox',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/playvox/resources/playvox.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'playvox',
		connectorClass: 'lite',
	},
	{
		name: 'Playvox Workforce Management',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/playvox_workforce_management/resources/playvox_workforce_management.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'playvox_workforce_management',
		connectorClass: 'lite',
	},
	{
		name: 'Podio',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/podio/resources/podio.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'podio',
		connectorClass: 'lite',
	},
	{
		name: 'PostHog',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/posthog/resources/posthog.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'posthog',
		connectorClass: 'lite',
	},
	{
		name: 'PostgreSQL',
		icon: 'https://fivetran.com/integrations/postgres/connector/resources/postgres.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'postgres',
		connectorClass: 'standard',
	},
	{
		name: 'Postmark',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/postmark/resources/postmark.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'postmark',
		connectorClass: 'lite',
	},
	{
		name: 'PowerReviews Enterprise',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/power_reviews_enterprise/resources/power_reviews_enterprise.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'power_reviews_enterprise',
		connectorClass: 'lite',
	},
	{
		name: 'Prisma Cloud',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/prisma_cloud/resources/prisma_cloud.svg',
		status: 'private_preview',
		type: 'Security',
		id: 'prisma_cloud',
		connectorClass: 'lite',
	},
	{
		name: 'Prive',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/prive/resources/prive.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'prive',
		connectorClass: 'lite',
	},
	{
		name: 'Procore',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/procore/resources/procore.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'procore',
		connectorClass: 'lite',
	},
	{
		name: 'Productboard',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/productboard/resources/productboard.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'productboard',
		connectorClass: 'lite',
	},
	{
		name: 'Productive',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/productive/resources/productive.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'productive',
		connectorClass: 'lite',
	},
	{
		name: 'Proofpoint Security Awareness',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/proofpoint_security_awareness/resources/proofpoint_security_awareness.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'proofpoint_security_awareness',
		connectorClass: 'lite',
	},
	{
		name: 'Qualaroo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/qualaroo/resources/qualaroo.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'qualaroo',
		connectorClass: 'lite',
	},
	{
		name: 'Qualtrics',
		icon: 'https://fivetran.com/integrations/qualtrics/resources/qualtrics.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'qualtrics',
		connectorClass: 'standard',
	},
	{
		name: 'QuickBooks',
		icon: 'https://fivetran.com/integrations/quickbooks/resources/quickbooks.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'quickbooks',
		connectorClass: 'standard',
	},
	{
		name: 'Quora Ads',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/quora_ads/resources/quora_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'quora_ads',
		connectorClass: 'lite',
	},
	{
		name: 'Quorum',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/quorum/resources/quorum.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'quorum',
		connectorClass: 'lite',
	},
	{
		name: 'REVIEWS.ai',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/reviewsai/resources/reviewsai.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'reviewsai',
		connectorClass: 'lite',
	},
	{
		name: 'REVIEWS.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/reviewsio/resources/reviewsio.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'reviewsio',
		connectorClass: 'lite',
	},
	{
		name: 'RTB House',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rtb_house/resources/rtb_house.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'rtb_house',
		connectorClass: 'lite',
	},
	{
		name: 'Rakuten Advertising',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rakutenadvertising/resources/rakutenadvertising.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'rakutenadvertising',
		connectorClass: 'lite',
	},
	{
		name: 'Ramp',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ramp/resources/ramp.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'ramp',
		connectorClass: 'lite',
	},
	{
		name: 'Rarible',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rarible/resources/rarible.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'rarible',
		connectorClass: 'lite',
	},
	{
		name: 'ReBound Returns',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rebound_returns/resources/rebound_returns.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'rebound_returns',
		connectorClass: 'lite',
	},
	{
		name: 'Recharge',
		icon: 'https://fivetran.com/integrations/recharge/resources/recharge.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'recharge',
		connectorClass: 'standard',
	},
	{
		name: 'Recurly',
		icon: 'https://fivetran.com/integrations/recurly/resources/recurly.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'recurly',
		connectorClass: 'standard',
	},
	{
		name: 'Reddit Ads',
		icon: 'https://fivetran.com/integrations/reddit/resources/reddit.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'reddit_ads',
		connectorClass: 'standard',
	},
	{
		name: 'ReferralHero',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/referralhero/resources/referralhero.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'referralhero',
		connectorClass: 'lite',
	},
	{
		name: 'Reltio',
		icon: 'https://fivetran.com/integrations/reltio/resources/reltio.svg',
		status: 'beta',
		type: 'Engineering',
		id: 'reltio',
		connectorClass: 'standard',
	},
	{
		name: 'Reply.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/replyio/resources/replyio.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'replyio',
		connectorClass: 'lite',
	},
	{
		name: 'Resource Management by Smartsheet',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/resource_management_by_smartsheet/resources/resource_management_by_smartsheet.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'resource_management_by_smartsheet',
		connectorClass: 'lite',
	},
	{
		name: 'RetailNext',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/retailnext/resources/retailnext.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'retailnext',
		connectorClass: 'lite',
	},
	{
		name: 'Retently',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/retently/resources/retently.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'retently',
		connectorClass: 'lite',
	},
	{
		name: 'RevOps',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/revops/resources/revops.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'revops',
		connectorClass: 'lite',
	},
	{
		name: 'RevX',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/revx/resources/revx.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'revx',
		connectorClass: 'lite',
	},
	{
		name: 'Revel Systems',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/revel/resources/revel.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'revel',
		connectorClass: 'lite',
	},
	{
		name: 'RevenueCat',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/revenuecat/resources/revenuecat.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'revenuecat',
		connectorClass: 'lite',
	},
	{
		name: 'Ricochet360',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ricochet360/resources/ricochet360.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'ricochet360',
		connectorClass: 'lite',
	},
	{
		name: 'RingCentral',
		icon: 'https://fivetran.com/integrations/ringcentral/resources/ringcentral.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'ringcentral',
		connectorClass: 'standard',
	},
	{
		name: 'Ringover',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ringover/resources/ringover.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'ringover',
		connectorClass: 'lite',
	},
	{
		name: 'Rippling',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rippling/resources/rippling.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'rippling',
		connectorClass: 'lite',
	},
	{
		name: 'Rithum',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rithum/resources/rithum.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'rithum',
		connectorClass: 'lite',
	},
	{
		name: 'Rocketlane',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rocketlane/resources/rocketlane.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'rocketlane',
		connectorClass: 'lite',
	},
	{
		name: 'Rokt',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rokt/resources/rokt.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'rokt',
		connectorClass: 'lite',
	},
	{
		name: 'Rollbar',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rollbar/resources/rollbar.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'rollbar',
		connectorClass: 'lite',
	},
	{
		name: 'Rootly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rootly/resources/rootly.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'rootly',
		connectorClass: 'lite',
	},
	{
		name: 'Ruddr',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ruddr/resources/ruddr.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'ruddr',
		connectorClass: 'lite',
	},
	{
		name: 'Rundeck',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/rundeck/resources/rundeck.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'rundeck',
		connectorClass: 'lite',
	},
	{
		name: 'SAP Business ByDesign',
		icon: 'https://fivetran.com/integrations/sap_business_bydesign/resources/logo.png',
		status: 'general_availability',
		type: 'Finance',
		id: 'sap_business_by_design',
		connectorClass: 'standard',
	},
	{
		name: 'SAP Concur',
		icon: 'https://fivetran.com/integrations/sap_concur/resources/sap_concur_logo.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'concur',
		connectorClass: 'standard',
	},
	{
		name: 'SAP SuccessFactors',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sap_success_factors/resources/sap_success_factors.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'sap_success_factors',
		connectorClass: 'standard',
	},
	{
		name: 'SCORM',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/scorm/resources/scorm.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'scorm',
		connectorClass: 'lite',
	},
	{
		name: 'SFTP',
		icon: 'https://fivetran.com/integrations/sftp/resources/sftp.svg',
		status: 'general_availability',
		type: 'File',
		id: 'sftp',
		connectorClass: 'standard',
	},
	{
		name: 'SISTRIX',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sistrix/resources/sistrix.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'sistrix',
		connectorClass: 'lite',
	},
	{
		name: 'SQL Server',
		icon: 'https://fivetran.com/integrations/sql_server/resources/sql_server.svg',
		status: 'general_availability',
		type: 'Database',
		id: 'sql_server',
		connectorClass: 'standard',
	},
	{
		name: 'SafeBase',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/safebase/resources/safebase.svg',
		status: 'private_preview',
		type: 'Security',
		id: 'safebase',
		connectorClass: 'lite',
	},
	{
		name: 'SafetyCulture',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/safetyculture/resources/safetyculture.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'safetyculture',
		connectorClass: 'lite',
	},
	{
		name: 'Sage HR',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sage_hr/resources/sage_hr.svg',
		status: 'private_preview',
		type: 'HumanResources',
		id: 'sage_hr',
		connectorClass: 'lite',
	},
	{
		name: 'Sage Intacct',
		icon: 'https://fivetran.com/integrations/sage_intacct/resources/sage_intacct_logo.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'sage_intacct',
		connectorClass: 'standard',
	},
	{
		name: 'Sailthru',
		icon: 'https://fivetran.com/integrations/sailthru/resources/sailthru.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'sailthru',
		connectorClass: 'standard',
	},
	{
		name: 'Salesforce',
		icon: 'https://fivetran.com/integrations/salesforce/resources/salesforce.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'salesforce',
		connectorClass: 'standard',
	},
	{
		name: 'Salesforce Commerce Cloud',
		icon: 'https://fivetran.com/integrations/salesforce_commerce_cloud/resources/salesforce_commerce_cloud.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'salesforce_commerce_cloud',
		connectorClass: 'standard',
	},
	{
		name: 'Salesforce Marketing Cloud',
		icon: 'https://fivetran.com/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'salesforce_marketing_cloud',
		connectorClass: 'standard',
	},
	{
		name: 'Salesforce sandbox',
		icon: 'https://fivetran.com/integrations/salesforce/resources/salesforce_sandbox.png',
		status: 'general_availability',
		type: 'Sales',
		id: 'salesforce_sandbox',
		connectorClass: 'standard',
	},
	{
		name: 'Salesloft',
		icon: 'https://fivetran.com/integrations/salesloft/resources/salesloft.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'salesloft',
		connectorClass: 'standard',
	},
	{
		name: 'Salsify',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/salsify/resources/salsify.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'salsify',
		connectorClass: 'lite',
	},
	{
		name: 'Samsara',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/samsara/resources/samsara.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'samsara',
		connectorClass: 'lite',
	},
	{
		name: 'Sana',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sana/resources/sana.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'sana',
		connectorClass: 'lite',
	},
	{
		name: 'Security Journey',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/security_journey/resources/security_journey.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'security_journey',
		connectorClass: 'lite',
	},
	{
		name: 'Segment',
		icon: 'https://fivetran.com/integrations/segment/resources/segment.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'segment',
		connectorClass: 'standard',
	},
	{
		name: 'Self Hosted Elasticsearch',
		icon: 'https://fivetran.com/integrations/elasticsearch/resources/logos/elastic.png',
		status: 'beta',
		type: 'Database',
		id: 'es_self_hosted',
		connectorClass: 'standard',
	},
	{
		name: 'SendGrid',
		icon: 'https://fivetran.com/integrations/sendgrid/resources/sendgrid.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'sendgrid',
		connectorClass: 'standard',
	},
	{
		name: 'Sensor Tower',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sensor_tower/resources/sensor_tower.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'sensor_tower',
		connectorClass: 'lite',
	},
	{
		name: 'ServiceNow',
		icon: 'https://fivetran.com/integrations/service_now/resources/logo.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'servicenow',
		connectorClass: 'standard',
	},
	{
		name: 'ServiceTitan',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/servicetitan/resources/servicetitan.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'servicetitan',
		connectorClass: 'lite',
	},
	{
		name: 'SharePoint',
		icon: 'https://fivetran.com/integrations/azure_consumer_file/resources/share_point.svg',
		status: 'general_availability',
		type: 'File',
		id: 'share_point',
		connectorClass: 'standard',
	},
	{
		name: 'Sharetribe',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sharetribe/resources/sharetribe.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'sharetribe',
		connectorClass: 'lite',
	},
	{
		name: 'ShipHero',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shiphero/resources/shiphero.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'shiphero',
		connectorClass: 'lite',
	},
	{
		name: 'ShipNetwork',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shipnetwork/resources/shipnetwork.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'shipnetwork',
		connectorClass: 'lite',
	},
	{
		name: 'ShipStation',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shipstation/resources/shipstation.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'shipstation',
		connectorClass: 'lite',
	},
	{
		name: 'Shopify',
		icon: 'https://fivetran.com/integrations/shopify/resources/shopify.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'shopify',
		connectorClass: 'standard',
	},
	{
		name: 'Shopware',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shopware/resources/shopware.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'shopware',
		connectorClass: 'lite',
	},
	{
		name: 'Short.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shortio/resources/shortio.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'shortio',
		connectorClass: 'lite',
	},
	{
		name: 'Shortcut',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/shortcut/resources/shortcut.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'shortcut',
		connectorClass: 'lite',
	},
	{
		name: 'Sigma Computing',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sigma_computing_source/resources/sigma_computing_source.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'sigma_computing_source',
		connectorClass: 'lite',
	},
	{
		name: 'Simplecast',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/simplecast/resources/simplecast.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'simplecast',
		connectorClass: 'lite',
	},
	{
		name: 'Simplesat',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/simplesat/resources/simplesat.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'simplesat',
		connectorClass: 'lite',
	},
	{
		name: 'Skilljar',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/skilljar/resources/skilljar.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'skilljar',
		connectorClass: 'lite',
	},
	{
		name: 'SkillsTX',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/skillstx/resources/skillstx.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'skillstx',
		connectorClass: 'lite',
	},
	{
		name: 'SkuVault',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/skuvault/resources/skuvault.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'skuvault',
		connectorClass: 'lite',
	},
	{
		name: 'Slab',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/slab/resources/slab.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'slab',
		connectorClass: 'lite',
	},
	{
		name: 'Slack',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/slack/resources/slack.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'slack',
		connectorClass: 'standard',
	},
	{
		name: 'Smadex',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/smadex/resources/smadex.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'smadex',
		connectorClass: 'lite',
	},
	{
		name: 'SmartHR',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/smarthr/resources/smarthr.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'smarthr',
		connectorClass: 'lite',
	},
	{
		name: 'SmartRecruiters',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/smartrecruiters/resources/smartrecruiters.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'smartrecruiters',
		connectorClass: 'lite',
	},
	{
		name: 'Smartwaiver',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/smartwaiver/resources/smartwaiver.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'smartwaiver',
		connectorClass: 'lite',
	},
	{
		name: 'Snapchat Ads',
		icon: 'https://fivetran.com/integrations/snapchat_ads/resources/snapchat.png',
		status: 'general_availability',
		type: 'Marketing',
		id: 'snapchat_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Snowflake',
		icon: 'https://fivetran.com/integrations/snowflake/resources/snowflake.png',
		status: 'general_availability',
		type: 'Database',
		id: 'snowflake_db',
		connectorClass: 'standard',
	},
	{
		name: 'Snowplow',
		icon: 'https://fivetran.com/integrations/snowplow/resources/snowplow.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'snowplow',
		connectorClass: 'standard',
	},
	{
		name: 'Snyk',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/snyk/resources/snyk.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'snyk',
		connectorClass: 'lite',
	},
	{
		name: 'SolarWinds Service Desk',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/solarwinds_service_desk/resources/solarwinds_service_desk.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'solarwinds_service_desk',
		connectorClass: 'lite',
	},
	{
		name: 'SonarQube',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sonarqube/resources/sonarqube.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'sonarqube',
		connectorClass: 'lite',
	},
	{
		name: 'SparkPost',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sparkpost/resources/sparkpost.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'sparkpost',
		connectorClass: 'lite',
	},
	{
		name: 'Splash',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/splash/resources/splash.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'splash',
		connectorClass: 'lite',
	},
	{
		name: 'Split',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/splitio/resources/splitio.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'splitio',
		connectorClass: 'lite',
	},
	{
		name: 'Splunk',
		icon: 'https://fivetran.com/integrations/splunk/resources/splunk.svg',
		status: 'beta',
		type: 'Engineering',
		id: 'splunk',
		connectorClass: 'standard',
	},
	{
		name: 'Spotify Ads',
		icon: 'https://fivetran.com/integrations/spotify_ads/resources/spotify_icon.png',
		status: 'beta',
		type: 'Marketing',
		id: 'spotify_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Sprout Social',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sprout/resources/sprout.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'sprout',
		connectorClass: 'standard',
	},
	{
		name: 'Square',
		icon: 'https://fivetran.com/integrations/square/resources/square.png',
		status: 'general_availability',
		type: 'Finance',
		id: 'square',
		connectorClass: 'standard',
	},
	{
		name: 'Squarespace',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/squarespace/resources/squarespace.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'squarespace',
		connectorClass: 'lite',
	},
	{
		name: 'StackAdapt',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/stackadapt/resources/stackadapt.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'stackadapt',
		connectorClass: 'lite',
	},
	{
		name: 'Standard Metrics',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/standard_metrics/resources/standard_metrics.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'standard_metrics',
		connectorClass: 'lite',
	},
	{
		name: 'StarRez',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/starrez/resources/starrez.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'starrez',
		connectorClass: 'lite',
	},
	{
		name: 'Statistics Netherlands CBS',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/statistics_netherlands_cbs/resources/statistics_netherlands_cbs.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'statistics_netherlands_cbs',
		connectorClass: 'lite',
	},
	{
		name: 'Statsig',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/statsig/resources/statsig.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'statsig',
		connectorClass: 'standard',
	},
	{
		name: 'Statuspage',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/statuspage/resources/statuspage.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'statuspage',
		connectorClass: 'lite',
	},
	{
		name: 'Stripe',
		icon: 'https://fivetran.com/integrations/stripe/resources/stripe.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'stripe',
		connectorClass: 'standard',
	},
	{
		name: 'Stripe test mode',
		icon: 'https://fivetran.com/integrations/stripe/resources/stripe.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'stripe_test',
		connectorClass: 'standard',
	},
	{
		name: 'Stylight',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/stylight/resources/stylight.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'stylight',
		connectorClass: 'lite',
	},
	{
		name: 'Subscript',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/subscript/resources/subscript.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'subscript',
		connectorClass: 'lite',
	},
	{
		name: 'SugarCRM',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/sugarcrm/resources/sugarcrm.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'sugarcrm',
		connectorClass: 'lite',
	},
	{
		name: 'SurveyMonkey',
		icon: 'https://fivetran.com/integrations/survey_monkey/resources/survey_monkey.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'survey_monkey',
		connectorClass: 'standard',
	},
	{
		name: 'Survicate',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/survicate/resources/survicate.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'survicate',
		connectorClass: 'lite',
	},
	{
		name: 'Swoogo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/swoogo/resources/swoogo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'swoogo',
		connectorClass: 'lite',
	},
	{
		name: 'Tableau',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/tableau_source/resources/tableau_source.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'tableau_source',
		connectorClass: 'lite',
	},
	{
		name: 'Taboola',
		icon: 'https://fivetran.com/integrations/taboola/resources/taboola.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'taboola',
		connectorClass: 'standard',
	},
	{
		name: 'Talkdesk',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/talkdesk/resources/talkdesk.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'talkdesk',
		connectorClass: 'standard',
	},
	{
		name: 'Talkwalker',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/talkwalker/resources/talkwalker.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'talkwalker',
		connectorClass: 'lite',
	},
	{
		name: 'Teads',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/teads/resources/teads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'teads',
		connectorClass: 'lite',
	},
	{
		name: 'Teamtailor',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/teamtailor/resources/teamtailor.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'teamtailor',
		connectorClass: 'lite',
	},
	{
		name: 'Teamwork',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/teamwork/resources/teamwork.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'teamwork',
		connectorClass: 'lite',
	},
	{
		name: 'Tempo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/tempo/resources/tempo.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'tempo',
		connectorClass: 'lite',
	},
	{
		name: 'TestRail',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/testrail/resources/testrail.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'testrail',
		connectorClass: 'lite',
	},
	{
		name: 'TextUs',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/textus/resources/textus.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'textus',
		connectorClass: 'lite',
	},
	{
		name: 'The Movie Database',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/the_movie_database/resources/the_movie_database.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'the_movie_database',
		connectorClass: 'lite',
	},
	{
		name: 'The Trade Desk',
		icon: 'https://fivetran.com/integrations/the_trade_desk/resources/the-trade-desk-logo-vector.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'the_trade_desk',
		connectorClass: 'standard',
	},
	{
		name: 'Thinkific',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/thinkific/resources/thinkific.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'thinkific',
		connectorClass: 'lite',
	},
	{
		name: 'Ticket Tailor',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ticket_tailor/resources/ticket_tailor.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ticket_tailor',
		connectorClass: 'lite',
	},
	{
		name: 'Tiktok Ads',
		icon: 'https://fivetran.com/integrations/tiktok_ads/resources/tiktok-logo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'tiktok_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Tive',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/tive/resources/tive.svg',
		status: 'private_preview',
		type: 'Sales',
		id: 'tive',
		connectorClass: 'lite',
	},
	{
		name: 'Toast',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/toast/resources/toast.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'toast',
		connectorClass: 'lite',
	},
	{
		name: 'Toggl Track',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/toggl_track/resources/toggl_track.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'toggl_track',
		connectorClass: 'lite',
	},
	{
		name: 'Totango',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/totango/resources/totango.svg',
		status: 'private_preview',
		type: 'Support',
		id: 'totango',
		connectorClass: 'lite',
	},
	{
		name: 'Transcend',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/transcend/resources/transcend.svg',
		status: 'general_availability',
		type: 'Security',
		id: 'transcend',
		connectorClass: 'lite',
	},
	{
		name: 'Trelica',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/trelica/resources/trelica.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'trelica',
		connectorClass: 'lite',
	},
	{
		name: 'Trello',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/trello/resources/trello.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'trello',
		connectorClass: 'lite',
	},
	{
		name: 'Tremendous',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/tremendous/resources/tremendous.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'tremendous',
		connectorClass: 'lite',
	},
	{
		name: 'Triple Whale',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/triple_whale/resources/triple_whale.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'triple_whale',
		connectorClass: 'lite',
	},
	{
		name: 'Trisolute',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/trisolute/resources/trisolute.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'trisolute',
		connectorClass: 'lite',
	},
	{
		name: 'Twilio',
		icon: 'https://fivetran.com/integrations/twilio/resources/twilio.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'twilio',
		connectorClass: 'standard',
	},
	{
		name: 'Twitter Ads',
		icon: 'https://fivetran.com/integrations/twitter/resources/twitter_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'twitter_ads',
		connectorClass: 'standard',
	},
	{
		name: 'Twitter Organic',
		icon: 'https://fivetran.com/integrations/twitter/resources/twitter_ads.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'twitter',
		connectorClass: 'standard',
	},
	{
		name: 'Tymeshift',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/tymeshift/resources/tymeshift.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'tymeshift',
		connectorClass: 'lite',
	},
	{
		name: 'Typeform',
		icon: 'https://fivetran.com/integrations/typeform/resources/typeform.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'typeform',
		connectorClass: 'standard',
	},
	{
		name: 'UKG Pro',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ukg_pro/resources/ukg_pro.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'ukg_pro',
		connectorClass: 'lite',
	},
	{
		name: 'Udemy Business',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/udemy_business/resources/udemy_business.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'udemy_business',
		connectorClass: 'lite',
	},
	{
		name: 'Unicommerce',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/unicommerce/resources/unicommerce.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'unicommerce',
		connectorClass: 'lite',
	},
	{
		name: 'UpPromote',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/uppromote/resources/uppromote.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'uppromote',
		connectorClass: 'lite',
	},
	{
		name: 'Upland Software',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/upland/resources/upland.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'upland',
		connectorClass: 'lite',
	},
	{
		name: 'UserVoice',
		icon: 'https://fivetran.com/integrations/uservoice/resources/uservoice.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'uservoice',
		connectorClass: 'standard',
	},
	{
		name: 'VTS',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vts/resources/vts.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'vts',
		connectorClass: 'lite',
	},
	{
		name: 'VWO',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vwo/resources/vwo.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'vwo',
		connectorClass: 'lite',
	},
	{
		name: 'Veeqo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/veeqo/resources/veeqo.svg',
		status: 'general_availability',
		type: 'Sales',
		id: 'veeqo',
		connectorClass: 'lite',
	},
	{
		name: 'Veeva Vault',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/veevavault/resources/veevavault.svg',
		status: 'private_preview',
		type: 'Productivity',
		id: 'veevavault',
		connectorClass: 'lite',
	},
	{
		name: 'Verizon Media',
		icon: 'https://fivetran.com/integrations/yahoo_gemini/resources/verizon.png',
		status: 'sunset',
		type: 'Marketing',
		id: 'yahoo_gemini',
		connectorClass: 'standard',
	},
	{
		name: 'Vimeo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vimeo/resources/vimeo.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'vimeo',
		connectorClass: 'lite',
	},
	{
		name: 'Visit by GES',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/visit_by_ges/resources/visit_by_ges.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'visit_by_ges',
		connectorClass: 'lite',
	},
	{
		name: 'Visma',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/visma/resources/visma.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'visma',
		connectorClass: 'lite',
	},
	{
		name: 'Vitally',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vitally/resources/vitally.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'vitally',
		connectorClass: 'lite',
	},
	{
		name: 'Vonage',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vonage/resources/vonage.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'vonage',
		connectorClass: 'lite',
	},
	{
		name: 'Vonage Contact Center',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/vonage_contact_center/resources/vonage_contact_center.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'vonage_contact_center',
		connectorClass: 'lite',
	},
	{
		name: 'Walmart DSP',
		icon: 'https://fivetran.com/integrations/the_trade_desk/resources/walmart-0.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'walmart_dsp',
		connectorClass: 'standard',
	},
	{
		name: 'Walmart Marketplace',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/walmart_marketplace/resources/walmart_marketplace.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'walmart_marketplace',
		connectorClass: 'lite',
	},
	{
		name: 'Wasabi Cloud Storage',
		icon: 'https://fivetran.com/integrations/wasabi_cloud_storage/resources/wasabi_cloud_storage.svg',
		status: 'private_preview',
		type: 'File',
		id: 'wasabi_cloud_storage',
		connectorClass: 'standard',
	},
	{
		name: 'Web Scraper',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/web_scraper/resources/web_scraper.svg',
		status: 'private_preview',
		type: 'Engineering',
		id: 'web_scraper',
		connectorClass: 'lite',
	},
	{
		name: 'Webconnex',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/webconnex/resources/webconnex.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'webconnex',
		connectorClass: 'lite',
	},
	{
		name: 'Webhooks',
		icon: 'https://fivetran.com/integrations/webhooks/resources/webhooks.svg',
		status: 'general_availability',
		type: 'Events',
		id: 'webhooks',
		connectorClass: 'standard',
	},
	{
		name: 'When I Work',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/when_i_work/resources/when_i_work.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'when_i_work',
		connectorClass: 'lite',
	},
	{
		name: 'Wherefour',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/wherefour/resources/wherefour.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'wherefour',
		connectorClass: 'lite',
	},
	{
		name: 'Wicked Reports',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/wicked_reports/resources/wicked_reports.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'wicked_reports',
		connectorClass: 'lite',
	},
	{
		name: 'WooCommerce',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/woocommerce/resources/woocommerce.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'woocommerce',
		connectorClass: 'lite',
	},
	{
		name: 'WordPress',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/wordpress/resources/wordpress.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'wordpress',
		connectorClass: 'lite',
	},
	{
		name: 'WorkRamp',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/workramp/resources/workramp.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'workramp',
		connectorClass: 'lite',
	},
	{
		name: 'Workable',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/workable/resources/workable.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'workable',
		connectorClass: 'lite',
	},
	{
		name: 'Workday Financial Management',
		icon: 'https://fivetran.com/integrations/workday_financial_management/resources/workday.png',
		status: 'beta',
		type: 'Finance',
		id: 'workday_financial_management',
		connectorClass: 'standard',
	},
	{
		name: 'Workday HCM',
		icon: 'https://fivetran.com/integrations/workday_hcm/resources/workday.png',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'workday_hcm',
		connectorClass: 'standard',
	},
	{
		name: 'Workday RaaS',
		icon: 'https://fivetran.com/integrations/workday/resources/workday.png',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'workday',
		connectorClass: 'standard',
	},
	{
		name: 'Workday Strategic Sourcing',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/workday_strategic_sourcing/resources/workday_strategic_sourcing.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'workday_strategic_sourcing',
		connectorClass: 'lite',
	},
	{
		name: 'Wrike',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/wrike/resources/wrike.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'wrike',
		connectorClass: 'lite',
	},
	{
		name: 'Xactly',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/xactly/resources/xactly.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'xactly',
		connectorClass: 'lite',
	},
	{
		name: 'Xandr',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/xandr/resources/xandr.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'xandr',
		connectorClass: 'lite',
	},
	{
		name: 'Xero',
		icon: 'https://fivetran.com/integrations/xero/resources/xero.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'xero',
		connectorClass: 'standard',
	},
	{
		name: 'Xray',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/xray/resources/xray.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'xray',
		connectorClass: 'lite',
	},
	{
		name: 'Xsolla',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/xsolla/resources/xsolla.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'xsolla',
		connectorClass: 'lite',
	},
	{
		name: 'Yahoo DSP',
		icon: 'https://fivetran.com/integrations/yahoo_dsp/resources/logo-svg.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'yahoo_dsp',
		connectorClass: 'standard',
	},
	{
		name: 'Yahoo Search Ads on Yahoo Japan',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/yahoo_search_ads_yahoo_japan/resources/yahoo_search_ads_yahoo_japan.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'yahoo_search_ads_yahoo_japan',
		connectorClass: 'lite',
	},
	{
		name: 'Yotpo',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/yotpo/resources/yotpo.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'yotpo',
		connectorClass: 'lite',
	},
	{
		name: 'YouGov Sport',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/yougov_sport/resources/yougov_sport.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'yougov_sport',
		connectorClass: 'lite',
	},
	{
		name: 'YouTube Analytics',
		icon: 'https://fivetran.com/integrations/youtube_analytics/resources/youtube_analytics.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'youtube_analytics',
		connectorClass: 'standard',
	},
	{
		name: 'Younium',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/younium/resources/younium.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'younium',
		connectorClass: 'lite',
	},
	{
		name: 'Zendesk Chat',
		icon: 'https://fivetran.com/integrations/zendesk_chat/resources/zendesk_chat.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zendesk_chat',
		connectorClass: 'standard',
	},
	{
		name: 'Zendesk Sell',
		icon: 'https://fivetran.com/integrations/zendesk_sell/resources/zendesk_sell.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zendesk_sell',
		connectorClass: 'standard',
	},
	{
		name: 'Zendesk Sunshine',
		icon: 'https://fivetran.com/integrations/zendesk_sunshine/resources/logo-zendesk.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zendesk_sunshine',
		connectorClass: 'standard',
	},
	{
		name: 'Zendesk Support',
		icon: 'https://fivetran.com/integrations/zendesk/resources/zendesk.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zendesk',
		connectorClass: 'standard',
	},
	{
		name: 'Zenefits',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zenefits/resources/zenefits.svg',
		status: 'general_availability',
		type: 'HumanResources',
		id: 'zenefits',
		connectorClass: 'lite',
	},
	{
		name: 'Zingtree',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zingtree/resources/zingtree.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zingtree',
		connectorClass: 'lite',
	},
	{
		name: 'Zoho Books',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zoho_books/resources/zoho_books.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'zoho_books',
		connectorClass: 'lite',
	},
	{
		name: 'Zoho CRM',
		icon: 'https://fivetran.com/integrations/zoho_crm/resources/zoho.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zoho_crm',
		connectorClass: 'standard',
	},
	{
		name: 'Zoho Campaigns',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zoho_campaigns/resources/zoho_campaigns.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'zoho_campaigns',
		connectorClass: 'lite',
	},
	{
		name: 'Zoho Desk',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zoho_desk/resources/zoho_desk.svg',
		status: 'general_availability',
		type: 'Support',
		id: 'zoho_desk',
		connectorClass: 'lite',
	},
	{
		name: 'Zoho Inventory',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zoho_inventory/resources/zoho_inventory.svg',
		status: 'private_preview',
		type: 'Marketing',
		id: 'zoho_inventory',
		connectorClass: 'lite',
	},
	{
		name: 'Zonka Feedback',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zonka_feedback/resources/zonka_feedback.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'zonka_feedback',
		connectorClass: 'lite',
	},
	{
		name: 'Zoom',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/zoom/resources/zoom.svg',
		status: 'general_availability',
		type: 'Productivity',
		id: 'zoom',
		connectorClass: 'lite',
	},
	{
		name: 'Zuora',
		icon: 'https://fivetran.com/integrations/zuora/resources/zuora.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'zuora',
		connectorClass: 'standard',
	},
	{
		name: 'Zuora Sandbox',
		icon: 'https://fivetran.com/integrations/zuora/resources/zuora.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'zuora_sandbox',
		connectorClass: 'standard',
	},
	{
		name: 'commercetools',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/commercetools/resources/commercetools.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'commercetools',
		connectorClass: 'standard',
	},
	{
		name: 'crowd.dev',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/crowddev/resources/crowddev.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'crowddev',
		connectorClass: 'lite',
	},
	{
		name: 'dbt Cloud',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/dbt_cloud/resources/dbt_cloud.svg',
		status: 'general_availability',
		type: 'Engineering',
		id: 'dbt_cloud',
		connectorClass: 'lite',
	},
	{
		name: 'e-conomic',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/economic/resources/economic.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'economic',
		connectorClass: 'lite',
	},
	{
		name: 'eBay',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ebay/resources/ebay.svg',
		status: 'general_availability',
		type: 'Finance',
		id: 'ebay',
		connectorClass: 'lite',
	},
	{
		name: 'iLevel',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ilevel/resources/ilevel.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ilevel',
		connectorClass: 'lite',
	},
	{
		name: 'ironSource',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/ironsource/resources/ironsource.svg',
		status: 'general_availability',
		type: 'Marketing',
		id: 'ironsource',
		connectorClass: 'lite',
	},
	{
		name: 'myosh',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/myosh/resources/myosh.svg',
		status: 'general_availability',
		type: 'Security',
		id: 'myosh',
		connectorClass: 'lite',
	},
	{
		name: 'sticky.io',
		icon: 'https://fivetran.com/integrations/coil_connectors/resources/stickyio/resources/stickyio.svg',
		status: 'private_preview',
		type: 'Finance',
		id: 'stickyio',
		connectorClass: 'lite',
	},
]
	.filter(
		({ status, connectorClass }) =>
			status == 'general_availability' && (connectorClass == 'standard' || connectorClass == 'lite')
	)
	.sort((a, b) => a.name.localeCompare(b.name));

export const allConnectorTypes = new Set(connectorsInfos.map(({ type }) => type).sort((a, b) => a.localeCompare(b)));
const POPULAR_CONNECTORS = ['Salesforce', 'HubSpot', 'Google Sheets', 'Google Drive'];
export const popularConnectors: DataConnectorInfo[] = connectorsInfos.filter(({ name }) => {
	return POPULAR_CONNECTORS.includes(name);
});
