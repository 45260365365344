import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import useParams from 'src/common/hooks/navigation/useParams';
import { OntologyPagePath } from 'src/pages/OntologyPage/OntologyBuilderPage';
import useNavigation from 'src/services/useNavigation';
import { writePartialOntologyState } from '../atoms/OntologyState';
import { allOntologyTypes, OntologyType } from './useOntologyPageState';

export function useOntologyParams(): { ontologyType: OntologyType; ontologyName?: string } {
	const { ontologyType: ontologyTypeParam, ontologyName: ontologyNameParam } = useParams();

	const ontologyType =
		ontologyTypeParam && allOntologyTypes.map((type) => type.toString()).includes(ontologyTypeParam)
			? (ontologyTypeParam as OntologyType)
			: 'entity';
	const backwardCompatibleOntologyName = ontologyNameParam ?? ontologyTypeParam;

	return {
		ontologyType,
		ontologyName: (allOntologyTypes as readonly string[]).includes(backwardCompatibleOntologyName ?? '')
			? undefined
			: backwardCompatibleOntologyName,
	};
}

export function usePropertyNameParam() {
	const { propertyName } = useParams();
	return propertyName;
}

const YamlModePropertyName = 'yaml-mode';
export const CreateNewRelationshipPropertyName = 'create-relationship';
export const CreateNewDimensionPropertyName = 'create-dimension';

export function useIsFullAdvancedMode() {
	const propertyName = usePropertyNameParam();
	const updateOntologyLocation = useSetOntologyLocation();
	const setIsAdvancedMode = useCallback(
		(ontologyType: OntologyType, isAdvancedMode: boolean, ontologyName?: string) => {
			updateOntologyLocation({ ontologyType, ontologyName, isAdvancedMode });
		},
		[updateOntologyLocation]
	);
	return { isAdvancedMode: propertyName == YamlModePropertyName, setIsAdvancedMode };
}

export function useSetOntologyLocation() {
	const { navigate } = useNavigation();
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);

	const updateOntologyLocation = useCallback(
		({
			ontologyType,
			ontologyName,
			propertyName,
			isAdvancedMode,
		}: {
			ontologyType: OntologyType;
			ontologyName?: string;
			propertyName?: string;
			isAdvancedMode?: boolean;
		}) => {
			setPartialOntologyState({
				editorRequestMessageState: 'NONE',
				errorMessage: '',
			});
			if (!ontologyName) {
				navigate({
					path: OntologyPagePath,
				});
			} else {
				if (isAdvancedMode) propertyName = YamlModePropertyName;
				if (propertyName)
					navigate({
						path: `${OntologyPagePath}/${ontologyType}/${ontologyName}/${propertyName}`,
					});
				else
					navigate({
						path: `${OntologyPagePath}/${ontologyType}/${ontologyName}`,
					});
			}
		},
		[setPartialOntologyState, navigate]
	);

	return updateOntologyLocation;
}
