import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';

export default function HeaderBadge({ text, testId }: { text: string; testId?: string }) {
	return (
		<Flex
			data-testid={testId}
			justifyContent="center"
			alignItems="center"
			py={'2px'}
			px={'8px'}
			borderRadius={'2px'}
			bg={'gray.200'}
		>
			<Typography color={'gray.700'} variant="Paragraph12M">
				{text}
			</Typography>
		</Flex>
	);
}
