import Flex from '@components/Flex';
import Box from '@components/Box';
import colors from 'src/style/colors';
import { ChevronDown16, Office } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';

export const PermissionsSelector = () => {
	return (
		<Flex
			justifyContent={'space-between'}
			alignItems={'center'}
			padding={'11px 12px 11px 16px'}
			gap={'16px'}
			borderRadius={'8px'}
			border={`1px solid ${colors.gray[300]}`}
			backgroundColor={'gray.100'}
		>
			<Flex alignItems={'center'} gap={'12px'}>
				<Box color={'gray.1000'}>
					<Office />
				</Box>
				<Flex flexDirection={'column'} gap={'4px'}>
					<Typography variant={'DesktopH8Regular'} color={'gray.1000'}>
						{'Open'}
					</Typography>
					<Typography variant={'DesktopH8Regular'} color={'gray.700'}>
						{'Anyone can see'}
					</Typography>
				</Flex>
			</Flex>
			<Box color={'gray.1000'}>
				<ChevronDown16 />
			</Box>
		</Flex>
	);
};
