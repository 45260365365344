import { filterStringByTerm } from 'src/common/utils/utils';

export const spaceBeforeCapitalLetters = (str: string): string => {
	if (!str || typeof str !== 'string' || str.length === 0) {
		return '';
	}

	return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const capitalizedFirstLetter = (string: string) => {
	if (!string || typeof string !== 'string' || string.length === 0) {
		return string;
	}

	return string.charAt(0).toUpperCase() + string.substring(1);
};

export const getInitials = (name: string) => {
	if (!name || name.length === 0) {
		return '';
	}

	const words = name.split(' ').filter((word) => word.length > 0);
	const isAtLeastTwoWords = words.length > 1;
	if (isAtLeastTwoWords) {
		const firstLetterOfInitials = words[0][0];
		const secondLetterOfInitials = words[1][0];
		return firstLetterOfInitials.toUpperCase() + secondLetterOfInitials.toUpperCase();
	}

	const firstLetter = words[0][0];
	const secondLetter = words[0].length > 1 ? words[0][1] : '';
	return firstLetter.toUpperCase() + secondLetter.toUpperCase();
};

export const getErrorMessage = (rawErrorMessage: string) => {
	const pattern = 'error: ';

	return rawErrorMessage.replaceAll(pattern, '');
};

export const checkForMatch = ({ subject, value }: { subject?: string; value: string }) =>
	filterStringByTerm(value, subject);
