import Box from '@components/Box';
import { ArrowUpSmall16, AskAIGray24, CRemove16, StopCircle16 } from '@components/Icons';
import Input from '@components/Input';
import { InputProps } from '@components/Input/types';
import { useCallback, useMemo, useState } from 'react';
import Button from '../Button';
import classes from './AskAI.module.scss';
import { useReportAIEvent } from './hooks/useReportAIEvent';
import { useAIAgents } from './hooks/useAIAgents';

type AskAIInputProps = InputProps & {
	placeholder: string;
	initialValue?: string;
	hasBorder: boolean;
	onSubmit?: (value: string) => void;
	onCancel?: () => void;
	testId?: string;
	isErasable?: boolean;
	isCancellable?: boolean;
	borderRadius?: string;
	submitButtonBorderRadius?: string;
	clearInputOnSubmit?: boolean;
	withLeftIcon?: boolean;
	autoFocus?: boolean;
	reportEvent: string;
	reportFeature: string;
	reportMetadata?: Record<string, any>;
};

function SubmitButton({
	isDisabled,
	isCancellable,
	isBusy,
	onSubmit,
	onCancel,
	borderRadius,
	size,
}: {
	isDisabled: boolean;
	isCancellable: boolean;
	isBusy: boolean;
	onSubmit: () => void;
	onCancel?: () => void;
	borderRadius?: string;
	size?: string;
}) {
	return (
		<Button
			variant={'solid'}
			size={size === 'md' ? 'xs' : 'micro'}
			isIconOnly={true}
			colorScheme={isBusy ? 'primaryBlack' : 'primaryBlue'}
			border={'none'}
			isDisabled={!isCancellable && isDisabled}
			onClick={isBusy ? onCancel : onSubmit}
			borderRadius={borderRadius}
		>
			{isBusy ? <StopCircle16 /> : <ArrowUpSmall16 />}
		</Button>
	);
}

export function AskAIInput({
	placeholder,
	isDisabled,
	isReadOnly,
	onSubmit,
	onCancel,
	onClick,
	onChange,
	isErasable,
	isCancellable = false,
	hasBorder,
	withLeftIcon = false,
	initialValue = '',
	borderRadius = '8px',
	size = 'md',
	submitButtonBorderRadius,
	clearInputOnSubmit = true,
	autoFocus = true,
	reportEvent,
	reportFeature,
	reportMetadata = {},
}: AskAIInputProps) {
	const { reportAIEvent } = useReportAIEvent();
	const [inputValue, setInputValue] = useState(initialValue);
	const { isBusy } = useAIAgents();
	const isSubmitDisabled = useMemo(() => !inputValue || isBusy, [isBusy, inputValue]);

	const onInnerChange = useCallback(
		(value: string) => {
			setInputValue(value);
			onChange?.(value);
		},
		[onChange]
	);

	const onEnter = useCallback(
		(userPrompt: string) => {
			if (isSubmitDisabled || userPrompt.trim() == '') return;
			if (clearInputOnSubmit) setInputValue('');
			reportAIEvent({
				event: reportEvent,
				feature: reportFeature,
				metaData: {
					...reportMetadata,
					userPrompt,
				},
			});
			onSubmit?.(userPrompt);
		},
		[clearInputOnSubmit, isSubmitDisabled, onSubmit, reportAIEvent, reportEvent, reportFeature, reportMetadata]
	);

	const onClickSubmit = useCallback(() => {
		onEnter(inputValue);
	}, [inputValue, onEnter]);

	return (
		<Box
			className={hasBorder ? classes['ask-ai-input'] : ''}
			borderRadius={borderRadius}
			flex={1}
			onClick={onClick}
			cursor={onClick ? 'pointer' : undefined}
		>
			<Input
				borderRadius={borderRadius}
				placeholder={placeholder}
				isDisabled={isDisabled}
				isReadOnly={isReadOnly}
				color={'gray.1100'}
				size={size}
				shouldShowAutocomplete={false}
				onEnter={onEnter}
				isErasable={isErasable && !!inputValue}
				eraseIcon={<CRemove16 />}
				variant={'ghost'}
				leftComponent={withLeftIcon ? <AskAIGray24 /> : undefined}
				leftComponentPadding={size === 'md' ? '42px' : '38px'}
				leftComponentLeftPadding={size === 'md' ? '12px' : '8px'}
				leftComponentBorder={false}
				rightComponent={
					!isErasable ? (
						<SubmitButton
							isDisabled={isSubmitDisabled}
							isCancellable={isBusy && isCancellable}
							size={size}
							isBusy={isBusy}
							onCancel={onCancel}
							onSubmit={onClickSubmit}
							borderRadius={submitButtonBorderRadius}
						/>
					) : undefined
				}
				rightComponentPadding={size === 'md' ? '10px' : '9px'}
				onChange={onInnerChange}
				value={inputValue}
				testId={`ask-ai-input`}
				autoFocus={autoFocus}
			/>
		</Box>
	);
}
