import { useMemo } from 'react';
import { BooleanEditor } from '../Editors/BooleanEditor';
import { EditParameterProps } from './types';
import { getListOptions } from './utils';

export function ParameterBooleanEditor({ parameter, value, setSelectedValue }: EditParameterProps) {
	const parameterDef = parameter.definition;
	const { firstOption, secondOption } = useMemo(() => {
		const options = getListOptions(parameterDef.values) ?? [];
		return {
			firstOption: { title: options[0]?.title ?? 'True', value: options[0]?.value ?? 'true' },
			secondOption: { title: options[1]?.title ?? 'False', value: options[1]?.value ?? 'false' },
		};
	}, [parameterDef.values]);

	return (
		<BooleanEditor value={value} firstOption={firstOption} secondOption={secondOption} onApply={setSelectedValue} />
	);
}
