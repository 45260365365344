import { useCallback, useMemo } from 'react';
import { Parameter } from '../types/parameter';
import { useMetricDerivedState } from '../pages/MetricPage/hooks/useMetricDerivedState';
import { useSemanticDefinitions } from '../common/hooks/stores/useSemanticDefinitions';
import { ListValues, RangeValues } from '../lib/completions/semanticTypes/parameters.schema';

export function isParameterValuesTypeList(
	values?: ListValues | RangeValues,
	values_type?: string | undefined
): values is ListValues {
	return !!values && values_type === 'list';
}

export function useParameters() {
	const { semanticDefinitions } = useSemanticDefinitions();
	const { parameters } = useMetricDerivedState();

	const allParameters = useMemo(() => {
		return semanticDefinitions?.parameters ?? [];
	}, [semanticDefinitions?.parameters]);

	const parametersMap = useMemo(() => {
		return parameters.reduce((out, param) => {
			out.set(param.definition.name, param);
			return out;
		}, new Map<string, Parameter>());
	}, [parameters]);

	const getParameterDisplayName = useCallback(
		(name: string) => {
			const parameter = allParameters.find((param) => param.name === name);
			if (!parameter) return name;
			return parameter.label ?? parameter.name;
		},
		[allParameters]
	);

	const getParameterDisplayValue = useCallback(
		(name: string, value?: string) => {
			const parameter = allParameters.find((param) => param.name === name);
			if (!parameter) return value;
			const paramValue = value ?? `${parameter.default}`;
			const { values, values_type } = parameter;
			if (isParameterValuesTypeList(values, values_type)) {
				const valueInList = values.find((val) => `${val.value}` === paramValue);
				if (valueInList) {
					return valueInList.label ?? paramValue;
				}
			}

			return paramValue;
		},
		[allParameters]
	);

	const getParameterDisplayNameWithValue = useCallback(
		(name: string, value?: string) => {
			return `${getParameterDisplayName(name)} = ${getParameterDisplayValue(name, value)}`;
		},
		[getParameterDisplayName, getParameterDisplayValue]
	);

	return {
		allParameters,
		parametersMap,
		getParameterDisplayValue,
		getParameterDisplayName,
		getParameterDisplayNameWithValue,
	};
}
