import { FormulaMetric, Metric } from './metrics.schema';
import { Dimension, Entity, RawSQLDataSource, Relationship, TableDataSource } from './normalization.schema';
import { Parameter, ParametersSchema } from './parameters.schema';
export type { FormulaMetric, Metric, MetricMeta } from './metrics.schema';

export type EntityDefinitionState = {
	name: string;
	data_source?: { schema: string; table: string; filters: { sql: string }[] };
	primary_keys?: string[];
	meta?: { description?: string; display_name?: string };
};

export type EntityWithMetrics = EnrichedMetricsSchema & LoadedEntity;

export type AvailableParameter = Parameter;

export type SourceLocation = {
	filePath?: string;
	lineNo?: number;
	lineCount?: number;
};

export type BaseLoadedSemantic = {
	isDefined: boolean;
} & SourceLocation;

export type LoadedDimension = Dimension & BaseLoadedSemantic;

export type LoadedRelationship = Relationship & BaseLoadedSemantic;

export type LoadedEntity = {
	dimensions: LoadedDimension[];
	relationships?: LoadedRelationship[];
} & Entity &
	BaseLoadedSemantic;

export type LoadedEntities = {
	entities: LoadedEntity[];
};

export type SemanticDefinitions = {
	entities: EntityWithMetrics[];
} & EnrichedMetricsSchema;

export type EnrichedSemanticDefinitions = {
	entities: EntityWithMetrics[];
} & EnrichedMetricsSchema &
	ParametersSchema;

export type MetricTemplateData = {
	template?: string;
} & SourceLocation;

export type EntityWithTableDataSource = Omit<Entity, 'data_source'> & { data_source: TableDataSource };
export type EnrichedMetricsDefinition<T extends Metric | FormulaMetric> = MetricTemplateData & T;
export type EnrichedMetric = EnrichedMetricsDefinition<Metric>;
export type EnrichedFormulaMetric = EnrichedMetricsDefinition<FormulaMetric>;
export type EnrichedMetricsWithOverrideDefinitions<T extends Metric | FormulaMetric> = Partial<MetricTemplateData> & T;
export type EnrichedMetricWithOverrideDefinitions = EnrichedMetricsWithOverrideDefinitions<Metric>;
export type EnrichedFormulaMetricWithOverrideDefinitions = EnrichedMetricsWithOverrideDefinitions<FormulaMetric>;

export type EnrichedMetricSchemaWithOverrides = {
	metrics?: EnrichedMetricWithOverrideDefinitions[];
	formula_metrics?: EnrichedFormulaMetricWithOverrideDefinitions[];
};

export type EnrichedMetricsSchema = {
	metrics: EnrichedMetric[];
	formula_metrics: EnrichedFormulaMetric[];
};

export type SourceDimension = {
	name: string;
	normalizedName: string;
	description?: string;
};

export type TypedEntity = {
	type: 'entity';
	object: LoadedEntity;
};
export type TypedRelationship = {
	type: 'relationship';
	object: LoadedRelationship;
};

export type TypedDimension = {
	type: 'dimension';
	object: LoadedDimension;
};

export type TypedSourceDimension = {
	type: 'sourceDimension';
	object: SourceDimension;
};

export type TypedAnyDimension = TypedDimension | TypedSourceDimension;

export type TypedMetric = {
	type: 'metric';
	object: Metric;
};

export type TypedFormulaMetric = {
	type: 'formulaMetric';
	object: FormulaMetric;
};

export type TypedNotExistingMetric = {
	type: 'notExistingMetric';
	object: {
		name: string;
	};
};

export type TypedEnvironmentVariable = {
	type: 'environmentVariable';
	object: {
		name: string;
	};
};

export type RelationshipWithSourceEntity = LoadedRelationship & { sourceEntityName: string };

export type TypedGlobalSemanticObject = TypedEnvironmentVariable; // add more types here later

export type TypedSemanticObject =
	| TypedEntity
	| TypedRelationship
	| TypedAnyDimension
	| TypedMetric
	| TypedFormulaMetric
	| TypedNotExistingMetric;

export function isFormulaMetric(metric: EnrichedFormulaMetric | EnrichedMetric): metric is EnrichedFormulaMetric {
	return 'formula' in metric;
}

export function isRawSQLDataSource(dataSource: LoadedEntity['data_source']): dataSource is RawSQLDataSource {
	return 'raw_sql' in dataSource;
}
