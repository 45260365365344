import { mode, StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyleControl: SystemStyleFunction = (props) => {
	const { colorScheme: color } = props;
	const isWhite = color === 'white';

	const colorVariants = {
		// White is special!
		vColor: isWhite ? 'black' : color,
		vHoverColor: isWhite ? mode('gray.700', 'gray.700')(props) : 'white',
		checkedBorder: isWhite ? '1px solid #28293D' : '0',
		disabledBackgroundColor: isWhite ? mode('gray.100', 'gray.100')(props) : color,
		borderHoverColor: isWhite ? 'black' : color,
		disabledVColor: isWhite ? mode('gray.500', 'gray.500')(props) : 'white',
		disabledBorderColor: isWhite ? 'grey.300' : 'null',
	};

	return {
		w: '100%',
		transitionProperty: 'box-shadow',
		transitionDuration: 'normal',
		border: '1px solid',
		borderRadius: '2px',
		borderColor: mode('gray.500', 'gray.300')(props),
		color: colorVariants.vColor,

		_hover: {
			borderColor: mode('gray.600', 'gray.200')(props),
		},

		_checked: {
			position: 'relative',
			border: `1px solid ${colorVariants.vColor}`,
			color: colorVariants.vColor,
			'&::before': {
				content: '""',
				position: 'absolute',
				width: '100%',
				height: '100%',
				left: 0,
				top: 0,
				bg: color,
				opacity: 0.1,
			},
			_hover: {
				borderColor: colorVariants.borderHoverColor,
			},

			_disabled: {
				bg: colorVariants.disabledBackgroundColor,
				color: colorVariants.disabledVColor,
				borderColor: colorVariants.disabledBorderColor,
				opacity: isWhite ? 1 : 0.2,
			},
		},

		_indeterminate: {
			bg: color,
			color: colorVariants.vColor,

			_hover: {
				color: colorVariants.vHoverColor,
				bg: color,
				border: colorVariants.borderHoverColor,
				opacity: isWhite ? 1 : 0.6,
			},

			_disabled: {
				bg: colorVariants.disabledBackgroundColor,
				color: colorVariants.disabledVColor,
				borderColor: colorVariants.disabledBorderColor,
				opacity: isWhite ? 1 : 0.2,
			},
		},

		_disabled: {
			bg: mode('gray.100', 'whiteAlpha.100')(props),
			borderColor: mode('gray.300', 'transparent')(props),
			_hover: {
				borderColor: mode('gray.300', 'transparent')(props),
			},
		},

		_focusVisible: {
			boxShadow: 'outline',
		},

		_invalid: {
			borderColor: mode('red.800', 'red.400')(props),
		},
	};
};

const rounded = definePartsStyle({
	control: defineStyle({
		rounded: 'full',
		_checked: {
			border: 'none',
			'&::before': {
				bg: 'transparent',
			},
		},
	}),
});

const sizes: Record<string, any> = {
	xs: {
		control: { h: '16px', w: '16px' },
		label: { fontSize: 'xs' },
		icon: { fontSize: '7px' },
	},
	sm: {
		control: { h: '16px', w: '16px' },
		label: { fontSize: 'sm' },
		icon: { fontSize: '7px' },
	},
	md: {
		control: { h: '18px', w: '18px' },
		label: { fontSize: 'md' },
		icon: { fontSize: '8px' },
	},
};

const baseStyleContainer: SystemStyleFunction = (props) => {
	const { colorScheme: color } = props;
	const isWhite = color === 'white';
	const colorVariants = {
		vColor: isWhite ? 'black' : 'white',
	};
	return {
		_disabled: { cursor: 'unset' },
		'&.isFluid': {
			'.chakra-checkbox__control[data-checked]': {
				bg: color,
				color: colorVariants.vColor,
			},
		},
	};
};

export default defineMultiStyleConfig({
	variants: { rounded },
	baseStyle: (props: StyleFunctionProps) => ({
		control: baseStyleControl(props),
		container: baseStyleContainer(props),
	}),
	sizes,
});
