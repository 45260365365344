import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { AccessController } from 'src/common/components/AccessController';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { DotsSolid16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import useUser from 'src/common/hooks/stores/useUser';
import classes from 'src/pages/WorkspacePage/SignalWidget/WorkspaceSignalWidget.module.scss';
import { Permission, Permissions } from 'src/types/environment';
import { FeedSignal } from 'src/types/spaces';
import { CommentsButton } from './CommentIcon';
import { NoOwnerPermissionActions } from './NoOwnerPermissionActions';
import { SignalEdit } from './SignalEdit';
import { SignalMoreMenu } from './SignalMoreMenu';

export function SignalActions({
	signal,
	duplicateSignal,
	deleteSignal,
	onCommentClick,
}: {
	signal: FeedSignal;
	duplicateSignal?: VoidFunction;
	deleteSignal?: VoidFunction;
	onCommentClick?: VoidFunction;
}) {
	const { onCopyText } = useOnCopy();
	const [{ id: userId }] = useUser();
	const { author_id: authorId } = signal.signal;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const signalUpdatePermissions: Permission[] = [Permissions.updateAllSignals];
	if (authorId === userId) {
		signalUpdatePermissions.push(Permissions.crudMySignals);
	}

	const onCopyLink = () => {
		const currentURL = `${getUrl(window.location.href)}&signalId=${signal.signal_id}`;
		onCopyText({ contentToCopy: currentURL });
		onClose();
	};

	const getUrl = (url: string) => url.replace(/&signalId=[^&]*/, '');

	const hasEditActions = duplicateSignal && deleteSignal;

	return (
		<Flex direction="row">
			{hasEditActions && (
				<Flex direction="row" alignItems={'center'} height="32px">
					<AccessController
						permission={signalUpdatePermissions}
						noAccessChild={<NoOwnerPermissionActions onDuplicateClick={duplicateSignal} />}
						logic="OR"
					>
						<Box className={classes.workspaceSignalWidgetActions}>
							<SignalEdit signal={signal} />
						</Box>

						<Popover
							onClose={onClose}
							isOpen={isOpen}
							placement="bottom-end"
							triggerElement={(isOpen) => (
								<Box className={isOpen ? undefined : classes.workspaceSignalWidgetActions}>
									<IconButton onClick={onOpen} colorScheme="black" icon={<DotsSolid16 />} isActive={isOpen} />
								</Box>
							)}
						>
							<SignalMoreMenu
								duplicateSignal={() => {
									duplicateSignal();
									onClose();
								}}
								onDeleteSignal={() => {
									deleteSignal();
									onClose();
								}}
								onCopyLink={onCopyLink}
							/>
						</Popover>

						<Box height="24px" paddingX="8px" className={classes.workspaceSignalWidgetActions}>
							<Divider direction={'vertical'} />
						</Box>
					</AccessController>
				</Flex>
			)}
			{onCommentClick && <CommentsButton commentCount={signal.signal.comments.length} onClick={onCommentClick} />}
		</Flex>
	);
}
