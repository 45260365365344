import {
	formatDimensionLabel,
	formatMetricFilterLabel,
	replaceLastCommaWithOr,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { FilterV2, FilterV2Value } from '@pages/MetricPage/utils/state.types';
import { useCallback, useMemo } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { useModal } from 'src/common/hooks/ui/useModal';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionTooltip } from 'src/lib/completions/widgetBuilder/contextTooltip';
import { resolveContextFromSemanticsAndName } from 'src/pages/OntologyPage/components/JoinKeys/utils';
import { useReportEvent } from 'src/services/analytics';
import { DrilldownCard } from '../DrillDownCard';
import { ValueEditorByFilter } from './Editors/ValueEditorByFilter';
import { OperatorSelect } from './Operator';
import { supportedOperatorDisplayNames, supportedOperatorsByFilterType, typeIcon } from './constants';
import { useMetricFilter2 } from './useMetricFilter2';

export const FilterDrilldownCard2 = ({
	filter,
	onRemove,
	isEditorAutoOpened,
	eventMetadata,
	onFilterCompletenessChange,
}: {
	filter: FilterV2;
	onRemove?: VoidFunction;
	isEditorAutoOpened?: boolean;
	eventMetadata: object;
	onFilterCompletenessChange?: (isComplete: boolean) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const {
		isOpen: isEditing,
		onClose: onCloseEditor,
		onOpen: onOpenEditor,
	} = useModal({
		defaultIsOpen: isEditorAutoOpened,
	});

	const { isLoadingDimensionValues, options, updateFilter, dimensionsEntity, dimensionName, filterState } =
		useMetricFilter2({
			filter,
		});
	const { values, label, operator, type, key } = filterState;

	const formattedLabel = formatDimensionLabel(label);

	const { semanticDefinitions } = useSemanticDefinitions();

	const extendedEventMetadata = {
		...eventMetadata,
		dimension: key,
		dimensionType: type,
	};

	const texts = useMemo(() => {
		const firstCommaSeparatedValuesLabels = values
			?.map((v) => formatMetricFilterLabel(v?.toString() ?? null))
			?.filter((v) => v !== '')
			?.map(formatMetricFilterLabel)
			.join(', ');
		const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

		return {
			title: formattedLabel,
			displayValue,
		};
	}, [values, formattedLabel]);

	const { title, displayValue } = texts;

	const icon = useMemo(() => {
		return typeIcon[type];
	}, [type]);

	const onApply = useCallback(
		(values: FilterV2Value[]) => {
			onFilterCompletenessChange?.(updateFilter(values, operator));
		},
		[onFilterCompletenessChange, operator, updateFilter]
	);

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetadata={extendedEventMetadata}
			valueEditor={
				<ValueEditorByFilter
					filter={filterState}
					options={options}
					displayValue={displayValue}
					values={values}
					onApply={onApply}
					prefixComponent={
						<OperatorSelect
							operator={operator}
							availableOperators={supportedOperatorsByFilterType[type]}
							type={type}
							onSelectOperator={(operator) => {
								onFilterCompletenessChange?.(updateFilter(filter.values, operator));
								onOpenEditor();
							}}
							dimension={filter.key}
						/>
					}
					isLoadingDimensionValues={isLoadingDimensionValues}
					eventMetadata={extendedEventMetadata}
					isEditing={isEditing}
					onOpenEditor={() => {
						reportEvent({
							event: 'filters-edit-values',
							metaData: {
								dimension: filter.key,
								values: filter.values,
								operator: supportedOperatorDisplayNames[operator][filter.type],
								'dimension type': filter.type,
								application: 'metric investigation',
								feature: 'filters',
							},
						});
						onOpenEditor();
					}}
					onCloseEditor={onCloseEditor}
					isEditorAutoOpened={true}
				/>
			}
			isEditing={isEditing}
			titleHoverTooltipBuilder={() => {
				if (!dimensionName || !semanticDefinitions || !dimensionsEntity) return;
				const context = resolveContextFromSemanticsAndName(semanticDefinitions, dimensionsEntity, dimensionName);
				if (!context) return;
				return <MonacoTooltipComponent tooltipMarkdownString={getContextCompletionTooltip(context, dimensionName)} />;
			}}
		/>
	);
};
