import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Button from '@components/Button';
import Popover from 'src/common/components/Popover';

import { DeleteMetricModal } from '@components/DeleteMetricModal';
import Flex from '@components/Flex';
import ListItem from '@components/ListItem';
import { useModal } from '@hooks/ui/useModal';
import { DeleteTrash16, DotsSolid16 } from '@icons/index';
import { TestIDs } from 'src/common/types/test-ids';
import { useMetricMeta } from 'src/lib/metricRules/builder/useCachedBuilderProperties';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import { useEntitlementsCheck } from 'src/services/entitlements';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';

export function MoreButton() {
	const {
		isOpen: isConfirmationModalOpen,
		onOpen: onConfirmationModalOpen,
		onClose: onConfirmationModalClose,
	} = useModal({ defaultIsOpen: false });
	const { metricNameWithFlavor: metricName, metricDisplayName: metricDisplayNameFromCoreReader } =
		useMetricDerivedState();
	const { metricMeta } = useMetricMeta();
	const metricDisplayNameFromMeta = metricMeta.display_name;
	const metricDisplayName =
		metricDisplayNameFromCoreReader == '' ? metricDisplayNameFromMeta : metricDisplayNameFromCoreReader;
	const hasDeletePermissions = usePermissionCheck().isHavingPermission(Permissions.deleteMetric);
	const hasDeleteEntitlement = useEntitlementsCheck({
		action: 'delete',
		resourceType: 'metric',
		resourceId: metricName,
	});
	const { reportEvent } = useReportEvent();
	const { isOpen, onClose, onOpen } = useDisclosure();
	if (!hasDeletePermissions || !hasDeleteEntitlement) return null; // After entitlements rolled out remove this duplication with Frontegg checks
	return (
		<>
			<Popover
				variant="solid"
				isOpen={isOpen}
				onClose={onClose}
				triggerElement={
					<Button
						testId={TestIDs.METRIC_MORE_BUTTON}
						size={'inline'}
						variant={'outline'}
						colorScheme={'black'}
						onClick={onOpen}
						isIconOnly={true}
					>
						<DotsSolid16 />
					</Button>
				}
				placement={'bottom-end'}
			>
				<PopoverContent>
					<Flex alignSelf="center" direction={'column'} py="8px" minWidth="160px" borderRadius="10px">
						<ListItem
							color="gray.1000"
							hoverColor={'blue.100'}
							label={'Delete'}
							size="sm"
							prefixIcon={<DeleteTrash16 />}
							onClick={() => {
								reportEvent({
									event: 'metric-delete-clicked',
									metaData: { metricName, page: 'metric page' },
								});
								onConfirmationModalOpen();
							}}
						/>
					</Flex>
				</PopoverContent>
			</Popover>
			<DeleteMetricModal
				isOpen={isConfirmationModalOpen}
				originPage={'metric page'}
				metricName={metricName}
				metricDisplayName={metricDisplayName}
				onClose={() => {
					onConfirmationModalClose();
					onClose();
				}}
			/>
		</>
	);
}
