import { mergeColorSchemas } from 'src/common/utils/style';

const primaryBlueBaseSchema = {
	normalBackground: 'blue.600',
	hoverBackground: 'blue.600',
	activeBackground: 'blue.600',
	disabledBackground: 'gray.300',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	hoverShadow: `glow.blue`,
	activeShadow: `glow.blue`,
};

const primaryBlackBaseSchema = {
	normalBackground: 'gray.1000',
	hoverBackground: 'gray.1000',
	activeBackground: 'gray.1000',
	disabledBackground: 'gray.400',
	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	hoverShadow: 'glow.black',
	activeShadow: 'glow.black',
};

const blueBaseSchema = {
	normalBackground: 'blue.700',
	hoverBackground: 'blue.800',
	activeBackground: 'blue.700',
	disabledBackground: 'blue.300',

	normalBorderColor: 'blue.700',
	activeBorderColor: 'blue.800',
	hoverBorderColor: 'blue.600',
	disabledBorderColor: 'blue.300',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	activeShadow: `blueActiveShadow.200`,
};

const darkblueBaseSchema = {
	normalBackground: 'blue.1000',
	hoverBackground: 'blue.900',
	activeBackground: 'blue.1100',
	disabledBackground: 'blue.400',

	normalBorderColor: 'blue.1000',
	activeBorderColor: 'blue.900',
	hoverBorderColor: 'blue.1100',
	disabledBorderColor: 'blue.400',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',
};

const greenBaseSchema = {
	normalBackground: 'green.800',
	hoverBackground: 'green.700',
	activeBackground: 'green.900',
	disabledBackground: 'green.300',

	normalBorderColor: 'green.700',
	activeBorderColor: 'green.800',
	hoverBorderColor: 'green.800',
	disabledBorderColor: 'green.300',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',
};

const redBaseSchema = {
	normalBackground: 'red.700',
	hoverBackground: 'red.800',
	activeBackground: 'red.700',
	disabledBackground: 'red.300',

	normalBorderColor: 'red.700',
	activeBorderColor: 'red.800',
	hoverBorderColor: 'red.600',
	disabledBorderColor: 'red.300',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',
};

const grayBaseSchema = {
	normalBackground: 'gray.800',
	hoverBackground: 'gray.900',
	activeBackground: 'gray.800',
	disabledBackground: 'gray.300',

	normalBorderColor: 'none',
	activeBorderColor: 'none',
	hoverBorderColor: 'none',
	disabledBorderColor: 'none',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	activeShadow: 'focus.medium.gray.200',
};

const stoneBaseSchema = {
	normalBackground: 'white',
	hoverBackground: 'gray.200',
	activeBackground: 'gray.200',
	disabledBackground: 'gray.300',

	normalBorderColor: 'none',
	activeBorderColor: 'none',
	hoverBorderColor: 'none',
	disabledBorderColor: 'none',

	normalTextColor: 'gray.800',
	hoverTextColor: 'gray.800',
	activeTextColor: 'gray.800',
	disabledTextColor: 'gray.800',
	activeShadow: 'focus.medium.gray.200',
};

const lightGrayBaseSchema = {
	normalBackground: 'gray.700',
	hoverBackground: 'gray.800',
	activeBackground: 'gray.700',
	disabledBackground: 'gray.300',

	normalBorderColor: 'none',
	activeBorderColor: 'none',
	hoverBorderColor: 'none',
	disabledBorderColor: 'none',

	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	activeShadow: 'focus.medium.gray.200',
};

const darkGrayBaseSchema = {
	normalBackground: 'gray.1000',
	hoverBackground: 'rgba(255, 255, 255, 0.16)',
	activeBackground: 'gray.1000',
	disabledBackground: 'gray.400',
	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',
	disabledOpacity: 0.3,
	activeShadow: 'focus.medium.gray.200',
};

const whiteBaseSchema = {
	normalBackground: 'white',
	hoverBackground: 'rgba(255, 255, 255, 0.88)',
	activeBackground: 'white',
	disabledBackground: 'white',
	normalBorderColor: 'none',
	activeBorderColor: 'none',
	hoverBorderColor: 'none',
	disabledBorderColor: 'none',

	disabledOpacity: 0.3,
	normalTextColor: 'gray.1000',
	hoverTextColor: 'gray.1000',
	activeTextColor: 'gray.1000',
	disabledTextColor: 'gray.1000',
	activeShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.40), 0px 0px 0px 0px rgba(255, 255, 255, 0.40)',
};

const blackBaseSchema = {
	normalBackground: 'gray.1000',
	hoverBackground: 'gray.1100',
	activeBackground: 'gray.1000',
	disabledBackground: 'gray.400',
	normalTextColor: 'white',
	hoverTextColor: 'white',
	activeTextColor: 'white',
	disabledTextColor: 'white',

	activeShadow: 'focus.medium.gray.200',
};

export const baseSchema: Record<string, any> = {
	solid: {
		primaryBlue: primaryBlueBaseSchema,
		primaryBlack: primaryBlackBaseSchema,
		blue: blueBaseSchema,
		red: redBaseSchema,
		green: greenBaseSchema,
		gray: grayBaseSchema,
		black: blackBaseSchema,
		lightGray: lightGrayBaseSchema,
		darkGray: darkGrayBaseSchema,
		white: whiteBaseSchema,
	},
	ghost: {
		blue: mergeColorSchemas(blueBaseSchema, {
			normalTextColor: 'blue.700',
			normalBorderColor: 'blue.600',
			normalBackgroundColor: 'white',
			hoverTextColor: 'blue.700',
			hoverBackground: 'blue.100',
			hoverBorderColor: 'blue.600',
			activeTextColor: 'blue.700',
			activeBackground: 'white',
			activeBorderColor: 'blue.600',
			disabledTextColor: 'blue.300',
			disabledBackground: 'blue.300',
			disabledBorderColor: 'blue.300',
			activeShadow: `blueActiveShadow.100`,
		}),
		red: mergeColorSchemas(redBaseSchema, {
			normalTextColor: 'red.700',
			normalBackgroundColor: 'white',
			hoverBackground: 'white',
			activeBackground: 'white',
			hoverTextColor: 'red.800',
			hoverBorderColor: 'red.800',
			activeTextColor: 'red.700',
			disabledTextColor: 'red.300',
			activeBorderColor: 'red.800',
		}),
		green: mergeColorSchemas(greenBaseSchema, {
			normalTextColor: 'green.800',
			hoverTextColor: 'green.700',
			activeTextColor: 'green.900',
			disabledTextColor: 'green.300',
			activeBorderColor: 'green.900',
			hoverBorderColor: 'green.700',
		}),
		lightGray: mergeColorSchemas(lightGrayBaseSchema, {
			normalTextColor: 'gray.800',
			normalBorderColor: 'gray.300',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.800',
			hoverBackground: 'gray.100',
			hoverBorderColor: 'gray.300',
			activeTextColor: 'gray.800',
			activeBackground: 'white',
			activeBorderColor: 'gray.300',
			disabledTextColor: 'gray.300',
			disabledBackground: 'white',
			disabledBorderColor: 'gray.300',
			activeShadow: 'focus.medium.gray.100',
		}),
		darkGray: mergeColorSchemas(darkGrayBaseSchema, {
			normalTextColor: 'gray.1100',
			hoverBackground: 'transparent',
		}),
		stone: stoneBaseSchema,
		white: mergeColorSchemas(whiteBaseSchema, {
			normalTextColor: 'white',
			normalBorderColor: 'white',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.1000',
			hoverBackground: 'white',
			hoverBorderColor: 'white',
			activeTextColor: 'gray.1000',
			activeBackground: 'white',
			activeBorderColor: 'white',
			disabledTextColor: 'gray.400',
			disabledBackground: 'gray.400',
			disabledBorderColor: 'gray.400',
			activeShadow: 'focus.medium.gray.100',
		}),
		black: mergeColorSchemas(blackBaseSchema, {
			normalTextColor: 'gray.1000',
			normalBorderColor: 'gray.1000',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.1000',
			hoverBackground: 'gray.100',
			hoverBorderColor: 'gray.900',
			activeTextColor: 'gray.1000',
			activeBackground: 'white',
			activeBorderColor: 'gray.900',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'gray.400',
			activeShadow: 'focus.medium.gray.100',
		}),
		gray: mergeColorSchemas(grayBaseSchema, {
			normalTextColor: 'gray.800',
			normalBorderColor: 'gray.400',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.800',
			hoverBackground: 'gray.100',
			hoverBorderColor: 'gray.400',
			activeTextColor: 'gray.800',
			activeBackground: 'white',
			activeBorderColor: 'gray.400',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'gray.400',
			activeShadow: 'focus.medium.gray.100',
		}),
		darkblueBaseSchema: mergeColorSchemas(darkblueBaseSchema, {
			normalTextColor: 'blue.1000',
			hoverTextColor: 'blue.1000',
			activeTextColor: 'blue.1100',
			activeBorderColor: 'blue.1000',
			activeBackground: 'blue.200',
			hoverBackground: 'blue.100',
			hoverBorderColor: 'blue.1000',
			normalBorderColor: 'blue.1000',
			normalBackground: 'white',
		}),
	},
	outline: {
		black: mergeColorSchemas(blackBaseSchema, {
			normalTextColor: 'gray.1000',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.1000',
			hoverBackground: 'gray.200',
			hoverBorderColor: 'none',
			activeTextColor: 'gray.1000',
			activeBackground: 'white',
			activeBorderColor: 'none',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'gray.400',
			activeShadow: 'focus.medium.gray.100',
		}),
		gray: mergeColorSchemas(grayBaseSchema, {
			normalTextColor: 'gray.800',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.800',
			hoverBackground: 'gray.200',
			hoverBorderColor: 'none',
			activeTextColor: 'gray.800',
			activeBackground: 'white',
			activeBorderColor: 'gray.400',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'none',
			activeShadow: 'focus.medium.gray.100',
		}),
		blue: mergeColorSchemas(blueBaseSchema, {
			normalTextColor: 'blue.700',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'blue.700',
			hoverBackground: 'blue.100',
			hoverBorderColor: 'blue.none',
			activeTextColor: 'blue.700',
			activeBackground: 'white',
			activeBorderColor: 'blue.600',
			disabledTextColor: 'blue.300',
			disabledBackground: 'white',
			disabledBorderColor: 'none',
			activeShadow: 'blueActiveShadow.100',
		}),
		red: mergeColorSchemas(redBaseSchema, {
			normalTextColor: 'red.700',
			hoverTextColor: 'red.700',
			activeTextColor: 'red.700',
			disabledTextColor: 'red.300',
			hoverBackground: 'red.100',
			activeBackground: 'red.200',
			disabledBackground: 'white',
		}),
		green: mergeColorSchemas(greenBaseSchema, {
			normalTextColor: 'green.800',
			hoverTextColor: 'green.800',
			activeTextColor: 'green.800',
			disabledTextColor: 'green.300',
			hoverBackground: 'green.100',
			activeBackground: 'green.200',
			disabledBackground: 'white',
		}),
		lightGray: mergeColorSchemas(lightGrayBaseSchema, {
			normalTextColor: 'gray.700',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.800',
			hoverBackground: 'gray.200',
			hoverBorderColor: 'none',
			activeTextColor: 'gray.700',
			activeBackground: 'white',
			activeBorderColor: 'none',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'none',
			activeShadow: 'focus.medium.gray.100',
		}),
		lighterGray: mergeColorSchemas(lightGrayBaseSchema, {
			normalTextColor: 'gray.600',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.800',
			hoverBackground: 'gray.200',
			hoverBorderColor: 'none',
			activeTextColor: 'gray.700',
			activeBackground: 'white',
			activeBorderColor: 'none',
			disabledTextColor: 'gray.400',
			disabledBackground: 'white',
			disabledBorderColor: 'none',
			activeShadow: 'focus.medium.gray.100',
		}),
		darkGray: darkGrayBaseSchema,
		stone: stoneBaseSchema,
		white: mergeColorSchemas(whiteBaseSchema, {
			normalTextColor: 'gray.1000',
			normalBorderColor: 'none',
			normalBackgroundColor: 'white',
			hoverTextColor: 'gray.1000',
			hoverBackground: 'white',
			hoverBorderColor: 'none',
			activeTextColor: 'gray.1000',
			activeBackground: 'white',
			activeBorderColor: 'none',
			disabledTextColor: 'gray.400',
			disabledBackground: 'gray.200',
			disabledBorderColor: 'gray.200',
			activeShadow: 'focus.medium.gray.100',
		}),
		darkblue: mergeColorSchemas(darkblueBaseSchema, {
			normalTextColor: 'blue.1000',
			hoverTextColor: 'blue.900',
			activeTextColor: 'blue.1100',
			disabledTextColor: 'blue.400',
			disabledBorderColor: 'blue.400',
			activeBorderColor: 'blue.1100',
			hoverBackground: 'blue.100',
			disabledBackground: 'white',
		}),
	},
	link: {
		blue: mergeColorSchemas(blueBaseSchema, {
			normalTextColor: 'blue.700',
			hoverTextColor: 'blue.800',
			activeTextColor: 'blue.900',
			disabledTextColor: 'blue.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
		gray: mergeColorSchemas(grayBaseSchema, {
			normalTextColor: 'blue.1000',
			hoverTextColor: 'blue.1000',
			activeTextColor: 'blue.1100',
			activeBorderColor: 'blue.1000',
			activeBackground: 'blue.200',
			hoverBackground: 'blue.100',
			hoverBorderColor: 'blue.1000',
			normalBorderColor: 'blue.1000',
			normalBackground: 'white',
		}),
		black: mergeColorSchemas(blackBaseSchema, {
			normalTextColor: 'blue.1000',
			hoverTextColor: 'blue.1000',
			activeTextColor: 'blue.1100',
			activeBorderColor: 'blue.1000',
			activeBackground: 'blue.200',
			hoverBackground: 'blue.100',
			hoverBorderColor: 'blue.1000',
			normalBorderColor: 'blue.1000',
			normalBackground: 'white',
		}),
		red: mergeColorSchemas(redBaseSchema, {
			normalTextColor: 'red.700',
			hoverTextColor: 'red.800',
			activeTextColor: 'red.900',
			disabledTextColor: 'red.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
		green: mergeColorSchemas(greenBaseSchema, {
			normalTextColor: 'green.700',
			hoverTextColor: 'green.800',
			activeTextColor: 'green.900',
			disabledTextColor: 'green.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
		lightGray: mergeColorSchemas(lightGrayBaseSchema, {
			normalTextColor: 'gray.700',
			hoverTextColor: 'gray.800',
			activeTextColor: 'black',
			disabledTextColor: 'gray.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
		darkGray: mergeColorSchemas(darkGrayBaseSchema, {
			normalTextColor: 'gray.1000',
			hoverTextColor: 'gray.1000',
			activeTextColor: 'gray.1000',
			disabledTextColor: 'gray.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
		stone: stoneBaseSchema,
		white: mergeColorSchemas(whiteBaseSchema, {
			normalTextColor: 'white',
			hoverTextColor: 'white',
			activeTextColor: 'white',
			disabledTextColor: 'gray.300',
			hoverBackground: 'transparent',
			activeBackground: 'transparent',
			disabledBackground: 'transparent',
		}),
	},
};
