import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from 'src/common/components/Select/types';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useNormalizedPropsSelectedOption } from 'src/common/hooks/useNormalizedPropsSelectedOption';
import { usePersistentState } from 'src/common/hooks/usePersistentState';
import { formatMetricFullname } from 'src/lib/metricRules/utils';
import { useReportEvent } from 'src/services/analytics';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { NodeSchemeReturnType } from './NodeScheme/types';
import { getFirstObjectType } from './NodeScheme/utils';
import * as CONSTS from './consts';
import {
	FiltersAndBreakdownItemType,
	FiltersAndBreakdownResponseType,
	ListType,
	TraversDimensionsCallback,
	TraverseActionType,
} from './types';
import { isUntitledMetric } from 'src/lib/metricRules/builder/useMetricBuilder';
import { filterStringByTerm } from 'src/common/utils/utils';
import { useIsFiltersV2Enabled } from '@pages/MetricPage/components/FiltersAndBreakdown/useIsFiltersV2Enabled';
import { MetricPagePath } from '@pages/MetricPage/pageRoutesPaths';
import { SignalPath } from '@pages/SignalPage/pageRoutePaths';

type Props = {
	type: ListType;
	onClose: () => void;
	onAddItems: (result: FiltersAndBreakdownResponseType) => void;
	nodeScheme: NodeSchemeReturnType;
	hasBaseNode?: boolean;
};

type ReturnStateType = {
	searchTerm: string;
	isLoading: boolean;
	isAllSelected: boolean;
	path: FiltersAndBreakdownItemType[];
	relationships: FiltersAndBreakdownItemType[];
	dimensions: FiltersAndBreakdownItemType[];
	selectedDimensionsCount: number;
	normalizedPropsSelectedOption: SelectOption;
};

type ReturnActionType = {
	reset: () => void;
	traverseFiltersAndBreakdowns: TraversDimensionsCallback;
	traverseToSpecificPath: (item?: FiltersAndBreakdownItemType) => void;
	fetchDimensionValues: (item: FiltersAndBreakdownItemType) => void;
	handleSelectAllDimensions: (isAllSelected: boolean) => void;
	handleDimensionSelection: (
		item: FiltersAndBreakdownItemType,
		isSelected: boolean,
		isSingleValueSelectable: boolean
	) => void;
	handleDimensionClick: (item: FiltersAndBreakdownItemType) => void;
	onSearchTermChange: (term: string) => void;
	handleSubmit: (item?: FiltersAndBreakdownItemType) => void;
	setNormalizedPropsSelectedOption: (option: SelectOption) => void;
	setPath: (path: FiltersAndBreakdownItemType[], values: string[]) => void;
};

type ReturnType = [ReturnStateType, ReturnActionType];

const useFiltersAndBreakdownLogic = ({
	type,
	onClose,
	onAddItems,
	nodeScheme,
	hasBaseNode = true,
}: Props): ReturnType => {
	const { reportEvent } = useReportEvent();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [relationships, setRelationships] = useState<FiltersAndBreakdownItemType[]>([]);
	const [dimensions, setDimensions] = useState<FiltersAndBreakdownItemType[]>([]);
	const [path, setPath] = useState<FiltersAndBreakdownItemType[]>([]);
	const [isAllSelected, setIsAllSelected] = useState(false);
	const selectedDimensionsCount = dimensions.filter((e: any) => e.isSelected).length;
	const [{ isLoading, schemeType }, { getFiltersAndBreakdowns, getAllDimensionValues }] = nodeScheme;
	const { metricNameWithoutFlavor, flavor } = useMetricDerivedState();
	const metricName = formatMetricFullname(metricNameWithoutFlavor, flavor?.selectedValue);
	const { normalizedPropsOptions } = useNormalizedPropsSelectedOption();
	const isDefaultDimensionsNormalizedFF = useFeatureFlag(
		'pulse.sightfull2.metricPage.filterBreakdown.viewDefaultOntologyDimensions'
	);
	const defaultNormalizedPropsOption = isDefaultDimensionsNormalizedFF
		? normalizedPropsOptions[1]
		: normalizedPropsOptions[0];
	const [normalizedPropsSelectedOption, setNormalizedPropsSelectedOption] = usePersistentState<SelectOption>(
		'metricPage.normalizedPropsSelectedOption',
		defaultNormalizedPropsOption,
		{ storeType: 'session' }
	);
	const { pathname, search } = window.location;
	const isMetricPage = pathname.includes(`/${MetricPagePath}/`);
	const isSignalPage = pathname.includes(`/${SignalPath}/`);
	const isMetricEditMode = isMetricPage && new URLSearchParams(search).get('pageMode') === 'edit';
	const isMetricOrSignalPage = isSignalPage || (isMetricPage && !isMetricEditMode);
	const isFiltersV2Enabled = useIsFiltersV2Enabled() && isMetricOrSignalPage;

	useEffect(() => {
		const isStateLoaded =
			(schemeType == 'global' || metricNameWithoutFlavor) && !isUntitledMetric({ name: metricNameWithoutFlavor });
		if (isStateLoaded) traverseFiltersAndBreakdowns('init');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [normalizedPropsSelectedOption]);

	const setMainNodepath = (nodeName: string) => {
		const shouldAddToPath = path.length === 0 && hasBaseNode;

		if (shouldAddToPath) {
			setPath([
				{
					key: nodeName,
					label: nodeName,
				},
			]);
		}
	};

	const setImplicatedBaseNode = (item: FiltersAndBreakdownItemType) => {
		const objectTypeName = getFirstObjectType(item.key);
		if (!hasBaseNode && path.length == 0) {
			setPath([
				{
					key: objectTypeName,
					label: objectTypeName,
				},
			]);
		}
		if (!hasBaseNode) {
			item.key = item.key.split('.')[1];
		}
		return objectTypeName;
	};

	const reset = () => {
		setPath([]);
		setDimensions([]);
		setRelationships([]);
		setIsAllSelected(false);
	};

	const setNewPath = (
		action: TraverseActionType,
		item?: FiltersAndBreakdownItemType
	): FiltersAndBreakdownItemType[] => {
		const initialPath = path || CONSTS.DEFAULT_PATH_VALUE;
		let newPath = action === CONSTS.INIT ? initialPath : (CONSTS.DEFAULT_PATH_VALUE as FiltersAndBreakdownItemType[]);

		switch (action) {
			case CONSTS.PUSH: {
				if (item) {
					newPath = [...path, item];
					setPath((path) => [...path, item]);
				}
				break;
			}

			case CONSTS.POP: {
				if (item) {
					newPath = cutPath(item);
				} else {
					newPath = path.slice(0, -1);
				}

				setPath(newPath);
				break;
			}

			default:
				break;
		}

		return newPath;
	};

	const getPathStr = (pathWithNoDimenstions: FiltersAndBreakdownItemType[]): string => {
		let pathStr = '';

		pathWithNoDimenstions.forEach((item: FiltersAndBreakdownItemType, i) => {
			const shouldExcludeSeparator = i + 1 === pathWithNoDimenstions.length;

			if (item.type !== 'dimension') {
				pathStr += item.key + (shouldExcludeSeparator ? '' : '.');
			}
		});

		return pathStr;
	};

	const handlePath = (action: TraverseActionType = CONSTS.PUSH, item?: FiltersAndBreakdownItemType): string => {
		const newPath = setNewPath(action, item);
		const pathWithNoDimenstions = newPath.filter((e: FiltersAndBreakdownItemType) => e.type !== 'dimension');
		const pathStr = getPathStr(pathWithNoDimenstions);

		return pathStr;
	};

	const cutPath = (item: FiltersAndBreakdownItemType) => {
		const index = path.findIndex((pathElement) => item.key === pathElement.key);

		if (!index && index !== 0) {
			throw Error('Cant traverse path, no index found');
		}

		const newPath = path.filter((_, i) => i <= index);

		setPath(newPath);

		return newPath;
	};

	const traverseFiltersAndBreakdowns = async (action: TraverseActionType, item?: FiltersAndBreakdownItemType) => {
		let pathKey;

		const isPush = action === CONSTS.PUSH;
		const isPop = action === CONSTS.POP;
		const isInit = action === CONSTS.INIT;
		if (isPush && !item) {
			throw Error('dont push action without item');
		}

		const isPushWithItem = isPush && item;
		const shouldHandlePath = ((isInit || isPop) && path.length) || isPushWithItem;

		if (shouldHandlePath) {
			if (isPush && item) {
				setImplicatedBaseNode(item);
			}
			pathKey = handlePath(action, item);
		}

		setDimensions([]);
		setRelationships([]);

		const response = await getFiltersAndBreakdowns({
			prefixPath: pathKey || '',
			shouldIncludeNotNormalizedProps: normalizedPropsSelectedOption.value === 'all-dimensions',
		});

		if (response) {
			setMainNodepath(response.type);
			setRelationships(response.relationships);
			setDimensions(
				response.dimensions.map((dimension) => ({
					...dimension,
					isSelectable: type === CONSTS.COLUMN_MODEL_TYPE,
					isSelected: false,
				}))
			);
		}
	};

	const traverseToSpecificPath = (item?: FiltersAndBreakdownItemType) => {
		traverseFiltersAndBreakdowns(CONSTS.POP, item);
	};

	const fetchDimensionValues = async (
		item: FiltersAndBreakdownItemType,
		selectedValues: any[] = [],
		pathKeyOverride?: string
	) => {
		const nodeType = setImplicatedBaseNode(item);
		const pathKey = handlePath(CONSTS.PUSH, item);

		setDimensions([]);
		setRelationships([]);
		const values = await getAllDimensionValues({
			dimensionLabel: item.key,
			prefixPath: pathKeyOverride || pathKey || nodeType,
			shouldIncludeNotNormalizedProps: normalizedPropsSelectedOption == CONSTS.NORMALIZED_PROPS_OPTIONS[1],
		});

		setDimensions(
			values
				? values.map((v) => {
						if (selectedValues.includes(v.value) || selectedValues.includes(v.label)) {
							v.isSelected = true;
						}
						return v;
				  })
				: []
		);
	};

	const handleSelectAllDimensions = (isAllSelected: boolean) => {
		const editedDimensions = [...dimensions].filter((dimension) => filterStringByTerm(searchTerm, dimension.label));
		editedDimensions.forEach((item) => (item.isSelected = isAllSelected));
		setDimensions(editedDimensions);
		setIsAllSelected(isAllSelected);
		reportEvent({
			event: `metric-filters-${isAllSelected ? 'select' : 'unselect'}-all-clicked`,
			metaData: {
				dimensionName: metricName,
			},
		});
	};

	const handleDimensionSelection = (
		item: FiltersAndBreakdownItemType,
		isSelected: boolean,
		isSingleValueSelectable: boolean
	) => {
		const editedDimensions = [...dimensions];
		let localIsAllSelected = true;

		for (let i = 0; i < editedDimensions.length; i++) {
			const dimension = dimensions[i];

			if (dimension.key === item.key) {
				dimension.isSelected = item.isSelected && isSingleValueSelectable ? true : isSelected;
			} else if (isSingleValueSelectable) {
				dimension.isSelected = false;
			}

			const shouldDisabledSelectAll = localIsAllSelected && !dimension.isSelected;

			if (shouldDisabledSelectAll) {
				localIsAllSelected = false;
			}
		}

		const shouldUpdateSelectAll = isAllSelected !== localIsAllSelected;

		if (shouldUpdateSelectAll) {
			setIsAllSelected(localIsAllSelected);
		}

		setDimensions(editedDimensions);
	};

	const handleDimensionClick = async (item: FiltersAndBreakdownItemType) => {
		if (type === CONSTS.FILTER_MODEL_TYPE) {
			setSearchTerm('');
			setIsAllSelected(false);

			if (!isFiltersV2Enabled) {
				return fetchDimensionValues(item);
			}
		}

		handleSubmit(item);
	};

	const getResponseType = () => {
		switch (type) {
			case CONSTS.FILTER_MODEL_TYPE:
				return 'filterBy';
			case CONSTS.BREAKDOWN_MODEL_TYPE:
				return 'groupBy';
			case CONSTS.COLUMN_MODEL_TYPE:
				return 'columns';
			default:
				return 'unknown';
		}
	};

	const handleSubmit = (item?: FiltersAndBreakdownItemType) => {
		const items = getSelectedResponse(item);
		const type = getResponseType();
		reportEvent({ event: `metric-${type}-apply-clicked` });
		if (items.length) {
			onAddItems({
				type,
				items,
			});
		}
		onClose();
	};

	const getItem = (item: FiltersAndBreakdownItemType): FiltersAndBreakdownItemType => {
		const key = getKey(item.key);

		return {
			key,
			value: item.value,
			label: item.key,
		};
	};

	const getSelectedResponse = (item?: FiltersAndBreakdownItemType): FiltersAndBreakdownItemType[] => {
		if (item && ([CONSTS.BREAKDOWN_MODEL_TYPE].includes(type) || isFiltersV2Enabled)) {
			return [getItem(item)];
		}

		return dimensions
			.filter((item: FiltersAndBreakdownItemType) => item.isSelected)
			.map((item: FiltersAndBreakdownItemType) => getItem(item));
	};

	const getKey = (key: string): string => {
		const fullPathKey = path.reduce((str, pathItem) => `${str}${str ? '.' : ''}${pathItem.key}`, '');

		switch (type) {
			case CONSTS.FILTER_MODEL_TYPE:
				if (isFiltersV2Enabled) {
					return `${fullPathKey}.${key}`;
				} else {
					return fullPathKey;
				}

			case CONSTS.COLUMN_MODEL_TYPE:
			case CONSTS.BREAKDOWN_MODEL_TYPE:
				return `${fullPathKey}.${key}`;

			default:
				return 'unknown';
		}
	};

	const onSearchTermChange = (newSearchTerm: string) => {
		setDimensions((dimensions) => {
			const hasSelectableItems = dimensions.find((dimension) => dimension.isSelectable) !== undefined;

			const localDimensions = dimensions.filter(
				(dimension) => !dimension.isValueDerivedFromSearchTerm || dimension.isSelected
			);

			if (
				hasSelectableItems &&
				newSearchTerm !== '' &&
				!dimensions.map((dimension) => dimension.label).includes(newSearchTerm)
			) {
				localDimensions.push({
					type: 'dimension',
					label: newSearchTerm,
					value: newSearchTerm,
					key: newSearchTerm,
					isSelectable: true,
					isSelected: false,
					isValueDerivedFromSearchTerm: true,
				});
			}

			setSearchTerm(newSearchTerm);
			return localDimensions;
		});
	};

	const openPath = (items: FiltersAndBreakdownItemType[], values: any[] = []) => {
		setPath(items.slice(0, -1));

		if (type === CONSTS.FILTER_MODEL_TYPE) {
			setSearchTerm('');
			setIsAllSelected(false);
			return fetchDimensionValues(
				{ type: 'dimension', ...items.reverse()[0] },
				values,
				items
					.reverse()
					.slice(0, -1)
					.map((s) => s.key)
					.join('.')
			);
		}
	};

	const actions = {
		reset,
		traverseFiltersAndBreakdowns,
		traverseToSpecificPath,
		fetchDimensionValues,
		handleSelectAllDimensions,
		handleDimensionSelection,
		handleDimensionClick,
		onSearchTermChange: useCallback(onSearchTermChange, []),
		handleSubmit,
		setNormalizedPropsSelectedOption,
		setPath: openPath,
	};

	return [
		{
			searchTerm,
			isLoading,
			isAllSelected,
			path,
			relationships,
			dimensions,
			selectedDimensionsCount,
			normalizedPropsSelectedOption,
		},
		actions,
	];
};

export default useFiltersAndBreakdownLogic;
