import { ReactNode } from 'react';
import Flex from 'src/common/components/Flex';
import { AddSolid14 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';

export const LabelWithIcon = ({
	isFieldsDisabled = true,
	text,
	onClick,
	icon = <AddSolid14 />,
}: {
	isFieldsDisabled?: boolean;
	text: string;
	onClick?: VoidFunction;
	icon?: ReactNode;
}) => (
	<Typography
		cursor={'inherit'}
		onClick={() => !isFieldsDisabled && onClick?.()}
		color={'gray.1100'}
		variant="DesktopH10Regular"
		textAlign="start"
	>
		<Flex
			as={'button'}
			cursor={isFieldsDisabled ? 'not-allowed' : 'pointer'}
			transition={'0.1s'}
			opacity={isFieldsDisabled ? 0.3 : 1}
			_hover={{ color: isFieldsDisabled ? 'gray.700' : 'gray.1000' }}
			color="gray.700"
			alignItems={'center'}
		>
			{icon}
			<Flex marginLeft={'6px'}>
				<Typography cursor={'inherit'} variant="DesktopH10Regular" textAlign="start">
					{text}
				</Typography>
			</Flex>
		</Flex>
	</Typography>
);
