import { Popover, PopoverArrow, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { showIntercom } from '@services/intercom';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { isConnectorStatus } from 'src/common/components/TopNavigation/DataConnectorsModal/utils';
import Typography from 'src/common/components/Typography';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { ConnectorStatus } from 'src/generated/graphql';

const ConnectorStatusLabel: { [key in ConnectorStatus]: string } = {
	BROKEN: 'broken',
	ACTIVE: 'active',
	DELAYED: 'delayed',
	SETUP_INCOMPLETE: 'in progress',
	PAUSED: 'paused',
	UNAUTHORIZED: 'incomplete',
};

function statusToLabel(status: string | null | undefined) {
	if (!status) return ConnectorStatusLabel[ConnectorStatus.Broken];
	if (isConnectorStatus(status)) {
		return ConnectorStatusLabel[status];
	}
	return ConnectorStatusLabel[ConnectorStatus.Broken];
}

export function StatusBadgeRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.valueFormatted || params?.value;
		const cellLabel = useMemo(() => capitalizedFirstLetter(statusToLabel(cellValue)), [cellValue]);

		const contentByStatus = useMemo(() => {
			switch (cellValue) {
				case ConnectorStatus.Broken:
					return {
						color: 'red',
						label: (
							<Typography cursor={'pointer'} onClick={showIntercom} wordBreak="break-word" variant={'Paragraph14R'}>
								Connector setup is broken. <u>Contact us for help.</u>
							</Typography>
						),
						colorShape: 700,
					};
				case ConnectorStatus.Active:
					return { color: 'green', label: '', colorShape: 700 };
				case ConnectorStatus.SetupIncomplete:
					return {
						color: 'blue',
						label: (
							<Typography cursor={'pointer'} wordBreak="break-word" variant={'Paragraph14R'}>
								We&apos;re setting up your connector and will be in touch shortly.
							</Typography>
						),
						colorShape: 700,
					};
				case ConnectorStatus.Unauthorized:
					return {
						color: 'gray',
						label: (
							<Typography cursor={'pointer'} wordBreak="break-word" variant={'Paragraph14R'}>
								Setup incomplete. Re-authorize the connector to complete the setup.
							</Typography>
						),
						colorShape: 700,
					};
				case ConnectorStatus.Paused:
					return {
						color: 'gray',
						label: '',
						colorShape: 700,
					};
				case ConnectorStatus.Delayed:
					return {
						color: 'yellow',
						label: 'The data is delayed for a longer time than expected for the update. This should be resolved soon.',
						colorShape: 800,
					};
				default:
					return { color: 'gray', label: '', colorShape: 700 };
			}
		}, [cellValue]);

		return (
			<Popover placement={'right'} variant="solid" arrowPadding={16} closeOnBlur={true} trigger="hover" isLazy>
				<PopoverTrigger>
					<Box cursor={'pointer'}>
						<Flex
							padding={'2px 8px'}
							borderRadius={'4px'}
							backgroundColor={`${contentByStatus.color}.${contentByStatus.color === 'gray' ? '200' : '100'}`}
						>
							<Typography
								variant={'DesktopH10Regular'}
								color={`${contentByStatus.color}.${contentByStatus.colorShape}`}
								noOfLines={1}
								wordBreak="break-all"
							>
								{cellLabel}
							</Typography>
						</Flex>
					</Box>
				</PopoverTrigger>
				{contentByStatus.label && (
					<Portal>
						<PopoverContent backgroundColor={'black'} padding={'10px 12px'} maxWidth={'256px'} borderRadius={'8px'}>
							<PopoverArrow backgroundColor={'black'} height={'32px'} width={'32px'} />
							<Flex>
								<Typography color={'white'} wordBreak="break-word" variant={'Paragraph14R'}>
									{contentByStatus.label}
								</Typography>
							</Flex>
						</PopoverContent>
					</Portal>
				)}
			</Popover>
		);
	}
	return Cell;
}
