import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Button from '@components/Button';
import { DeleteMetricModal } from '@components/DeleteMetricModal';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import { SearchDropdown } from '@components/SearchDropdown';
import { SearchDropdownBottomButton } from '@components/SearchDropdown/SearchDropdownBottomButton';
import { Option } from '@components/SearchDropdown/types';
import useOutsideClick from '@hooks/interaction/useOutsideClick';
import { useModal } from '@hooks/ui/useModal';
import useToast from '@hooks/ui/useToast';
import { DeleteTrash16, EyeHide16, EyeViewBlack16, Folder20, More16 } from '@icons/index';
import { CreateEditCategoryModal } from '@pages/MetricCatalog/CreateEditCategoryModal/CreateEditCategoryModal';
import { useMetricCategoriesV2 } from '@pages/MetricCatalog/hooks/useMetricCategoriesV2';
import { useMemo, useRef, useState } from 'react';
import {
	MetricCatalogV2Query,
	useChangeMetricCategoryMutation,
	useSetMetricHiddenMutation,
} from 'src/generated/graphql';
import { MetricCatalogV2 } from 'src/queries/core-reader';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import { useEntitlementsCheck } from 'src/services/entitlements';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { CategoryV2 } from 'src/types/metric';

export function MetricCardMoreButton({
	className,
	hidden,
	metricName,
	metricDisplayName,
	metricId,
	metricCategoryId,
	metricCategoryName,
	isPendingSetupCategory,
}: {
	className: string;
	hidden: boolean;
	metricName: string;
	metricDisplayName?: string;
	metricId?: string;
	metricCategoryId?: string;
	metricCategoryName?: string;
	isPendingSetupCategory: boolean;
}) {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const {
		isOpen: isConfirmationModalOpen,
		onOpen: onConfirmationModalOpen,
		onClose: onConfirmationModalClose,
	} = useModal({ defaultIsOpen: false });
	const {
		isOpen: isCreateCategoryModalOpen,
		onOpen: onCreateCategoryModalOpen,
		onClose: onCreateCategoryModalClose,
	} = useModal({ defaultIsOpen: false });
	const [metricCategories] = useMetricCategoriesV2();
	const hasEditEntitlement = useEntitlementsCheck({
		action: 'edit',
		resourceType: 'metric',
		resourceId: metricName,
	});
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const hasDeleteEntitlement = useEntitlementsCheck({
		action: 'delete',
		resourceType: 'metric',
		resourceId: metricName,
	});
	const hasDeletePermissions = usePermissionCheck().isHavingPermission(Permissions.deleteMetric);
	const { reportEvent } = useReportEvent();
	const [toggleHiddenMutate] = useSetMetricHiddenMutation();
	const showToast = useToast();
	const [changeMetricCategory] = useChangeMetricCategoryMutation();
	const categoriesToOptions = (categories: CategoryV2[]): Option[] =>
		categories.map(({ id, name }) => ({ title: name, value: id || '' }));

	const filteredCategoriesOptions = useMemo(() => {
		return categoriesToOptions(metricCategories)?.filter(({ value }) => value !== metricCategoryId);
	}, [metricCategories, metricCategoryId]);
	const { invalidateCache } = useInvalidateCache();

	const menuRef = useRef<HTMLDivElement>(null);
	const [isAdditionalMenuOpen, setIsAdditionalMenuOpen] = useState(false);
	const initialFocusRef = useRef<{ focus(): void }>({ focus: () => {} });
	const closeOnBlur = !isAdditionalMenuOpen;
	useOutsideClick(menuRef, () => {
		if (closeOnBlur) {
			onClose();
		}
	});
	const [searchedCategoryName, setSearchedCategoryName] = useState('');

	const moveMetric = async ({ value, title }: { value: string | null; title: string }) => {
		reportEvent({
			event: 'metric-moved-between-categories',
			metaData: { metricName, fromCategory: metricCategoryName, toCategory: title },
		});
		try {
			await changeMetricCategory({
				variables: {
					metricId,
					newCategory: value,
				},
			});
			onClose();
			invalidateCache();
			// TODO: This toast should wait for metric catalog to reload, otherwise we see the toast before we see the metric
			// TODO: actually move from the category..
			showToast({ variant: 'ok', message: `Successfully moved to ${title}` });
		} catch {
			showToast({ variant: 'error', message: 'Failed to move metric' });
		}
	};

	const toggleHidden = () => {
		toggleHiddenMutate({
			variables: {
				metric: metricName,
				hidden: !hidden,
			},
			optimisticResponse: {
				update_metrics_v2: {
					returning: [
						{
							metric: metricName,
							hidden: !hidden,
							__typename: 'metrics_v2',
						},
					],
				},
			},
			update: (cache) => {
				const catalogFromCache = cache.readQuery<MetricCatalogV2Query>({
					query: MetricCatalogV2,
				});

				if (catalogFromCache) {
					const newCatalogCategories = catalogFromCache.metricCatalogV2.categories.map((category) => {
						return {
							...category,
							metrics: category.metrics.map((metric) => {
								if (metric.metricName == metricName) {
									return {
										...metric,
										meta: {
											...metric.meta,
											hidden: !hidden,
										},
									};
								}
								return metric;
							}),
						};
					});

					cache.writeQuery({
						query: MetricCatalogV2,
						data: {
							metricCatalogV2: {
								...catalogFromCache.metricCatalogV2,
								categories: newCatalogCategories,
							},
						},
					});
				}
			},
		});
	};

	return (
		<Box position="absolute" padding="12px" opacity={'0'} className={className}>
			<Popover
				placement="bottom-start"
				variant="solid"
				isOpen={isOpen}
				onClose={onClose}
				triggerElement={
					<Flex position="relative">
						<Button
							size="xs"
							variant="outline"
							colorScheme="gray"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								if (isOpen) onClose();
								else onOpen();
							}}
							isActive={isOpen}
							isIconOnly={true}
							color={'gray.900'}
							_hover={{ bgColor: 'gray.200' }}
							blendMode={'multiply'}
						>
							<More16 />
						</Button>
					</Flex>
				}
				closeOnBlur={closeOnBlur}
				popoverContentProps={{
					borderRadius: '8px',
					border: `1px solid ${colors.gray[300]}`,
				}}
			>
				<PopoverContent>
					<Flex alignSelf="center" direction={'column'} py="8px" width="170px" borderRadius="4px" ref={menuRef}>
						{!isPendingSetupCategory && (
							<>
								<ListItem
									color="gray.1000"
									hoverColor={'blue.100'}
									label={hidden ? 'Unhide Metric' : 'Hide Metric'}
									size="sm"
									prefixIcon={hidden ? <EyeViewBlack16 /> : <EyeHide16 />}
									onClick={() => {
										reportEvent({
											event: 'toggle-metric-hidden-button-clicked',
											metaData: { metricName, hide: !hidden },
										});
										toggleHidden();
										if (!hidden) {
											showToast({ variant: 'ok', message: 'Metric was successfully hidden.' });
										}
									}}
								/>
								{/* After entitlements rolled out remove this duplication with Frontegg checks*/}
								{hasEditPermission && hasEditEntitlement && (
									<Popover
										triggerElement={
											<ListItem
												color="gray.1000"
												hoverColor={'blue.100'}
												label="Move to..."
												size="sm"
												prefixIcon={<Folder20 />}
												onClick={() => setIsAdditionalMenuOpen(true)}
												state={isAdditionalMenuOpen ? 'selected' : 'enabled'}
											/>
										}
										isOpen={isAdditionalMenuOpen}
										placement={'right-start'}
										onClose={() => setIsAdditionalMenuOpen(false)}
										popoverContentProps={{
											borderRadius: '8px',
											bottom: '45px',
										}}
										initialFocusRef={initialFocusRef}
									>
										<SearchDropdown
											options={filteredCategoriesOptions}
											onOptionClick={async (option) => {
												await moveMetric(option);
											}}
											placeholder={'Search destination category'}
											initialFocusRef={initialFocusRef}
											onInputChangeCallback={setSearchedCategoryName}
										>
											<SearchDropdownBottomButton
												onClick={onCreateCategoryModalOpen}
												eventName={'metric-move-to-new-category-clicked'}
												eventMetadata={{ metricName, page: 'metric catalog' }}
											>
												Create new category
											</SearchDropdownBottomButton>
										</SearchDropdown>
									</Popover>
								)}
							</>
						)}
						{/* After entitlements rolled out remove this duplication with Frontegg checks*/}
						{hasDeletePermissions && hasDeleteEntitlement && (
							<>
								{!isPendingSetupCategory && (
									<Box paddingX={'12px'} paddingY={'8px'}>
										<Divider direction={'horizontal'}></Divider>
									</Box>
								)}
								<ListItem
									color="gray.1000"
									hoverColor={'blue.100'}
									label={'Delete'}
									size="sm"
									prefixIcon={<DeleteTrash16 />}
									onClick={() => {
										reportEvent({
											event: 'metric-delete-clicked',
											metaData: { metricName, page: 'metric catalog' },
										});
										onConfirmationModalOpen();
									}}
								/>
							</>
						)}
					</Flex>
				</PopoverContent>
			</Popover>
			<DeleteMetricModal
				isOpen={isConfirmationModalOpen}
				originPage={'metric catalog'}
				metricName={metricName}
				metricDisplayName={metricDisplayName}
				onClose={onConfirmationModalClose}
			/>
			{isCreateCategoryModalOpen && (
				<CreateEditCategoryModal
					isOpen={isCreateCategoryModalOpen}
					onClose={onCreateCategoryModalClose}
					onSubmitCallback={moveMetric}
					newCategoryName={searchedCategoryName}
				/>
			)}
		</Box>
	);
}
