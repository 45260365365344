import { Option } from '@components/SearchDropdown/types';
import Checkbox from '@components/Checkbox';
import Center from '@components/Center';
import { CheckboxSquare16 } from '@icons/index';
import { Box } from '@chakra-ui/react';
import Divider from '@components/Divider';
import React, { ReactNode, useCallback, useMemo } from 'react';
import ListItem from '../ListItem';
import { EventMetadataObject, useReportEvent } from '@services/analytics';
import { List } from 'react-virtualized';
import { OPTION_LIST_HEIGHT_PX, OPTION_LIST_WIDTH_PX, CUSTOM_OPTION_HEIGHT_PX, OPTION_HEIGHT_PX } from './constants';

type Props = {
	selectedValues: (string | null)[];
	setSelectedValues?: (ids: (string | null)[]) => void;
	optionsArray: Option[];
	isMultiSelect?: boolean;
	searchValue: string;
	selectAllEventMetadata?: EventMetadataObject;
	selectAllMatchingEventMetadata?: EventMetadataObject;
};

export const SelectableSearchDropdown = ({
	selectedValues,
	setSelectedValues,
	optionsArray,
	isMultiSelect = true,
	searchValue,
	selectAllEventMetadata,
	selectAllMatchingEventMetadata,
}: Props) => {
	const { reportEvent } = useReportEvent();
	const isSelectedAll = selectedValues.length > 0 && selectedValues.length === optionsArray.length;

	const handleSelectOptionClick = useCallback(
		(option: Option) => {
			if (!isMultiSelect) {
				setSelectedValues?.([option.value]);
				return;
			}

			if (selectedValues.includes(option.value)) {
				setSelectedValues?.(selectedValues.filter((id) => id !== option.value));
			} else {
				setSelectedValues?.([...selectedValues, option.value]);
			}
		},
		[isMultiSelect, selectedValues, setSelectedValues]
	);

	const handleSelectAllClick = useCallback(() => {
		if (searchValue) {
			reportEvent({
				...selectAllMatchingEventMetadata,
				metaData: {
					currentState: isSelectedAll ? 'unselect matching' : 'select matching',
					...selectAllMatchingEventMetadata?.metaData,
				},
			});
		} else {
			reportEvent({
				...selectAllEventMetadata,
				metaData: {
					currentState: isSelectedAll ? 'unselect all' : 'select all',
					...selectAllEventMetadata?.metaData,
				},
			});
		}

		if (!isSelectedAll) {
			setSelectedValues?.(optionsArray.map((el) => el.value));
		} else {
			setSelectedValues?.([]);
		}
	}, [
		isSelectedAll,
		optionsArray,
		reportEvent,
		searchValue,
		selectAllEventMetadata,
		selectAllMatchingEventMetadata,
		setSelectedValues,
	]);

	const OptionSelectAllElement = useCallback(
		() => (
			<ListItem
				hoverColor="blue.100"
				color="gray.1000"
				size={'sm'}
				label={searchValue ? 'Select all matching' : 'Select all'}
				onClick={(event) => {
					event?.stopPropagation();
					handleSelectAllClick();
				}}
				hasRoundedCorners
				prefixComponent={
					<Checkbox
						checkboxColor="blue.600"
						isIntermediate={selectedValues.length > 0 && !isSelectedAll}
						isChecked={isSelectedAll}
						intermediateCustomIcon={
							<Center p={'4px'} backgroundColor={'white'} _hover={{ bgColor: 'blue.600' }}>
								<CheckboxSquare16 />
							</Center>
						}
					/>
				}
			/>
		),
		[isSelectedAll, searchValue, selectedValues, handleSelectAllClick]
	);

	const OptionSpecifiedSearchElement = useCallback(
		() => (
			<Box>
				<Divider key={'specified-search-item-divider'} marginY="12px" color="natural.200" direction="horizontal" />
				<ListItem
					hoverColor="blue.100"
					color="gray.1000"
					size={'sm'}
					label={`Specify: ${searchValue}`}
					onClick={(event) => {
						event?.stopPropagation();
						handleSelectOptionClick({ title: searchValue, value: searchValue });
					}}
					hasRoundedCorners
					prefixComponent={<Checkbox checkboxColor="blue.600" isChecked={selectedValues.includes(searchValue)} />}
					noOfLines={1}
				/>
			</Box>
		),
		[handleSelectOptionClick, searchValue, selectedValues]
	);

	const selectableOptionsArray = useMemo(() => {
		const options = optionsArray.map((el) => (
			<ListItem
				key={el.value}
				hoverColor="blue.100"
				color="gray.1000"
				size={'sm'}
				label={el?.title ?? el?.value}
				onClick={(event) => {
					event?.stopPropagation();
					handleSelectOptionClick(el);
				}}
				hasRoundedCorners
				prefixComponent={
					<Checkbox
						variant={!isMultiSelect ? 'rounded' : undefined}
						isFluid={!isMultiSelect}
						checkboxColor="blue.600"
						isChecked={selectedValues.includes(el.value)}
					/>
				}
				noOfLines={1}
			/>
		));

		const optionsElementsArray: ReactNode[] = [...options];

		if (isMultiSelect) {
			optionsElementsArray.unshift(<OptionSelectAllElement key={'option-select-all-element'} />);
		}

		if (searchValue) {
			optionsElementsArray.push(<OptionSpecifiedSearchElement key={'option-specified-search-element'} />);
		}

		return optionsElementsArray;
	}, [
		optionsArray,
		isMultiSelect,
		searchValue,
		selectedValues,
		handleSelectOptionClick,
		OptionSelectAllElement,
		OptionSpecifiedSearchElement,
	]);

	return (
		<List
			rowCount={selectableOptionsArray.length}
			height={OPTION_LIST_HEIGHT_PX}
			rowHeight={(index) => {
				if (index.index === selectableOptionsArray.length - 1 && searchValue) {
					return CUSTOM_OPTION_HEIGHT_PX;
				}

				return OPTION_HEIGHT_PX;
			}}
			rowRenderer={({ key, index, style }) => {
				return (
					<Box key={key} style={style}>
						{selectableOptionsArray[index]}
					</Box>
				);
			}}
			width={OPTION_LIST_WIDTH_PX}
		/>
	);
};
