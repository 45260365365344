import { useCallback } from 'react';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';
import { useParameters } from '@services/useParameters';

export function useMetricPageParameters() {
	const { allParameters } = useParameters();
	const { searchParams, setPartialSearchParams } = useMetricPageSearchParams();

	const setParameterInSearchParams = useCallback(
		(name: string, value: string) => {
			const parameter = allParameters.find((param) => param.name === name);
			if (!parameter) return;
			const params = searchParams.parameters ?? {};
			params[name] = value;
			setPartialSearchParams({ parameters: params });
		},
		[allParameters, searchParams.parameters, setPartialSearchParams]
	);

	const clearParameterFromSearchParams = useCallback(
		(name: string) => {
			const params = searchParams.parameters ?? {};
			if (!params[name]) return;
			delete params[name];
			setPartialSearchParams({ parameters: Object.keys(params).length > 0 ? params : undefined });
		},
		[searchParams.parameters, setPartialSearchParams]
	);

	const isParameterInSearchParams = useCallback(
		(name: string) => {
			const params = searchParams.parameters ?? {};
			return params[name] !== undefined;
		},
		[searchParams.parameters]
	);

	return {
		setParameterInSearchParams,
		clearParameterFromSearchParams,
		isParameterInSearchParams,
	};
}
