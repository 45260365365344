import { ColumnEvent, ColumnState } from 'ag-grid-community';
import { useAtom } from 'jotai';
import omit from 'lodash/omit';
import { useCallback, useEffect } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { defaultColumnState } from 'src/lib/metricRules/DerivedStateCalculators/calcTableColumnState';
import { TableRefAtom } from '../atoms/TableRef';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';
import { useGridTableApi, useIsGridTableV2Enabled } from '@components/GridTable';

const columnEvents = ['columnMoved', 'columnPinned', 'sortChanged', 'columnVisible'];

export function useUpdateTableColumnState(shouldAddListener = true) {
	const isGridTableV2 = useIsGridTableV2Enabled();
	const gridTableApiV2 = useGridTableApi().gridTableApi;
	const [tableRefApi] = useAtom(TableRefAtom);
	const tableApi = isGridTableV2 ? gridTableApiV2 : tableRefApi;
	const { setPartialSearchParams } = useMetricPageSearchParams();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const setColumnStateSearchParam = useCallback(
		(tableColumnState?: Partial<ColumnState>[]) => {
			setPartialSearchParams({
				tableColumnState,
			});
		},
		[setPartialSearchParams]
	);

	const getColumnState = useCallback(() => {
		const tableColumnState = tableApi?.columnApi?.getColumnState();
		return tableColumnState?.map((columnState) =>
			omit(
				columnState,
				Object.entries(columnState)
					.filter(([key, value]) => value == defaultColumnState[key as keyof ColumnState])
					.map(([key]) => key)
			)
		);
	}, [tableApi]);

	const updateColumnState = useCallback(
		(columnEvents: ColumnEvent) => {
			if (columnEvents.source === 'gridOptionsChanged') return;
			setColumnStateSearchParam(getColumnState());
		},
		[setColumnStateSearchParam, getColumnState]
	);

	useEffect(() => {
		if (!isSightfull2 || !shouldAddListener || !tableApi?.columnApi) return;

		columnEvents.forEach((event) => {
			tableApi?.api?.addEventListener(event, updateColumnState);
		});

		return () => {
			columnEvents.forEach((event) => {
				tableApi?.api?.removeEventListener(event, updateColumnState);
			});
		};
	}, [tableApi, isSightfull2, updateColumnState, shouldAddListener]);

	return { getColumnState };
}
