import { Copy16, FilterTool12 } from '@icons/index';
import { ColDef, ColDefUtil, GetContextMenuItemsParams, MenuItemDef, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { CellRenderer } from 'src/common/components/Table/CellRenderer';
import {
	datesDefaultFormatting,
	getDataType,
	numbersDefaultFormatting,
	textDefaultFormatting,
} from 'src/common/components/Table/utils';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import useToast from 'src/common/hooks/ui/useToast';
import { datesSorting, numbersSorting, textSorting } from 'src/common/utils/sort';
import { Filter } from 'src/generated/graphql';
import useDimensionsState from 'src/pages/MetricPage/components/InvestigatePanel/useDimensionsState';
import { PulseColDef } from 'src/pages/MetricPage/utils/state.types';
import { useReportEvent } from 'src/services/analytics';

export function useDefaultColDef(groupable: boolean, clickable: boolean): ColDef {
	const { decimalDigits } = useTenantConfig();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const defaultColDef: ColDef = useMemo(
		() => ({
			editable: false,
			sortable: true,
			filter: true,
			resizable: true,
			enableValue: true,
			enableRowGroup: groupable,
			enablePivot: true,
			cellStyle: { alignItems: 'center', display: 'flex' },
			cellRenderer: CellRenderer(clickable),
			comparator: (valueA: any, valueB: any) => {
				const isANullish = valueA == null;
				const isBNullish = valueB == null;

				if (isANullish && isBNullish) return 0;
				if (isANullish) return 1;
				if (isBNullish) return -1;

				const typeA = getDataType(valueA);
				const typeB = getDataType(valueB);

				if (typeA === typeB)
					switch (typeA) {
						case 'date':
							return datesSorting(valueA, valueB);
						case 'number':
							return numbersSorting(valueA, valueB);
						case 'text':
						default:
							return textSorting(valueA, valueB);
					}

				return textSorting(valueA.toString(), valueB.toString());
			},
			valueFormatter: isSightfull2
				? (params: ValueFormatterParams) => params.value
				: (params: ValueFormatterParams) => {
						const type = getDataType(params.value);
						switch (type) {
							case 'date':
								return datesDefaultFormatting(params.value);
							case 'number':
								return numbersDefaultFormatting(params.value, decimalDigits);
							case 'text':
							default:
								return textDefaultFormatting(params.value);
						}
				  },
		}),
		[groupable, clickable, isSightfull2, decimalDigits]
	);
	return defaultColDef;
}

export function useContextMenuItems() {
	const { reportEvent } = useReportEvent();
	const [{ filters }, { addDimensionsParamsByType }] = useDimensionsState();

	return useMenuItemsParams(
		filters.map<Filter>((filter) => ({
			key: filter.key,
			values: filter.values,
		})),
		(filterObj: { key: string; value: string }) => {
			reportEvent({ event: 'add-filter-from-table', metaData: { key: filterObj?.key, value: filterObj?.value } });
			return addDimensionsParamsByType('filterBy', [filterObj]);
		}
	);
}

export function useMenuItemsParams(filters: Filter[], onSetValue: (filterObj: { key: string; value: string }) => void) {
	const toast = useToast();
	return (params: GetContextMenuItemsParams) => {
		const providedColDef = params.column?.getUserProvidedColDef() as PulseColDef;
		const filterIcon = renderToStaticMarkup(<FilterTool12 />);
		const value = (params.value ?? params.node?.data?.[providedColDef?.field ?? ''] ?? '')?.toString();
		const result: MenuItemDef[] = [
			{
				name: 'Copy',
				icon: renderToStaticMarkup(<Copy16 />),
				action: () =>
					navigator.clipboard
						.writeText(value || 0)
						.then(() => toast({ variant: 'ok', message: 'Copied to clipboard' })),
			},
		];
		const isFilteringEnabled = !!providedColDef?.filterKey;
		const isFilterApplied = filters.map((f) => f.key).includes(providedColDef?.filterKey ?? '');
		if ((isFilteringEnabled && !isFilterApplied) || providedColDef?.field === '$name') {
			result.push({
				name: 'Filter',
				icon: filterIcon,
				action: () => {
					const key = providedColDef.filterKey;
					if (!key) return;

					onSetValue({ key, value });
				},
			});
		}
		return result;
	};
}

export const sideBarConfig = {
	toolPanels: [
		{
			id: 'columns',
			labelDefault: 'Columns',
			labelKey: 'columns',
			iconKey: 'columns',
			toolPanel: 'agColumnsToolPanel',
			minWidth: 125,
			width: 225,
			maxWidth: 225,
			toolPanelParams: {
				suppressRowGroups: true,
				suppressValues: true,
			},
		},
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			minWidth: 125,
			width: 225,
			maxWidth: 225,
		},
	],
	defaultToolPanel: 'columns',
	hiddenByDefault: true,
};

// Hack to exclude filter key from the 'Wrong Property' validation https://github.com/ag-grid/ag-grid/issues/2320
// @ts-ignore
ColDefUtil.ALL_PROPERTIES = [...ColDefUtil.ALL_PROPERTIES, 'filterKey', 'expanded'];
