import { useMutation } from '@apollo/client';
import Flex from '@components/Flex';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import Button from 'src/common/components/Button';
import { StarChecked } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import useToast from 'src/common/hooks/ui/useToast';
import { ConnectorStatus } from 'src/generated/graphql';
import { ReauthenticateSource } from 'src/queries/hertzl';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';

export function ActionsRenderer() {
	function Cell(params: ICellRendererParams) {
		const toast = useToast();
		const value = useMemo(() => params?.value || {}, [params?.value]);
		const { connectorType, connectorId, status } = value;
		const { isHavingOneOfPermissions } = usePermissionCheck();
		const canEdit = isHavingOneOfPermissions(['sf.connector.*', `sf.connector.write`]);
		const { reportEvent } = useReportEvent({ feature: 'Connectors' });

		const [reauthenticate, { loading: isReauthenticationLoading }] = useMutation(ReauthenticateSource);

		const onReauthenticate = async () => {
			await reauthenticate({
				variables: { sourceId: connectorId, hideSetupGuide: false },
			}).then((reuthenticateResult) => {
				const redirectURL = reuthenticateResult.data?.reauthenticateSource?.integratorMetadata?.connectionCardUrl;
				if (redirectURL) window.location.href = redirectURL;
			});
		};

		const performReauthenticate = async () => {
			reportEvent({
				event: 'connectors-open-connect-card',
				metaData: { name: connectorType },
			});
			await onReauthenticate().catch(() =>
				toast({
					variant: 'error',
					message: 'Something went wrong. Please try again later',
				})
			);
		};

		if (!canEdit || status === ConnectorStatus.SetupIncomplete) {
			return null;
		}

		return (
			<Flex minH={'80px'} minW={'80px'} justifyContent={'center'} alignItems={'center'} gap={'4px'}>
				<Tooltip size={'md'} label={'Re-authorize'}>
					<Button
						onClick={performReauthenticate}
						mixBlendMode={'multiply'}
						size={'inline'}
						variant="outline"
						colorScheme="stone"
						isIconOnly
						isActive={isReauthenticationLoading}
					>
						<StarChecked />
					</Button>
				</Tooltip>
			</Flex>
		);
	}
	return Cell;
}
