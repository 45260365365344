import { useMemo } from 'react';
import Typography from '@components/Typography';
import Box from '@components/Box';
import Markdown from '@components/Markdown';

import { AskAIChatCardProps, isAskAIMetricDiscoveryChatMessage } from '../../types';
import { AskAIMetricDiscoveryChatSuggestions } from './AskAIMetricDiscoveryChatSuggestions';
import { LoadingAIResults } from '../AskAILoadingResults';
import { useCachedPerformDiscovery } from '../../hooks/useCachedPerformDiscovery';

export function AskAIMetricDiscoveryCardContent({ chatMessageAtom, chatIndex }: AskAIChatCardProps) {
	const { chatMessage, onQueryDone } = useCachedPerformDiscovery({ chatMessageAtom });
	const completionTime = chatMessage?.completionTime;
	const isMetricDicoveryMessage = isAskAIMetricDiscoveryChatMessage(chatMessage);
	const result = isMetricDicoveryMessage ? chatMessage.result : undefined;
	const formattedResponse = useMemo(() => {
		return result?.reasoning ?? ' ';
	}, [result]);

	if (!isMetricDicoveryMessage) return;

	return (
		<Typography variant="Paragraph14R" color={'gray.1000'}>
			{!completionTime ? (
				<LoadingAIResults
					text={formattedResponse}
					isLoading={true}
					component={(text) => <Markdown message={text} />}
					onDone={onQueryDone}
				/>
			) : (
				<Box data-testid={`ask-ai-chat-card-content-${chatIndex}`} padding={'0 16px 16px 16px'}>
					<Markdown message={formattedResponse} />
				</Box>
			)}
			{result?.metrics || result?.examples ? (
				<AskAIMetricDiscoveryChatSuggestions
					metrics={result.metrics}
					examples={result.examples}
					chatMessageAtom={chatMessageAtom}
					chatIndex={chatIndex}
				/>
			) : null}
		</Typography>
	);
}
