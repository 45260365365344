import Box from '@components/Box';
import Flex from '@components/Flex';
import Spacer from '@components/Spacer';

import Typography from '@components/Typography';

import { Icon, Enter16, ChevronDown16, ChevronUp16 } from '@components/Icons';
import ResizableIcon from '../../ResizableIcon';

export function AskAIFooter() {
	const textColor = 'gray.700';
	const textVariant = 'Paragraph12R';
	const boldTextVariant = 'Paragraph12SB';

	return (
		<Box flex={1} data-testid={'ask-ai-main-page-footer'}>
			<Flex padding={'2px 16px 2px 16px'} sx={{ color: textColor }} alignItems={'center'} textAlign={'center'}>
				<Flex alignItems={'center'}>
					<Icon inlineSize={'12px'} as={ChevronUp16} />
					<Icon inlineSize={'12px'} as={ChevronDown16} />
				</Flex>
				<Flex paddingLeft={'8px'}>
					<Typography variant={textVariant} textAlign="start">
						to navigate
					</Typography>
				</Flex>
				<Flex paddingLeft={'32px'} alignItems={'center'} textAlign={'center'}>
					<ResizableIcon size={'12px'} icon={Enter16} />
					<Flex paddingLeft={'8px'}>
						<Typography variant={textVariant} textAlign="start">
							to open
						</Typography>
					</Flex>
				</Flex>
				<Spacer />
				<Flex alignItems={'center'} textAlign={'end'}>
					<Typography variant={textVariant}>
						<Typography variant={boldTextVariant}>esc</Typography> to close
					</Typography>
				</Flex>
			</Flex>
		</Box>
	);
}
