import { useCallback } from 'react';
import { ParameterType } from '../../../../../generated/graphql';
import { InputStringEditor } from '../Editors/InputStringEditor';
import { EditParameterProps } from './types';

export function ParameterInputEditor({ parameter, value, setSelectedValue, onEdit }: EditParameterProps) {
	const parameterDef = parameter.definition;

	const validateChange = useCallback(
		(newValue: string) => {
			const valuesType = parameterDef.values?.__typename;
			const range = valuesType === 'RangeValues' ? parameterDef.values?.range?.[0] : undefined;
			if (!range) return true;
			const { from, to } = range ?? { from: Number.NEGATIVE_INFINITY, to: Number.POSITIVE_INFINITY };
			const asNumber: number = parseFloat(newValue);
			return asNumber >= from && asNumber <= to;
		},
		[parameterDef]
	);

	return (
		<InputStringEditor
			type={parameterDef.type === ParameterType.Number ? 'number' : 'text'}
			value={value}
			onEdit={onEdit}
			validateChange={validateChange}
			onApply={setSelectedValue}
		/>
	);
}
