import { AskAIChatCard } from './AskAIChatCard';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { ErrorBoundary } from 'react-error-boundary';
import { AskAIErrorCard } from './AskAIErrorCard';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useCallback, useEffect, useRef } from 'react';
import { AskAIChatEmptyState } from '../../Icons';
import Typography from '../../Typography';
import useUser from '../../../hooks/stores/useUser';

type OnErrorType = (
	error: Error,
	info: {
		componentStack: string;
	}
) => void;

type AskAIChatCardsListProps = {
	cardMargin?: string;
	builderType?: string;
};

export function AskAIChatCardsList({ cardMargin, builderType }: AskAIChatCardsListProps) {
	const { chatMessages } = useAskAIChatMessages();
	const cardRef = useRef<HTMLDivElement>(null);
	const [user] = useUser();

	useEffect(() => {
		if (!cardRef.current?.scrollTo) return;
		cardRef.current.scrollTo({ top: 0, left: 0, behavior: 'auto' });
	}, [chatMessages]);

	const onError: OnErrorType = useCallback((error, info) => {
		console.log(error);
		console.log(info);
	}, []);

	if (builderType && chatMessages.length === 0) {
		return (
			<Flex
				direction={'column'}
				flex={1}
				padding={'48px 26px'}
				gap={'24px'}
				alignItems={'center'}
				justifyContent={'center'}
				textColor={'gray.1000'}
			>
				<Box>
					<AskAIChatEmptyState />
				</Box>
				<Flex direction={'column'} flex={1} gap={'4px'} alignItems={'center'} justifyContent={'center'}>
					<Typography variant={'Paragraph14M'}>Hi {user?.firstName ?? 'there'}!</Typography>
					<Typography variant={'Paragraph14R'}>
						Ask Sightfull AI to help you build and refine your {builderType}
					</Typography>
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex
			direction={'column-reverse'}
			flex={1}
			padding={'0 8px'}
			overflowY={'auto'}
			data-testid={'ask-ai-chat-page-card-list'}
			ref={cardRef}
		>
			{chatMessages
				.map((chatMessage, index) => (
					<Box key={`${chatMessage}`}>
						<ErrorBoundary
							fallback={<AskAIErrorCard chatMessageAtom={chatMessage} chatIndex={index} />}
							onError={onError}
						>
							<AskAIChatCard
								chatMessageAtom={chatMessage}
								chatIndex={index}
								cardMargin={cardMargin}
								isLast={index === chatMessages.length - 1}
							/>
						</ErrorBoundary>
					</Box>
				))
				.reverse()}
		</Flex>
	);
}
