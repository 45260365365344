import useRESTAuthInfo from '@hooks/useRESTAuthInfo';
import { useCallback, useMemo } from 'react';
import { AIAgentName, CreateAIAgentThreadResponse } from '../types';
import { atom, useAtom, Provider } from 'jotai';
import useToast from '../../../hooks/ui/useToast';

const AGENT_CALL_TIMEOUT = 30000;

type AIAgentsContext = {
	threadId?: string;
	abortController?: AbortController;
};

export class AIAgentAbortError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = 'AbortError';
	}
}

export function isAIAgentAbortError(error: any): error is DOMException {
	return error.name === 'AbortError';
}

const aiAgentsContextScope = Symbol();
const AIAgentsContextAtom = atom<AIAgentsContext>({});

export function AIAgentsContextBoundary({ children }: { children: React.ReactNode }) {
	return (
		<Provider initialValues={[[AIAgentsContextAtom, {}]]} scope={aiAgentsContextScope}>
			{children}
		</Provider>
	);
}

export function useAIAgents() {
	const toast = useToast();
	const { accessToken, tenantId, role, apiUrl } = useRESTAuthInfo();
	const [aiAgentsContext, setAIAgentsContext] = useAtom(AIAgentsContextAtom, aiAgentsContextScope);

	const isBusy = useMemo(() => !!aiAgentsContext.abortController, [aiAgentsContext.abortController]);

	const handleAIAgentError = useCallback(
		(error: string) => {
			toast({ variant: 'error', message: error, duration: 5000 });
		},
		[toast]
	);

	const abortAIAgent = useCallback(
		(reason?: string) => {
			if (aiAgentsContext?.abortController) {
				aiAgentsContext.abortController.abort(reason ? new AIAgentAbortError(reason) : undefined);
			}
		},
		[aiAgentsContext.abortController]
	);

	const callAIAgent = useCallback(
		async <T,>({
			agentName,
			threadId,
			body,
		}: {
			agentName: AIAgentName;
			threadId?: string;
			body?: any;
		}): Promise<T | undefined> => {
			try {
				let url = `${apiUrl}/ai/agents/${agentName}/threads`;
				if (threadId) {
					url = `${url}/${threadId}`;
				}
				const abortController = new AbortController();
				const timeoutHandle = setTimeout(
					() => abortController.abort(new AIAgentAbortError('There was a timeout generating the response')),
					AGENT_CALL_TIMEOUT
				);
				setAIAgentsContext((cur) => ({ ...cur, abortController }));
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-Role': role,
						'X-Tenant-Id': `${tenantId}`,
					},
					body: typeof body === 'object' ? JSON.stringify(body) : body,
					signal: abortController.signal,
				});
				clearTimeout(timeoutHandle);

				if (response.status >= 400) {
					throw new Error(response.statusText.toLowerCase());
				}
				return response.json();
			} finally {
				setAIAgentsContext((cur) => ({ ...cur, abortController: undefined }));
			}
		},
		[accessToken, apiUrl, role, setAIAgentsContext, tenantId]
	);

	const createAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			const data = await callAIAgent<CreateAIAgentThreadResponse>({ agentName });
			if (data) {
				setAIAgentsContext((cur) => ({ ...cur, threadId: data.threadId }));
			}
			return data;
		},
		[callAIAgent, setAIAgentsContext]
	);

	const getOrCreateAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			if (aiAgentsContext.threadId) {
				return aiAgentsContext.threadId;
			}
			const response = await createAIAgentThread({ agentName });
			return response?.threadId;
		},
		[aiAgentsContext.threadId, createAIAgentThread]
	);

	const clearAIAgentsContext = useCallback(() => setAIAgentsContext({}), [setAIAgentsContext]);

	return {
		isBusy,
		createAIAgentThread,
		getOrCreateAIAgentThread,
		callAIAgent,
		abortAIAgent,
		handleAIAgentError,
		clearAIAgentsContext,
	};
}
