import Cookies from 'universal-cookie';

const HUBSPOT_FORM_URL =
	'https://api.hsforms.com/submissions/v3/integration/submit/26165421/340b4999-013d-45a9-8b71-0d43c9016603';

const buildHubspotFormBody = (email: string, hubspotCookie: string) => ({
	fields: [
		{
			objectTypeId: '0-1',
			name: 'email',
			value: email,
		},
	],
	context: {
		hutk: hubspotCookie,
		pageUri: 'https://app.sightfull.com/',
		pageName: 'Playground | Sightfull',
		ipAddress: '127.0.0.1',
	},
	legalConsentOptions: {
		consent: {
			consentToProcess: true,
			text: 'By signing up, I agree with the Terms and Privacy Policy',
			communications: [
				{
					value: true,
					subscriptionTypeId: 999,
					text: 'I agree to receive marketing communications from Sightfull',
				},
			],
		},
	},
});

export const hubspotLinkUserWithCookie = async (email: string) => {
	const cookies = new Cookies();
	const hubspotCookie = cookies.get('hubspotutk');

	if (!hubspotCookie) {
		return;
	}

	console.log('Found hubspot cookie');

	try {
		await fetch(HUBSPOT_FORM_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(buildHubspotFormBody(email, hubspotCookie)),
		});
	} catch (error) {
		console.error('Failed to link Hubspot user:', error);
	}
};
