import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import { SelectLabel } from '../CalculatePanel/Builder/components';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import { useMetricCategoriesV2 } from 'src/pages/MetricCatalog/hooks/useMetricCategoriesV2';
import { PermissionsSelector } from 'src/pages/MetricCatalog/PermissionsSelector';
import { parseName } from '../../hooks/useSaveAsNewMetric';
import { useReportEvent } from 'src/services/analytics';
import ModalFooter from 'src/common/components/ModalFooter';
import { toLower } from 'lodash';
import { addUnderscoresAndToLowerCase } from 'src/normalize';
import { NameField } from 'src/common/components/NameField';

type SaveAsNewModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	onSubmit: (metricName: string, categoryName?: string) => void;
	currentMetricName: string;
	currentMetricDisplayName?: string;
	isLoading?: boolean;
	isInputModalInvalid: boolean;
	setIsInputModalInvalid: (value: boolean) => void;
};

export function SaveAsNewModal({
	isOpen,
	onClose,
	onSubmit,
	currentMetricName,
	currentMetricDisplayName,
	isLoading,
	isInputModalInvalid,
	setIsInputModalInvalid,
}: SaveAsNewModalProps) {
	const [metricCategories] = useMetricCategoriesV2();
	const [selectedCategoryId, setSelectedCategoryId] = useState<undefined | string>();
	const [displayName, setDisplayName] = useState('');
	const [isNameUnique, setIsNameUnique] = useState(true);
	const { reportEvent } = useReportEvent();

	const checkIfNameExists = useCallback((name: string, categories: typeof metricCategories) => {
		return categories
			.map((category) => category.metrics)
			.flat()
			.some((el) => el.name === name);
	}, []);

	const setInitialValues = useCallback(() => {
		const currentMetricCategory = metricCategories.find((category) =>
			category.metrics.some((el) => el.name === currentMetricName)
		);
		setSelectedCategoryId(currentMetricCategory?.id || undefined);
		const metricNameFromProps = currentMetricDisplayName
			? addUnderscoresAndToLowerCase(currentMetricDisplayName)
			: currentMetricName;
		let uniqueName = metricNameFromProps + '_copy';

		const getUniqueName = (name: string, counter: number = 0): string => {
			const newName = counter > 0 ? `${name}_${counter}` : name;
			return checkIfNameExists(newName, metricCategories) ? getUniqueName(name, counter + 1) : newName;
		};

		uniqueName = getUniqueName(uniqueName);
		setDisplayName(parseName(uniqueName));
	}, [currentMetricName, currentMetricDisplayName, metricCategories, checkIfNameExists]);

	useEffect(() => setInitialValues(), [setInitialValues]);

	const categoriesOptions = useMemo(
		() => metricCategories?.map((category) => ({ label: category?.name || '', value: category?.id || '' })),
		[metricCategories]
	);

	const categoryControlledValue = useMemo(
		() => categoriesOptions?.find((el) => el.value === selectedCategoryId),
		[categoriesOptions, selectedCategoryId]
	);

	const closeModal = () => {
		onClose();
		setInitialValues();
		setIsInputModalInvalid(false);
	};

	const modalHeader = (
		<ModalHeader borderBottom={'1px solid'} borderColor={'gray.300'} color="gray.1000" padding={'16px'}>
			<Flex maxWidth={'calc(50% + 48px)'} justifyContent={'space-between'} alignItems={'center'}>
				<Flex>
					<Button variant="outline" isIconOnly onClick={closeModal} size="inline" colorScheme="black">
						<CloseLarge16 />
					</Button>
				</Flex>
				<Flex>
					<Typography variant={'DesktopH7Medium'} color={'gray.1000'}>
						Save as new
					</Typography>
				</Flex>
			</Flex>
		</ModalHeader>
	);

	const modalBody = (
		<Box borderBottom={'1px solid'} borderColor={'gray.300'} width={'100%'} padding={'24px'}>
			<NameField
				onBlur={() =>
					setIsNameUnique(!checkIfNameExists(toLower(addUnderscoresAndToLowerCase(displayName)), metricCategories))
				}
				isEditable
				isAutoSelected
				label="Metric name"
				isInvalid={isInputModalInvalid || !isNameUnique}
				onNameUpdate={(name) => {
					setDisplayName(name);
					setIsInputModalInvalid(false);
				}}
				propertyDefinitionValueName={currentMetricName}
				value={displayName}
			/>
			{isInputModalInvalid ||
				(!isNameUnique && (
					<Typography marginTop={'8px'} variant="Paragraph14R" color={'red.800'}>
						This name already exists. Try a unique name.
					</Typography>
				))}
			<Box marginY={'24px'}>
				<SelectLabel marginBottom={'12px'} color={'gray.1100'} text="Category" />
				<AdvancedSelect
					onReportEvent={(value) =>
						reportEvent({
							event: 'metric-edit-save-as-new-category-changed',
							metaData: {
								newCategory: metricCategories.find((category) => category.id === value)?.name,
							},
						})
					}
					onChange={(value) => setSelectedCategoryId(value.value)}
					placeholder="Select"
					options={categoriesOptions}
					controlledValue={categoryControlledValue}
					dataIntercomTarget="category"
				/>
			</Box>
			<Box>
				<SelectLabel marginBottom={'12px'} color={'gray.1100'} text="Permissions" />
				<PermissionsSelector />
			</Box>
		</Box>
	);

	const modalFooter = (
		<ModalFooter
			size={'md'}
			primaryButtonLabel={'Save'}
			isPrimaryLoading={isLoading}
			onPrimaryClick={() => onSubmit(displayName, selectedCategoryId)}
			onCancel={closeModal}
			color={'blue'}
			cancelButtonColor={'black'}
		/>
	);

	return (
		<Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} maxWidth="460px" isCentered>
			{modalHeader}
			{modalBody}
			{modalFooter}
		</Modal>
	);
}
