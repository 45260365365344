import { ColumnsAndFilters } from '../utils/types';
import { useCallback, useMemo, useState } from 'react';

type ColumnsAndFiltersState = {
	[nameKey: string]: { columnsAndFilters: ColumnsAndFilters };
};

export function useColumnsAndFilters(nameKey: string) {
	const initialColumnsAndFilters = useMemo(
		() => ({
			additionalColumns: [],
			filter: [],
		}),
		[]
	);

	const [columnsAndFiltersState, setColumnsAndFiltersState] = useState<ColumnsAndFiltersState>({
		[nameKey]: { columnsAndFilters: initialColumnsAndFilters },
	});

	const setColumnsAndFilters = useCallback(
		(newColumnsAndFilters: ColumnsAndFilters) => {
			setColumnsAndFiltersState((prevState) => ({
				...prevState,
				[nameKey]: { columnsAndFilters: newColumnsAndFilters },
			}));
		},
		[nameKey]
	);

	const columnsAndFilters = useMemo(
		() => columnsAndFiltersState[nameKey]?.columnsAndFilters || initialColumnsAndFilters,
		[columnsAndFiltersState, nameKey, initialColumnsAndFilters]
	);

	return [columnsAndFilters, setColumnsAndFilters] as const;
}
