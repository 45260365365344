import { useEntitySourceInfoQuery } from 'src/generated/graphql';
import { connectorsInfos } from '@components/TopNavigation/DataConnectorsModal/connectors';
import { toLower } from 'lodash';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import Image from '@components/Image';

export type PrimaryKeys = { [primaryKey: string]: string };

type SourceInfo = {
	name: string;
	logo: null | React.FC;
	bigLogo: null | React.FC;
	urlFormatter: (baseUrl: string, primaryKeys: PrimaryKeys) => string | null;
};

const salesforceUrlFormatter = (baseUrl: string, primaryKeys: PrimaryKeys) => {
	try {
		return new URL(Object.values(primaryKeys)[0], baseUrl).href;
	} catch {
		return null;
	}
};

function getConnectorsSourceInfo({ source }: { source: string }): SourceInfo | undefined {
	const connectorInfo = connectorsInfos.find(
		(info) => toLower(info.id) === toLower(source) || toLower(info.backwardsCompatibleName) === toLower(source)
	);
	if (connectorInfo == undefined) {
		return undefined;
	}

	const logo = () => (
		<Image src={connectorInfo.icon || PLACEHOLDER_ICON} maxWidth={'16px'} height={'16px'} width={'100%'} />
	);
	const bigLogo = () => (
		<Image src={connectorInfo.icon || PLACEHOLDER_ICON} maxWidth={'20px'} height={'20px'} width={'100%'} />
	);

	return {
		name: connectorInfo.name,
		logo,
		bigLogo,
		urlFormatter:
			connectorInfo.name === 'Salesforce'
				? salesforceUrlFormatter
				: // eslint-disable-next-line @typescript-eslint/no-unused-vars
				  (baseUrl: string, primaryKeys: PrimaryKeys) => null,
	};
}
export function getSourcesInfo({
	source,
	baseUrl,
	primaryKeys,
}: {
	source?: string;
	baseUrl?: string;
	primaryKeys?: PrimaryKeys;
}) {
	if (!source) return null;
	const { name, logo, bigLogo, urlFormatter } = getConnectorsSourceInfo({ source }) ?? {
		name: 'Source',
		Logo: null,
		BigLogo: null,
		urlFormatter: () => null,
	};

	if (!baseUrl || !primaryKeys) return { name, logo, bigLogo, url: null };
	return { name, logo, bigLogo, url: urlFormatter(baseUrl, primaryKeys) };
}

export function useSourceInfo({ entityName, primaryKeys }: { entityName: string; primaryKeys?: PrimaryKeys }) {
	const { data, loading, error } = useEntitySourceInfoQuery({ variables: { entity: entityName } });
	const sourceInfo = data?.entityInfo.schema;
	if (loading || error || !sourceInfo) return null;

	return getSourcesInfo({ source: sourceInfo.sourceDisplayName, baseUrl: sourceInfo.sourceURL, primaryKeys });
}
