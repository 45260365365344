const environment = import.meta.env.PULSE_ENV;

import { FeatureFlagsType } from 'src/types/featureFlags';

/*
DEV = npm run dev, locally
TEST = integration + unit + e2e tests
PROD = npm run build => dev1 dev2 staging us1 etc...
 */

export const isLocalDevelopment = environment === 'DEV';
export const isTestingMode = environment === 'TEST';
export const isProductionMode = environment === 'PROD';

// is app.sightfull.com vs stage.sightfull.com
export const isStageEnv: boolean = isLocalDevelopment || (window.webConfig?.isStage ?? false);
export const isProductionEnv = !isStageEnv;

export const reactMountNodeId = 'pulse-root';

export const defaultFeatureFlags: FeatureFlagsType = {
	'pulse.react.exportLiveData.nameRanges': true,
	'pulse.react.exportLiveData.enable': true,
	'pulse.dataHygiene.enabled': false,
	'pulse.node.overrideEnabled': true,
	'pulse.react.metrics.longLoading': false,
	'pulse.react.selfServiceConfig': true,
	'pulse.react.generative.dataOverview.enable': true,
	'pulse.react.showPipelineStagesConfiguration': true,
	'pulse.generative.interactiveMetric.enable': true,
	'pulse.sightfull2.enable': false,
	'pulse.generative.interactiveMetric.dynamicChips.enable': false,
	'pulse.discovery.enable': true,
	'pulse.gridtable.v2': true,
	'pulse.analytics.verbose': false,
	'pulse.react.metricCatalog.showLibraryBanner': true,
	'pulse.sightfull2.createNewMetric.enable': true,
	'pulse.settings.enable': true,
	'pulse.sightfull2.addTemplate.enable': true,
	'pulse.sightfull2.upsertEntityModal.enable': true,
	'pulse.sightfull2.metricPage.filterBreakdown.viewDefaultOntologyDimensions': false,
	'pulse.metricPage.sqlQueryView.enable': true,
	'pulse.sightfull2.dataConnectorsPage.enable': true,
	'pulse.sightfull2.dataConnectorsDynamicModal.enable': true,
	'pulse.sightfull2.playground.enable': false,
	'pulse.sightfull2.metricBuilderAIAgent.enable': true,
	'pulse.sightfull2.filters2.enable': true,
	'pulse.sightfull2.filters2.operators.enable': true,
	'pulse.sightfull2.entitlements.metrics.enable': false,
};
