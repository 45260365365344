import Flex from '@components/Flex';
import {
	GridTable,
	GridTableBody,
	GridTableHeader,
	GridTableHeaderActionAddColumn,
	GridTableHeaderActionBack,
	GridTableHeaderActionDivider,
	GridTableHeaderActions,
	GridTableHeaderIconAction,
	useGridTableApi,
} from '@components/GridTable';
import { DuplicateSolid16, GranularPeriod16 } from '@icons/index';
import { GridReadyEvent, RowClickedEvent, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { useCallback, useMemo } from 'react';
import Skeleton from 'src/common/components/Skeleton';
import 'src/common/components/Table/Table.scss';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useTenantState from 'src/common/hooks/ui/useTenantState';
import useToast from 'src/common/hooks/ui/useToast';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricSQLQueryView } from 'src/pages/MetricPage/hooks/useMetricSQLQueryView';
import { useReportEvent } from 'src/services/analytics';
import { useContextMenuItems } from '../../../../../common/components/Table/config';
import { useUpdateTableColumnState } from '../../../hooks/useUpdateTableColumnState';
import { GridTableHeaderActionExport } from '../../Header/ExportTableModal/GridTableHeaderActionExport';
import { MonacoSQLEditor } from '../../MonacoEditor/MonacoSQLEditor';

function HeaderActionShowSqlQuery({ metadata }: { metadata: Record<string, any> }) {
	const { reportEvent } = useReportEvent();
	const { showSqlQueryView, toggleShowSqlQueryView } = useMetricSQLQueryView();
	const onClick = useCallback(() => {
		const isShowing = toggleShowSqlQueryView();
		reportEvent({
			event: 'change-show-sql-query-view',
			metaData: { showQuery: isShowing, ...metadata },
		});
	}, [metadata, reportEvent, toggleShowSqlQueryView]);
	return (
		<>
			<GridTableHeaderActionDivider />
			<TinySwitch onClick={onClick} isEnabled={showSqlQueryView} text="SQL" />
		</>
	);
}

function SqlQueryBody({ sqlQuery }: { sqlQuery?: string }) {
	return (
		<Flex flexGrow={1} flexDir={'column'} pb={'32px'}>
			{sqlQuery ? <MonacoSQLEditor value={sqlQuery} /> : <Skeleton height={'200px'} />}
		</Flex>
	);
}

function SqlQueryHeader({ sqlQuery, metadata }: { sqlQuery?: string; metadata: Record<string, any> }) {
	const toast = useToast();
	const { reportEvent } = useReportEvent();

	const onCopySql = useCallback(() => {
		if (!sqlQuery) return;
		navigator.clipboard.writeText(sqlQuery).then(() => toast({ variant: 'ok', message: 'Copied to clipboard' }));
		reportEvent({ event: 'copy-to-clipboard-sql-query', metaData: metadata });
	}, [metadata, reportEvent, sqlQuery, toast]);

	return (
		<GridTableHeader
			title={'Metric SQL Query'}
			tableActions={
				<GridTableHeaderActions>
					<GridTableHeaderIconAction
						tooltip={'Copy SQL Query for this metric'}
						icon={<DuplicateSolid16 />}
						text={'Copy SQL'}
						onClick={onCopySql}
					/>
					<HeaderActionShowSqlQuery metadata={metadata} />
				</GridTableHeaderActions>
			}
		/>
	);
}

function RawTableHeader({
	title,
	subtitle,
	metadata,
	onSelectAllPeriodsClicked,
	onAddColumn,
	onCloseClicked,
}: {
	title: string;
	subtitle?: React.ReactNode;
	metadata: Record<string, any>;
	onSelectAllPeriodsClicked: (() => void) | undefined;
	onAddColumn: (() => void) | undefined;
	onCloseClicked?: () => void;
}) {
	const isSqlQueryViewFF = useFeatureFlag('pulse.metricPage.sqlQueryView.enable');
	const { reportEvent } = useReportEvent();
	const { gridTableApi } = useGridTableApi();
	const { isGranularDataForAllPeriodsButtonEnabled } = useMetricDerivedState();
	const shouldShowSelectAllPeriodButton = onSelectAllPeriodsClicked && isGranularDataForAllPeriodsButtonEnabled;
	const { shouldLimitExports } = useTenantState();

	const exportTableDataAsCSV = () => {
		const METRIC_TABLE_TRIAL_LIMIT = 5;
		const LIMITED_EXPORT_MSG = `Export is limited to ${METRIC_TABLE_TRIAL_LIMIT} lines during the trial period. Please upgrade to access the full data.`;
		const shouldRowBeSkipped = shouldLimitExports
			? (params: ShouldRowBeSkippedParams) => !!params.node.rowIndex && params.node.rowIndex >= METRIC_TABLE_TRIAL_LIMIT
			: undefined;

		gridTableApi?.api?.exportDataAsCsv?.({
			shouldRowBeSkipped,
			appendContent: shouldLimitExports ? LIMITED_EXPORT_MSG : undefined,
		});
	};

	return (
		<GridTableHeader
			title={title}
			subtitle={subtitle}
			tableActions={
				<GridTableHeaderActions>
					{shouldShowSelectAllPeriodButton && (
						<GridTableHeaderIconAction
							tooltip={'Granular data for all periods'}
							icon={<GranularPeriod16 />}
							onClick={() => {
								reportEvent({ event: 'select-all-periods-in-table-clicked' });
								onSelectAllPeriodsClicked();
							}}
						/>
					)}
					<GridTableHeaderActionExport exportTableDataAsCSV={exportTableDataAsCSV} />
					{onAddColumn && <GridTableHeaderActionAddColumn onClick={onAddColumn} />}
					{isSqlQueryViewFF && <HeaderActionShowSqlQuery metadata={metadata} />}
				</GridTableHeaderActions>
			}
			titleActions={onCloseClicked && <GridTableHeaderActionBack onClick={onCloseClicked} />}
		/>
	);
}

function TableHeader({
	title,
	subtitle,
	isThumbnail,
	sqlQuery,
	metadata,
	onAddColumn,
	onCloseClicked,
	onSelectAllPeriodsClicked,
}: {
	title: string;
	subtitle: React.ReactNode;
	isThumbnail: boolean;
	sqlQuery?: string;
	metadata: Record<string, any>;
	onAddColumn?: () => void;
	onCloseClicked?: () => void;
	onSelectAllPeriodsClicked?: () => void;
}) {
	const { showSqlQueryView } = useMetricSQLQueryView();
	if (isThumbnail) return null;

	return showSqlQueryView ? (
		<SqlQueryHeader sqlQuery={sqlQuery} metadata={metadata} />
	) : (
		<RawTableHeader
			title={title}
			subtitle={subtitle}
			metadata={metadata}
			onSelectAllPeriodsClicked={onSelectAllPeriodsClicked}
			onAddColumn={onAddColumn}
			onCloseClicked={onCloseClicked}
		/>
	);
}

function TableBody({
	columnDefs,
	data,
	isGroupable,
	sqlQuery,
	onRowClicked,
	onTableReady,
}: {
	sqlQuery?: string;
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isGroupable?: boolean;
	onRowClicked?: (event: RowClickedEvent) => void;
	onTableReady?: (event: GridReadyEvent) => void;
}) {
	const { showSqlQueryView } = useMetricSQLQueryView();
	const { tableColumnState } = useMetricDerivedState();
	const contextMenuItems = useContextMenuItems();

	const onTableBodyReady = (event: GridReadyEvent) => {
		onTableReady?.(event);
		event.columnApi.applyColumnState({ state: tableColumnState, applyOrder: false });
	};

	return showSqlQueryView ? (
		<SqlQueryBody sqlQuery={sqlQuery} />
	) : (
		<GridTableBody
			columnDefs={columnDefs}
			data={data}
			onRowClicked={onRowClicked}
			groupable={isGroupable}
			onTableReady={onTableBodyReady}
			contextMenuItems={contextMenuItems}
		/>
	);
}

type Props = {
	onTableReady?: () => void;
	onAddColumn?: () => void;
	onCloseClicked?: () => void;
	onSelectAllPeriodsClicked?: () => void;
	onRowClicked?: (event: RowClickedEvent) => void;
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	title: string;
	subtitle: React.ReactNode;
	isGroupable?: boolean;
	isThumbnail?: boolean;
	shouldSuppressDotNotation?: boolean;
	dataIntercomTarget?: string;
};

export function RawMetricTableV2({
	onTableReady,
	data,
	onAddColumn,
	onRowClicked,
	onCloseClicked,
	onSelectAllPeriodsClicked,
	columnDefs,
	title,
	subtitle,
	isGroupable = true,
	isThumbnail = false,
	dataIntercomTarget = 'table',
}: Props) {
	const { metricNameWithoutFlavor, breakdowns, filters, periodRange, sqlQuery } = useMetricDerivedState();
	useUpdateTableColumnState();

	const metadata = useMemo(
		() => ({
			metricName: metricNameWithoutFlavor,
			breakdowns: breakdowns.values,
			filters: filters,
			startPeriod: periodRange.asAbsoluteRange.startPeriod.id,
			endPeriod: periodRange.asAbsoluteRange.endPeriod.id,
		}),
		[breakdowns, filters, metricNameWithoutFlavor, periodRange]
	);

	return (
		<GridTable padding={'0 32px 22px 32px'} intercom={{ area: 'main', type: 'main', target: dataIntercomTarget }}>
			<TableHeader
				title={title}
				subtitle={subtitle}
				isThumbnail={isThumbnail}
				onSelectAllPeriodsClicked={onSelectAllPeriodsClicked}
				onAddColumn={onAddColumn}
				onCloseClicked={onCloseClicked}
				metadata={metadata}
				sqlQuery={sqlQuery}
			/>
			<TableBody
				columnDefs={columnDefs}
				data={data}
				isGroupable={isGroupable}
				onRowClicked={onRowClicked}
				sqlQuery={sqlQuery}
				onTableReady={onTableReady}
			/>
		</GridTable>
	);
}
