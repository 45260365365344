export function buildSectionColumn(additionalStyles: string, header: string, description: string) {
	const entityHeader = buildDiv('section-header', header);
	const entity = buildDiv('description', description);
	return buildColumnLayout(additionalStyles, entityHeader, entity);
}

export function buildColumnLayout(additionalStyles: string, ...children: (string | undefined | false)[]) {
	return buildDivChildren(`column-layout ${additionalStyles}`, ...children);
}

export function buildDivChildren(dataCode: string, ...children: (string | undefined | false)[]) {
	return buildDiv(dataCode, children.filter(Boolean).join(''));
}

export function buildYamlCodeBlock(yaml: string, link?: string) {
	const codeBlock = '\n\n```yaml \n' + yaml + '\n ```\n\n';
	const codeBlockWithCssClass = `<div class="code">${codeBlock}</div>`;
	const content: string[] = [codeBlockWithCssClass];
	if (link) content.push(buildLink(link, buildDiv('source-link', 'Read full definition')));
	return buildDiv('yaml-code-block', content.join(''));
}

export function buildLink(href: string, child: string) {
	return `<a href="${href}" target="_blank">${child}</a>`;
}

export function buildDiv(dataCode: string, child?: string) {
	return `<div data-code="${dataCode}">${child ? child : ''}</div>`;
}
