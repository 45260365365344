import { Box } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import Flex from 'src/common/components/Flex';
import { RelationshipGroup90, Search32 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import { EntityCatalogQuery } from 'src/generated/graphql';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import ListHeader from './ListHeader';
import NoResults from './NoResults';
import RelationshipItem from './RelationshipItem';

export default function RelationshipsList({
	entityDisplayName,
	onSelect,
	onCreateNewClick,
	entities,
	isEditable = true,
	searchValue,
	list,
	onSearch,
	setPropertyToDelete,
}: {
	entityDisplayName: string;
	onSelect: (val: OntologyRelationshipInfo) => void;
	onCreateNewClick: VoidFunction;
	entities: EntityCatalogQuery['entityCatalog'];
	isEditable?: boolean;
	searchValue?: string;
	list: OntologyRelationshipInfo[];
	onSearch: (val?: string) => void;
	setPropertyToDelete: (val: OntologyDimensionInfo | OntologyRelationshipInfo) => void;
}) {
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const entitySemanticDefinition = useSemanticDefinitionsForEntity(
		ontologyState.loading ? undefined : ontologyState.entityName
	);
	const isActiveSearch = searchValue !== undefined;
	const isListHeaderVisible = list.length || isActiveSearch;

	const getReferencedEntityName = useCallback(
		(el: string) => {
			const referenceEntity = entities.find((entity) => entity.entityName === el);
			return removeUnderscoresAndCapitalize(referenceEntity?.displayName || referenceEntity?.entityName || el);
		},
		[entities]
	);

	return (
		<Flex flexDir={'column'} gap={'24px'}>
			<Typography variant="Paragraph12R" color={'gray.700'}>
				A relationship joins one entity to another and can be used across metrics and dimensions.
			</Typography>
			<Flex alignSelf={'stretch'} flexDir={'column'}>
				{isListHeaderVisible && (
					<ListHeader
						isEditable={isEditable}
						onAdd={onCreateNewClick}
						searchValue={searchValue}
						onSearch={onSearch}
						total={list.length}
						type="relationships"
					/>
				)}

				<Box marginTop={'12px'}>
					{!list.length ? (
						<Box marginTop={'110px'}>
							{searchValue ? (
								<NoResults
									icon={<Search32 />}
									title="No relationships found."
									description="Try adjusting your search to find the relationship you're looking for."
								/>
							) : (
								!isActiveSearch && (
									<NoResults
										buttonLabel={isEditable ? 'Create a relationhip' : ''}
										onClick={onCreateNewClick}
										icon={<RelationshipGroup90 />}
										title="No relationships yet."
										description="Add a simple mapping relationship or create a more complex calculated relationship."
									/>
								)
							)}
						</Box>
					) : (
						list.map((el, i) => (
							<Box key={i} onClick={() => onSelect(el)}>
								<RelationshipItem
									isEditable={isEditable}
									onDelete={() => setPropertyToDelete(el)}
									referencedEntityDisplayName={getReferencedEntityName(el.referenced_entity)}
									entityDisplayName={entityDisplayName}
									isFullyDefined={
										entitySemanticDefinition?.relationships?.find((relationship) => relationship.name == el.name)
											?.isDefined
									}
									item={el}
								/>
							</Box>
						))
					)}
				</Box>
			</Flex>
		</Flex>
	);
}
