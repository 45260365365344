import { Option } from '@components/SearchDropdown/types';
import { ReactNode, useMemo } from 'react';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { FilterV2, FilterV2Value } from 'src/pages/MetricPage/utils/state.types';
import { FilterBooleanEditor } from './FilterBooleanEditor';
import { FilterInputNumberEditor } from './FilterInputNumberEditor';
import { FilterInputStringEditor } from './FilterInputStringEditor';
import { FilterStringDropdownEditor } from './FilterStringDropdownEditor';
import { FilterTwoNumbersEditor } from './FilterTwoNumbersEditor';

export type ValueEditorProps = {
	displayValue: string;
	values: FilterV2Value[];
	options: Option[];
	onApply: (values: FilterV2Value[]) => void;
	prefixComponent?: ReactNode;
	eventMetadata?: object;
	isEditing: boolean;
	onOpenEditor: VoidFunction;
	onCloseEditor: VoidFunction;
	filter: FilterV2;
	isEditorAutoOpened?: boolean;
	isLoadingDimensionValues: boolean;
};

export function ValueEditorByFilter({ filter, ...valueProps }: ValueEditorProps) {
	const editorByOperatorAndDimensionType: {
		[t in SupportedOperators]: {
			[t in SupportedDimensionTypes]: ((values: ValueEditorProps) => ReactNode) | undefined;
		};
	} = useMemo(
		() => ({
			[SupportedOperators.OneOf]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.Date]: FilterStringDropdownEditor,
			},
			[SupportedOperators.OneOfSensitive]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.String]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.Date]: FilterStringDropdownEditor,
			},
			[SupportedOperators.Contains]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: FilterInputStringEditor,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.NotContains]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: FilterInputStringEditor,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.NotContainsSensitive]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: FilterInputStringEditor,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.NotOneOfSensitive]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.String]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.Date]: FilterStringDropdownEditor,
			},
			[SupportedOperators.ContainsSensitive]: {
				[SupportedDimensionTypes.Bool]: FilterBooleanEditor,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: FilterInputStringEditor,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.GreaterThan]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.GreaterThanOrEqualTo]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.LessThan]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.LessThanOrEqualTo]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.Between]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterTwoNumbersEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.IsNull]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.IsNotNull]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: undefined,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.NotBetween]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterTwoNumbersEditor,
				[SupportedDimensionTypes.String]: undefined,
				[SupportedDimensionTypes.Date]: undefined,
			},
			[SupportedOperators.NotOneOf]: {
				[SupportedDimensionTypes.Bool]: undefined,
				[SupportedDimensionTypes.Number]: FilterInputNumberEditor,
				[SupportedDimensionTypes.String]: FilterStringDropdownEditor,
				[SupportedDimensionTypes.Date]: FilterStringDropdownEditor,
			},
		}),
		[]
	);

	if ([SupportedOperators.IsNull, SupportedOperators.IsNotNull].includes(filter.operator)) {
		return valueProps.prefixComponent;
	}

	return editorByOperatorAndDimensionType[filter.operator][filter.type]?.({ ...valueProps, filter });
}
