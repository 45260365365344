import { ModalHeader } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Button from '@components/Button';
import { ClappingHands82, CloseLarge16 } from '@components//Icons';
import Typography from '@components//Typography';
import colors from 'src/style/colors';
import { useReportEvent } from 'src/services/analytics';
import { getConnectorsName } from 'src/pages/ConnectorsPage/utils/utils';

const REDIRECT_URL = 'https://meetings-eu1.hubspot.com/sightfull/book-a-demo';

export function DataConnectorsOnboardingCallModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
	const { reportEvent } = useReportEvent({ feature: 'Connectors' });

	const onRedirect = () => {
		reportEvent({
			event: 'connectors-playground-authorization-success-book-a-call',
			metaData: { name: getConnectorsName() },
		});
		onClose();
		window.location.href = REDIRECT_URL;
	};
	const modalHeader = (
		<ModalHeader color="gray.1000" padding={'16px'} paddingBottom={'0px'}>
			<Button variant="outline" isIconOnly onClick={onClose} size="inline" colorScheme="black">
				<CloseLarge16 />
			</Button>
		</ModalHeader>
	);

	const modalBody = (
		<Box textAlign={'center'} width={'100%'} padding={'4px 32px'} paddingBottom={'32px'}>
			<Flex marginBottom={'20px'} justifyContent={'center'}>
				<ClappingHands82 />
			</Flex>
			<Typography color={colors.gray[1000]} variant="DesktopH5">
				Authorized successfully.
			</Typography>
			<Typography marginTop={'8px'} color={colors.gray[900]} variant="Paragraph14R">
				Your secure connector is in progress and will be ready within 24 hours. We&apos;d love to help you get a head
				start by
				<Typography color={colors.gray[900]} variant="Paragraph14SB">
					setting up an onboarding call.
				</Typography>
			</Typography>
		</Box>
	);

	const modalFooter = (
		<Flex paddingX={'52px'} paddingBottom={'48px'} gap={'12px'} flexDirection={'column'}>
			<Button borderRadius={'8px'} size={'medium'} variant="solid" colorScheme={'blue'} onClick={onRedirect}>
				Set up an onboarding call
			</Button>
			<Button
				borderRadius={'8px'}
				color={colors.gray[1000]}
				size={'medium'}
				variant="ghost"
				colorScheme={'gray'}
				onClick={onClose}
			>
				Maybe later
			</Button>
		</Flex>
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={true}
			maxWidth={'460px'}
			isCentered={true}
			borderRadius={'16px'}
			closeOnEsc={false}
		>
			{modalHeader}
			{modalBody}
			{modalFooter}
		</Modal>
	);
}
