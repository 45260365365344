import Box from '@components/Box';
import Chart from '@components/Chart';
import { ChartRenderErrorPlaceHolder } from '@components/Chart/ChartRenderErrorPlaceHolder';
import { ChartOptions } from '@components/Chart/types';
import Flex from '@components/Flex';
import { MetricPeriod } from '@sightfull/period-ranges';
import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { SkeletonMetricChart } from 'src/pages/MetricPage/components/SkeletonComponents';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useSelectedXAxisElements } from 'src/pages/MetricPage/hooks/useSelectedXAxisElement';
import { useReportEvent } from 'src/services/analytics';
import { ChartComponentRef } from '../../Chart/Chart';
import { LayoutOptions } from '../MetricViewLayout';

export default function MetricChart({
	layoutState,
	chartOptions,
	isLoading,
	isSinglePeriod,
	chartRef,
	handleCloseDropdownsOnChartClick,
	height,
}: {
	layoutState: LayoutOptions;
	height: string;
	chartOptions: ChartOptions;
	isLoading: boolean;
	isSinglePeriod: boolean;
	chartRef?: Ref<ChartComponentRef>;
	handleCloseDropdownsOnChartClick: () => void;
}) {
	const { reportEvent } = useReportEvent();
	const [selectedXAxisElements, setSelectedXAxisElement] = useSelectedXAxisElements();
	const { metricInfo } = useMetricDerivedState();
	const { width, ref: containerChartRef } = useObserveElementWidth<HTMLDivElement>();

	const onChartClicked = useCallback(
		(index: number) => {
			const xIndex = isSinglePeriod ? 0 : index;
			const element = chartOptions.xAxis.values[xIndex] as MetricPeriod;
			const isAlreadySelected = selectedXAxisElements
				.map((e) => (e instanceof MetricPeriod ? e.id : e))
				.includes(element?.id);
			setSelectedXAxisElement({ xAxisElements: isAlreadySelected ? undefined : [element] });

			reportEvent({
				event: 'chart-click',
				metaData: {
					'metric-info': metricInfo?.name,
					'selected-dimension': element.id,
					'opened-data-grid': selectedXAxisElements != null,
				},
			});
			handleCloseDropdownsOnChartClick();
		},
		[
			chartOptions.xAxis.values,
			handleCloseDropdownsOnChartClick,
			isSinglePeriod,
			metricInfo?.name,
			reportEvent,
			selectedXAxisElements,
			setSelectedXAxisElement,
		]
	);

	if (layoutState === 'top-collapsed') return <Box ref={containerChartRef} />; // for the proper calculation of width on resize

	return (
		<Flex
			transition={'all 0.2s'}
			ref={containerChartRef}
			position="relative"
			overflow="visible"
			flexGrow={1}
			width={'100%'}
		>
			{!isLoading ? (
				<Chart
					layoutState={layoutState}
					width={width}
					height={height}
					onColumnClick={onChartClicked}
					ref={chartRef}
					fallBackComponent={<ChartRenderErrorPlaceHolder />}
				/>
			) : (
				<SkeletonMetricChart />
			)}
		</Flex>
	);
}

export const useObserveElementWidth = <T extends HTMLElement>() => {
	const [width, setWidth] = useState(0);
	const ref = useRef<T>(null);

	useEffect(() => {
		let observerRefValue: HTMLElement | null = null;
		const observer = new ResizeObserver((entries) => {
			setWidth(entries[0].contentRect.width);
		});

		if (ref.current) {
			observer.observe(ref.current);
			observerRefValue = ref.current;
		}

		return () => {
			if (observerRefValue) observer.unobserve(observerRefValue);
		};
	}, []);

	return {
		width,
		ref,
	};
};
