import { ChartOptions, ChartType } from '@components/Chart/types';
import { AvailablePeriodRanges, AvailableRangePresets, MetricPeriod, PeriodRange } from '@sightfull/period-ranges';
import { ColDef, ColumnState as TableColumnState } from 'ag-grid-community';
import { SelectOption } from 'src/common/components/Select/types';
import { RawDimensionsDict, YAxisConfig } from 'src/common/utils/MetricSearchParams';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { MetricOperator } from 'src/models/MetricOperator';
import { SortOrder } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { MetricCalcInfo, MetricMissingDependency, StatisticalOperation } from 'src/types/metric';
import { Parameter } from 'src/types/parameter';

export interface StatisticOperation {
	color: React.CSSProperties['color'];
	isChecked: boolean;
	options: SelectOption[];
	selectedOptionIndex: number; // TODO: options can be Selector<Options>
	name: StatisticalOperation;
	operationInfoKey: string;
}
export type DimensionType = 'text' | 'date' | 'number';

export type Breakdown = {
	label: string;
	key: string;
	type: DimensionType;
};

export type Breakdowns = {
	isDisabled: boolean;
	values: Breakdown[];
};

export type Filter = {
	label: string;
	key: string;
	values: any[];
	type: DimensionType;
};

export type FilterV2Value = string | boolean | number | null;
export type FilterV2 = {
	label: string;
	key: string;
	values: FilterV2Value[];
	operator: SupportedOperators;
	type: SupportedDimensionTypes;
};

export function convertFiltersToRawDimensionsDict(filters: Filter[]): RawDimensionsDict {
	const entries = filters.map((filter) => [[filter.key], filter.values.map((v) => v ?? `${v}`)]);

	return Object.fromEntries(entries);
}
export type Flavor = Selector<string>;

export type DisplayLegendItems = MultiSelector<LegendItem>;

export type MetricDerivedState = {
	metricDisplayName: string;
	metricExplanationArticleId?: string;
	metricNameWithoutFlavor: string;
	metricNameWithFlavor: string;
	metricSource?: string;
	periodRange: PeriodRange;
	displayedLegendItems: DisplayLegendItems;
	chartType: Selector<ChartType>;
	isTableView?: boolean;
	flavor?: Flavor;
	filters: Filter[] | FilterV2[];
	breakdowns: Breakdowns;
	parameters: Parameter[];
	collectedProps: string[];
	selectedXAxisElements: SelectedXAxisElements;
	isExecutiveView: boolean;
	metricExplanationOneliner: string;
	metricOperator: MetricOperator;
	isLoading: boolean;
	objectsTypes: string[];
	errorMessage?: string;
	availableRangePresets: AvailableRangePresets;
	availablePeriodRanges: AvailablePeriodRanges;
	statisticsOperations: StatisticOperation[];
	chartOptions: ChartOptions;
	hasComponents: boolean;
	hasGroupBy: boolean;
	isAllComponentSameUnit: boolean;
	tableColDefs: PulseColDef[];
	tableRowsData: Record<string, any>[];
	detailedTableError?: string;
	tableType?: TableType;
	availableTargets: LegendItem[];
	metricInfo?: MetricCalcInfo;
	displayUnits: { [k in DisplayUnitType]: PossiblyDisabled<boolean> };
	isPartiallyLoadingChart?: boolean;
	isPartiallyLoadingTable?: boolean;
	isRulesEngineRunning: boolean;
	tableColumnState?: TableColumnState[];
	isGranularDataForAllPeriodsButtonEnabled: boolean;
	decimalDigits?: number;
	sortOrder: Selector<SortOrder>;
	isFormula?: boolean;
	metricYamlEditorMetricDefinitionJson?: unknown;
	doesMetricExist: boolean;
	isFullyDefined: boolean;
	missingDependencies: MetricMissingDependency[];
	yAxisConfig?: YAxisConfig;
	sqlQuery?: string;
};

type LegendItem = string;
export type SelectedXAxisElements = MetricPeriod[] | LegendItem[];

export enum DisplayUnit {
	value = 'value',
	count = 'componentCount',
	percentage = 'percentage',
	percentageFirst = 'percentage-first',
	percentagePrev = 'percentage-prev',
}
export const displayUnits = [
	DisplayUnit.value,
	DisplayUnit.count,
	DisplayUnit.percentage,
	DisplayUnit.percentagePrev,
	DisplayUnit.percentageFirst,
] as const;
export type DisplayUnitType = typeof displayUnits[number];
export type TableType = 'MetricTable' | 'DetailedTable' | 'MultiPeriodDetailedTable';

export type PulseColDef = { filterKey?: string } & ColDef;

export type Selector<T> = {
	selectedValue: T;
	optionalValues: T[];
};

export type MultiSelector<T> = {
	selectedValues: T[];
	optionalValues: T[];
};

export type PossiblyDisabled<T> = {
	isDisabled: boolean;
	value?: T;
};

export type MetricYamlEditorHashState = {
	requestedPreviewHash: string;
	calculatedRulesEngineHash: string;
};
