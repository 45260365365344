import Flex from '@components/Flex';
import { DataConnectorsSection } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsSection';
import React, { useCallback, useMemo } from 'react';
import {
	allConnectorTypes,
	connectorsInfos,
	popularConnectors,
} from '@components/TopNavigation/DataConnectorsModal/connectors';
import { filterStringByTerm } from 'src/common/utils/utils';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { DataConnectorsNoResults } from './DataConnectorsNoResults';

export function DataConnectorsCardsDisplay({
	searchTerm,
	selectedConnector,
	setSelectedConnector,
	hasSearchTerm,
}: {
	searchTerm: string;
	selectedConnector: DataConnectorInfo | null;
	setSelectedConnector: React.Dispatch<React.SetStateAction<DataConnectorInfo | null>>;
	hasSearchTerm: boolean;
}) {
	const filterBySearchTerm = useCallback((searchTerm: string, stringToFilter: string) => {
		return searchTerm == '' || filterStringByTerm(searchTerm, stringToFilter);
	}, []);

	const popularConnectorsFilteredBySearchTerm = useMemo(() => {
		return popularConnectors.filter(({ name }) => filterBySearchTerm(searchTerm, name));
	}, [filterBySearchTerm, searchTerm]);

	const allConnectorsFilteredBySearchTerm = useMemo(() => {
		return connectorsInfos.filter(({ name }) => filterBySearchTerm(searchTerm, name));
	}, [filterBySearchTerm, searchTerm]);

	const onSelectConnector = useCallback(
		(connectorInfo: DataConnectorInfo) => {
			setSelectedConnector(selectedConnector?.name == connectorInfo.name ? null : connectorInfo);
		},
		[selectedConnector, setSelectedConnector]
	);
	const sections = useMemo(() => {
		return Array.from(allConnectorTypes).map((connectorType, index) => {
			return (
				<DataConnectorsSection
					key={index}
					title={connectorType}
					dataConnectorInfos={allConnectorsFilteredBySearchTerm.filter(({ type }) => type == connectorType)}
					selectedConnector={selectedConnector}
					onSelectConnector={onSelectConnector}
				/>
			);
		});
	}, [allConnectorsFilteredBySearchTerm, onSelectConnector, selectedConnector]);

	return (
		<Flex flexDirection={'column'} gap={'32px'} flex={1}>
			{!hasSearchTerm ? (
				<Flex flexDirection={'column'} gap={'32px'}>
					<DataConnectorsSection
						key={sections.length + 1}
						title={'Popular'}
						dataConnectorInfos={popularConnectorsFilteredBySearchTerm}
						selectedConnector={selectedConnector}
						onSelectConnector={onSelectConnector}
					/>
					{sections}
				</Flex>
			) : allConnectorsFilteredBySearchTerm.length > 0 ? (
				<DataConnectorsSection
					title={''}
					dataConnectorInfos={allConnectorsFilteredBySearchTerm}
					selectedConnector={selectedConnector}
					onSelectConnector={onSelectConnector}
				/>
			) : (
				<DataConnectorsNoResults />
			)}
		</Flex>
	);
}
