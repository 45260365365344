import { MetricPeriod, PeriodUnit } from '@sightfull/period-ranges';
import { MissingObject, MissingSemantic } from 'src/generated/graphql';
import { MetricOperator } from 'src/models/MetricOperator';

export type Category = {
	name: string;
	metrics: MetricMetadata[];
};

export type CategoryV2 = {
	id?: string | null;
	name: string;
	description: string;
	metrics: MetricMetadataV2[];
	isFullyDefined: boolean;
};

export type MetricMetadataV2 = {
	id: string;
	entity: string;
	description: string;
	source: string;
	isFullyDefined: boolean;
	isNew: boolean;
} & Pick<MetricMetadata, 'name' | 'displayName' | 'oneliner' | 'defaultChartType' | 'hide'>;

export type MetricMetadata = {
	articleId?: string;
	flavors: string[];
	defaultChartType?: string;
	defaultRelativePeriod?: string;
	displayName?: string;
	oneliner?: string;
	name: string;
	op: string;
	order?: number;
	unit: string;
	hide: boolean;
	relevantPeriodRanges: RelevantPeriodRanges;
	//TODO: Stronger typing for the following properties
	lastToolSyncedTs: string;
	createdAt: string;
	updatedAt: string;
	isCalculated: boolean;
};

export type RelevantPeriodRanges = { [key in PeriodUnit]?: [MetricPeriod, MetricPeriod] };

export type MetricPostBody = {
	filter_by?: FilterRequestsOptions;
	group_by?: readonly string[];
	collect_props?: readonly string[];
	operation_info?: StatisticalOperationInfo;
};

export type FilterRequestsOptions = { [DimensionKey: string]: (string | boolean)[] };

export const LineStatistics = ['Average'] as const;
export const BubbleStatistics = ['Growth', 'Delta'] as const;
export const OrderedAllStatistics = [...BubbleStatistics, ...LineStatistics] as const;
export type StatisticalOperation = typeof OrderedAllStatistics[number];
export type StatisticalComparisonDuration = 'WoW' | 'MoM' | 'QoQ' | 'YoY';
export type StatisticalOperationInfo =
	| { comparison_duration: StatisticalComparisonDuration }
	| { periods_count: string };

export type DimensionKey = string;

export type MetricResultSeriesType = 'TOTAL' | 'COMPONENT' | 'GROUP' | 'STATISTIC';

export interface TargetInfo {
	name: string;
	value: number;
}

export interface StatisticInfo {
	name: StatisticalOperation;
	value: number;
}

export type MetricUnit = '$' | '#' | '%' | '% growth' | ':';
export type CoreMetricUnit = 'usd' | 'percentage' | 'number' | 'eur' | 'gbp';

export const isMetricUnitType = (value: any): value is MetricUnit => {
	return ['$', '#', '%', '% growth', ':'].includes(value);
};

export const isCoreMetricUnitType = (value: any): value is CoreMetricUnit => {
	return ['usd', 'percentage', 'number', 'eur', 'gbp'].includes(value);
};

export interface MetricCalcSeriesResult {
	name: string;
	value: number;
	type: MetricResultSeriesType;
	rawName?: string;
	groupsNames?: string[];
	targets?: TargetInfo[];
	statistics?: StatisticInfo[];
	count?: number;
	op?: MetricOperator;
	unit?: MetricUnit;
}

export interface MetricCalcPeriodResult {
	period: MetricPeriod;
	series: MetricCalcSeriesResult[];
}

export interface MetricCalcInfo {
	name: string;
	op: MetricOperator;
	unit: MetricUnit;
	components?: Omit<MetricCalcInfo, 'components'>[];
	period: MetricPeriod;
	rawName?: string;
	legendName: string;
}

export interface MetricCalcResult {
	info: MetricCalcInfo;
	results: MetricCalcPeriodResult[];
}

export interface MetricCalcRawResult {
	metric: Record<string, any>;
	result: [key: string | null, data: any][];
}

export type MetricCalcMultiResult = Record<string, MetricCalcRawResult>;

export const TotalSeriesName = '*' as const;

export type RecordsRow = {
	[key: string]: any;
};
export type DetailedTable = RecordsRow[];
export type MultiPeriodDetailedTable = {
	[id in string]: RecordsRow;
};

export type MetricMissingDependencyType = 'metrics' | 'entities' | 'dimensions' | 'relationships';
type MissingDependencyExtraFields = {
	type: MetricMissingDependencyType;
	numOfDependencies: number;
	description: string;
	entityName?: string;
	orderingValue: number;
};
export type MetricMissingDependency = (MissingObject | MissingSemantic) & MissingDependencyExtraFields;
