import Popover from '@components/Popover';
import shadows from 'src/style/shadows';
import { ExploreMenu } from '../TopNavigation/ExploreMenu';
import { MetricType } from '../../types/common';

export type AskAICreateNewMetricProps = {
	prompt?: string;
	children: React.ReactNode;
	createInNewTab: boolean;
	onMetricTypeClick?: (type: MetricType) => void;
	isSelectMetricTypeOpen: boolean;
	onSelectMetricTypeClose: VoidFunction;
};

export function AskAICreateNewMetricPopover({
	prompt,
	children,
	createInNewTab,
	onMetricTypeClick,
	isSelectMetricTypeOpen,
	onSelectMetricTypeClose,
}: AskAICreateNewMetricProps) {
	return (
		<Popover
			triggerElement={children}
			isOpen={isSelectMetricTypeOpen}
			placement={'auto-start'}
			onClose={onSelectMetricTypeClose}
			popoverContentProps={{
				boxShadow: shadows['a-22'],
			}}
			closeOnBlur={true}
		>
			<ExploreMenu
				isCreateNewMetric
				onClose={onSelectMetricTypeClose}
				onMetricTypeClick={onMetricTypeClick}
				isNewTab={createInNewTab}
				autoRunPrompt={prompt}
			/>
		</Popover>
	);
}
