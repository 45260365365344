import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';

export function StringValueDisplay({
	value,
	tooltip,
	isEditing,
	isEnabled,
	isFaded = false,
	onStartEditing,
}: {
	value: string;
	tooltip?: string;
	isEditing: boolean;
	isEnabled: boolean;
	isFaded?: boolean;
	onStartEditing: () => void;
}) {
	return (
		<Tooltip size={'sm'} label={tooltip} variant="fluid" placement={'right'} as={'div'} shouldWrapChildren={false}>
			<Flex
				alignItems={'center'}
				color={isFaded ? 'gray.700' : 'gray.1000'}
				padding={'8px'}
				borderRadius={'6px'}
				width={'100%'}
				backgroundColor={isEditing ? 'gray.200' : 'transparent'}
				_hover={{
					backgroundColor: !isEnabled ? 'transparent' : 'blue.100',
				}}
				cursor={isEnabled ? 'pointer' : 'default'}
				onClick={isEnabled ? onStartEditing : undefined}
			>
				<Typography
					variant={'DesktopH10Regular'}
					textOverflow={'ellipsis'}
					whiteSpace={'nowrap'}
					overflow={'hidden'}
					wordBreak={'break-all'}
					as={'div'}
					width={'100%'}
				>
					{value}
				</Typography>
			</Flex>
		</Tooltip>
	);
}
