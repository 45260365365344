import { EnrichedSemanticDefinitions } from 'src/lib/completions/semanticTypes';
import { CompletionProvider } from 'src/lib/completions/completionProvider';
import { getEntity } from 'src/lib/completions/utils/utils';
import { removeDollarSigns } from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';

export const resolveContextFromSemanticsAndName = (
	semanticDefinitions: EnrichedSemanticDefinitions,
	entity: string,
	name: string
) => {
	const completionProvider = new CompletionProvider(semanticDefinitions);
	const enrichedEntity = getEntity(semanticDefinitions, entity);
	const contexts = completionProvider.createContexts({
		entity: enrichedEntity,
	});
	const context = contexts.find((context) => context.name === removeDollarSigns(name));
	return context;
};
