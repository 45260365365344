import { SqlLanguage } from 'sql-formatter';

const sqlLanguages = [
	'bigquery',
	'db2',
	'db2i',
	'hive',
	'mariadb',
	'mysql',
	'n1ql',
	'plsql',
	'postgresql',
	'redshift',
	'singlestoredb',
	'snowflake',
	'spark',
	'sql',
	'sqlite',
	'tidb',
	'transactsql',
	'trino',
	'tsql',
];

export function isSQLLanguage(language?: string | null): language is SqlLanguage {
	if (!language) return false;

	return sqlLanguages.includes(language);
}
