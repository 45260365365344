import { PopoverContent } from '@chakra-ui/react';
import { RefObject, useRef } from 'react';
import useOutsideClick from 'src/common/hooks/interaction/useOutsideClick';
import { useModal } from 'src/common/hooks/ui/useModal';
import shadows from 'src/style/shadows';
import Box from '../Box';
import { ChevronDown16, ChevronUp16 } from '../Icons';
import Popover from '../Popover';
import { ExploreMenu } from './ExploreMenu';
import { NavigationTab } from './NavigationTab';
import { clickOutsideChameleonPopover } from './utils';

export function ExplorePopover({
	isSelected,
	refExploreButton,
}: {
	isSelected: boolean;
	refExploreButton: RefObject<HTMLDivElement>;
}) {
	const { isOpen, onClose, onOpen } = useModal();
	const refExplorePopover = useRef<HTMLDivElement>(null);
	useOutsideClick(refExplorePopover, () => clickOutsideChameleonPopover(onClose));

	return (
		<Popover
			triggerElement={
				<Box ref={refExploreButton}>
					<NavigationTab
						label="Explore"
						isSelected={isOpen || isSelected}
						path={'explore'}
						onClick={onOpen}
						rightIcon={isOpen ? <ChevronUp16 /> : <ChevronDown16 />}
					/>
				</Box>
			}
			isOpen={isOpen}
			placement={'bottom-start'}
			onClose={onClose}
			popoverContentProps={{
				boxShadow: shadows['a-22'],
			}}
			closeOnBlur={false}
		>
			<PopoverContent ref={refExplorePopover}>
				<ExploreMenu onClose={onClose} />
			</PopoverContent>
		</Popover>
	);
}
