import Box from '@components/Box';
import { AllowedColors, ComponentState, ValidationState } from '@components/BuilderTextInput/types';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { SQL16 } from '@icons/index';
import { Editor } from '@monaco-editor/react';
import classNames from 'classnames';
import { SetStateAction } from 'jotai';
import * as monaco from 'monaco-editor';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { insertNewLineAtCursorPosition } from 'src/common/utils/monacoEditor';
import { sleep } from 'src/common/utils/utils';
import {
	SuggestionsContextSettings,
	useMonacoTextFieldProviders,
} from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { SIGHTFULL_TEXTBOX_MONACO_THEME_NAME } from 'src/pages/MetricPage/components/MonacoEditor';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import TEXTBOX_MONACO_THEME from 'src/theme/textbox-monaco-theme.json';
import './TextInputEditor.scss';

const MAX_LINES = 5;
const LINE_HEIGHT = 24;

export const BuilderTextInputField = ({
	validation,
	validationText,
	inputText = '',
	setInputText,
	componentState,
	setComponentState,
	borderColor,
	backgroundColor,
	onChange,
	contextSettings,
	id,
	onBlur,
	rightContent,
	rightIcon,
	readOnly,
}: {
	validation: ValidationState;
	validationText: string;
	inputText: string;
	setInputText: (value: string) => void;
	componentState: ComponentState;
	setComponentState: Dispatch<SetStateAction<ComponentState>>;
	borderColor: ({ isHover }: { isHover: boolean }) => AllowedColors;
	backgroundColor: string;
	onChange?: (value: string) => void;
	contextSettings?: SuggestionsContextSettings;
	id: string;
	onBlur?: () => void;
	rightContent?: React.ReactNode;
	rightIcon?: React.ReactNode;
	readOnly?: boolean;
}) => {
	const wrapperId = `monaco-wrapper-${id}`;
	const editorRef = useRef<null | monaco.editor.IStandaloneCodeEditor>(null);
	const [textFieldHeight, setTextFieldHeight] = useState<number>(20);

	useEffect(() => {
		if (editorRef.current?.getValue().trim() !== inputText.trim()) {
			editorRef.current?.setValue(inputText);
		}
	}, [inputText, editorRef]);
	useMonacoTextFieldProviders(id, contextSettings);

	const adjustEditorHeight = useCallback(() => {
		if (!editorRef.current) {
			return;
		}
		editorRef.current.layout();
		const contentHeight = editorRef.current.getContentHeight();
		if (!contentHeight) return;
		setTextFieldHeight(Math.min(contentHeight, MAX_LINES * LINE_HEIGHT + 20));
		editorRef.current.layout();
	}, []);

	const registerResizeObserver = useCallback(
		(node: Element | null) => {
			if (!node) return;
			const resizeObserver = new ResizeObserver(adjustEditorHeight);
			resizeObserver.observe(node);
		},
		[adjustEditorHeight]
	);

	const onChangeHandler = useCallback(
		(value: string) => {
			setInputText(value);
			adjustEditorHeight();
			onChange?.(value?.trim());
		},
		[setInputText, adjustEditorHeight, onChange]
	);

	const onEnterPressed = (editor: monaco.editor.IStandaloneCodeEditor) => (e: monaco.IKeyboardEvent) => {
		if (e.keyCode === monaco.KeyCode.Enter) {
			if (e.shiftKey) {
				e.preventDefault();
				insertNewLineAtCursorPosition(editor);
				return;
			}

			const suggestionController = editor.getContribution<monaco.editor.IEditorContribution>(
				'editor.contrib.suggestController'
			);

			const domNode: HTMLElement | undefined = (suggestionController as any)?.widget._value.element.domNode;
			if (domNode) {
				const isDomNodeVisible = domNode.classList.contains('visible');
				const isElementSelected = !!domNode.querySelector('.tree .element-focused');

				if (isDomNodeVisible && isElementSelected) {
					return;
				}
			}

			e.preventDefault();
			e.target.blur();
		}
	};

	const onEscapePressed = () => (e: monaco.IKeyboardEvent) => {
		if (e.keyCode === monaco.KeyCode.Escape) {
			e.preventDefault();
			e.target.blur();
			document.addEventListener('keydown', focusNextInputOnTab);
		}
	};

	const rightIconClassName = 'rightIconButton';

	function conditionalBlurHandler(e: React.FocusEvent<HTMLDivElement>) {
		const targetIconClassName =
			e.relatedTarget && e.relatedTarget.parentElement && e.relatedTarget.parentElement.className;

		const shouldIgnoreBlurOnActionClick =
			targetIconClassName && targetIconClassName.split(' ').includes(rightIconClassName);

		if (shouldIgnoreBlurOnActionClick) {
			return;
		}

		onBlur?.();
	}

	const focusNextInputOnTab = (event: KeyboardEvent) => {
		if (event.key === 'Tab') {
			focusNextInput(event.shiftKey ? false : true);
			event.preventDefault();
		}
	};
	const focusNextInput = (isNext = true) => {
		const targetClasses = ['builder-filter-button', 'monaco-mouse-cursor-text'];
		const focusableElements = Array.from(document.querySelectorAll('input, button, textarea'));

		const isTargetClass = (el: Element) => targetClasses.some((className) => el.classList.contains(className));

		const focusedElement =
			focusableElements.find((el) => document.getElementById(wrapperId)?.contains(el)) ||
			focusableElements.find(isTargetClass);

		if (!focusedElement) return;

		const currentIndex = focusableElements.indexOf(focusedElement);
		const elementToMove = focusableElements[currentIndex + (isNext ? 1 : -1)];

		if (
			elementToMove instanceof HTMLInputElement ||
			(elementToMove instanceof HTMLElement && elementToMove.tabIndex >= 0)
		) {
			elementToMove.focus();
		} else if (elementToMove instanceof HTMLElement) {
			elementToMove.click();
		}

		document.removeEventListener('keydown', focusNextInputOnTab);
	};

	return (
		<Flex
			id={wrapperId}
			flexDirection={'column'}
			alignItems={'flex-start'}
			gap={'8px'}
			width={'100%'}
			onBlur={conditionalBlurHandler}
		>
			<Flex
				borderColor={borderColor({ isHover: false })}
				borderWidth={'1px'}
				borderStyle={'solid'}
				borderRadius={'8px'}
				width={'100%'}
				_hover={{
					borderColor: borderColor({ isHover: true }),
				}}
				boxShadow={componentState == 'Focus' ? shadows.focus.medium.gray['100'] : 'none'}
				backgroundColor={backgroundColor}
				overflow={'hidden'}
			>
				<Flex
					position={'relative'}
					flex={1}
					alignItems={'center'}
					paddingLeft={'11px'}
					paddingRight={'4px'}
					justifyContent={'space-between'}
					overflowX={'hidden'}
				>
					<Flex alignItems={'flex-start'} gap={'8px'} width={'100%'}>
						<Flex alignItems={'center'} gap={'8px'} height={'20px'} zIndex="1" marginY={'12px'}>
							<Box>
								<SQL16 color={validation == 'Error' ? colors.red[600] : colors.gray[600]} />
							</Box>
							<Box height={'16px'}>
								<Divider direction="vertical" color={validation == 'Error' ? colors.red[400] : colors.gray[400]} />
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							height={`${textFieldHeight}px`}
							className={classNames('monaco-text-field-container', 'monaco-with-css-tooltips')}
							ref={registerResizeObserver}
						>
							<Editor
								path={textFieldIdToFileUri(id)}
								language="sql"
								options={{ ...editorOptions, readOnly }}
								onChange={() => onChangeHandler?.(editorRef.current?.getValue()?.trim() ?? '')}
								defaultValue={inputText}
								onMount={(editor: monaco.editor.IStandaloneCodeEditor) => {
									editorRef.current = editor;

									monaco.editor.defineTheme(SIGHTFULL_TEXTBOX_MONACO_THEME_NAME, TEXTBOX_MONACO_THEME as any);
									monaco.editor.setTheme(SIGHTFULL_TEXTBOX_MONACO_THEME_NAME);
									editor.onKeyDown(onEnterPressed(editor));
									editor.onKeyDown(onEscapePressed());
									if (inputText === '') editor.trigger('', 'editor.action.triggerSuggest', { auto: true });
									editor.onDidBlurEditorText(() => {
										setComponentState('Idle');
										editor.trigger('editor', 'hideSuggestWidget', []);
									});
									editor.onDidFocusEditorText(() => {
										editor.trigger('', 'editor.action.triggerSuggest', { auto: true });
										sleep(50).then(() => {
											moveCursortToEnd(editor);
										});
										setComponentState('Focus');
									});
									editor.onKeyUp((e) => {
										if ([monaco.KeyCode.Space, monaco.KeyCode.Backspace].includes(e.keyCode)) {
											editor.trigger('', 'editor.action.triggerSuggest', { auto: true });
										}
									});
									adjustEditorHeight();
								}}
								loading={<></>}
							/>
						</Flex>
					</Flex>
					{!!rightIcon && !inputText.length && <Box className={rightIconClassName}>{rightIcon}</Box>}
				</Flex>
				{rightContent && (
					<Flex
						paddingX={'12px'}
						backgroundColor={'gray.100'}
						borderLeft={'1px solid'}
						borderColor={'gray.400'}
						borderRadius={'8px'}
						borderTopLeftRadius={0}
						borderBottomLeftRadius={0}
						justifyContent={'center'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						{rightContent}
					</Flex>
				)}
			</Flex>
			{validation == 'Error' && (
				<Box width={'100%'} alignSelf={'stretch'}>
					<Typography
						width={'100%'}
						minHeight={'40px'}
						variant={'Paragraph12R'}
						overflow={'hidden'}
						color={'red.800'}
						textOverflow={'ellipsis'}
						whiteSpace={'nowrap'}
					>
						{validationText}
					</Typography>
				</Box>
			)}
		</Flex>
	);
};

export function textFieldIdToFileUri(id: string) {
	return `file:///textfield-${id}.sql`;
}

export default BuilderTextInputField;

function moveCursortToEnd(editor: monaco.editor.IStandaloneCodeEditor) {
	const model = editor.getModel();
	if (!model) return;
	const position = model.getPositionAt(model.getValueLength());
	editor.revealPosition(position);
	editor.setPosition(position);
}

const editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
	minimap: { enabled: false },
	automaticLayout: true,
	fontSize: 14,
	suggestLineHeight: 28,
	fontWeight: '400',
	fontFamily: 'inter',
	scrollBeyondLastLine: false,
	lineDecorationsWidth: 0,
	glyphMargin: false,
	folding: false,
	lineNumbers: 'off',
	fixedOverflowWidgets: true,
	scrollbar: {
		vertical: 'visible',
		useShadows: true,
		verticalSliderSize: 6,
		verticalScrollbarSize: 6,
	},
	renderLineHighlight: 'none',
	hideCursorInOverviewRuler: true,
	overviewRulerBorder: false,
	overviewRulerLanes: 0,
	padding: { top: 10, bottom: 10 },
	lineHeight: LINE_HEIGHT,
	tabSize: 2,
	wordWrap: 'on',
	wrappingStrategy: 'advanced',
	suggest: {
		selectionMode: 'never',
	},
	lineNumbersMinChars: 0,
	'semanticHighlighting.enabled': true,
	cursorStyle: 'line-thin',
};
