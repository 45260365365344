import { useCallback } from 'react';
import { ParameterType, ValuesType } from '../../../../../generated/graphql';
import { useReportEvent } from '../../../../../services/analytics';
import { ParameterBooleanEditor } from './ParameterBooleanEditor';
import { EditParameterProps } from './types';
import { ParameterInputEditor } from './ParameterInputEditor';
import { ParameterSelectOneFromListEditor } from './ParameterSelectOneFromListEditor';

export function ParameterEditValueContent({ parameter, value, setSelectedValue }: EditParameterProps) {
	const { reportEvent } = useReportEvent();

	const onEdit = useCallback(() => {
		reportEvent({ event: 'edit-metric-parameters-clicked', metaData: { parameter: parameter.definition.name } });
	}, [parameter.definition.name, reportEvent]);

	if (parameter.definition.type === ParameterType.Bool) {
		return (
			<ParameterBooleanEditor parameter={parameter} value={value} setSelectedValue={setSelectedValue} onEdit={onEdit} />
		);
	}

	if (parameter.definition.values_type === ValuesType.List) {
		return (
			<ParameterSelectOneFromListEditor
				parameter={parameter}
				value={value}
				setSelectedValue={setSelectedValue}
				onEdit={onEdit}
			/>
		);
	}

	return (
		<ParameterInputEditor parameter={parameter} value={value} setSelectedValue={setSelectedValue} onEdit={onEdit} />
	);
}
