import { useAtom } from 'jotai';
import { useEffect, useCallback } from 'react';
import { AIAgentMetricDiscoveryResponse, AIAgentName, AskAIChatCardProps } from '../types';
import { useAIAgents } from './useAIAgents';

type DiscoveryAgentState = {
	running: boolean;
};

const discoveryAgentState: DiscoveryAgentState = {
	running: false,
};

export function useCachedPerformDiscovery({ chatMessageAtom }: Pick<AskAIChatCardProps, 'chatMessageAtom'>) {
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { userPrompt, result: cachedResult, error: cachedError } = chatMessage;

	const { getOrCreateAIAgentThread, callAIAgent } = useAIAgents();

	const callMetricDiscoveryAIAgent = useCallback(async () => {
		if (discoveryAgentState.running) return;
		try {
			discoveryAgentState.running = true;
			const agentName: AIAgentName = `metric-discovery`;
			const threadId = await getOrCreateAIAgentThread({ agentName });
			const data = await callAIAgent<AIAgentMetricDiscoveryResponse>({
				agentName,
				threadId,
				body: { prompt: userPrompt },
			});
			setChatMessage((prev) => ({
				...prev,
				type: 'metric-discovery',
				result: data?.suggestion,
				error: data?.status === 'error',
			}));
		} finally {
			discoveryAgentState.running = false;
		}
	}, [getOrCreateAIAgentThread, callAIAgent, userPrompt, setChatMessage]);

	useEffect(() => {
		if (cachedResult || cachedError) return;
		callMetricDiscoveryAIAgent().catch((error) => setChatMessage((prev) => ({ ...prev, error: !!error })));
	}, [cachedError, cachedResult, callMetricDiscoveryAIAgent, setChatMessage]);

	const onQueryDone = useCallback(() => {
		setChatMessage((prev) => ({ ...prev, completionTime: Date.now() }));
	}, [setChatMessage]);

	return { chatMessage, onQueryDone };
}
