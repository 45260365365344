import Markdown from '@components/Markdown';
import Button from '@components/Button';
import { ArrowCCW16 } from '@components/Icons';
import Typography from '@components/Typography';

import { PrimitiveAtom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { AskAIChatCardLayout } from './AskAIChatCardLayout';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { AskAIChatMessage } from '../types';
import Box from '../../Box';

export type AskAIErrorCardProps = {
	chatMessageAtom: PrimitiveAtom<AskAIChatMessage>;
	chatIndex: number;
};

function CardFooter({ onRegenerate }: { onRegenerate: () => void }) {
	return (
		<Button variant={'solid'} onClick={onRegenerate} size={'small'} leftIcon={<ArrowCCW16 />}>
			Regenerate
		</Button>
	);
}

export function AskAIErrorCard({ chatMessageAtom, chatIndex }: AskAIErrorCardProps) {
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { userPrompt, type, errorText } = chatMessage;

	useEffect(() => {
		if (chatMessage.completionTime) return;
		setChatMessage((prev) => ({ ...prev, error: true, completionTime: Date.now() }));
	}, [setChatMessage, chatMessage]);

	const onRegenerate = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-error-regenerate-response',
			metaData: {
				...chatMessage,
				kind: 'negative',
			},
		});
		addChatMessage({ userPrompt, type: chatMessage.type, promptContext: chatMessage.promptContext });
	}, [reportAskAIEvent, chatMessage, addChatMessage, userPrompt]);

	return (
		<AskAIChatCardLayout
			type={type}
			userPrompt={userPrompt}
			borderColor={'red.300'}
			backgroundColor={'red.100'}
			footer={<CardFooter onRegenerate={onRegenerate} />}
		>
			<Box padding={'0 16px 16px 16px'}>
				<Typography variant="Paragraph14R" color={'gray.1000'}>
					<Markdown
						message={errorText ?? 'Something went wrong, please try again'}
						data-testid={`ask-ai-chat-card-error-${chatIndex}`}
					/>
				</Typography>
			</Box>
		</AskAIChatCardLayout>
	);
}
