import { Box } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { NormalizedOntology } from '../../hooks/useOntologyPageState';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import { BuilderTypes } from '../../utils/types';
import { isOntologyDimensionInfo, isOntologyRelationshipInfo } from '../../utils/utils';
import { TestIDs } from 'src/common/types/test-ids';

export default function DeleteItemsModal({
	propertyToDelete,
	affectedMetricsList,
	onClose,
	type,
	onSubmit,
	isLoading = false,
}: {
	type: BuilderTypes;
	propertyToDelete?: OntologyDimensionInfo | OntologyRelationshipInfo | NormalizedOntology;
	affectedMetricsList: string[];
	onClose: VoidFunction;
	onSubmit: VoidFunction;
	isLoading?: boolean;
}) {
	const name =
		isOntologyRelationshipInfo(propertyToDelete) || isOntologyDimensionInfo(propertyToDelete)
			? propertyToDelete?.meta?.display_name || removeUnderscoresAndCapitalize(propertyToDelete?.name || '')
			: propertyToDelete?.displayName || removeUnderscoresAndCapitalize(propertyToDelete?.name || '');

	const descriptionMaintext = `${pluralize(type, 1)} ${
		type === 'entity'
			? 'will be permanently deleted from the ontology with its related dimensions and relationships.'
			: 'will be permanently deleted from the ontology.'
	}`;

	const deleteModalDescription = useMemo(() => {
		if (!propertyToDelete || !affectedMetricsList.length) return;
		const metricsCount = affectedMetricsList.length;
		const displayedMetrics = affectedMetricsList.slice(0, 5).join(', ');

		return (
			<Box marginTop={'20px'} borderRadius="8px" p="12px 16px" bg="gray.200">
				<Typography textAlign="start" variant="Paragraph14R">
					This action will affect {metricsCount} {pluralize('metrics', metricsCount)}: {displayedMetrics}
					{metricsCount > 5 && (
						<>
							&nbsp;and&nbsp;
							<Typography as="span" variant="Paragraph14R">
								+{metricsCount - 5} more.
							</Typography>
						</>
					)}
				</Typography>
			</Box>
		);
	}, [affectedMetricsList, propertyToDelete]);

	return (
		<ConfirmationModal
			testId={TestIDs.ENTITY_DELETE_MODAL}
			isLoading={isLoading}
			contentMaxWidth="auto"
			primaryButtonLabel={'Delete'}
			isOpen={!!propertyToDelete}
			modalText={
				<Flex direction="column" gap="10px">
					<Box>
						<Box as="span" fontWeight="600">
							{name}
						</Box>
						&nbsp;
						{descriptionMaintext}
						{deleteModalDescription}
					</Box>
				</Flex>
			}
			modalTitle={`Delete ${pluralize(capitalizedFirstLetter(type), 1)}`}
			onSubmit={onSubmit}
			onClose={onClose}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
		/>
	);
}
