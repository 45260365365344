import { Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { ErrorYellow16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useSemanticsGetMetricDisplayName } from 'src/common/hooks/stores/useSemanticDefinitions';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { OntologyDimensionInfo } from '../../utils/normalizeYaml';
import { getIconByType } from '../../utils/utils';
import ItemCard from './ItemCard';
import ThreeDotsButton from './ThreeDotsButton';
import { TestIDs } from 'src/common/types/test-ids';

export interface ListHeaderProps {
	testId?: string;
	item: OntologyDimensionInfo;
	isFullyDefined?: boolean;
	affectedMetricsList?: string[];
	onDelete: VoidFunction;
	isEditable: boolean;
}

export default function DimensionItem({
	item,
	isFullyDefined,
	affectedMetricsList,
	onDelete,
	isEditable,
}: ListHeaderProps) {
	const isAffectingMetrics = affectedMetricsList && affectedMetricsList?.length > 0;
	return (
		<ItemCard
			cardActions={
				<ThreeDotsButton
					testId={TestIDs.DIMENSION_ACTION_DROPDOWN}
					actionTestId={TestIDs.DELETE_DIMENSION_ACTION}
					type="dimensions"
					onDelete={onDelete}
					isEditable={isEditable}
				/>
			}
			icon={getIconByType(item.type)}
			title={item?.meta?.display_name || removeUnderscoresAndCapitalize(item?.name)}
			suffixIcon={!isFullyDefined && <ErrorYellow16 />}
			description={
				<AffectingMetricsTooltip affectedMetricsList={affectedMetricsList ?? []}>
					{isAffectingMetrics
						? `Affecting ${affectedMetricsList.length} ${pluralize('metric', affectedMetricsList.length)}`
						: 'No affected metrics'}
				</AffectingMetricsTooltip>
			}
		/>
	);
}

export function AffectingMetricsTooltip({
	affectedMetricsList,
	children,
}: {
	affectedMetricsList: string[];
	children: React.ReactNode;
}) {
	const getDisplayName = useSemanticsGetMetricDisplayName();
	const isAffectingMetrics = affectedMetricsList.length > 0;
	const fullyPreviewedMetrics = useMemo(() => {
		return affectedMetricsList?.slice(0, 7).map((metric) => (
			<ListItem key={metric}>
				<Typography variant="Paragraph14R" textOverflow={'ellipsis'} noOfLines={0}>
					{getDisplayName(metric)}
				</Typography>
			</ListItem>
		));
	}, [affectedMetricsList, getDisplayName]);
	const plusMore = useMemo(() => {
		if (affectedMetricsList?.length > 7) {
			return (
				<Typography variant="Paragraph14R" textOverflow={'ellipsis'} noOfLines={0} paddingTop={'8px'}>
					+{affectedMetricsList.length - 7} more.
				</Typography>
			);
		}
	}, [affectedMetricsList.length]);

	return (
		<Tooltip
			label={
				isAffectingMetrics && (
					<Flex direction={'column'}>
						<UnorderedList>{fullyPreviewedMetrics}</UnorderedList>
						{plusMore}
					</Flex>
				)
			}
			size="md"
		>
			{children}
		</Tooltip>
	);
}
