import { useReportEvent } from '@services/analytics';
import { useShareSignalApi } from '@hooks/useShareSignalApi';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { cleanSearchParams, useMetricPageSearchParams } from '@pages/MetricPage/hooks/useMetricPageSearchParams';
import useToast from '@hooks/ui/useToast';
import { useSignal } from '@pages/SignalPage/hooks/useSignal';
import { useTableColumnStateAsSearchParam } from '@pages/MetricPage/components/ShareSignalModal/useTableColumnStateAsSearchParam';
import useUser from '@hooks/stores/useUser';
import { Permission, Permissions } from 'src/types/environment';
import Button from '@components/Button';
import { AccessController } from '@components/AccessController';
import { SaveButton } from '@pages/SignalPage/components/SaveButton';
import { removeNonAlphabeticCharacters } from 'src/normalize';

export function SaveSignalButton({
	onCloseClicked,
	onSaveAsNew,
	title,
	description,
	isDuplicateLoading,
	isDuplicateSignal,
	isParamsChanged,
	collectionType,
}: {
	title: string;
	description: string;
	onCloseClicked: VoidFunction;
	onSaveAsNew: VoidFunction;
	isDuplicateLoading: boolean;
	isDuplicateSignal?: boolean;
	isParamsChanged: boolean;
	collectionType: string;
}) {
	const { reportEvent } = useReportEvent();
	const [shareSignal, { isLoading }] = useShareSignalApi();
	const { periodRange } = useMetricDerivedState();
	const { metricNameWithFlavor, dangerousRawSearchParams } = useMetricPageSearchParams();
	const showToast = useToast();
	const signal = useSignal();
	const { getColumnStateSearchParam } = useTableColumnStateAsSearchParam();

	const [{ id: userId }] = useUser();
	const oneOfPermissionsIsMatched: Permission[] = [Permissions.updateAllSignals];
	if (signal?.author_id == userId) oneOfPermissionsIsMatched.push(Permissions.crudMySignals);

	function onSaveClicked() {
		const formattedTitle = removeNonAlphabeticCharacters(title)?.trim();
		const metricName = metricNameWithFlavor;
		const searchParams = new URLSearchParams(dangerousRawSearchParams);

		cleanSearchParams(searchParams);
		const isRelative = periodRange.isRelativeSupported && collectionType == 'dashboard';
		const range = isRelative ? periodRange.asRelativeRange : periodRange.asAbsoluteRange;

		searchParams.set('periodRange', JSON.stringify(range.toUrlParams().periodRange));

		searchParams.delete('tableColumnState');
		if (searchParams.get('chartType') == 'table') {
			searchParams.set('tableColumnState', getColumnStateSearchParam());
		} else {
			searchParams.delete('selectedXAxisElements');
		}

		const attachment = encodeURI(`metric/${metricName}?${decodeURI(searchParams.toString())}`);

		reportEvent({
			event: 'submit-signal-edit',
			metaData: { feature: 'Signal header', message: signal?.message, userAttachment: attachment },
		});
		shareSignal({
			attachmentContentURL: attachment,
			message: description || signal?.message,
			collectionToPin: [],
			collectionToUnPin: [],
			title: formattedTitle || (signal?.title ?? metricNameWithFlavor),
			signalId: signal?.id,
		}).then(() => {
			showToast({ variant: 'ok', message: 'Signal Saved' });
			onCloseClicked();
		});
	}

	const viewAccessOnlyChip = (
		<Button
			color={'gray.1000'}
			colorScheme="gray"
			variant="ghost"
			onClick={() => {
				reportEvent({
					event: 'signal-page-close-clicked',
					metaData: { feature: 'Signal header' },
				});
				onCloseClicked();
			}}
			size="inline"
		>
			Close
		</Button>
	);

	return (
		<AccessController permission={Permissions.crudMySignals} logic="OR" noAccessChild={viewAccessOnlyChip}>
			<SaveButton
				isDuplicateSignal={isDuplicateSignal}
				description={description}
				title={title}
				metricNameWithFlavor={metricNameWithFlavor}
				oneOfPermissionsIsMatched={oneOfPermissionsIsMatched}
				isParamsChanged={isParamsChanged}
				isDuplicateLoading={isDuplicateLoading}
				onSaveClicked={onSaveClicked}
				isLoading={isLoading}
				onSaveAsNew={onSaveAsNew}
				onCloseClicked={() => {
					reportEvent({
						event: 'signal-page-close-clicked',
						metaData: { feature: 'Signal header' },
					});
					onCloseClicked();
				}}
			/>
		</AccessController>
	);
}
