import { buildDiv, buildDivChildren, buildLink } from './baseBuilders';

type SourceFooterProps = {
	source: string;
	isWithSourceIcon: boolean;
	entityName?: string;
	entityLink?: string;
};

export function buildSourceFooter({ source, entityLink, entityName, isWithSourceIcon }: SourceFooterProps): string {
	const hasEntity = !!entityName && !!entityLink;
	const sourceIcon = isWithSourceIcon && buildDiv(`source-icon-${source.replaceAll(' ', '-')}`);
	const entityNameDiv = entityName && buildDiv('source-entity-name', entityName);
	const arrowIcon = buildDiv(`source-icon-arrow`);
	const sourceArrowIcon = hasEntity && buildLink(entityLink, arrowIcon);
	const footerContent = buildDivChildren('footer-content', sourceIcon, entityNameDiv);
	return buildDivChildren('source-footer', footerContent, sourceArrowIcon);
}
