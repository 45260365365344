import Popover from '@components/Popover';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { PlacementType } from '@components/Popover/types';
import { AskAIInput } from '../AskAIInput';
import { AskAIChatCardsList } from './AskAIChatCardList';
import Button from '../../Button';
import { CloseLarge16, EditBox16 } from '../../Icons';
import { useCallback } from 'react';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useAIAgents } from '../hooks/useAIAgents';
import Tooltip from '../../Tooltip';

export type AskAIChatPopoverProps = {
	isOpen: boolean;
	onClose: () => void;
	triggerElement: React.ReactNode;
	placement?: PlacementType;
	inputPlaceholder?: string;
	reportEvent: string;
	onPrompt: (prompt: string) => void;
	builderType?: string;
};

export function AskAIChatPopover({
	isOpen,
	onClose,
	triggerElement,
	placement,
	onPrompt,
	inputPlaceholder,
	reportEvent,
	builderType,
}: AskAIChatPopoverProps) {
	const { clearAIAgentsContext, abortAIAgent } = useAIAgents();
	const { resetChatMessages } = useAskAIChatMessages();

	const onClearChat = useCallback(() => {
		resetChatMessages();
		clearAIAgentsContext();
	}, [clearAIAgentsContext, resetChatMessages]);

	const onCancel = useCallback(() => {
		abortAIAgent('Canceled');
	}, [abortAIAgent]);

	const onClickClose = useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Popover
			data-testid={'ask-ai-chat-popover'}
			isOpen={isOpen}
			autoFocus={true}
			onClose={onClose}
			placement={placement ?? 'right-start'}
			triggerElement={triggerElement}
			popoverContentProps={{
				border: '1px',
				borderColor: 'gray.300',
				borderRadius: '12px',
				padding: '4px',
			}}
		>
			<Flex direction={'column'} minHeight={'440px'} maxHeight={'692px'} width={'442px'}>
				<Flex gap={'4px'} alignItems={'center'} justifyContent={'right'} padding={'8px'}>
					<Tooltip label={'Start new chat'} size={'md'} placement={'top'}>
						<Button isIconOnly={true} size={'xs'} variant={'outline'} colorScheme={'lightGray'} onClick={onClearChat}>
							<EditBox16 />
						</Button>
					</Tooltip>
					<Tooltip label={'Close'} size={'md'} placement={'top'}>
						<Button isIconOnly={true} size={'xs'} variant={'outline'} colorScheme={'lightGray'} onClick={onClickClose}>
							<CloseLarge16 />
						</Button>
					</Tooltip>
				</Flex>
				<AskAIChatCardsList cardMargin={'12px 4px'} builderType={builderType} />
				<Box padding={'12px'}>
					<AskAIInput
						placeholder={inputPlaceholder ?? `Ask Sightfull AI`}
						hasBorder={true}
						autoFocus={true}
						borderRadius={'32px'}
						submitButtonBorderRadius={'26px'}
						clearInputOnSubmit={true}
						onSubmit={onPrompt}
						onCancel={onCancel}
						isCancellable={true}
						reportEvent={reportEvent}
						reportFeature={'Ask AI'}
						size={'sm'}
					/>
				</Box>
			</Flex>
		</Popover>
	);
}
