import { Box, Button, Flex } from '@chakra-ui/react';
import TextEditor from 'src/common/components/TextEditor';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import { SignalPath } from 'src/pages/SignalPage/pageRoutePaths';
import { WorkspacePath } from 'src/pages/WorkspacePage';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import { FeedSignal } from 'src/types/spaces';
import { WorkspaceAttachment } from './WorkspaceAttachment';
import { ErrorBoundary } from 'react-error-boundary';

export function SignalBody({
	signal,
	hasEditActions,
}: {
	signal: FeedSignal;
	hasEditActions?: boolean;
	onAttachmentClick?: VoidFunction;
}) {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const mentions = useMentionsState();

	const onAttachmentEdit = () => {
		reportEvent({
			event: 'signal-attachment-edit',
			metaData: {
				signalId: signal.signal_id,
				workspaceId: signal.feed_id,
			},
		});
		navigate({ path: `${WorkspacePath}/${signal.feed_id}/${SignalPath}/${signal.signal_id}` });
	};

	const onHeaderClick = () => {
		reportEvent({
			event: 'signal-attachment-header-clicked',
			metaData: {
				signalId: signal.signal_id,
				workspaceId: signal.feed_id,
			},
		});
		navigate({ path: `${WorkspacePath}/${signal.feed_id}/${SignalPath}/${signal.signal_id}` });
	};

	return (
		<Box width="100%">
			<Flex p="16px 24px 24px 24px" direction="column">
				{signal.signal.message && (
					<Box overflowY="auto">
						<TextEditor mentions={mentions} value={signal.signal.message} onChange={() => void 0} isEditMode={false} />
					</Box>
				)}
				{signal.signal.attachment && (
					<ErrorBoundary
						fallback={<AttachmentError attachment={signal.signal.attachment} />}
						key={`workspace-signal-${signal.signal_id}`}
					>
						<Box mt="24px" borderRadius={'4px'} border={`1px solid ${colors.gray[300]}`} overflow="clip">
							<WorkspaceAttachment
								headerOnClickHandler={onHeaderClick}
								authorId={signal.signal.author_id}
								onEdit={hasEditActions ? onAttachmentEdit : undefined}
							/>
						</Box>
					</ErrorBoundary>
				)}
			</Flex>
		</Box>
	);
}

function AttachmentError({ attachment }: { attachment: string }) {
	const { navigate } = useNavigation();
	const isNode = attachment.includes('node/');
	if (!isNode) return null;

	return (
		<Box>
			<Button
				variant="link"
				size={'sm'}
				color={colors.blue[700]}
				onClick={() => navigate({ path: attachment, isNewTab: true })}
			>
				View zoom-in page
			</Button>
		</Box>
	);
}
