import { AskAIChatCardProps, AskAIChatFieldSuggestion, isAskAIOntologyBuilderEntityChatMessage } from '../../types';
import { useOntologyBuilderAIAgent } from '../../hooks/useOntologyBuilderAIAgent';
import { useMemo } from 'react';
import { AskAIChatFieldSuggestions } from '../AskAIChatFieldSuggestions';
import { AskAILoadingResults } from '../AskAILoadingResults';

export function AskAIOntologyBuilderEntityCardContent({ chatMessageAtom, isLast }: AskAIChatCardProps) {
	const { chatMessage } = useOntologyBuilderAIAgent({ chatMessageAtom });
	const isEntityMessage = isAskAIOntologyBuilderEntityChatMessage(chatMessage);
	const result = isEntityMessage ? chatMessage.result : undefined;
	const entity = result?.entity;
	const existingEntity: typeof entity = chatMessage.promptContext?.parsedYaml;

	const fields = useMemo(() => {
		if (!entity) return [];

		const fields: AskAIChatFieldSuggestion[] = [];

		if (entity.name && (!existingEntity || existingEntity.name === 'untitled')) {
			fields.push({
				label: '',
				propertyName: 'name',
				value: entity.name,
			});
		}

		if (entity.meta?.display_name) {
			fields.push({
				label: 'Name',
				propertyName: 'display_name',
				display: entity.meta.display_name,
				value: entity.meta.display_name,
				existingValue: existingEntity?.meta?.display_name,
			});
		}

		if (entity.data_source) {
			if ('schema' in entity.data_source && entity.data_source.schema) {
				fields.push({
					label: 'Schema',
					propertyName: 'schema',
					display: entity.data_source.schema,
					value: entity.data_source.schema,
					existingValue: existingEntity?.data_source?.schema,
				});
			}

			if ('table' in entity.data_source && entity.data_source.table) {
				fields.push({
					label: 'Source table',
					propertyName: 'table',
					display: entity.data_source.table,
					value: entity.data_source.table,
					existingValue: existingEntity?.data_source?.table,
				});
			}
		}

		if (entity.primary_keys) {
			fields.push({
				label: 'Primary keys',
				propertyName: 'primary_keys',
				display: entity.primary_keys.join(', '),
				value: entity.primary_keys,
				existingValue: existingEntity?.primary_keys,
			});
		}

		if (entity.data_source && 'filters' in entity.data_source && entity.data_source.filters) {
			fields.push({
				label: 'Filters',
				propertyName: 'filters',
				display: entity.data_source.filters.map((filter) => filter.sql).join(', '),
				value: entity.data_source.filters,
				existingValue: existingEntity?.data_source?.filters,
			});
		}

		fields.push({
			label: 'Description',
			propertyName: 'description',
			display: entity.meta?.description,
			value: entity.meta?.description,
			existingValue: existingEntity?.meta?.description,
		});

		return fields;
	}, [entity, existingEntity]);

	if (!isEntityMessage) return;

	return entity ? (
		<AskAIChatFieldSuggestions
			fields={fields}
			onAcceptSuggestions={chatMessage.onAcceptSuggestions}
			isLastChatCard={isLast}
		/>
	) : (
		<AskAILoadingResults />
	);
}
