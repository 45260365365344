import Box from '@components/Box';
import Flex from '@components/Flex';
import { useCallback, useEffect } from 'react';
import { DataConnectorsHelpModal } from 'src/common/components/TopNavigation/DataConnectorsModal/DataConnectorsHelpModal';
import { DataConnectorsOnboardingCallModal } from 'src/common/components/TopNavigation/DataConnectorsModal/DataConnectorsOnboardingCallModal';
import { DataConnectorsThankYouModal } from 'src/common/components/TopNavigation/DataConnectorsModal/DataConnectorsThankYouModal';
import {
	FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME,
	GET_HELP_MODAL_LIFESPAN_HOURS,
} from 'src/common/components/TopNavigation/DataConnectorsModal/constants';
import { isHoursPassed } from 'src/common/components/TopNavigation/DataConnectorsModal/utils';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import Page from 'src/layout/Page';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { ConnectorsPagePath } from '.';
import { ConnectorsBanner } from './components/ConnectorsBanner';
import { ConnectorsTable } from './components/ConnectorsTable';
import { getConnectorsName } from './utils/utils';

type ConnectorsPageProps = {
	isThankYouModalActive?: boolean;
};

export default function ConnectorsPage({ isThankYouModalActive = false }: ConnectorsPageProps) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isDataConnectorsPageEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsPage.enable');
	const isThankYouModalEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsDynamicModal.enable');

	if (!isSightfull2 || !isDataConnectorsPageEnabled) return null;

	return (
		<Page page="Connectors Page">
			<ConnectorsPageGuard isThankYouModalActive={isThankYouModalActive && isThankYouModalEnabled} />
		</Page>
	);
}

export function ConnectorsPageGuard({ isThankYouModalActive = false }: ConnectorsPageProps) {
	const { reportEvent } = useReportEvent({ feature: 'Connector' });
	const { isOpen, onOpen, onClose } = useModal();
	const {
		isOpen: isOnboardingCallModalOpened,
		onOpen: onOnboardingCallModalOpen,
		onClose: onOnboardingCallModalClose,
	} = useModal();
	const { isOpen: isHelpModalOpened, onOpen: onHelpModalOpen, onClose: onHelpModalClose } = useModal();
	const { navigate } = useNavigation();

	const dataConnectorsPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');

	const openHelpModalWithReport = useCallback(() => {
		if (isHelpModalOpened) {
			reportEvent({
				event: 'connectors-playground-authorization-failed-modal-displayed',
				metaData: { name: getConnectorsName() },
			});
		}
		onHelpModalOpen();
	}, [isHelpModalOpened, onHelpModalOpen, reportEvent]);

	const openHelpModal = useCallback(() => {
		if (isThankYouModalActive) return;
		const data = localStorage.getItem(FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME);
		if (!data) return;

		const { timestamp } = JSON.parse(data);
		const shouldDisplayHelpModal = isHoursPassed(timestamp, GET_HELP_MODAL_LIFESPAN_HOURS);
		if (shouldDisplayHelpModal) {
			openHelpModalWithReport();
		} else {
			localStorage.removeItem(FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME);
		}
	}, [isThankYouModalActive, openHelpModalWithReport]);

	useEffect(() => openHelpModal(), [openHelpModal]);

	useEffect(() => {
		if (!isThankYouModalActive) return;

		if (isOnboardingCallModalOpened || isOpen) {
			reportEvent({
				event: dataConnectorsPlaygroundEnabled
					? 'connectors-playground-authorized-modal-displayed'
					: 'connectors-authorized-modal-displayed',
				metaData: { name: getConnectorsName() },
			});
		}

		const openHelperModal = dataConnectorsPlaygroundEnabled ? onOnboardingCallModalOpen : onOpen;
		openHelperModal();
	}, [
		isThankYouModalActive,
		onOnboardingCallModalOpen,
		onOpen,
		dataConnectorsPlaygroundEnabled,
		reportEvent,
		isOnboardingCallModalOpened,
		isOpen,
	]);

	const navigateAndRemmoveStorage = () => {
		localStorage.removeItem(FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME);
		navigate({ path: ConnectorsPagePath });
	};

	return (
		<Flex direction={'column'} height="100%" padding={'32px'}>
			<Box marginBottom={'36px'}>
				<ConnectorsBanner />
			</Box>
			<Box flexGrow={1}>
				<ConnectorsTable
					onAddConnectorError={() => {
						if (dataConnectorsPlaygroundEnabled) openHelpModalWithReport();
					}}
				/>
			</Box>
			<DataConnectorsThankYouModal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					navigateAndRemmoveStorage();
				}}
			/>
			<DataConnectorsOnboardingCallModal
				isOpen={isOnboardingCallModalOpened}
				onClose={() => {
					onOnboardingCallModalClose();
					navigateAndRemmoveStorage();
				}}
			/>
			<DataConnectorsHelpModal
				isOpen={isHelpModalOpened}
				onClose={() => {
					onHelpModalClose();
					navigateAndRemmoveStorage();
				}}
			/>
		</Flex>
	);
}
