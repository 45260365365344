import { Suspense, useCallback, useEffect, useState } from 'react';
import { ArrowCCW16, DislikeThin16, LikeThin16 } from '@components/Icons';
import Button from '@components/Button';
import Typography from '@components/Typography';
import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useAtom, useAtomValue } from 'jotai';
import { AskAIErrorCard } from './AskAIErrorCard';
import { AskAIChatCardLayout } from './AskAIChatCardLayout';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import {
	AskAIChatCardProps,
	isAskAIMetricDiscoveryChatMessage,
	isAskAIOntologyBuilderDimensionChatMessage,
	isAskAIOntologyBuilderEntityChatMessage,
	isAskAIOntologyBuilderRelationshipChatMessage,
} from '../types';
import { AskAILoadingResults } from './AskAILoadingResults';
import { AskAIMetricDiscoveryCardContent } from './MetricDiscovery/AskAIMetricDiscoveryCardContent';
import { AskAIOntologyBuilderDimensionCardContent } from './OntologyBuilder/AskAIOntologyBuilderDimensionCardContent';
import { AskAIOntologyBuilderEntityCardContent } from './OntologyBuilder/AskAIOntologyBuilderEntityCardContent';
import { AskAIOntologyBuilderRelationshipCardContent } from './OntologyBuilder/AskAIOntologyBuilderRelationshipCardContent';

type CardFooterProps = {
	onLike: () => void;
	onDislike: () => void;
	onRegenerate: () => void;
};

en.now = { now: 'Just now' };
TimeAgo.addDefaultLocale(en);

function CardFooter({
	chatMessageAtom,
	onLike,
	onDislike,
	onRegenerate,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'> & CardFooterProps) {
	const { reportAskAIEvent } = useReportAIEvent();
	const chatMessage = useAtomValue(chatMessageAtom);
	const { completionTime, vote } = chatMessage;
	const [currentTime, setCurrentTime] = useState<number>(Date.now());
	const timeAgo = new TimeAgo('en-US');

	useEffect(() => {
		const interval = setInterval(() => setCurrentTime(Date.now()), 60 * 1000);
		return () => clearInterval(interval);
	}, [currentTime, setCurrentTime]);

	const onLikeWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-feedback',
			metaData: {
				...chatMessage,
				kind: 'positive',
			},
		});
		onLike();
	}, [chatMessage, onLike, reportAskAIEvent]);

	const onDislikeWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-feedback',
			metaData: {
				...chatMessage,
				kind: 'negative',
			},
		});
		onDislike();
	}, [chatMessage, onDislike, reportAskAIEvent]);

	const onRegenerateWithReport = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-regenerate',
			metaData: {
				...chatMessage,
			},
		});
		onRegenerate();
	}, [chatMessage, onRegenerate, reportAskAIEvent]);

	return (
		<Flex gap={'12px'} direction={'row'} alignItems={'center'}>
			<Typography variant={'Paragraph14R'} color={'gray.600'}>
				{completionTime ? timeAgo.format(completionTime, 'round-minute') : 'Analyzing...'}
			</Typography>
			{completionTime ? (
				<Flex direction={'row'} alignItems={'center'}>
					<Tooltip label="Regenerate" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
						<Button
							variant={'outline'}
							colorScheme={'lightGray'}
							size={'xs'}
							p={'6px'}
							onClick={onRegenerateWithReport}
						>
							<ArrowCCW16 />
						</Button>
					</Tooltip>
					<Tooltip label="Bad response" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
						<Button
							variant={'outline'}
							colorScheme={'lightGray'}
							size={'xs'}
							p={'6px'}
							onClick={onDislikeWithReport}
							isDisabled={vote === 'down'}
						>
							<Flex color={vote === 'down' ? 'red.700' : undefined}>
								<DislikeThin16 />
							</Flex>
						</Button>
					</Tooltip>
					<Tooltip label="Good response" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
						<Button
							variant={'outline'}
							colorScheme={'lightGray'}
							size={'xs'}
							p={'6px'}
							onClick={onLikeWithReport}
							isDisabled={vote === 'up'}
						>
							<Flex color={vote === 'up' ? 'green.700' : undefined}>
								<LikeThin16 />
							</Flex>
						</Button>
					</Tooltip>
				</Flex>
			) : null}
		</Flex>
	);
}

function CardContent({ chatMessageAtom, chatIndex, isLast }: AskAIChatCardProps) {
	const chatMessage = useAtomValue(chatMessageAtom);
	if (isAskAIMetricDiscoveryChatMessage(chatMessage)) {
		return <AskAIMetricDiscoveryCardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} />;
	}

	if (isAskAIOntologyBuilderEntityChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderEntityCardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isLast={isLast} />
		);
	}

	if (isAskAIOntologyBuilderDimensionChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderDimensionCardContent
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
				isLast={isLast}
			/>
		);
	}

	if (isAskAIOntologyBuilderRelationshipChatMessage(chatMessage)) {
		return (
			<AskAIOntologyBuilderRelationshipCardContent
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
				isLast={isLast}
			/>
		);
	}
}

export function AskAIChatCard({ chatMessageAtom, chatIndex, cardMargin, isLast }: AskAIChatCardProps) {
	const { addChatMessage } = useAskAIChatMessages();
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { type, userPrompt, promptContext } = chatMessage;

	const onLike = useCallback(() => {
		setChatMessage(() => ({ ...chatMessage, vote: 'up' }));
	}, [chatMessage, setChatMessage]);

	const onDislike = useCallback(() => {
		setChatMessage(() => ({ ...chatMessage, vote: 'down' }));
	}, [chatMessage, setChatMessage]);

	const onRegenerate = useCallback(() => {
		addChatMessage({ userPrompt, type, promptContext });
	}, [addChatMessage, promptContext, type, userPrompt]);

	if (chatMessage.error) {
		return <AskAIErrorCard chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} />;
	}

	return (
		<AskAIChatCardLayout
			type={type}
			cardMargin={cardMargin}
			borderColor={'gray.300'}
			userPrompt={userPrompt ?? ''}
			footer={
				<CardFooter
					chatMessageAtom={chatMessageAtom}
					onLike={onLike}
					onDislike={onDislike}
					onRegenerate={onRegenerate}
				/>
			}
		>
			<Suspense fallback={<AskAILoadingResults />}>
				<CardContent chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} isLast={isLast} />
			</Suspense>
		</AskAIChatCardLayout>
	);
}
