import Box from '@components/Box';
import Flex from '@components/Flex';
import {
	DataConnectorsDynamicModal,
	DataConnectorsModal,
	DataConnectorsPermissionModal,
	DataConnectorsResponseModal,
} from '@components/TopNavigation/DataConnectorsModal';
import { ConnectorMockData, useMockData } from '@pages/ConnectorsPage/components/ConnectorsTable/useMockData';
import { HttpStatusCode } from 'axios';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import { GridTableBody } from 'src/common/components/GridTable';
import { ConnectorsEmptyState, Plus16, Search16 } from 'src/common/components/Icons';
import Input from 'src/common/components/Input';
import Tooltip from 'src/common/components/Tooltip';
import { DataConnectorInfo } from 'src/common/components/TopNavigation/DataConnectorsModal/types';
import Typography from 'src/common/components/Typography';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { useModal } from 'src/common/hooks/ui/useModal';
import { checkForMatch } from 'src/common/utils/format';
import { useListConnectorsQuery, useUserRequestSetupConnectorLazyQuery } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { connectorsTableMetadata } from '../../utils/constants';
import { serializeConnectorsTableColumnDef, serializeConnectorsTableRowDef } from '../../utils/utils';
import { SkeletonTableLayout } from '../SkeletonComponents/SkeletonTableLayout';
import { TestIDs } from 'src/common/types/test-ids';

const MAX_ROWS_NUMBER = 7;

export const ConnectorsTable = ({ onAddConnectorError }: { onAddConnectorError: VoidFunction }) => {
	const [selectedConnector, setSelectedConnector] = useState<DataConnectorInfo | null>(null);
	const tenantConfig = useTenantConfig();
	const [searchValue, setSearchValue] = useState<string | undefined>();
	const { reportEvent } = useReportEvent();

	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeConnector);
	const isDataConnectorsDynamicModalEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsDynamicModal.enable');
	const dataConnectorsPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');

	const {
		isOpen: isDataConnectorsModalOpen,
		onOpen: onDataConnectorsModalOpen,
		onClose: onDataConnectorsModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsResponseModalOpen,
		onOpen: onDataConnectorsResponseModalOpen,
		onClose: onDataConnectorsResponseModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsDynamicModalOpen,
		onOpen: onDataConnectorsDynamicModalOpen,
		onClose: onDataConnectorsDynamicModalClose,
	} = useModal();
	const {
		isOpen: isDataConnectorsPermissionModalOpen,
		onOpen: onDataConnectorsPermissionModalOpen,
		onClose: onDataConnectorsPermissionModalClose,
	} = useModal();

	const { isMockTenant, mockData } = useMockData();

	const { data: connectorsArray, loading: isLoadingConnectorsList } = useListConnectorsQuery({
		fetchPolicy: 'no-cache',
		skip: isMockTenant,
	});

	const connectors = connectorsArray?.listConnectors?.connectors;

	const connectorsList: ConnectorMockData[] = useMemo(() => {
		return (isMockTenant ? mockData : connectors) || [];
	}, [connectors, isMockTenant, mockData]);

	const [requestSetupConnector, { data: requestSetupConnectorResponse, loading: requestSetupConnectorLoading }] =
		useUserRequestSetupConnectorLazyQuery({ fetchPolicy: 'no-cache' });

	const dataConnectorsSubmit = () => {
		if (isDataConnectorsDynamicModalEnabled) {
			if (hasWritePermission || dataConnectorsPlaygroundEnabled) {
				onDataConnectorsDynamicModalOpen();
			} else {
				onDataConnectorsPermissionModalOpen();
			}
		} else {
			onDataConnectorsResponseModalOpen();
		}
	};

	const columnDefs = useMemo(
		() => serializeConnectorsTableColumnDef(connectorsTableMetadata, tenantConfig) || [],
		[tenantConfig]
	);

	const rowData = useMemo(() => {
		const checkAllProps = (obj: unknown): boolean => {
			if (typeof obj === 'object' && obj !== null) {
				return Object.values(obj).some((prop) => {
					if (typeof prop === 'string') return checkForMatch({ subject: prop, value: searchValue || '' });
					if (typeof prop === 'object' && prop !== null) return checkAllProps(prop);
					return false;
				});
			}
			return false;
		};

		return serializeConnectorsTableRowDef(connectorsList)?.filter((connector) => checkAllProps(connector));
	}, [connectorsList, searchValue]);

	const isActiveSearch = useMemo(() => searchValue !== undefined, [searchValue]);

	const onCloseSearchInput = () => !searchValue && onSearch?.(undefined);

	useKeyPress(['Escape'], onCloseSearchInput);

	const onSearch = useCallback(
		(val?: string) => {
			setSearchValue(val);
			reportEvent({
				event: 'connectors-object-search-type',
				metaData: { objectType: 'connectors', searchTerm: val },
			});
		},
		[reportEvent]
	);

	const AddConnectorButton = () => (
		<div data-intercom-area={'main'} data-intercom-type={'main'} data-intercom-target={`data-connectors-modal-button`}>
			<Button
				borderRadius={'8px'}
				variant="solid"
				onClick={() => {
					reportEvent({
						event: 'connectors-add-connector',
					});
					setSelectedConnector(null);
					onDataConnectorsModalOpen();
				}}
				leftIcon={<Plus16 />}
				size="small"
				colorScheme="blue"
				data-testid={TestIDs.ADD_NEW_DATA_SOURCE_BUTTON}
			>
				Add new data source
			</Button>
		</div>
	);

	const SearchBar = () => (
		<Flex gap={'16px'} alignItems={'center'} marginBottom={'20px'}>
			<Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
				{isActiveSearch ? (
					<Flex maxW={'280px'} width={'100%'} borderRadius={'8px'}>
						<Input
							borderRadius="4px"
							borderColor="gray.300"
							isErasable={!!searchValue}
							autoFocus
							size="xs"
							leftComponent={<Search16 />}
							placeholder="Search"
							value={searchValue}
							onEnter={onCloseSearchInput}
							onBlur={onCloseSearchInput}
							onChange={(val: string) => onSearch?.(val)}
						/>
					</Flex>
				) : (
					<Flex alignItems={'center'}>
						<Tooltip label="Search" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
							<Button isIconOnly variant="outline" onClick={() => setSearchValue('')} size="inline" colorScheme="black">
								<Search16 />
							</Button>
						</Tooltip>
						{rowData && (
							<Typography variant={'DesktopH7Medium'} color={'gray.1000'} textAlign="start">
								{rowData.length} Data {pluralize('sources', rowData.length)}
							</Typography>
						)}
					</Flex>
				)}
				{<AddConnectorButton />}
			</Flex>
		</Flex>
	);

	return (
		<Box h="100%">
			{isLoadingConnectorsList ? (
				<SkeletonTableLayout />
			) : connectorsList.length > 0 ? (
				<>
					<SearchBar />
					<Box paddingBottom={'32px'}>
						{columnDefs && rowData && (
							<GridTableBody
								data={rowData}
								maxRowsToShow={MAX_ROWS_NUMBER}
								headerHeight={49}
								rowHeight={80}
								columnDefs={columnDefs}
							/>
						)}
					</Box>
				</>
			) : (
				<Flex h="100%" pb="80px" alignItems={'center'} justifyContent={'center'}>
					<NoConnectors addConnectorButton={<AddConnectorButton />} />
				</Flex>
			)}
			<DataConnectorsModal
				selectedConnector={selectedConnector}
				setSelectedConnector={setSelectedConnector}
				isOpen={isDataConnectorsModalOpen}
				onClose={onDataConnectorsModalClose}
				requestSetupConnectorLazyQuery={requestSetupConnector}
				onNextButtonClick={dataConnectorsSubmit}
			/>
			<DataConnectorsResponseModal
				isOpen={isDataConnectorsResponseModalOpen}
				onClose={onDataConnectorsResponseModalClose}
				isSuccess={requestSetupConnectorResponse?.userRequestSetupConnector.status == HttpStatusCode.Ok.toString()}
				isLoading={requestSetupConnectorLoading}
			/>
			<DataConnectorsDynamicModal
				onAddConnectorError={onAddConnectorError}
				selectedConnector={selectedConnector}
				isOpen={isDataConnectorsDynamicModalOpen}
				onClose={() => {
					setSelectedConnector(null);
					onDataConnectorsDynamicModalClose();
				}}
			/>
			<DataConnectorsPermissionModal
				selectedConnector={selectedConnector}
				isOpen={isDataConnectorsPermissionModalOpen}
				onClose={onDataConnectorsPermissionModalClose}
			/>
		</Box>
	);
};

function NoConnectors({ addConnectorButton }: { addConnectorButton: JSX.Element }) {
	return (
		<Flex direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
			<ConnectorsEmptyState />
			<Flex alignItems={'center'} direction={'column'} pt="24px" pb="20px">
				<Typography pb="4px" variant={'DesktopH7Medium'} color={'gray.1000'} textAlign="center">
					Add your first data source.
				</Typography>
				<Typography variant={'Paragraph16R'} color={'gray.1000'} textAlign="center">
					Every source you add unlocks additional insights.
				</Typography>
				<Box pt="20px">{addConnectorButton}</Box>
			</Flex>
		</Flex>
	);
}
