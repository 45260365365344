import { formatInTimeZone } from 'date-fns-tz';

const dateRegexp = new RegExp('(\\d{4}-\\d{2}-\\d{2}T00:00:00\\.000Z)');

export const isDate = (value: any) => {
	const isValidDate = value?.toString()?.match(dateRegexp);
	return isValidDate;
};

export const formatDate = (value: any) => {
	const dateParsed = new Date(value);
	return formatInTimeZone(dateParsed, 'UTC', 'MMM dd, yyyy');
};

export const formatDateTime = (value: any) => {
	const dateParsed = new Date(value);
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return formatInTimeZone(dateParsed, timeZone, 'MMM dd, yyyy HH:mm a');
};
