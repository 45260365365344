import Box from '@components/Box';
import Flex from '@components/Flex';
import { formatDistance } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import CopyLinkButton from 'src/common/components/CopyLinkButton';
import Divider from 'src/common/components/Divider';
import { DotsSolid16, Link16, Ok8 } from 'src/common/components/Icons';

import Button from 'src/common/components/Button';
import { Can } from 'src/common/components/Can/Can';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import TitleWithBreadcrumbs from 'src/common/components/TitleWithBreadcrumbs';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { TestIDs } from 'src/common/types/test-ids';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { MetricMeta } from 'src/lib/metricRules/builder/useCachedBuilderProperties';
import {
	UNTITLED_METRIC_DISPLAY,
	UNTITLED_METRIC_NAME,
	isUntitledMetric,
} from 'src/lib/metricRules/builder/useMetricBuilder';
import { MoreButton } from 'src/pages/MetricPage/components/Header/MoreButton';
import { ShareSignalModal } from 'src/pages/MetricPage/components/ShareSignalModal/ShareSignalModal';
import { useReportEvent } from 'src/services/analytics';
import { useEntitlementsCheck } from 'src/services/entitlements';
import { usePermissionCheck } from 'src/stores/environment';
import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricFlavor } from '../../hooks/useMetricFlavor';
import { formatBuilderFeatureEditMode } from '../../utils/eventMetadata';
import { EditorFeature } from '../CalculatePanel/EditPanel';
import { HeaderSkeleton } from '../SkeletonComponents';
import { ApplyOverridesButton } from './ApplyOverridesButton';
import { CloseButton } from './CloseButton';
import { FlavorSelector } from './FlavorSelector';
import classes from './Header.module.scss';
import { MetricButton } from './MetricButton';
import { MetricExplanation } from './MetricExplanation';
import { ShareSignalButton } from './ShareSignalButton';

type Props = {
	isEditMode: boolean;
	isTiny?: boolean;
	onSaveClick: VoidFunction;
	isSaveAllowed: boolean;
	loaderRunning?: boolean;
	selectedFeature?: EditorFeature;
	onPreview?: VoidFunction;
	metricMeta?: MetricMeta;
	isSaveAsNewEnabled?: boolean;
	onSaveAsNewModalOpen: VoidFunction;
	isUpsertMetricLoading?: boolean;
};

type HeaderActionsProps = {
	isEditMode: boolean;
	isTiny?: boolean;
	isEditMetricEnabled?: boolean;
	onSaveClick: VoidFunction;
	isSaveAllowed: boolean;
	isSaveAsNewEnabled: boolean;
	onSaveAsNewModalOpen: VoidFunction;
	isUpsertMetricLoading?: boolean;
	selectedFeature?: EditorFeature;
};

type HeaderTitleProps = {
	isTiny?: boolean;
	isEditMetricEnabled?: boolean;
	selectedFeature?: EditorFeature;
	onPreview?: VoidFunction;
	metricMeta?: MetricMeta;
};

export function Header({
	isEditMode,
	isTiny,
	onSaveClick,
	isSaveAllowed,
	selectedFeature,
	onPreview,
	metricMeta,
	isSaveAsNewEnabled = true,
	onSaveAsNewModalOpen,
	isUpsertMetricLoading,
}: Props) {
	const isEditMetricEnabled = useFeatureFlag('pulse.sightfull2.enable');
	const { isLoading } = useMetricDerivedState();

	if (isLoading) return <HeaderSkeleton />;

	return (
		<header>
			<Flex
				maxHeight="66px"
				flexDirection="row"
				justifyContent="space-between"
				className={classes.header}
				alignItems="center"
				data-testid={TestIDs.METRIC_PAGE_HEADER}
			>
				<Flex
					padding={'10px 32px'}
					height={isEditMetricEnabled ? '56px' : 'fit-content'}
					width={'100%'}
					justifyContent="space-between"
				>
					<HeaderTitle
						metricMeta={metricMeta}
						onPreview={onPreview}
						selectedFeature={selectedFeature}
						isEditMetricEnabled={isEditMetricEnabled}
						isTiny={isTiny}
					/>
					<HeaderActions
						selectedFeature={selectedFeature}
						onSaveAsNewModalOpen={onSaveAsNewModalOpen}
						isSaveAsNewEnabled={isSaveAsNewEnabled}
						isSaveAllowed={isSaveAllowed}
						isTiny={isTiny}
						isEditMetricEnabled={isEditMetricEnabled}
						isEditMode={isEditMode}
						onSaveClick={onSaveClick}
						isUpsertMetricLoading={isUpsertMetricLoading}
					/>
				</Flex>
			</Flex>
		</header>
	);
}

export function HeaderTitle({ isTiny, isEditMetricEnabled, selectedFeature, onPreview, metricMeta }: HeaderTitleProps) {
	const [flavor, setFlavor] = useMetricFlavor();

	const { metricNameWithFlavor, metricExplanationOneliner, metricExplanationArticleId, metricDisplayName } =
		useMetricDerivedState();
	const { reportEvent } = useReportEvent({
		feature: 'Metric Builder',
		metricName: metricNameWithFlavor,
		...(selectedFeature && { editMode: formatBuilderFeatureEditMode(selectedFeature) }),
	});
	const { metricBuilderState: builderState, isCalculatingPreview } = useBuilderDerivedState();
	const [newMetricValue, setNewMetricValue] = useState({
		title:
			(isUntitledMetric({ meta: metricMeta })
				? builderState?.meta?.display_name || metricDisplayName || metricNameWithFlavor
				: metricMeta?.display_name) || '',
		description: metricMeta?.description || metricExplanationOneliner,
	});

	const hasMetaChanges = useMemo(() => {
		const description = builderState?.meta?.description?.trim() || '';
		return (
			builderState?.meta?.display_name?.trim() !== metricDisplayName.trim() ||
			description !== metricExplanationOneliner?.trim()
		);
	}, [builderState?.meta?.description, builderState?.meta?.display_name, metricDisplayName, metricExplanationOneliner]);

	const shouldSetMetricValue =
		builderState?.name && builderState?.name !== UNTITLED_METRIC_NAME && hasMetaChanges && !isCalculatingPreview;

	useEffect(() => {
		if (shouldSetMetricValue) {
			setNewMetricValue({
				title: capitalizedFirstLetter(builderState?.meta?.display_name || UNTITLED_METRIC_DISPLAY),
				description: builderState?.meta?.description || '',
			});
		}
	}, [
		builderState?.meta,
		builderState?.name,
		onPreview,
		selectedFeature,
		metricDisplayName,
		metricExplanationOneliner,
		hasMetaChanges,
		isCalculatingPreview,
		shouldSetMetricValue,
	]);

	return (
		<Flex maxWidth={'45%'} flexDirection="row" gap="12px" alignItems="center">
			{isEditMetricEnabled ? (
				<TitleWithBreadcrumbs
					onSubmit={({ component, title, description, previousTitle, previousDescription }) => {
						const componentName = component === 'title' ? 'name' : 'description';
						reportEvent({
							event: `metric-${componentName}-edited`,
							metaData: {
								feature: 'Metric Builder',
								previousName: previousTitle,
								currentName: title,
								previousDescription: previousDescription,
								currentDescription: description,
							},
						});
					}}
					isEditMetric
					pageType="metric"
					isTiny={isTiny}
					title={newMetricValue.title}
					description={newMetricValue.description}
					isWithDescription
					isEditBlocked={true}
					setNewMetricValue={setNewMetricValue}
				/>
			) : (
				<>
					<MetricExplanation
						metricName={metricNameWithFlavor}
						metricTitle={metricDisplayName}
						oneliner={metricExplanationOneliner}
						articleId={metricExplanationArticleId}
					/>
					{flavor && (
						<FlavorSelector
							options={flavor.optionalValues.map((e) => ({ value: e }))}
							selectedOption={{ value: flavor.selectedValue }}
							onChange={(value) => setFlavor(value.value)}
						/>
					)}
					<AccessController
						noAccessChild={
							<Tooltip
								backgroundColor={'black'}
								label={'You have view only access'}
								placement="bottom"
								size="md"
								hasArrow
							>
								<Flex
									marginLeft={'4px'}
									alignItems={'center'}
									borderRadius={'2px'}
									padding={'2px 8px'}
									backgroundColor={colors.gray[200]}
								>
									<Typography color={colors.gray[700]} variant="Paragraph12M">
										View only
									</Typography>
								</Flex>
							</Tooltip>
						}
						permission={Permissions.crudMySignals}
					>
						<></>
					</AccessController>
				</>
			)}
		</Flex>
	);
}

function HeaderActions({
	isEditMode,
	isTiny,
	isEditMetricEnabled,
	onSaveClick,
	isSaveAllowed = false,
	isSaveAsNewEnabled,
	onSaveAsNewModalOpen,
	isUpsertMetricLoading,
	selectedFeature,
}: HeaderActionsProps) {
	const { metricNameWithFlavor, metricDisplayName } = useMetricDerivedState();
	const { reportEvent } = useReportEvent({ feature: 'Metric header', metricName: metricNameWithFlavor });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { metricBuilderState: builderState } = useBuilderDerivedState();
	const lastEtlSync = useTenantConfigStore(tenantConfigSelectors.getLastEtlSync);
	const memoCurrentDate = useMemo(() => new Date(), []);
	const shouldShowPublish = !isEditMode;
	const shouldShowSave = isEditMode;
	const lastSync = lastEtlSync && `Last sync: ${formatDistance(memoCurrentDate, lastEtlSync)} ago`;
	const isNameUntitled = isUntitledMetric(builderState);
	const hasDeletePermissions = usePermissionCheck().isHavingPermission(Permissions.deleteMetric);
	const hasDeleteEntitlement = useEntitlementsCheck({
		action: 'delete',
		resourceType: 'metric',
		resourceId: metricNameWithFlavor,
	});

	const copyLink = () => {
		reportEvent({
			event: 'copy-url-clicked',
			metaData: {},
		});
	};

	return (
		<>
			<Flex whiteSpace={'nowrap'} flexDirection="row-reverse" alignItems="center">
				{shouldShowPublish && (
					<AccessController permission={Permissions.crudMySignals}>
						<PublishButton
							isModalOpen={isModalOpen}
							setIsModalOpen={setIsModalOpen}
							metricDisplayName={metricDisplayName}
						/>
					</AccessController>
				)}
				<AccessController permission={Permissions.writeMetric}>
					<Can action="edit" resourceType="metric" resourceId={metricNameWithFlavor}>
						{shouldShowSave && (
							<>
								<Tooltip
									placement="left"
									size={'md'}
									label={
										isNameUntitled ? 'Name cannot be untitled' : !isSaveAllowed && 'Preview your metric before saving.'
									}
								>
									<MetricButton
										size={'inline'}
										isTextOnly={isSaveAllowed}
										leftIcon={<Ok8 />}
										isDisabled={!isSaveAllowed}
										eventName="metric-save-clicked"
										eventMetadata={{
											feature: 'Metric Builder',
											metricName: metricNameWithFlavor,
											...(selectedFeature && { editMode: formatBuilderFeatureEditMode(selectedFeature) }),
										}}
										onClick={onSaveClick}
									>
										{!isSaveAllowed ? 'Saved' : 'Save metric'}
									</MetricButton>
								</Tooltip>

								<Button
									marginLeft="12px"
									isDisabled={!isSaveAsNewEnabled || isUpsertMetricLoading}
									colorScheme="gray"
									variant="ghost"
									size="inline"
									onClick={onSaveAsNewModalOpen}
									color={'gray.1000'}
								>
									Save as new
								</Button>
							</>
						)}
					</Can>
				</AccessController>
				<AccessController permission={Permissions.crudMySignals} noAccessChild={<CloseButton variant="outline" />}>
					<CloseButton variant="ghost" />
				</AccessController>
				<Box height="28px">
					<Divider direction="vertical" color={colors.gray[400]} mx="12px" />
				</Box>
				{isEditMetricEnabled && hasDeletePermissions && hasDeleteEntitlement && <MoreButton />}{' '}
				{/* After entitlements rolled out remove this duplication with Frontegg checks*/}
				{!isEditMode && (
					<>
						{!isEditMetricEnabled && (
							<Popover
								popoverContentProps={{
									boxShadow: 'panelShadow',
									border: '1px solid',
									borderColor: 'gray.300',
								}}
								placement="bottom-start"
								triggerElement={(isOpen) => (
									<Box marginLeft={'4px'}>
										<Button isActive={isOpen} size="inline" isIconOnly variant="outline" colorScheme="black">
											<DotsSolid16 />
										</Button>
									</Box>
								)}
							>
								<Box width={'224px'}>
									{isTiny && <CopyLinkTiny copyLink={copyLink} />}
									<LastSyncIndicator lastSync={lastSync} />
								</Box>
							</Popover>
						)}

						{(!isTiny || isEditMetricEnabled) && (
							<Flex gap={'4px'}>
								<CopyLinkButton onClick={copyLink} dataIntercomArea={'metric'} />
							</Flex>
						)}
						{!isEditMetricEnabled && (
							<AccessController permission={Permissions.crudMySignals}>
								<Box gap="4px" display="flex" paddingRight="12px">
									<ApplyOverridesButton />
								</Box>
							</AccessController>
						)}
					</>
				)}
			</Flex>
		</>
	);
}

function PublishButton({
	isModalOpen,
	setIsModalOpen,
	metricDisplayName,
}: {
	isModalOpen: boolean;
	setIsModalOpen: (newState: boolean) => void;
	metricDisplayName: string;
}) {
	return (
		<Box marginRight="12px" paddingLeft="12px">
			<ShareSignalModal
				isModalOpen={isModalOpen}
				onModalClose={() => setIsModalOpen(false)}
				metricTitle={metricDisplayName}
				headerName="Metric"
			>
				<ShareSignalButton onClick={() => setIsModalOpen(true)} />
			</ShareSignalModal>
		</Box>
	);
}

function CopyLinkTiny({ copyLink }: { copyLink: () => void }) {
	return (
		<>
			<Box paddingY="8px" data-intercom-area={'metric'} data-intercom-type={'button'} data-intercom-target={'Link'}>
				<ListItem color="gray.1000" size="sm" onClick={copyLink} prefixIcon={<Link16 />} label="Copy URL" />
			</Box>
			<Divider direction="horizontal" color={'gray.300'} />
		</>
	);
}

function LastSyncIndicator({ lastSync }: { lastSync: string | undefined }) {
	return (
		<Box color={'gray.800'} paddingX="16px">
			{lastSync && (
				<Box color={'gray.700'} paddingY="16px">
					<Typography display={'block'} variant={'DesktopH10Regular'}>
						{lastSync}
					</Typography>
				</Box>
			)}
		</Box>
	);
}
