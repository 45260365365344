import { ConnectorStatus } from 'src/generated/graphql';
import { TransformedObject, UpdatedFieldsType } from './types';

export const testConnectorsValue = (name: string) => /^[a-z_][a-z0-9_]*$/.test(name);

export const isConnectorStatus = (value: string): value is ConnectorStatus => {
	const isConnectorStatus = Object.values(ConnectorStatus).some((status: string) => status === value);
	return isConnectorStatus;
};

export const parseArrayIntoObject = (array: UpdatedFieldsType[]): TransformedObject => {
	return array.reduce((acc: Record<string, string>, { id, value }) => {
		acc[id] = value;
		return acc;
	}, {});
};

export const setTimeValueToLocalStorage = (serviceId: string, key: string) => {
	const data = {
		timestamp: new Date().toISOString(),
		serviceId,
	};
	localStorage.setItem(key, JSON.stringify(data));
};

export const isHoursPassed = (timestamp: string, hoursPassed: number): boolean => {
	const currentTime = new Date().getTime();
	const timeToCalculateFrom = new Date(timestamp).getTime();
	const timeDifferenceInHours = (currentTime - timeToCalculateFrom) / (1000 * 60 * 60);
	return Math.floor(timeDifferenceInHours) <= hoursPassed;
};

export const LOCAL_STORAGE_MOCK_DESTINATION_NAME_KEY_FOR_TESTS = '_testCreatedMockDestination';
export function setMockDestinationNameToLocalStorage(mockDestinationName?: string) {
	if (mockDestinationName != undefined) {
		localStorage.setItem(LOCAL_STORAGE_MOCK_DESTINATION_NAME_KEY_FOR_TESTS, mockDestinationName);
	}
}
