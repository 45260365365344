import { XAxis } from 'src/lib/completions/semanticTypes/metrics.schema';
import { MetricType } from 'src/common/types/common';

export type MetaProperty = {
	display_name?: string;
	description?: string;
	unit?: string;
};

export type MetricMetaProperty = MetaProperty & {
	isHidden?: boolean;
};

export type MetricDefinitionStateShared = {
	name: string;
	meta?: MetricMetaProperty;
	entity: string;
};

export type MetricDefinitionStateFormula = {
	type: 'formula';
	formula: string;
};

export const AGGREGATE_METRIC_OPERATIONS = ['sum', 'count', 'avg', 'min', 'max'] as const;
export const JOIN_TYPES = ['inner_join', 'left_join', 'right_join', 'full_join'] as const;
export type JoinType = typeof JOIN_TYPES[number];
export type Join = {
	from: string;
	select: string;
	alias: string;
	filters?: Filter[];
	join_type?: JoinType;
	meta?: MetaProperty;
};

export type MetricDefinitionStateAggregate = {
	type: 'aggregate';
	operation: typeof AGGREGATE_METRIC_OPERATIONS[number];
	filters?: Filter[];
	measure?: string;
	x_axis?: XAxis;
	joins?: Join[];
};

export type Filter = {
	sql: string;
};

export type MetricDefinitionState = MetricDefinitionStateShared &
	(MetricDefinitionStateFormula | MetricDefinitionStateAggregate);

export type MetricEditorLoadingState = {
	isLoading: true;
};

export type MetricEditorLoadedState = {
	isLoading: false;
	userDefinedValue?: string; // Editor value
	previewValue: string; // Preview value
	savedValue: string;
	kind: MetricType;
};

export type MetricEditorState = MetricEditorLoadingState | MetricEditorLoadedState;
