import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { removeDollarSigns } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useFiltersV2 } from 'src/pages/MetricPage/components/InvestigatePanel/useFiltersV2';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import { defaultOperatorByFilterType, dimensionTypeToFilterType } from './constants';
import { useGetEntityToFetchLazy } from './useGetEntityToFetch';
import { getSubstringAfterDot } from './utils';

export function useAddMetricFilter2() {
	const [, { addFilters }] = useFiltersV2();
	const { semanticDefinitions } = useSemanticDefinitions();
	const {
		objectsTypes: [baseEntity],
	} = useMetricDerivedState();
	const getEntityToFetch = useGetEntityToFetchLazy();

	const addFilter = async (filter: Omit<FilterV2, 'type' | 'operator'>) => {
		const entityToFetch = await getEntityToFetch({ entity: baseEntity, filterKey: filter.key });
		const dimensionToFetch = removeDollarSigns(getSubstringAfterDot(filter?.key || '') || filter?.key || '');

		const type =
			semanticDefinitions?.entities
				.find((entity) => entity.name === entityToFetch)
				?.dimensions.find((dimension) => dimension.name === dimensionToFetch)?.type ?? 'string';

		const fullFilter = {
			...filter,
			type: dimensionTypeToFilterType[type],
			operator: defaultOperatorByFilterType[dimensionTypeToFilterType[type]],
		};

		addFilters([fullFilter]);
	};

	return addFilter;
}
