import { useCallback, useMemo } from 'react';
import { useReportEvent } from 'src/services/analytics';
import { Abc16, Boolean16, HashMark16, SlidersVertical16 } from '@components/Icons';
import { DrilldownCard } from '../DrillDownCard';
import { ParameterType } from 'src/generated/graphql';
import { Parameter } from 'src/types/parameter';
import { ParameterEditValueContent } from './ParameterEditValueContent';
import { useMetricPageParameters } from '@pages/MetricPage/hooks/useMetricPageParameters';

function ParameterIcon({ parameter }: { parameter: Parameter }) {
	switch (parameter.definition.type) {
		case ParameterType.Bool:
			return <Boolean16 />;

		case ParameterType.Number:
			return <HashMark16 />;

		case ParameterType.String:
			return <Abc16 />;

		default:
			return <SlidersVertical16 />;
	}
}

export function ParameterDrilldownCard({ parameter }: { parameter: Parameter }) {
	const { reportEvent } = useReportEvent();
	const { setParameterInSearchParams, clearParameterFromSearchParams, isParameterInSearchParams } =
		useMetricPageParameters();

	const parameterDef = parameter.definition;

	const title = useMemo(() => parameterDef.label ?? parameterDef.name, [parameterDef.label, parameterDef.name]);
	const value = useMemo(() => parameter.value ?? parameterDef.default, [parameter.value, parameterDef.default]);
	const isParameterSet = isParameterInSearchParams(parameterDef.name);

	const onRemove = useCallback(() => {
		clearParameterFromSearchParams(parameterDef.name);
		reportEvent({
			event: 'edit-metric-parameters-value-changed',
			metaData: { parameter: parameterDef.name, value: null },
		});
	}, [clearParameterFromSearchParams, parameterDef.name, reportEvent]);

	const setSelectedValue = useCallback(
		(selectedValue: string) => {
			if (selectedValue) {
				setParameterInSearchParams(parameterDef.name, selectedValue);
				reportEvent({
					event: 'edit-metric-parameters-value-changed',
					metaData: { parameter: parameterDef.name, value: selectedValue },
				});
			}
		},
		[parameterDef.name, reportEvent, setParameterInSearchParams]
	);

	return (
		<DrilldownCard
			title={title}
			valueEditor={
				<ParameterEditValueContent parameter={parameter} setSelectedValue={setSelectedValue} value={value} />
			}
			icon={<ParameterIcon parameter={parameter} />}
			removeTooltip={`Revert to default`}
			onRemove={isParameterSet ? onRemove : undefined}
		/>
	);
}
