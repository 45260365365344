import { useCallback, useEffect, useState } from 'react';
import { RelationshipsAndDimensionsQuery, useRelationshipsAndDimensionsLazyQuery } from 'src/generated/graphql';
import { FiltersAndBreakdownItemType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { findEntityToFetch } from './utils';

function responseToRelationshipItems(response?: RelationshipsAndDimensionsQuery): FiltersAndBreakdownItemType[] {
	return (
		response?.relationshipsAndDimensions.entities.reduce<FiltersAndBreakdownItemType[]>((acc, { relationships }) => {
			return [
				...acc,
				...relationships.map((relationship): FiltersAndBreakdownItemType => {
					return {
						type: 'relationship',
						key: `${relationship.identifier}>${relationship.entity}`,
						label: relationship.is_normalized ? relationship.name : `${relationship.name} (Source)`,
						isSelectable: false,
						isSelected: false,
					};
				}),
			];
		}, []) ?? []
	);
}

export function useGetEntityToFetchLazy() {
	const [fetchRelationships] = useRelationshipsAndDimensionsLazyQuery({
		fetchPolicy: 'cache-first',
	});

	const getEntityToFetchRecursive = useCallback(
		async (entity: string, remainingKeyParts: string[]) => {
			const relationshipsResponse = await fetchRelationships({
				variables: {
					entities: [entity],
				},
			});

			const nextEntityToFetch = findEntityToFetch({
				entity,
				filterKey: remainingKeyParts.join('.'),
				relationships: responseToRelationshipItems(relationshipsResponse.data),
			});
			if (remainingKeyParts.length > 2) {
				return getEntityToFetchRecursive(nextEntityToFetch, remainingKeyParts.slice(1));
			}
			return nextEntityToFetch;
		},
		[fetchRelationships]
	);

	return useCallback(
		({ entity, filterKey }: { entity: string; filterKey: string }) =>
			getEntityToFetchRecursive(entity, filterKey.split('.')),
		[getEntityToFetchRecursive]
	);
}

export function useGetEntityToFetch({ entity, filterKey }: { entity: string; filterKey: string }) {
	const getEntityToFetchRecursive = useGetEntityToFetchLazy();
	const [entityToFetch, setEntityToFetch] = useState<string | undefined>(undefined);

	useEffect(() => {
		setEntityToFetch(undefined);
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getEntityToFetchRecursive({ entity, filterKey }).then(setEntityToFetch);
	}, [entity, filterKey, getEntityToFetchRecursive]);

	return entityToFetch;
}
