import { Box, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import Modal from 'src/common/components/Modal';
import ModalHeader from 'src/common/components/ModalHeader';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { FillDetailsModal, ModalDetails } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsModal';
import colors from 'src/style/colors';
import { CollectionType, Signal } from 'src/types/signal';

export function EditSignalModal({
	signal,
	metricDisplayName,
	collectionType,
	isModalOpen,
	onModalClose = () => void 0,
}: {
	signal: Signal;
	metricDisplayName: string;
	collectionType: CollectionType;
	isModalOpen: boolean;
	onModalClose: () => void;
}) {
	const { isOpen, onClose: onHookClose } = useDisclosure();
	const [shareSignal, { mutationError, isLoading }] = useShareSignalApi(signal);
	const showToast = useToast();

	useEffect(() => {
		if (mutationError) showToast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, showToast]);

	const onClose = () => {
		onHookClose();
		onModalClose?.();
	};
	function onSubmit({ description, title }: ModalDetails) {
		shareSignal({
			attachmentContentURL: signal.attachment ?? '',
			message: description,
			collectionToPin: [],
			collectionToUnPin: [],
			title: title,
			signalId: signal.id,
		}).then(onClose);
	}

	return (
		<Box>
			<Modal isOpen={isOpen || isModalOpen} onClose={onClose} hasOverlay isCentered maxWidth={'420px'}>
				<ModalHeader size={'md'} title="Rename signal" />
				<Box paddingX="16px" _selection={{ background: colors.indigo[100] }}>
					<FillDetailsModal
						metricTitle={signal.title || metricDisplayName}
						description={signal.message}
						onSubmit={onSubmit}
						collectionType={collectionType}
						isLoading={isLoading}
						onClose={onClose}
						isShowingPublishAs={false}
						submitButtonText="Save"
						isShowingDialogTitle={false}
					/>
				</Box>
			</Modal>
		</Box>
	);
}
