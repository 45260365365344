import colors from 'src/style/colors';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { showIntercom } from '@services/intercom';

export function DataConnectorsNoResults() {
	return (
		<Flex marginTop={'136px'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
			<Flex flexDirection={'column'}>
				<Typography marginBottom={'4px'} textAlign={'center'} variant={'DesktopH8Medium'} color={colors.gray['1000']}>
					No search results
				</Typography>
				<Typography textAlign={'center'} maxWidth={'326px'} variant={'Paragraph14R'} color={colors.gray['1000']}>
					Can’t find the connector you need?&nbsp;
					<Typography onClick={showIntercom} cursor={'pointer'} variant={'Paragraph14R'} textDecoration={'underline'}>
						Let us know.
					</Typography>
				</Typography>
			</Flex>
		</Flex>
	);
}
