import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useMetricSearchParamsBuilder } from 'src/pages/MetricPage/hooks/useMetricSearchParamsBuilder';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { AskAIChatCardProps, DiscoverMetricsSuggested } from '../types';
import { useReportAIEvent } from './useReportAIEvent';
import useNavigation from 'src/services/useNavigation';

export function useOpenInNewTab({
	metric,
	chatMessageAtom,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'> & {
	metric: DiscoverMetricsSuggested;
}) {
	const { reportAskAIEvent: reportDiscoveryEvent } = useReportAIEvent();
	const chatMessage = useAtomValue(chatMessageAtom);

	const [, { metricName, searchParams }] = useMetricSearchParamsBuilder(metric);
	const { navigate } = useNavigation();

	const onOpenInNewTab = useCallback(() => {
		reportDiscoveryEvent({
			event: 'ask-ai-discovery-result-click',
			metaData: {
				...metric,
				queryText: chatMessage.userPrompt,
				searchParams: searchParams.toString(),
			},
		});
		navigate({ path: `/${MetricPagePath}/${metricName}`, additionalSearchParams: searchParams, isNewTab: true });
	}, [chatMessage.userPrompt, metric, metricName, navigate, reportDiscoveryEvent, searchParams]);

	return { onOpenInNewTab, searchParams };
}
