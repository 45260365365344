import Flex from '@components/Flex';
import { TestIDs } from 'src/common/types/test-ids';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import Tooltip from '../Tooltip';
import Typography from '../Typography';

export const ViewOnlyLabel = ({ pageType }: { pageType: string }) => {
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	return pageType === 'metric' && hasEditPermission ? null : (
		<Tooltip
			backgroundColor={'black'}
			label={
				pageType === 'metric' ? (
					'You have view only access'
				) : (
					<Typography maxWidth={'250px'} variant="DesktopH8Regular">
						You have view only access. To save signal changes,&nbsp;
						<Typography variant="DesktopH8Bold">save as new.</Typography>
					</Typography>
				)
			}
			placement="bottom"
			size="md"
			hasArrow
		>
			<Flex
				data-testid={TestIDs.VIEW_ONLY_BADGE}
				marginLeft="8px"
				alignItems={'center'}
				borderRadius={'2px'}
				padding={'2px 8px'}
				backgroundColor={colors.gray[200]}
				width="max-content"
			>
				<Typography color={colors.gray[700]} variant="Paragraph12M">
					View only
				</Typography>
			</Flex>
		</Tooltip>
	);
};
