import Box from '@components/Box';
import Typography from '@components/Typography';
import { BreakdownOutline16 } from '@components/Icons';
import { BreakdownDrilldownCard } from '@components/LeftExpandCollapsePanel/Drilldown';
import { DisabledBreakdownCard } from '@components/LeftExpandCollapsePanel/Drilldown/DisabledBreakdownCard';
import { AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useReportEvent } from '@services/analytics';
import useDimensionsState from '../InvestigatePanel/useDimensionsState';
import { OnModalOpenType, OnModalCloseType } from './types';
import { Title } from '../PanelTitle/Title';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';
import { useCallback } from 'react';

export function BreakdownsPanel({
	isShowingFullSize,
	isDisabled,
	onModalOpen,
	onModalClose,
}: {
	isShowingFullSize: boolean;
	isDisabled?: boolean;
	onModalOpen: OnModalOpenType;
	onModalClose: OnModalCloseType;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [{ breakdowns }, { removeAllParamsByType, removeParamByKey }] = useDimensionsState();
	const { wrapWithReport } = useReportEvent();
	const shouldAllowAnotherBreakdown = isSightfull2 || breakdowns.values.length < 1;

	const onAddBreakdownModalOpen = useCallback(
		() => onModalOpen(CONSTS.BREAKDOWN_MODEL_TYPE, onModalClose),
		[onModalClose, onModalOpen]
	);

	return (
		<>
			<Box>
				<Box
					whiteSpace="nowrap"
					maxHeight={isShowingFullSize ? 'min-content' : '0'}
					opacity={isShowingFullSize ? '1' : '0'}
				>
					<Title
						onAction={() => removeAllParamsByType('groupBy')}
						text={'Breakdown'}
						showAction={!!breakdowns?.values?.length}
					/>
					{!isDisabled &&
						breakdowns.values.map((breakdown) => (
							<BreakdownDrilldownCard
								key={breakdown.key}
								breakdown={breakdown}
								onRemove={wrapWithReport(
									() => removeParamByKey('groupBy', breakdown.key),
									'metric-breakdowns-delete-clicked'
								)}
							/>
						))}
					{shouldAllowAnotherBreakdown &&
						(isDisabled ? (
							<AddDrillDownCard
								isDisabled={isDisabled}
								type={CONSTS.BREAKDOWN_MODEL_TYPE}
								onClick={wrapWithReport(onAddBreakdownModalOpen, 'metric-breakdowns-clicked')}
								dataIntercomTarget={'Add breakdown'}
							/>
						) : breakdowns.isDisabled ? (
							<DisabledBreakdownCard />
						) : (
							<AddDrillDownCard
								type={CONSTS.BREAKDOWN_MODEL_TYPE}
								onClick={wrapWithReport(onAddBreakdownModalOpen, 'metric-breakdowns-clicked')}
								dataIntercomTarget={'Add breakdown'}
							/>
						))}
				</Box>
			</Box>
			{!isShowingFullSize && (
				<Box display="flex" width="32px" height="32px" position="relative" justifyContent="center" alignItems="center">
					<BreakdownOutline16 />
					{!!breakdowns.values.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{breakdowns.values.length}</Typography>
						</Box>
					)}
				</Box>
			)}
		</>
	);
}
