import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './scrollable.override.css';
import classes from './Scrollable.module.scss';
import { onScrollEvent } from '@components/Scrollable/types';
import { isEvent, isScrollEvent } from './typeguards';

export function Scrollable({
	children,
	onScroll,
	scrollableRef,
}: {
	children: React.ReactNode;
	onScroll?: (event: onScrollEvent) => void;
	scrollableRef?: React.RefObject<HTMLDivElement>;
}) {
	return (
		<SimpleBar
			className={classes.scrollable}
			style={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
				height: '100%',
				alignSelf: 'stretch',
			}}
			scrollableNodeProps={{
				ref: scrollableRef,
				onScroll: (event: unknown) => {
					if (onScroll == undefined) {
						return;
					}

					const scrollTop = isEvent(event) && isScrollEvent(event) ? event.target.scrollTop : undefined;

					onScroll({ originalEvent: event, scrollTop });
				},
			}}
		>
			{children}
		</SimpleBar>
	);
}
