import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import colors from 'src/style/colors';
import { ReactNode } from 'react';
import { ERROR_TITLE } from '../ErrorBanner/ErrorBanner';

type ErrorModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	errorMessage: string;
	submitButton?: ReactNode;
};

export function ErrorReadMoreModal({ isOpen, onClose, errorMessage, submitButton }: ErrorModalProps) {
	const modalHeader = (
		<ModalHeader borderBottom={'1px solid'} borderColor={'gray.300'} color="gray.1000" padding={'16px'}>
			<Flex maxWidth={'330px'} justifyContent={'space-between'} alignItems={'center'}>
				<Flex>
					<Button variant="outline" isIconOnly onClick={onClose} size="inline" colorScheme="black">
						<CloseLarge16 />
					</Button>
				</Flex>
				<Flex>
					<Typography variant={'DesktopH7Medium'} color={'gray.1000'}>
						{ERROR_TITLE}
					</Typography>
				</Flex>
			</Flex>
		</ModalHeader>
	);

	const modalBody = (
		<Box
			maxHeight={'400px'}
			overflowY={'scroll'}
			borderBottom={'1px solid'}
			borderColor={'gray.300'}
			width={'100%'}
			padding={'24px'}
		>
			<Typography color={colors.gray[1000]} variant={'Paragraph14R'}>
				{errorMessage}
			</Typography>
		</Box>
	);

	const modalFooter = (
		<Flex padding={'16px 24px'} justifyContent={'space-between'}>
			<Button
				onClick={onClose}
				size="small"
				variant="outline"
				colorScheme="gray"
				color={colors.gray[1000]}
				borderRadius={'8px'}
			>
				Close
			</Button>
			{submitButton}
		</Flex>
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} maxWidth="460px" isCentered>
			{modalHeader}
			{modalBody}
			{modalFooter}
		</Modal>
	);
}
