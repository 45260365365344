import Flex from '@components/Flex';
import { PeriodUnit } from '@sightfull/period-ranges';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Select from 'src/common/components/Select';
import { SelectOption } from 'src/common/components/Select/types';
import { useReportEvent } from 'src/services/analytics';
import { CustomPeriodPickerDialog } from './CustomPeriodPickerDialog/CustomPeriodPickerDialog';
import { PeriodCell } from './PeriodCell';
import classes from './PeriodPicker.module.scss';
import { PresetsPeriodPicker } from './PresetsPeriodPicker';
import {
	CustomPeriodPickerOption,
	PeriodPickerOption,
	PeriodPickerProps,
	defaultPeriodPickerOptions,
	defaultPresetByPeriod,
	periodsWithDefaultPresets,
} from './types';

export default function PeriodPicker({
	availableRangePresets,
	availablePeriodRanges,
	selectedPeriodRange,
	onSelectPeriodRange,
	size = 'md',
	hasDefaultOption = false,
	defaultPeriodPickerOption,
	isPeriodPickerOpen,
	onOpen,
	onClose,
}: PeriodPickerProps) {
	const { reportEvent, wrapWithReport } = useReportEvent();
	const [periodPickerOptions, setPeriodPickerOptions] = useState<PeriodPickerOption[]>(defaultPeriodPickerOptions);
	const [selectedPickerType, setSelectedPickerType] = useState<PeriodPickerOption>(periodPickerOptions[0]);

	const inferredPickerType: PeriodPickerOption =
		(!selectedPeriodRange
			? defaultPeriodPickerOption
			: periodPickerOptions.find((option) => {
					if (option.value == 'custom') return false;
					return Object.values(availableRangePresets[option.value] ?? {}).find(
						(p) =>
							p.startDate.getTime() == selectedPeriodRange.startDate.getTime() &&
							p.endDate.getTime() == selectedPeriodRange.endDate.getTime() &&
							p.periodUnit == selectedPeriodRange.periodUnit
					);
			  })) ?? CustomPeriodPickerOption;

	useEffect(() => {
		setSelectedPickerType(inferredPickerType);
	}, [inferredPickerType, periodPickerOptions]);

	useEffect(() => {
		setPeriodPickerOptions([
			...defaultPeriodPickerOptions.filter((option) => Object.keys(availableRangePresets).includes(option.value)),
			CustomPeriodPickerOption,
		]);
	}, [availableRangePresets]);

	if (!availableRangePresets) return <>Loading</>;

	function onSelectPeriodType(newSelection: SelectOption) {
		const availablePresets = availableRangePresets[newSelection.value as PeriodUnit] ?? {};

		reportEvent({
			event: 'metric-period-preset-type-change',
			metaData: { periodPreset: newSelection.label },
		});
		setSelectedPickerType(newSelection as PeriodPickerOption);
		onSelectPeriodRange(availablePresets[defaultPresetByPeriod[newSelection.value as periodsWithDefaultPresets]]);
	}

	const isCustomPickerTypeInferred = CustomPeriodPickerOption.value == inferredPickerType.value;
	const isCustomPicker = selectedPickerType.value == CustomPeriodPickerOption.value;

	const periodPickerChild = isCustomPicker ? (
		<CustomPeriodPickerDialog
			size={size}
			onCancel={() => {
				if (!isCustomPickerTypeInferred) setSelectedPickerType(inferredPickerType);
			}}
			availablePeriodRanges={availablePeriodRanges}
			onApplyPeriodRange={onSelectPeriodRange}
			periodRange={selectedPeriodRange}
			isCustomPickerTypeInfered={isCustomPickerTypeInferred}
			isEnding={!hasDefaultOption}
		/>
	) : (
		<PresetsPeriodPicker
			size={size}
			availablePresets={availableRangePresets[selectedPickerType.value as PeriodUnit] ?? {}}
			selectedPeriod={selectedPeriodRange}
			onSelectedPeriod={onSelectPeriodRange}
			isEnding={!hasDefaultOption}
		/>
	);

	const realPeriodPicker = (
		<>
			<Flex
				className={classNames(classes.periodCell, classes.first, classes[size], {
					[classes.selected]: isPeriodPickerOpen,
				})}
				alignItems="center"
			>
				<Select
					menuIsOpen={isPeriodPickerOpen}
					options={periodPickerOptions}
					selectedOption={selectedPickerType}
					size={size == 'sm' ? 'small' : 'medium'}
					isGhost={true}
					onChange={onSelectPeriodType}
					width="115px"
					onMenuOpen={onOpen}
					onMenuClose={onClose}
				/>
			</Flex>
			{periodPickerChild}
			{hasDefaultOption && (
				<PeriodCell
					isLast
					actualPeriodString={'Default Period Range'}
					periodName={'Default'}
					isSelected={!selectedPeriodRange}
					size={size}
					label={'Default'}
					onClick={wrapWithReport(() => onSelectPeriodRange(undefined), 'period-picker-default-clicked', {})}
				/>
			)}
		</>
	);

	return (
		<Flex
			bg="white"
			flexDirection="row"
			alignItems="stretch"
			data-intercom-area={'metric'}
			data-intercom-type={'main'}
			data-intercom-target={'period-picker'}
		>
			{realPeriodPicker}
		</Flex>
	);
}
