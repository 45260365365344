import { Box, useToast as UseChakraToast } from '@chakra-ui/react';
import Toast, { ToastProps } from '@components/Toast/Toast';
import { useCallback, useMemo } from 'react';
import useTrial from './useTrial';

export const TOAST_DURATION = 2000;
export function useToast() {
	const defaultOptions = useMemo(
		() => ({
			containerStyle: {
				minWidth: 'auto',
			},
		}),
		[]
	);

	const toast = UseChakraToast(defaultOptions);

	const { trialBarHeight } = useTrial();
	const padding = 60 + trialBarHeight;

	return useCallback(
		({ variant, message, duration = TOAST_DURATION, isCloseable = false, onClick, link }: ToastProps) => {
			return toast({
				position: 'top',
				duration: duration,
				isClosable: isCloseable,
				containerStyle: {
					pointerEvents: 'none',
					maxWidth: '800px',
				},
				render: () => (
					<Box pointerEvents={'auto'} position="relative" top={padding.toString() + 'px'}>
						<Toast variant={variant} message={message} link={link} onClick={onClick} />
					</Box>
				),
			});
		},
		[padding, toast]
	);
}
export default useToast;
