import Box from '@components/Box';
import { AskAIInput } from '../../AskAIInput';
import { AskAIModalItem } from '../../AskAIModalItem';
import { AskAIChatHeader } from '../AskAIChatHeader';
import { AskAIChatCardsList } from '../AskAIChatCardList';
import { useCallback } from 'react';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { DrawerFooter, DrawerHeader } from '../../../Drawer';

type AskAIMetricDiscoveryChatPageProps = {
	onBack: () => void;
	onClose: () => void;
};

export function AskAIMetricDiscoveryChatPage({ onBack, onClose }: AskAIMetricDiscoveryChatPageProps) {
	const { addChatMessage } = useAskAIChatMessages();

	const onSubmit = useCallback(
		(userPrompt: string) => {
			addChatMessage({ userPrompt, type: 'metric-discovery' });
		},
		[addChatMessage]
	);

	return (
		<>
			<DrawerHeader padding={0}>
				<AskAIModalItem borderBottom={true}>
					<AskAIChatHeader onBack={onBack} onClose={onClose} />
				</AskAIModalItem>
			</DrawerHeader>

			<AskAIChatCardsList />

			<DrawerFooter padding={0}>
				<AskAIModalItem backgroundColor={'gray.100'} borderTop={true}>
					<Box padding={'8px'} flex={1}>
						<AskAIInput
							placeholder={'Ask any business question'}
							hasBorder={true}
							onSubmit={onSubmit}
							borderRadius={'32px'}
							submitButtonBorderRadius={'26px'}
							clearInputOnSubmit={true}
							size={'sm'}
							reportEvent={'ask-ai-chat-query'}
							reportFeature={'Ask AI'}
						/>
					</Box>
				</AskAIModalItem>
			</DrawerFooter>
		</>
	);
}
