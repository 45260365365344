export function removeMacroPeriodIn(value: string) {
	return value.replace(PeriodInMacroRegEx, '$1');
}
export function isMacroPeriodIn(value: string) {
	return PeriodInMacroRegEx.test(value.trim());
}
export function addMacroPeriodIn(value: string) {
	return `${PeriodInMacro}(${value})`;
}
export function shouldAddMacroPeriodIn(value: string) {
	return !isMacroPeriodIn(value) && !value.match(/\s+/);
}
export function addMacroPeriodInIfNeeded(value: string) {
	return shouldAddMacroPeriodIn(value) ? `${PeriodInMacro}(${value})` : value;
}

const PeriodInMacroRegEx = /^\$macros.PERIOD_IN\(([^\s]+)\)$/;
const PeriodInMacro = '$macros.PERIOD_IN';
