import { api as fronteggAPI, IBaseGetUserResponse, IRole } from '@frontegg/rest-api';
import { useEffect, useState } from 'react';

export type FronteggUserWithRole = IBaseGetUserResponse & { roles: IRole[] };
const MAX_USERS_FRONTEGG_API_LIMIT = 200;

export enum SIGHTFULL_FRONTEGG_ROLES {
	Admin = 'Admin',
	SuperStaff = 'Super Staff',
	ReadOnly = 'Read Only',
	Owner = 'Owner',
	User = 'User',
	APIUser = 'API User',
	Staff = 'Staff',
	ITAdmin = 'IT Admin',
	Editor = 'Editor',
}

export function useFronteggUsersRoles() {
	const [usersWithRoles, setUsersWithRoles] = useState<FronteggUserWithRole[] | undefined>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const fetchUsersRoles = async () => {
			try {
				const roles = await fronteggAPI.roles.getRoles();

				const users = await fronteggAPI.users
					.getUsersV3({ _limit: MAX_USERS_FRONTEGG_API_LIMIT })
					.then((usersResponse) => usersResponse.items.map((user) => user));

				const userRoleList = await fronteggAPI.users.getUsersRoles({ ids: users.map((user) => user.id) });

				const usersWithRoles = users.map((user) => {
					const userRoles = userRoleList.filter((userRole) => userRole.userId === user.id);
					const roleData = userRoles.flatMap((userRoles) =>
						userRoles.roleIds.flatMap((userRole) => roles.find((role) => role.id == userRole)).filter((role) => !!role)
					);

					return { ...user, roles: roleData };
				});

				setUsersWithRoles(usersWithRoles);
			} catch (err) {
				setError(err instanceof Error ? err : new Error(String(err)));
			} finally {
				setLoading(false);
			}
		};

		fetchUsersRoles();
	}, []);

	return { usersWithRoles, loading, error };
}
