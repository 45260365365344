import {
	FILTER_EMPTY_STRING_OPTION,
	FILTER_NULL_OPTION,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/constants';
import {
	findEntityToFetch,
	getSubstringAfterDot,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { useCoreReaderDimensionValuesLazyApi } from '@hooks/fetching/useCoreReaderFiltersApi';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useSemanticDefinitionsForEntityDimension } from '@hooks/stores/useSemanticDefinitions';
import {
	convertModalResultToValidCoreValues,
	removeDollarSigns,
	useCoreNodeScheme,
} from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useNodeScheme } from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useNodeScheme';
import { FiltersAndBreakdownResponseType } from '@pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdownsLogic from '@pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdownLogic';
import useDimensionsState from '@pages/MetricPage/components/InvestigatePanel/useDimensionsState';
import { useMetricDerivedState } from '@pages/MetricPage/hooks/useMetricDerivedState';
import { Filter } from '@pages/MetricPage/utils/state.types';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

export const useMetricFilter = ({ filter, onClose }: { filter?: Filter; onClose?: VoidFunction }) => {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [, { addDimensionsParamsByType }] = useDimensionsState();
	const { isLoading: isRulesEngineLoading, metricNameWithFlavor, periodRange, objectsTypes } = useMetricDerivedState();

	const nodeScheme = useNodeScheme({
		metricName: metricNameWithFlavor,
		periods: periodRange.asAbsoluteRange.toMetricPeriods().map((e) => e.id),
		objectsTypes,
	});
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes,
		readyToFetch: !isRulesEngineLoading,
	});

	const onSaveFilters = (result: FiltersAndBreakdownResponseType) => {
		addDimensionsParamsByType(
			result.type as any,
			isSightfull2 ? convertModalResultToValidCoreValues(result, objectsTypes[0]) : result.items
		);
	};

	const [{ relationships, path }] = useFiltersAndBreakdownsLogic({
		type: 'filter',
		onClose: () => onClose?.(),
		onAddItems: onSaveFilters,
		nodeScheme: isSightfull2 ? coreNodeScheme : nodeScheme,
	});

	const [getDimensionValuesState, fetchDimensionValues] = useCoreReaderDimensionValuesLazyApi();
	const isLoadingDimensionValues = getDimensionValuesState.loading;
	const fetchedDimensionValues = getDimensionValuesState.data?.entityDimensionValues;

	const getDimensions = useCallback(
		async (entity: string, dimension: string) => {
			return await fetchDimensionValues(entity, dimension);
		},
		[fetchDimensionValues]
	);

	const entity = path[0]?.key;
	const entityToFetch = findEntityToFetch({ entity, filterKey: filter?.key, relationships });
	const dimensionToFetch = removeDollarSigns(getSubstringAfterDot(filter?.key || '') || filter?.key || '');
	const semanticDefinitionsForEntityDimension = useSemanticDefinitionsForEntityDimension(
		entityToFetch,
		dimensionToFetch
	);

	useEffect(() => {
		if (entity) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			getDimensions(entityToFetch, dimensionToFetch || '');
		}
	}, [dimensionToFetch, entity, entityToFetch, getDimensions]);

	const filterOptionKey = `${entity}.${filter?.key}`;
	const options = useMemo(() => {
		const isStringDimension = semanticDefinitionsForEntityDimension?.type === 'string';
		const additionalOptionsForStringDimensions = [FILTER_NULL_OPTION, FILTER_EMPTY_STRING_OPTION];

		return [
			...(isStringDimension ? additionalOptionsForStringDimensions : []),
			...(fetchedDimensionValues?.map((value) => ({
				title: String(value),
				value,
			})) || []),
		];
	}, [fetchedDimensionValues, semanticDefinitionsForEntityDimension?.type]);

	const updateFilter = (selectedValues: (string | null)[]) => {
		const matchedOptions = options.filter((option) => selectedValues.includes(option.value));

		const items = matchedOptions.map((option) => ({
			key: filterOptionKey,
			label: option.title,
			value: option.value,
		}));

		const unmatchedIds = _.difference(
			selectedValues,
			matchedOptions.map((option) => option.value)
		);

		const additionalItems = unmatchedIds.map((id) => ({
			key: filterOptionKey,
			label: String(id),
			value: id,
		}));

		const finalItems = [...items, ...additionalItems];

		onSaveFilters({
			type: 'filterBy',
			items: finalItems,
		});
	};

	return {
		semanticDefinitionsForEntityDimension,
		isLoadingDimensionValues,
		options,
		updateFilter,
		entityToFetch,
		dimensionToFetch,
	};
};
