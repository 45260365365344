import Box from '@components/Box';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Link from '@components/Link';
import Popover from '@components/Popover';
import Typography from '@components/Typography';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import useToast from '@hooks/ui/useToast';
import { Excel18, File16, GoogleSheets18 } from '@icons/index';
import { useMemo } from 'react';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import useTenantState from 'src/common/hooks/ui/useTenantState';
import { SIGHTFULL_DOCS_CLOUD_LINK } from 'src/common/utils/consts';
import { ExportListItem } from 'src/pages/MetricPage/components/Header/ExportTableModal/ExportListItem';
import {
	buildExcelPowerQueryFile,
	buildExportLiveUrlFromState,
	buildGoogleSheetsFormula,
} from 'src/pages/MetricPage/components/Header/ExportTableModal/buildExportLiveUrl';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { useEnvironment, usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { AdminOnlyLabel, ComingSoonLabel, PremiumLabel } from './Labels';
import { useCoreReaderDetailedExport } from './useCoreReaderExport';

export function ExportPopover({
	isOpen,
	onCsvExport,
	onClose,
}: {
	isOpen: boolean;
	onCsvExport: () => any;
	onClose: () => any;
}) {
	const { isHavingPermission } = usePermissionCheck();
	const isHavingCreateUserApiPermission = isHavingPermission(Permissions.createUserApiToken);
	const isExportLiveFeatureFlagOn = useFeatureFlag('pulse.react.exportLiveData.enable');
	const shouldUseLiveExport = isHavingCreateUserApiPermission && isExportLiveFeatureFlagOn;
	const popoverContentProps = { w: '340px', boxShadow: 'z-05', borderRadius: '12px', bottom: '8px', right: '155px' };
	const currentState = useMetricDerivedState();
	const { apiUrl } = useEnvironment();
	const metricPageSearchParams = useMetricPageSearchParams();
	const toast = useToast();
	const { onCopyText } = useOnCopy();
	const shouldUseNameRanges = useFeatureFlag('pulse.react.exportLiveData.nameRanges');
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { shouldLimitExports } = useTenantState();

	const { exportToGoogleSheet, exportToExcelPowerQueryFile } = useCoreReaderDetailedExport(false);

	const isRealTimeDataExportDisabled = useMemo(() => {
		const hasMetricDefinition = currentState.metricYamlEditorMetricDefinitionJson != null;

		return isSightfull2 && hasMetricDefinition;
	}, [currentState.metricYamlEditorMetricDefinitionJson, isSightfull2]);
	const limitCsvExports = shouldLimitExports && !isRealTimeDataExportDisabled;

	if (!metricPageSearchParams) return null;

	const baseApiRequestUrl = buildExportLiveUrlFromState(currentState, `${apiUrl}/v2/metric/calculate/`);

	const onCopySheetsFormula = async () => {
		if (isSightfull2) {
			try {
				const importFromApiSheetsText = await exportToGoogleSheet(shouldUseNameRanges);
				return onCopyText({ contentToCopy: importFromApiSheetsText });
			} catch {
				toast({ variant: 'error', message: 'Failed creating formula.' });
				return;
			}
		}

		if (!baseApiRequestUrl) {
			toast({ variant: 'error', message: 'Failed creating formula, current period is unsupported.' });
			return;
		}

		const importFromApiSheetsText = buildGoogleSheetsFormula({ shouldUseNameRanges, baseApiRequestUrl });
		onCopyText({ contentToCopy: importFromApiSheetsText });
	};
	const onDownloadExcelWebQuery = async () => {
		if (isSightfull2) {
			try {
				const importFromApiSheetsText = await exportToExcelPowerQueryFile();
				return onCopyText({ contentToCopy: importFromApiSheetsText });
			} catch {
				toast({ variant: 'error', message: 'Failed creating formula.' });
				return;
			}
		}

		if (!baseApiRequestUrl) {
			toast({ variant: 'error', message: 'Failed creating formula, current period is unsupported.' });
			return;
		}
		const fileContent = buildExcelPowerQueryFile(baseApiRequestUrl);

		onCopyText({ contentToCopy: fileContent });
		onClose();
	};

	const isItemFaded = isRealTimeDataExportDisabled || shouldLimitExports;
	const isItemDisabled = isItemFaded || !shouldUseLiveExport;

	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			placement="top"
			variant="strong"
			popoverContentProps={popoverContentProps}
			closeOnEsc={true}
		>
			<Box data-intercom-area={'metric'} data-intercom-type={'main'} data-intercom-target={'export-menu'}>
				<Flex p={'8px'} direction={'column'}>
					{onCsvExport && (
						<Flex direction={'column'} flexGrow={1} gap={'8px'}>
							<Flex p={'12px 16px 0px 16px'} alignItems={'center'} w={'100%'} gap={'12px'}>
								<Typography variant={'DesktopH7Medium'} color={'gray.1000'}>
									Export
								</Typography>

								{limitCsvExports && PremiumLabel}
							</Flex>

							<ExportListItem
								title={limitCsvExports ? 'CSV (Limited view)' : 'CSV'}
								subTitle={limitCsvExports ? 'Get full access: Upgrade your trial.' : 'Download CSV file.'}
								icon={<File16 />}
								onClick={() => {
									onCsvExport();
									onClose();
								}}
							/>
						</Flex>
					)}
					<Box padding={'8px 16px'}>
						<Divider direction={'horizontal'} color={'gray.300'} />
					</Box>
					<Flex direction={'column'} flexGrow={1} gap={'8px'}>
						<Flex p={'12px 16px 0px 16px'} alignItems={'center'} gap={'12px'}>
							<Typography variant={'DesktopH7Medium'} color={'gray.1000'} whiteSpace="nowrap">
								Real-time data export
							</Typography>
							{!shouldUseLiveExport && AdminOnlyLabel}
							{isRealTimeDataExportDisabled ? ComingSoonLabel : shouldLimitExports && PremiumLabel}
						</Flex>
						<Box>
							<ExportListItem
								isItemFaded={isItemFaded}
								isDisabled={isItemDisabled}
								title={'Google Sheets formula'}
								subTitle={
									<>
										Paste this formula into a cell.{' '}
										<Typography variant={'Disclaimer12SB'} display={'inline'}>
											Replace the [Placeholders]&nbsp;
										</Typography>
										with your own tokens.
									</>
								}
								icon={<GoogleSheets18 />}
								onClick={() => {
									onCopySheetsFormula();
									onClose();
								}}
							/>
							<ExportListItem
								isItemFaded={isItemFaded}
								isDisabled={isItemDisabled}
								title={'Power Query for Excel and Power BI'}
								subTitle={'Download & run in your Excel sheet.'}
								icon={<Excel18 />}
								onClick={onDownloadExcelWebQuery}
							/>
						</Box>
					</Flex>
				</Flex>
				<LiveExportFooter />
			</Box>
		</Popover>
	);
}

function LiveExportFooter() {
	return (
		<Box p={'12px 16px 16px 16px'} shadow={'borderTop'}>
			<Typography variant={'Paragraph12R'} color={'gray.700'} as={'div'}>
				<span>To learn more about how</span>
				<Typography variant={'Disclaimer12SB'} as={'span'}>
					&nbsp;Real-time data export&nbsp;
				</Typography>
				work,&nbsp;
				<Link target={'_blank'} href={`${SIGHTFULL_DOCS_CLOUD_LINK}/export/live_export_sheets_excel`}>
					<Typography
						variant={'Disclaimer12SB'}
						_hover={{ color: 'gray.800' }}
						cursor={'pointer'}
						display={'inline'}
						textDecoration={'underline'}
					>
						visit our help center
					</Typography>
					.
				</Link>
			</Typography>
		</Box>
	);
}
