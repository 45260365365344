import { Box, Center } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { StepGraph1, StepGraph2, StepGraph3, TagIcon24 } from '@components/Icons';
import Typography from '@components/Typography';
import { Fragment, useMemo } from 'react';
import Spinner from 'src/common/components/Spinner';
import { EditorFeature } from 'src/pages/MetricPage/components/CalculatePanel/EditPanel';
import colors from 'src/style/colors';

const REPEAT_STEPS_NUMBER = 2;
const STEPS = ['entity', 'formula', 'preview'] as const;
export type MetricBuilderSteps = typeof STEPS[number];

export const MetricBodyProgress = ({
	activeStep,
	type = 'formula',
	selectedFeature = 'YAML Builder',
}: {
	activeStep: MetricBuilderSteps;
	type?: string;
	selectedFeature?: EditorFeature;
}) => {
	const isYAMLBuilderSelected = selectedFeature === 'YAML Builder';

	const PROGRESS_ICONS = [<StepGraph1 key={0} />, <StepGraph2 key={1} />, <StepGraph3 key={2} />];

	const PROGRESS_TEXT = [
		<Fragment key={0}>
			Select <Typography variant="DesktopH7Bold">Entity</Typography> to start building a metric.
		</Fragment>,
		<Fragment key={1}>
			Fill in the&nbsp;
			<Typography variant="DesktopH7Bold"> {type === 'formula' ? 'Formula' : 'Measure'}</Typography>
			&nbsp;to preview your metric
		</Fragment>,
		<Fragment key={2}>
			<Spinner color="gray.800" trackColor="gray.500" size={'20px'} />
		</Fragment>,
	];

	const ProgressBar = useMemo(
		() => (
			<Flex gap={'8px'}>
				{[...Array(REPEAT_STEPS_NUMBER)].map((_, index) => (
					<Box
						key={index}
						width={'132px'}
						height={'6px'}
						borderRadius={'40px'}
						backgroundColor={index < STEPS.indexOf(activeStep) ? 'blue.600' : 'gray.200'}
					/>
				))}
			</Flex>
		),
		[activeStep]
	);

	const BuilderProgressBody = (
		<>
			<Flex
				marginBottom={'12px'}
				height={'30px'}
				maxH={'30px'}
				justifyContent={'end'}
				flexDirection={'column'}
				width={'fit-content'}
			>
				{PROGRESS_ICONS[STEPS.indexOf(activeStep)]}
			</Flex>
			<Typography marginBottom={'32px'} color={'gray.1100'} variant="DesktopH7Regular">
				{PROGRESS_TEXT[STEPS.indexOf(activeStep)]}
			</Typography>
			{ProgressBar}
		</>
	);

	const YAMLProgressBody = (
		<>
			<TagIcon24 color={colors.blue[600]} />
			<Typography marginTop={'16px'} color={'gray.1000'} variant="DesktopH7Regular">
				Write your metric in YAML and <Typography variant="DesktopH7Bold">Preview</Typography> the changes.
			</Typography>
			<Typography marginTop={'8px'} color={'gray.900'} variant="DesktopH8Regular">
				Include name, entity, {type === 'formula' ? 'and formula' : 'operation, and measure'}.
			</Typography>
		</>
	);

	return (
		<Box textAlign={'center'} marginTop={'324px'}>
			<Center width={'100%'}>
				<Box>
					<Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
						{isYAMLBuilderSelected ? BuilderProgressBody : YAMLProgressBody}
					</Flex>
				</Box>
			</Center>
		</Box>
	);
};
