import Flex from '@components/Flex';
import { ICellRendererParams } from 'ag-grid-community';
import Typography from 'src/common/components/Typography';

export function CreatedByRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.value || {};

		return (
			<Flex flexDirection={'column'}>
				<Typography
					marginBottom={'8px'}
					variant={'DesktopH8Regular'}
					color="gray.800"
					noOfLines={1}
					wordBreak="break-all"
				>
					{cellValue.date}
				</Typography>
				{cellValue.author && (
					<Typography variant={'DesktopH10Regular'} color="gray.800" noOfLines={1} wordBreak="break-all">
						By {cellValue.author}
					</Typography>
				)}
			</Flex>
		);
	}
	return Cell;
}
