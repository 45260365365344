import { ReactNode, useCallback } from 'react';
import Flex from '@components/Flex';
import Box from '@components/Box';
import NavigationAvatarMenu from './NavigationAvatarMenu';
import NavigationItemFrame from './NavigationItemFrame';
import { ChevronDown16, ChevronUp16 } from 'src/common/components/Icons';

function NavigationAvatar({
	icon,
	isTopNavigationMenuOpen,
	setIsTopNavigationMenuOpen,
}: {
	icon: ReactNode;
	isTopNavigationMenuOpen: boolean;
	setIsTopNavigationMenuOpen: (value: boolean) => void;
}) {
	const toggleIsTopNavigationMenuOpen = useCallback(() => {
		setIsTopNavigationMenuOpen(!isTopNavigationMenuOpen);
	}, [isTopNavigationMenuOpen, setIsTopNavigationMenuOpen]);

	return (
		<NavigationAvatarMenu
			isTopNavigationMenuOpen={isTopNavigationMenuOpen}
			setIsTopNavigationMenuOpen={setIsTopNavigationMenuOpen}
		>
			<Flex gap={'4px'} alignItems={'center'} onClick={toggleIsTopNavigationMenuOpen}>
				<Box color={'gray.1000'}>{isTopNavigationMenuOpen ? <ChevronUp16 /> : <ChevronDown16 />}</Box>
				<NavigationItemFrame variant="avatar" icon={icon} />
			</Flex>
		</NavigationAvatarMenu>
	);
}

export default NavigationAvatar;
