import { collectionsNavigationDrawer } from 'src/stores/utils';
import { useState } from 'react';
import { Collection } from '@layout/Menu/NavigationDrawer/types';
import { useCollectionsSubscriptions } from '@hooks/fetching/useCollectionsSubscriptions';
import Box from '@components/Box';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import Flex from '@components/Flex';
import classNames from 'classnames';
import { TestIDs } from 'src/common/types/test-ids';
import { CollectionUpsertModal } from '@layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { SpaceListItem } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem';
import Scrollable from '../../../Scrollable';

type SpacesListDrawerProps = {
	isCreateEditModalOpen: boolean;
	onCreateEditModalOpen: VoidFunction;
	onCreateEditModalClose: VoidFunction;
	isDashboardsPage: boolean;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	isParentPanelCollapsed: boolean;
};

export const SpacesListDrawer = ({
	isCreateEditModalOpen,
	onCreateEditModalOpen,
	onCreateEditModalClose,
	isDashboardsPage,
	setIsPanelActingLikeOnHover,
	isParentPanelCollapsed,
}: SpacesListDrawerProps) => {
	const currentDrawerView = isDashboardsPage
		? collectionsNavigationDrawer.dashboard
		: collectionsNavigationDrawer.workspace;
	const [selectedMenuItemId, setSelectedItem] = useState<number | null>(null);
	const [editedItem, setEditedItem] = useState<Collection>();

	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();

	const rawCollectionsListItems =
		currentDrawerView == collectionsNavigationDrawer.dashboard ? rawDashboardsList : rawWorkspacesList;

	return (
		<Box className={classNames(classes.itemsList, { [classes.collapsed]: isParentPanelCollapsed })}>
			<Flex
				data-testid={currentDrawerView}
				h="100%"
				justifyContent="flex-start"
				flexDir="column"
				overflow="hidden"
				zIndex="1"
			>
				{/* <Box overflowX="hidden" overflowY="auto" data-testid={TestIDs.SIDEBAR_ITEMS(currentDrawerView)}> */}
				<Scrollable data-testid={TestIDs.SIDEBAR_ITEMS(currentDrawerView)}>
					{rawCollectionsListItems?.workspaces?.map((collection, index) => (
						<SpaceListItem
							key={collection.id}
							currentDrawerView={currentDrawerView}
							collection={collection}
							index={index}
							setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
							onCreateEditModalOpen={onCreateEditModalOpen}
							setEditedItem={setEditedItem}
							testId={TestIDs.SIDEBAR_ITEM(currentDrawerView)}
							selectedMenuItemId={selectedMenuItemId}
							setSelectedItem={setSelectedItem}
						/>
					))}
				</Scrollable>
				{/* </Box> */}
			</Flex>

			<CollectionUpsertModal
				isOpen={isCreateEditModalOpen}
				onClose={() => {
					onCreateEditModalClose();
					setEditedItem(undefined);
				}}
				collection={editedItem}
				collectionType={editedItem?.collection_type || currentDrawerView}
			/>
		</Box>
	);
};
