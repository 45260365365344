import { ReactNode } from 'react';
import Flex from '@components/Flex';

type OverlayProps = {
	isActive: boolean;
	children: ReactNode;
};
export function Overlay({ children, isActive }: OverlayProps) {
	return (
		<Flex
			width={'100%'}
			direction={'column'}
			flex={1}
			transition={'0.2s'}
			filter={isActive ? 'blur(1px)' : undefined}
			opacity={isActive ? 0.5 : undefined}
			_before={
				isActive
					? {
							content: `""`,
							position: 'absolute',
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							zIndex: 10000,
					  }
					: undefined
			}
		>
			{children}
		</Flex>
	);
}
