import Box from '@components/Box';
import LoadingText from '@components/LoadingText';
import { LoadingTextProps } from '../../LoadingText/LoadingText';

export function AskAILoadingResults() {
	return <LoadingAIResults text={''} isLoading={true} component={() => <></>} />;
}

export function LoadingAIResults({ text, component, isLoading, onDone }: LoadingTextProps) {
	return (
		<Box padding={'0 16px 16px 16px'}>
			<LoadingText text={text} isLoading={isLoading} component={component} onDone={onDone} />
		</Box>
	);
}
