import { useSetAtom } from 'jotai';
import omit from 'lodash/omit';
import { useCallback, useEffect } from 'react';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { buildCoreReaderChartOptions } from 'src/common/utils/coreReaderParsers';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import {
	ParameterDefinition,
	useCalculateDetailedMetricCoreReaderLazyQuery,
	useCalculateMetricCoreReaderLazyQuery,
} from 'src/generated/graphql';

import { useIsFiltersV2Enabled } from '@pages/MetricPage/components/FiltersAndBreakdown/useIsFiltersV2Enabled';
import * as yaml from 'js-yaml';
import { format } from 'sql-formatter';
import { ChartType } from 'src/common/components/Chart/types';
import { useCoreReaderFiltersApi } from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { CancelControl, useExecutionPool } from 'src/common/hooks/useExecutionPool';
import { buildParametersForApi } from 'src/common/utils/parameters';
import {
	calcCoreTable,
	calcDisplayedLegendItems,
	calcFiltersForQuery,
} from 'src/lib/metricRules/DerivedStateCalculators';
import { calcPlotBands } from 'src/lib/metricRules/DerivedStateCalculators/calcPlotBands';
import { calcSelectedXAxisElement } from 'src/lib/metricRules/DerivedStateCalculators/calcSelectedXAxisElement';
import {
	calcPieSeriesVisibility,
	calcSeriesVisibility,
} from 'src/lib/metricRules/DerivedStateCalculators/calcSeriesVisibility';
import { calcTableType } from 'src/lib/metricRules/DerivedStateCalculators/calcTableType';
import { MultiplyOp } from 'src/models/MetricOperator';
import { DerivedStateAtom, loadingDerivedState } from 'src/pages/MetricPage/atoms/DerivedState';
import { addPrefixDollarSignIfNeeded } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { useRulesEngineInput } from 'src/pages/MetricPage/hooks/useRulesEngineInput';
import { useUpdatedPreviewHashState } from 'src/pages/MetricPage/hooks/useUpdatedPreviewHashAtom';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { hashMetricYamlEditorValueForPreview } from 'src/pages/MetricPage/utils/stateHelpers';
import { Parameter } from 'src/types/parameter';
import { MetricsSchema } from '../completions/semanticTypes/metrics.schema';
import { isUntitledMetric } from './builder/useMetricBuilder';
import {
	calcAvailablePeriods,
	calcAvailableRangePresets,
	calcCollectedProps,
	calcColoredBubbles,
	calcColoredSeries,
	calcCoreAvailableChartTypes,
	calcCoreBreakdowns,
	calcCoreValidFilters,
	calcDecimalDigits,
	calcIsExecutiveView,
	calcMissingDependencies,
	calcOverrideChartType,
	calcStatisticFormatting,
	calcTableColumnState,
} from './DerivedStateCalculators';
import { calcAvailableSortOrders } from './DerivedStateCalculators/calcAvailableSortOrders';
import { calcCoreFormatting } from './DerivedStateCalculators/CoreReader/calcCoreFormatting';
import { calcCorePeriodRange } from './DerivedStateCalculators/CoreReader/calcCorePeriodRange';
import { DataLabelFormatConfig } from './statisticOperations/types';
import { isSQLLanguage } from './types';

export function useCoreReaderRulesEngine() {
	const { rulesInput } = useRulesEngineInput();
	const setDerivedState = useSetAtom(DerivedStateAtom);
	const { decimalDigits, graphColors, lookbackMonths } = useTenantConfig();
	const [calculateMetricCoreReaderLazy] = useCalculateMetricCoreReaderLazyQuery();
	const [cancellableCalculateMetricCoreReader] = useExecutionPool(calculateMetricCoreReaderLazy);
	const [calculateDetailedMetricCoreReader] = useCalculateDetailedMetricCoreReaderLazyQuery();
	const [, fetchRelationshipsAndDimensions] = useCoreReaderFiltersApi('cache-first');
	const { previewValue, previewValueForCalculationOnly } = useMetricEditorState();
	const { setMetricYamlEditorHashState } = useUpdatedPreviewHashState();
	const clearStateOnExit = useCallback(() => setDerivedState(loadingDerivedState), [setDerivedState]);
	const isFiltersV2Enabled = useIsFiltersV2Enabled();

	useEffect(() => clearStateOnExit, [clearStateOnExit]);

	useEffect(() => {
		setDerivedState((s) => ({ ...s, isPartiallyLoadingTable: true }));
	}, [setDerivedState, rulesInput.searchParams.selectedXAxisElements, rulesInput.searchParams.collectProps]);

	useEffect(
		() => setDerivedState((s) => ({ ...s, isPartiallyLoadingChart: true })),
		[rulesInput.searchParams.statisticsOperations, setDerivedState]
	);

	const nonFullyReRenderingSearchParams: (keyof MetricSearchParams)[] = [
		'statisticsOperations',
		'hiddenLegendNames',
		'displayUnits',
		'selectedPeriod',
		'selectedXAxisElements',
		'tableColumnState',
		'showSQLQueryView',
		'pageMode',
		'decimalDigits',
		'orderedComponents',
		'yAxisConfig',
		'collectProps',
	];
	const stringifiedFullyReRenderingSearchParams = JSON.stringify(
		omit(rulesInput.searchParams, nonFullyReRenderingSearchParams)
	);
	useEffect(
		() => setDerivedState((s) => ({ ...s, isLoading: true })),
		[stringifiedFullyReRenderingSearchParams, setDerivedState, rulesInput.metricNameWithFlavor]
	);

	useEffect(() => {
		const cancelControl = new CancelControl();
		(async () => {
			setDerivedState((s) => ({ ...s, isRulesEngineRunning: true }));

			const withFlavor: Pick<MetricDerivedState, 'flavor'> = { flavor: undefined };
			const initialState = {
				metricNameWithoutFlavor: rulesInput.metricNameWithoutFlavor,
				metricNameWithFlavor: rulesInput.metricNameWithFlavor,
				metricExplanationArticleId: undefined,
				...withFlavor,
			};

			const withAvailablePeriodRange = {
				...initialState,
				...calcAvailablePeriods({ lookbackMonths }),
			};

			const withPeriodRange = {
				...withAvailablePeriodRange,
				...calcCorePeriodRange(rulesInput, {}, withAvailablePeriodRange.availablePeriodRanges),
			};
			const withRelevantRangePresets = {
				...withPeriodRange,
				...calcAvailableRangePresets(withPeriodRange, ['fweek']),
			};
			const withMetricOp = { ...withRelevantRangePresets, metricOperator: MultiplyOp }; // TODO: replace this logic
			const withTableColumnState = {
				...withMetricOp,
				...calcTableColumnState(rulesInput.searchParams.tableColumnState),
			};
			const withStaticOperations = {
				...withTableColumnState,
				statisticsOperations: [],
			};

			const metricYamlEditorMetricDefinitionJson = yaml.load(previewValue);
			const metricNameAfterOverrides =
				tryGetMetricNameFromMetricJson(metricYamlEditorMetricDefinitionJson as MetricsSchema) ??
				rulesInput.metricNameWithFlavor;

			const withCollectPropsAndMetricDefinitionJson = {
				...withStaticOperations,
				...calcCollectedProps(rulesInput.searchParams),
				metricYamlEditorMetricDefinitionJson,
			};

			setDerivedState((s) => ({ ...s, ...withCollectPropsAndMetricDefinitionJson }));

			const filtersV2ForQuery = calcFiltersForQuery(rulesInput.searchParams);

			const parametersInput = buildParametersForApi(rulesInput.searchParams.parameters);

			const isWithoutRequiredFieldsAggregateMetric = !!(
				previewValueForCalculationOnly?.metrics && !previewValueForCalculationOnly?.metrics[0].entity
			);

			const isWithoutRequiredFieldsFormulaMetric = !!(
				previewValueForCalculationOnly?.formula_metrics &&
				(!previewValueForCalculationOnly?.formula_metrics[0].entity ||
					!previewValueForCalculationOnly?.formula_metrics[0].formula)
			);

			const shouldSkipCalcMetric =
				(isUntitledMetric({ name: metricNameAfterOverrides }) && !metricYamlEditorMetricDefinitionJson) ||
				isWithoutRequiredFieldsAggregateMetric ||
				isWithoutRequiredFieldsFormulaMetric;

			const coreReaderCalc = shouldSkipCalcMetric
				? undefined
				: await cancellableCalculateMetricCoreReader(cancelControl).execute({
						variables: {
							metricName: metricNameAfterOverrides,
							start: withCollectPropsAndMetricDefinitionJson.periodRange.asAbsoluteRange.startPeriod.id,
							end: withCollectPropsAndMetricDefinitionJson.periodRange.asAbsoluteRange.endPeriod.id,
							groupBy: rulesInput.searchParams.groupBy
								? Object.entries(rulesInput.searchParams.groupBy).map(([key]) => addPrefixDollarSignIfNeeded(key))
								: [],
							filterBy: filtersV2ForQuery,
							parameters: parametersInput,
							userMetricDefinitions: previewValueForCalculationOnly,
						},
				  });

			const entity = coreReaderCalc?.data?.calcMetricV2?.metricInfo?.entity;
			const objectsTypes = entity ? [entity] : [];
			const { filters } = await calcCoreValidFilters(
				coreReaderCalc?.data?.calcMetricV2?.chartOptions?.filters ?? [],
				{ objectsTypes },
				fetchRelationshipsAndDimensions,
				rulesInput.searchParams
			);

			if (
				coreReaderCalc?.error ||
				!coreReaderCalc?.data?.calcMetricV2?.metricInfo ||
				!coreReaderCalc?.data?.calcMetricV2?.chartOptions
			) {
				const metricMisingDependencies = coreReaderCalc?.data?.calcMetricV2.metricInfo?.missingDependencies ?? {
					dimensions: [],
					entities: [],
					metrics: [],
					relationships: [],
				};

				setDerivedState((s) => ({
					...s,
					filters,
					errorMessage: `Metric could not be calculated: ${coreReaderCalc?.data?.calcMetricV2?.errorMessage}`,
					isLoading: false,
					isPartiallyLoadingTable: false,
					doesMetricExist: !!coreReaderCalc?.data?.calcMetricV2?.metricInfo,
					isFullyDefined: coreReaderCalc?.data?.calcMetricV2.metricInfo?.isFullyDefined ?? true,
					objectsTypes,
					missingDependencies: calcMissingDependencies(metricMisingDependencies),
					metricDisplayName: coreReaderCalc?.data?.calcMetricV2?.metricInfo?.metricName ?? '',
					metricExplanationOneliner: coreReaderCalc?.data?.calcMetricV2?.metricInfo?.meta?.one_liner ?? '',
				}));
				return;
			}

			const metricInfo = coreReaderCalc.data.calcMetricV2.metricInfo;

			const sqlQuery = coreReaderCalc?.data?.calcMetricV2?.sql;
			const dialect = coreReaderCalc?.data?.calcMetricV2?.dialect;
			const language = isSQLLanguage(dialect) ? dialect : undefined;

			const withMetadata = {
				...withCollectPropsAndMetricDefinitionJson,
				metricDisplayName: metricInfo.metricName ?? '',
				metricExplanationOneliner: metricInfo.meta?.one_liner ?? '',
				objectsTypes,
				isFormula: metricInfo.isFormula,
				metricSource: metricInfo.source,
				isFullyDefined: metricInfo.isFullyDefined,
				sqlQuery:
					sqlQuery != undefined
						? format(sqlQuery, {
								tabWidth: 2,
								dataTypeCase: 'upper',
								keywordCase: 'upper',
								functionCase: 'lower',
								identifierCase: 'lower',
								language,
						  })
						: undefined,
			};

			const withParameters = {
				...withMetadata,
				...calcParameters(metricInfo.parameters, rulesInput.searchParams),
			};

			const withFilters = {
				...withParameters,
				filters,
			};

			const withBreakdowns = {
				...withFilters,
				...calcCoreBreakdowns(coreReaderCalc.data.calcMetricV2.chartOptions.breakdowns),
			};

			const withHasGroupBy = {
				...withBreakdowns,
				hasGroupBy: coreReaderCalc.data.calcMetricV2.chartOptions.hasGroupBy,
			};

			const withIsGranularDataForAllPeriodsButtonEnabled = {
				...withHasGroupBy,
				isGranularDataForAllPeriodsButtonEnabled: true,
			};

			const withDisplayUnits = {
				...withIsGranularDataForAllPeriodsButtonEnabled,
				displayUnits: {
					'percentage-first': { isDisabled: true, value: false },
					'percentage-prev': { isDisabled: true, value: false },
					percentage: { isDisabled: true, value: false },
					componentCount: { isDisabled: true, value: false },
					value: { isDisabled: true, value: false },
				} as MetricDerivedState['displayUnits'],
			};

			const withAvailableSortOrders = {
				...withDisplayUnits,
				...calcAvailableSortOrders(
					withDisplayUnits,
					rulesInput.searchParams.sortOrder,
					rulesInput.searchParams.orderedComponents
				),
			};

			const withChartOptionsSeries = {
				...withAvailableSortOrders,
				chartOptions: buildCoreReaderChartOptions(
					coreReaderCalc.data?.calcMetricV2.chartOptions,
					withAvailableSortOrders,
					rulesInput.searchParams.orderedComponents
				),
			};

			const withDecimalDigits = {
				...withChartOptionsSeries,
				...calcDecimalDigits({
					searchParamsDecimalDigits: rulesInput.searchParams.decimalDigits,
					tenantConfigDecimalDigits: decimalDigits,
				}),
			};

			const formatConfig: DataLabelFormatConfig = {
				decimalDigits: withDecimalDigits.decimalDigits,
				displayUnits: withChartOptionsSeries.displayUnits,
			};

			const withFormatting = {
				...withDecimalDigits,
				...calcCoreFormatting(withDecimalDigits.chartOptions, {
					decimalDigits: withDecimalDigits.decimalDigits,
					displayUnits: withDecimalDigits.displayUnits,
				}),
			};

			const withChartType = {
				...withFormatting,
				...calcCoreAvailableChartTypes(
					coreReaderCalc.data.calcMetricV2.chartOptions.availableChartTypes as ChartType[],
					withFormatting,
					rulesInput.searchParams.chartType
				),
			};

			const withCorrectChartType = {
				...withChartType,
				...calcOverrideChartType(withChartType),
			};

			const withSelectedXAxisElement = {
				...withCorrectChartType,
				...calcSelectedXAxisElement(rulesInput.searchParams, withCorrectChartType),
			};

			const withPlotBands = {
				...withSelectedXAxisElement,
				...calcPlotBands(withSelectedXAxisElement),
				availableTargets: [],
			};

			const withDisplayedLegendItems = {
				...withPlotBands,
				...calcDisplayedLegendItems(withPlotBands, rulesInput),
			};

			const withSeriesVisibility = {
				...withDisplayedLegendItems,
				...calcSeriesVisibility(withDisplayedLegendItems),
			};

			const withPieChartSeriesVisibility = {
				...withSeriesVisibility,
				...calcPieSeriesVisibility(withSeriesVisibility),
			};

			const withColoredSeries = {
				...withPieChartSeriesVisibility,
				...calcColoredSeries(withPieChartSeriesVisibility, graphColors),
			};
			const withColoredBubbles = { ...withColoredSeries, ...calcColoredBubbles(withColoredSeries.chartOptions) };

			const withDisplayUnitsSeries = {
				...withColoredBubbles,
			};

			const withTableType = {
				...withDisplayUnitsSeries,
				...calcTableType(withDisplayUnitsSeries),
			};

			const withStatisticFormatting = {
				...withTableType,
				...calcStatisticFormatting(withTableType.chartOptions, formatConfig),
			};

			const withIsExecutiveView = { ...withStatisticFormatting, ...calcIsExecutiveView(rulesInput.searchParams) };

			const withTable = {
				...withIsExecutiveView,
				...(await calcCoreTable({
					metricNameAfterOverrides,
					derivedState: withIsExecutiveView,
					calculateDetailedMetricCoreReader,
					formatConfig,
					metricYamlEditorMetricDefinitionJson: previewValueForCalculationOnly,
					parameters: parametersInput,
				})),
			};

			const metricMisingDependencies = coreReaderCalc.data?.calcMetricV2.metricInfo?.missingDependencies ?? {
				dimensions: [],
				entities: [],
				metrics: [],
				relationships: [],
			};

			const yAxisConfig = rulesInput.searchParams.yAxisConfig;
			const withYAxisConfig = { ...withTable, yAxisConfig };

			const withIsLoading = { ...withYAxisConfig, isLoading: false, isPartiallyLoadingChart: false };

			setDerivedState({
				...withIsLoading,
				isRulesEngineRunning: false,
				hasComponents: false,
				isAllComponentSameUnit: true,
				metricDisplayName: coreReaderCalc.data?.calcMetricV2.metricInfo?.metricName ?? '',
				metricExplanationOneliner: coreReaderCalc.data?.calcMetricV2.metricInfo?.description ?? '',
				doesMetricExist: true,
				missingDependencies: calcMissingDependencies(metricMisingDependencies),
			});
		})()
			.catch((e) => {
				if (e.name !== 'CancelledError') {
					setDerivedState((s) => ({
						...s,
						errorMessage: `Metric could not be calculated: ${e}`,
						isLoading: false,
						isPartiallyLoadingTable: false,
					}));
				}
			})
			.finally(() => {
				setMetricYamlEditorHashState((s) => ({
					...s,
					calculatedRulesEngineHash: hashMetricYamlEditorValueForPreview(previewValue),
				}));
				setDerivedState((s) => ({ ...s, isRulesEngineRunning: false }));
			});

		return () => {
			cancelControl.cancel();
		};
	}, [
		rulesInput,
		setDerivedState,
		decimalDigits,
		graphColors,
		lookbackMonths,
		cancellableCalculateMetricCoreReader,
		calculateDetailedMetricCoreReader,
		setMetricYamlEditorHashState,
		fetchRelationshipsAndDimensions,
		previewValue,
		previewValueForCalculationOnly,
		isFiltersV2Enabled,
	]);
}

function tryGetMetricNameFromMetricJson(metricJson?: MetricsSchema): string | null {
	const allMetrics = [metricJson?.metrics ?? [], metricJson?.formula_metrics ?? []].flat();
	if (allMetrics.length === 1) {
		return allMetrics[0]?.name ?? null;
	}

	return null;
}

function calcParameters(
	parameterDefinitions: ParameterDefinition[],
	searchParams: MetricSearchParams
): { parameters: Parameter[] } {
	return {
		parameters: parameterDefinitions.map((param) => ({
			definition: param,
			value: searchParams.parameters?.[param.name],
		})),
	};
}
