import { useMemo } from 'react';
import {
	AskAIChatCardProps,
	AskAIChatFieldSuggestion,
	isAskAIOntologyBuilderRelationshipChatMessage,
} from '../../types';
import { useOntologyBuilderAIAgent } from '../../hooks/useOntologyBuilderAIAgent';
import { AskAIChatFieldSuggestions } from '../AskAIChatFieldSuggestions';
import { AskAILoadingResults } from '../AskAILoadingResults';

export function AskAIOntologyBuilderRelationshipCardContent({ chatMessageAtom, isLast }: AskAIChatCardProps) {
	const { chatMessage } = useOntologyBuilderAIAgent({ chatMessageAtom });
	const isRelationshipMessage = isAskAIOntologyBuilderRelationshipChatMessage(chatMessage);
	const result = isRelationshipMessage ? chatMessage.result : undefined;
	const relationship = result?.relationship;
	const existingRelationship: typeof relationship = chatMessage.promptContext?.parsedYaml;

	const fields = useMemo(() => {
		if (!relationship) return [];

		const fields: AskAIChatFieldSuggestion[] = [];

		if (relationship.name && (!existingRelationship || existingRelationship.name === 'untitled')) {
			fields.push({
				label: '',
				propertyName: 'name',
				value: relationship.name,
			});
		}

		if (relationship.meta?.display_name) {
			fields.push({
				label: 'Name',
				propertyName: 'display_name',
				display: relationship.meta.display_name,
				value: relationship.meta.display_name,
				existingValue: existingRelationship?.meta?.display_name,
			});
		}

		if (relationship.referenced_entity) {
			fields.push({
				label: 'Referenced entity',
				propertyName: 'referenced_entity',
				display: relationship.referenced_entity,
				value: relationship.referenced_entity,
				existingValue: existingRelationship?.referenced_entity,
			});
		}

		if (relationship.type) {
			fields.push({
				label: 'Type',
				propertyName: 'type',
				display: relationship.type,
				value: relationship.type,
				existingValue: existingRelationship?.type,
			});
		}

		if (relationship.on) {
			fields.push({
				label: 'Join on',
				propertyName: 'on',
				display: relationship.on,
				value: relationship.on,
				existingValue: existingRelationship?.on,
			});
		}

		if (relationship.meta?.description) {
			fields.push({
				label: 'Description',
				propertyName: 'description',
				display: relationship.meta?.description,
				value: relationship.meta?.description,
				existingValue: existingRelationship?.meta?.description,
			});
		}

		return fields;
	}, [relationship, existingRelationship]);

	if (!isRelationshipMessage) return;

	return relationship ? (
		<AskAIChatFieldSuggestions
			fields={fields}
			onAcceptSuggestions={chatMessage.onAcceptSuggestions}
			isLastChatCard={isLast}
		/>
	) : (
		<AskAILoadingResults />
	);
}
