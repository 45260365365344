import { ChartType } from '@components/Chart/types';
import Flex from '@components/Flex';
import Link from '@components/Link';
import { ExportPngButton } from '@components/MetricView/ExportPng/ExportPngButton';
import { MetricMissingDependencies } from '@components/MetricView/MetricMissingDependencies';
import { NotDefinedMetricPlaceholder } from '@components/MetricView/NotDefinedMetricPlaceholder';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/types';
import { useMetricBuilderAIAgent } from '@pages/MetricPage/hooks/useMetricBuilderAIAgent';
import { useMetricEditorState } from '@pages/MetricPage/hooks/useMetricEditorState';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { isSinglePeriodView } from 'src/lib/metricRules/DerivedStateCalculators';
import { OnModalOpenType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { LayersModal } from 'src/pages/MetricPage/components/Layers/LayersModal';
import PeriodPicker from 'src/pages/MetricPage/components/PeriodPicker';
import { SkeletonPeriodPicker } from 'src/pages/MetricPage/components/SkeletonComponents';
import { TableLayout } from 'src/pages/MetricPage/components/Table/TableLayout';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useOverrideChartType } from 'src/pages/MetricPage/hooks/useOverrideChartType';
import { usePeriodRange } from 'src/pages/MetricPage/hooks/usePeriodRange';
import { useReportEvent } from 'src/services/analytics';
import Box from '../Box';
import { BrokenChartPlaceholder } from '../BrokenChartPlaceholder';
import { ErrorBanner } from '../ErrorBanner';
import { MetricFiltersAndBreakdowns } from '../MetricFiltersAndBreakdowns/MetricFiltersAndBreakdowns';
import Typography from '../Typography';
import MetricChart from './MetricChart';
import { MetricViewLoading } from './MetricViewLoading';
import { ViewDivider } from './ViewDivider';
import useComponentVisibility, { ComponentVisibility } from './useComponentVisibility';

export type LayoutOptions = 'split' | 'top-collapsed' | 'bottom-collapsed';

type MetricViewLayoutProps = {
	onModalOpen?: OnModalOpenType;
	isEditMode?: boolean;
	isLeftPanelExpanded?: boolean;
	navigateToMetricPage?: VoidFunction;
};

type MetricHeaderProps = {
	isLoading: boolean;
	options: SelectOption[];
	selectedOption: SelectOption;
	onChange: (newChartType: SelectOption) => void;
	isEditMode: boolean;
	isDisabled?: boolean;
	componentVisibility: ComponentVisibility;
	onOpen: (component: keyof MetricHeaderProps['componentVisibility']) => void;
	onClose: (component: keyof MetricHeaderProps['componentVisibility']) => void;
};

function MetricHeader({
	isDisabled,
	isLoading,
	selectedOption,
	onChange,
	isEditMode,
	options,
	componentVisibility,
	onOpen,
	onClose,
}: MetricHeaderProps) {
	const { availablePeriodRanges, availableRangePresets } = useMetricDerivedState();

	const [selectedPeriodRange, setSelectedPeriodRange] = usePeriodRange();

	return (
		<Flex
			flexDirection="row"
			justifyContent="space-between"
			paddingBottom="12px"
			paddingX={'32px'}
			wrap="wrap"
			rowGap={'12px'}
			columnGap="8px"
		>
			{isLoading ? (
				<SkeletonPeriodPicker />
			) : (
				<Flex alignItems={'center'} gap="8px">
					<PeriodPicker
						isPeriodPickerOpen={componentVisibility.periodPicker}
						onOpen={() => onOpen('periodPicker')}
						onClose={() => onClose('periodPicker')}
						availablePeriodRanges={availablePeriodRanges}
						availableRangePresets={availableRangePresets}
						selectedPeriodRange={selectedPeriodRange}
						onSelectPeriodRange={(range) => range && setSelectedPeriodRange(range)}
						size="sm"
					/>
					{isEditMode && <MetricFiltersAndBreakdowns />}
				</Flex>
			)}

			<Flex gap="8px">
				{isLoading ? (
					<SkeletonPeriodPicker />
				) : (
					<>
						<ExportPngButton isDisabled={isDisabled} />
						<LayersModal
							isDecimalPopoverOpen={componentVisibility.decimalPopover}
							onOpen={() => onOpen('decimalPopover')}
							onClose={() => onClose('decimalPopover')}
							isDisabled={isDisabled}
						/>
						<Select
							menuIsOpen={componentVisibility.chartTypeSelect}
							isDisabled={isDisabled}
							options={options}
							size={'small'}
							selectedOption={selectedOption}
							onChange={onChange}
							onMenuOpen={() => onOpen('chartTypeSelect')}
							onMenuClose={() => onClose('chartTypeSelect')}
							iconsColor={isDisabled ? 'gray.500' : 'gray.800'}
						/>
					</>
				)}
			</Flex>
		</Flex>
	);
}

type MetricBodyProps = {
	isDisabled?: boolean;
	navigateToMetricPage?: VoidFunction;
	isEditMode?: boolean;
	onModalOpen?: OnModalOpenType;
	handleCloseDropdownsOnChartClick: () => void;
};

function MetricBody({
	isDisabled,
	navigateToMetricPage,
	onModalOpen,
	handleCloseDropdownsOnChartClick,
}: MetricBodyProps) {
	const pageRef = useRef<HTMLDivElement>(null);
	const [selectedLayoutOption, setSelectedLayoutOption] = useState<LayoutOptions>('split');
	const { periodRange, filters, breakdowns, chartOptions, isLoading, isTableView, isFullyDefined } =
		useMetricDerivedState();
	const isSinglePeriod = isSinglePeriodView(periodRange, breakdowns, chartOptions.series);
	const isLongLoadingEnabled = useFeatureFlag('pulse.react.metrics.longLoading');
	const chartSectionRef = useRef<HTMLDivElement>(null);
	const [chartHeight, setChartHeight] = useState('100%');
	const { reportEvent } = useReportEvent();

	useEffect(() => {
		if (!chartSectionRef.current) return;
		const observerElem = chartSectionRef.current;

		const resizeObserver = new ResizeObserver((entries) => {
			const newHeight = `${entries[0].contentRect.height}px`;
			if (newHeight === chartHeight) return;

			setChartHeight(newHeight);
		});

		resizeObserver.observe(observerElem);

		return () => {
			if (observerElem) resizeObserver.unobserve(observerElem);
			resizeObserver.disconnect();
		};
	}, [chartHeight]);

	useEffect(() => {
		setSelectedLayoutOption(isTableView ? 'top-collapsed' : 'split');
	}, [isTableView]);

	if (isLongLoadingEnabled && isLoading) {
		return (
			<MetricViewLoading periodRange={periodRange} hasFilters={filters.length > 0} breakdowns={breakdowns.values} />
		);
	}

	const renderBrokenMetricChartText = () => {
		return (
			<Typography maxWidth={'300px'} textAlign={'center'} color={'gray.1000'} variant="Paragraph12R">
				{navigateToMetricPage ? (
					<>
						Try&nbsp;
						<Link textDecoration={'underline'} onClick={navigateToMetricPage}>
							explore the metric
						</Link>
						&nbsp;or reach out to your Sightfull admin for assistance if needed.
					</>
				) : (
					<>Review the error message and try again.</>
				)}
			</Typography>
		);
	};

	if (!isFullyDefined || isDisabled) {
		return (
			<Flex marginTop={'120px'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
				{!isFullyDefined ? (
					<NotDefinedMetricPlaceholder />
				) : (
					<BrokenChartPlaceholder description={renderBrokenMetricChartText()} isBigIcon />
				)}
			</Flex>
		);
	}

	return (
		<Flex flexDirection="column" overflowY="auto" flexGrow={1} ref={pageRef}>
			<Flex
				paddingX={'32px'}
				width="0"
				minWidth="100%"
				maxHeight={selectedLayoutOption === 'top-collapsed' ? '0' : '100%'}
				flexGrow={selectedLayoutOption === 'top-collapsed' ? '0' : '1'}
				overflowY={'hidden'}
				flexBasis={'100vh'}
				ref={chartSectionRef}
			>
				<MetricChart
					handleCloseDropdownsOnChartClick={handleCloseDropdownsOnChartClick}
					height={chartHeight}
					layoutState={selectedLayoutOption}
					chartOptions={chartOptions}
					isSinglePeriod={isSinglePeriod}
					isLoading={isLoading || !chartOptions}
				/>
			</Flex>
			<Box width="0" minWidth="100%">
				<ViewDivider
					selectedLayout={selectedLayoutOption}
					onSelectLayout={(selectedLayout) => {
						setSelectedLayoutOption(selectedLayout);
						reportEvent({ event: 'metric-layout-changed', metaData: { selectedLayout } });
					}}
					isLoading={isLoading}
				/>
			</Box>
			<Flex
				flexGrow={1}
				flexBasis={'100vh'}
				overflowY={'auto'}
				maxHeight={selectedLayoutOption === 'bottom-collapsed' ? '0' : '100%'}
				display={selectedLayoutOption === 'bottom-collapsed' ? 'none' : 'flex'}
			>
				<TableLayout onModalOpen={onModalOpen} isLoading={isLoading} />
			</Flex>
		</Flex>
	);
}

export default function MetricViewLayout({
	onModalOpen,
	isEditMode = false,
	navigateToMetricPage,
	isLeftPanelExpanded,
}: MetricViewLayoutProps) {
	const { reportEvent } = useReportEvent();
	const { isLoading, metricNameWithFlavor, errorMessage, missingDependencies, objectsTypes } = useMetricDerivedState();
	const [{ optionalValues: chartTypesOptions, selectedValue: selectedChartType }, setChartType] =
		useOverrideChartType();
	const { componentVisibility, onOpen, onClose, closeAll } = useComponentVisibility({
		chartTypeSelect: false,
		periodPicker: false,
		decimalPopover: false,
	});

	const { latestEditorValue, metricEditorLoadedState } = useMetricEditorState();
	const { isMetricBuilderAIAgentEnabled, requestSilentMetricErrorFixingSuggestion, isBusy } = useMetricBuilderAIAgent();

	useEffect(() => {
		if (!isLeftPanelExpanded) {
			return;
		}
		closeAll();
	}, [closeAll, isLeftPanelExpanded]);

	const isFixWithAIAvailable = useMemo(() => {
		return isMetricBuilderAIAgentEnabled && isEditMode && !!metricEditorLoadedState;
	}, [isEditMode, isMetricBuilderAIAgentEnabled, metricEditorLoadedState]);

	const onFixWithAI = useCallback(
		(errorMessage: string) => {
			if (!metricEditorLoadedState) return;
			requestSilentMetricErrorFixingSuggestion({
				errorMessage,
				metricType: metricEditorLoadedState.kind,
				definition: latestEditorValue,
			});
		},
		[metricEditorLoadedState, latestEditorValue, requestSilentMetricErrorFixingSuggestion]
	);

	const isDisabled = !!errorMessage;

	const shouldShowMissingDependencies = missingDependencies.length > 0;

	return (
		<>
			<Flex paddingTop={isDisabled ? '0' : '24px'}>
				{isDisabled && (
					<ErrorBanner
						onFixWithAI={onFixWithAI}
						isFixWithAIAvailable={isFixWithAIAvailable}
						isAIBusy={isBusy}
						errorMessage={errorMessage}
					/>
				)}
			</Flex>
			{shouldShowMissingDependencies && (
				<MetricMissingDependencies
					metricMissingDependencies={missingDependencies}
					metricName={metricNameWithFlavor}
					// TA1.0: change objectsTypes[0] to metricEntity prop
					metricEntity={objectsTypes[0]}
				/>
			)}
			{!shouldShowMissingDependencies && !isDisabled && (
				<MetricHeader
					componentVisibility={componentVisibility}
					onClose={onClose}
					onOpen={onOpen}
					isLoading={isLoading}
					options={chartTypesOptions}
					selectedOption={selectedChartType}
					onChange={(newChartType) => {
						reportEvent({
							event: 'select-chart-type',
							metaData: { newChartType: newChartType.value, metric: metricNameWithFlavor },
						});
						setChartType(newChartType.value as ChartType);
					}}
					isEditMode={isEditMode}
				/>
			)}
			<MetricBody
				handleCloseDropdownsOnChartClick={closeAll}
				navigateToMetricPage={navigateToMetricPage}
				isDisabled={!!errorMessage}
				isEditMode={isEditMode}
				onModalOpen={onModalOpen}
			/>
		</>
	);
}
