import { useCallback, useEffect, useState } from 'react';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { AskAIChatPopover, AskAIChatPopoverProps } from '../AskAIChatPopover';
import { AskAIChatFieldSuggestion } from '../../types';
import * as yaml from 'js-yaml';
import { useAtomCallback } from 'jotai/utils';

type AskAIOntologyBuilderChatPopoverProps = {
	builderType: 'entity' | 'dimension' | 'relationship';
	entityName?: string;
	existingYaml?: string;
	onAcceptSuggestions: (selectedFields: AskAIChatFieldSuggestion[]) => void;
};

export function AskAIOntologyBuilderChatPopover({
	builderType,
	entityName,
	existingYaml,
	onAcceptSuggestions,
	...popoverProps
}: AskAIOntologyBuilderChatPopoverProps & Omit<AskAIChatPopoverProps, 'onPrompt' | 'reportEvent'>) {
	const { addChatMessage } = useAskAIChatMessages();

	const [chatAtom, setChatAtom] = useState<ReturnType<typeof addChatMessage> | null>(null);

	const updateAtomOnAcceptSuggestions = useAtomCallback(
		useCallback(
			(get, set) => {
				if (chatAtom) {
					const currentValues = get(chatAtom);
					set(chatAtom, { ...currentValues, onAcceptSuggestions });
				}
			},
			[chatAtom, onAcceptSuggestions]
		)
	);

	useEffect(() => {
		if (chatAtom) {
			void updateAtomOnAcceptSuggestions();
		}
	}, [chatAtom, updateAtomOnAcceptSuggestions]);

	const onPrompt = useCallback(
		(userPrompt: string) => {
			const atom = addChatMessage({
				userPrompt,
				type: `ontology-builder-${builderType}`,
				promptContext: { entityName, existingYaml, parsedYaml: existingYaml ? yaml.load(existingYaml) : undefined },
				onAcceptSuggestions,
			});
			setChatAtom(atom);
		},
		[addChatMessage, entityName, existingYaml, builderType, onAcceptSuggestions]
	);

	return (
		<AskAIChatPopover
			onPrompt={onPrompt}
			reportEvent={`ask-ai-ontology-builder-${builderType}-submit`}
			builderType={builderType}
			{...popoverProps}
		/>
	);
}
