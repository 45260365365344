import Flex from '@components/Flex';
import Typography from '@components/Typography';

export function Title({
	onAction,
	text,
	showAction = false,
	actionText = 'Clear all',
}: {
	onAction?: () => void;
	text: string;
	showAction?: boolean;
	actionText?: string;
}) {
	return (
		<Flex justifyContent={'space-between'} alignItems={'center'} paddingBottom={'12px'}>
			<Typography variant={'DesktopH10Regular'} color={'gray.900'}>
				{text}
			</Typography>
			{showAction && (
				<Typography
					variant={'DesktopH10Regular'}
					color={'gray.700'}
					_hover={{ color: 'gray.900' }}
					onClick={onAction}
					cursor={'pointer'}
				>
					{actionText}
				</Typography>
			)}
		</Flex>
	);
}
