import Modal from '@components/Modal';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16 } from '@icons/index';
import colors from 'src/style/colors';
import Typography from '../../Typography';
import { DataConnectorInfo } from './types';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { useReportEvent } from 'src/services/analytics';
import { SIGHTFULL_FRONTEGG_ROLES, useFronteggUsersRoles } from 'src/common/hooks/fetching/useFronteggUserRoles';
import { useMemo } from 'react';
import { getUsersByRole } from 'src/common/utils/users';

export function DataConnectorsPermissionModal({
	isOpen,
	onClose,
	selectedConnector,
}: {
	isOpen: boolean;
	onClose: () => void;
	selectedConnector?: DataConnectorInfo | null;
}) {
	const { reportEvent } = useReportEvent({ feature: 'Connectors' });

	const { usersWithRoles = [] } = useFronteggUsersRoles();

	const adminUsersEmails = useMemo(
		() => getUsersByRole(usersWithRoles, [SIGHTFULL_FRONTEGG_ROLES.Admin])?.map((user) => user.email),
		[usersWithRoles]
	);

	if (!selectedConnector || !adminUsersEmails) return;

	const mailToLink = `mailto:${adminUsersEmails.join(',')}?subject=Adding%20${capitalizedFirstLetter(
		selectedConnector?.name
	)}%20Connector%20to%20Sightfull&body=Hi%2C%0A%0AI%E2%80%99d%20like%20to%20add%20a%20${capitalizedFirstLetter(
		selectedConnector?.name
	)}%20connector%20to%20our%20Sightfull%20account.%20Could%20you%20please%20assist%20with%20the%20setup%3F%0A%0AThank%20you.`;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			maxWidth={'492px'}
			isCentered={true}
			borderRadius={'8px'}
			closeOnEsc={true}
		>
			<Flex padding={'16px 16px 0px 16px'}>
				<Flex justifyContent={'space-between'} width={'100%'} maxW={'315px'} alignItems={'center'}>
					<Button isIconOnly={true} onClick={onClose} variant="outline" size={'inline'} colorScheme={'gray'}>
						<CloseLarge16 color={colors.gray['900']} />
					</Button>
				</Flex>
			</Flex>
			<Flex padding={'24px 32px 32px 32px'}>
				<Flex flexDirection={'column'} padding={'0px 20px 16px 20px'}>
					<Typography textAlign={'center'} variant={'DesktopH5'} color={'gray.1000'}>
						You don’t have permissions to add a new data source.
					</Typography>

					<Typography marginTop={'12px'} textAlign={'center'} variant={'Paragraph14R'} color={'gray.900'}>
						<Typography variant={'Paragraph14SB'}>Contact your admin</Typography> to let them know you’re interested in
						adding this connector to your account.
					</Typography>
					<Flex paddingTop={'32px'} width={'100%'} gap={'12px'} justifyContent={'center'} alignItems={'center'}>
						<Button
							colorScheme={'blue'}
							variant={'solid'}
							borderRadius={'8px'}
							onClick={() => {
								reportEvent({
									event: 'connectors-view-only-contact-admin',
									metaData: {
										name: selectedConnector?.name,
									},
								});
								window.location.href = mailToLink;
							}}
						>
							Contact your admin
						</Button>
						<Button
							variant="ghost"
							colorScheme={'gray'}
							color={colors.gray[1000]}
							borderRadius={'8px'}
							onClick={onClose}
						>
							Cancel
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Modal>
	);
}
