import Modal from '@components/Modal';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16 } from '@icons/index';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import {
	ConfigNewMockDestinationMutation,
	ConfigNewMockDestinationMutationVariables,
	ConfigNewMockSourceMutation,
	ConfigNewMockSourceMutationVariables,
	ConfigNewSourceMutation,
	ConfigNewSourceMutationVariables,
	useConnectorCreationRequirementsLazyQuery,
} from 'src/generated/graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from '../../Spinner';
import { DataConnectorInfo, UpdatedFieldsType } from './types';
import { Image } from '@chakra-ui/react';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { useReportEvent } from 'src/services/analytics';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import {
	parseArrayIntoObject,
	setMockDestinationNameToLocalStorage,
	setTimeValueToLocalStorage,
	testConnectorsValue,
} from './utils';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { NameField } from '../../NameField';
import useMutation from '@hooks/fetching/useMutation';
import { ConfigNewMockDestination, ConfigNewMockSource, ConfigNewSource } from 'src/queries/hertzl';
import useToast from 'src/common/hooks/ui/useToast';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME } from './constants';
import { TestIDs } from 'src/common/types/test-ids';

const MOCK_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export function DataConnectorsDynamicModal({
	isOpen,
	onClose,
	selectedConnector,
	onAddConnectorError,
}: {
	isOpen: boolean;
	onClose: () => void;
	selectedConnector?: DataConnectorInfo | null;
	onAddConnectorError?: VoidFunction;
}) {
	const dataConnectorsPlaygroundEnabled = useFeatureFlag('pulse.sightfull2.playground.enable');

	const { reportEvent } = useReportEvent({ feature: 'Connectors' });
	const toast = useToast();

	const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
	const [updatedFieldsValues, setUpdatedFieldsValues] = useState<UpdatedFieldsType[]>([]);
	const [fetchCreationRequirements, { data: creationRequirements, loading: isLoadingRequirements }] =
		useConnectorCreationRequirementsLazyQuery();
	const [configSource, { loading: isConfigNewSourceLoading }] = useMutation<
		ConfigNewSourceMutation,
		ConfigNewSourceMutationVariables
	>(ConfigNewSource);

	const [configMockSource, { loading: isConfigNewMockSourceLoading }] = useMutation<
		ConfigNewMockSourceMutation,
		ConfigNewMockSourceMutationVariables
	>(ConfigNewMockSource);

	const [configMockDestination, { loading: isConfigMockLoading }] = useMutation<
		ConfigNewMockDestinationMutation,
		ConfigNewMockDestinationMutationVariables
	>(ConfigNewMockDestination);

	const requiredFields = creationRequirements?.connectorCreationRequirements?.requiredFields;
	const serviceId = selectedConnector?.id;
	const modalIsLoading =
		isLoadingRequirements || isConfigNewSourceLoading || isConfigNewMockSourceLoading || isConfigMockLoading;

	const closeAndReset = () => {
		onClose();
		setFieldsWithError([]);
	};

	const validateField = useCallback((value: string, fieldTitle: string) => {
		setFieldsWithError((prevErrors) =>
			testConnectorsValue(value)
				? prevErrors.filter((field) => field !== fieldTitle)
				: prevErrors.includes(fieldTitle)
				? prevErrors
				: [...prevErrors, fieldTitle]
		);
	}, []);

	const testRequiredFields = useDebouncedCallback(validateField, 500);

	useEffect(() => {
		if (serviceId)
			fetchCreationRequirements({
				fetchPolicy: 'no-cache',
				variables: { serviceId },
			}).catch(() => toast({ variant: 'error', message: MOCK_ERROR_MESSAGE }));
	}, [fetchCreationRequirements, serviceId, toast]);

	useEffect(
		() =>
			requiredFields &&
			setUpdatedFieldsValues(
				requiredFields.map((requiredField) => ({ id: requiredField.title, value: requiredField.defaultValue || '' }))
			),
		[requiredFields]
	);

	const onUpdateField = useCallback(
		(value: string, fieldTitle: string) => {
			setUpdatedFieldsValues((prevState) =>
				prevState?.map((field) => (field.id === fieldTitle ? { ...field, value } : field))
			);
			testRequiredFields(value, fieldTitle);
		},
		[testRequiredFields]
	);

	const isSubmitDisabled = useMemo(
		() => !!updatedFieldsValues?.some((field) => !field.value) || !!fieldsWithError.length,
		[updatedFieldsValues, fieldsWithError]
	);

	const onError = (err: Error) => {
		toast({
			variant: 'error',
			message: (
				<Typography textAlign={'center'} variant="Paragraph14R" color="gray.1000">
					{err.message}
				</Typography>
			),
			duration: 5000,
		});
		if (!err.message.includes('already exist')) onAddConnectorError?.();
	};

	const onCreate = async () => {
		if (!serviceId) return;
		reportEvent({
			event: 'connectors-required-fields-modal-create',
			metaData: {
				name: selectedConnector?.name,
			},
		});

		const requestData = {
			variables: {
				dataIntegrator: 'fivetran',
				source: serviceId,
				metadata: parseArrayIntoObject(updatedFieldsValues),
			},
		};

		if (dataConnectorsPlaygroundEnabled) {
			try {
				setTimeValueToLocalStorage(serviceId, FIVETRAN_FLOW_NOT_COMPLETE_STORAGE_NAME);
				const configMockDestinationResult = await configMockDestination();
				setMockDestinationNameToLocalStorage(
					configMockDestinationResult.data?.configNewMockDestination.innerDestinationName
				);
			} catch (error) {
				if (error instanceof Error) {
					onError(error);
					closeAndReset();
				}
				return;
			}
		}

		const configMutationRequest = dataConnectorsPlaygroundEnabled ? configMockSource : configSource;
		configMutationRequest(requestData)
			.then((res) => {
				if (!res.data) return;
				const newSource =
					dataConnectorsPlaygroundEnabled && 'configNewMockSource' in res.data
						? res.data.configNewMockSource
						: 'configNewSource' in res.data
						? res.data.configNewSource
						: null;
				const redirectURL = newSource?.integratorMetadata?.connectionCardUrl;
				if (redirectURL) window.location.href = redirectURL;
			})
			.catch(onError)
			.finally(closeAndReset);
	};

	const requiredFieldsArray = useMemo(
		() =>
			requiredFields?.map((field, index) => {
				const isInvalid = fieldsWithError.includes(field.title);
				const fieldValue = updatedFieldsValues.find((updatedField) => updatedField.id === field.title)?.value;
				return (
					<Box marginTop={index > 0 ? '20px' : 0} key={field.title}>
						<NameField
							isWithDefaultText={false}
							placeholder={field?.placeHolder || ''}
							onBlur={() => {
								reportEvent({
									event: 'connectors-required-fields-modal-input-provided',
									metaData: {
										name: selectedConnector?.name,
										fieldName: `${capitalizedFirstLetter(field.title)} name`,
										input: field.defaultValue,
									},
								});
							}}
							isInvalid={isInvalid}
							label={`${removeUnderscoresAndCapitalize(field.title)} name`}
							onNameUpdate={(value) => onUpdateField(value, field.title)}
							value={fieldValue}
						/>
						{isInvalid && (
							<Typography marginTop={'12px'} variant="Paragraph14R" color={'red.800'}>
								{capitalizedFirstLetter(field.title)} name shouldn&apos;t be empty, should contain only lowercase
								letters, numbers, or underscores, and cannot begin with a number.
							</Typography>
						)}
						<Typography marginTop={'8px'} variant={'Paragraph12R'} color={colors.gray[700]}>
							{field.description}
						</Typography>
					</Box>
				);
			}),
		[fieldsWithError, reportEvent, requiredFields, selectedConnector?.name, onUpdateField, updatedFieldsValues]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeAndReset}
			closeOnOverlayClick={false}
			maxWidth={'492px'}
			isCentered={true}
			borderRadius={'8px'}
			closeOnEsc={true}
		>
			<Flex padding={'16px'} borderBottom={`1px solid ${colors.gray['300']}`}>
				<Flex justifyContent={'space-between'} width={'100%'} maxW={'315px'} alignItems={'center'}>
					<Button isIconOnly={true} onClick={closeAndReset} variant="outline" size={'inline'} colorScheme={'gray'}>
						<CloseLarge16 color={colors.gray['900']} />
					</Button>
					<Flex>
						<Typography variant={'DesktopH7Medium'} color={'gray.1000'}>
							Connect data source
						</Typography>
					</Flex>
				</Flex>
			</Flex>
			{modalIsLoading ? (
				<Flex width={'100%'} height={'492px'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
					<Spinner thickness="3px" color="blue.600" size={'60px'} />
				</Flex>
			) : (
				<>
					<Flex padding={'32px'} flexDirection={'column'}>
						<Flex padding={'12px'} width={'fit-content'} backgroundColor={colors.gray[200]} borderRadius={'8px'}>
							<Image
								onError={(e) => {
									if (e.target instanceof HTMLImageElement) e.target.src = PLACEHOLDER_ICON;
								}}
								src={selectedConnector?.icon}
								maxWidth={'40px'}
								height={'40px'}
								width={'100%'}
							/>
						</Flex>
						<Flex marginTop={'24px'} marginBottom={'20px'} gap={'6px'} flexDirection={'column'} alignItems={'start'}>
							<Typography variant={'DesktopH7Medium'} color={colors.gray['1000']}>
								Connect {selectedConnector?.name}.
							</Typography>
						</Flex>
						{requiredFieldsArray}
					</Flex>
					<Flex
						padding={'16px 32px'}
						borderTop={`1px solid ${colors.gray[300]}`}
						width={'100%'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Button variant="outline" colorScheme={'black'} borderRadius={'8px'} onClick={closeAndReset}>
							Cancel
						</Button>
						<Button
							isDisabled={isSubmitDisabled}
							colorScheme={'blue'}
							variant={'solid'}
							borderRadius={'8px'}
							onClick={onCreate}
							data-testid={TestIDs.DYNAMIC_MODAL_CREATE_BUTTON}
						>
							Create
						</Button>
					</Flex>
				</>
			)}
		</Modal>
	);
}
