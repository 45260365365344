import Button from '@components/Button';
import Flex from '@components/Flex';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import { useCallback } from 'react';

export type ActionChipProps = {
	children: React.ReactNode;
	icon?: React.ReactElement;
	onClick: () => void;
	testId: string;
	metadata?: { [key: string]: any };
};

export function ActionChip({ children, onClick, icon, testId, metadata }: ActionChipProps) {
	const { reportAskAIEvent } = useReportAIEvent();

	const onActionClick = useCallback(() => {
		reportAskAIEvent({
			event: `ask-ai-action-chip-${testId}-click`,
			metaData: metadata,
		});
		onClick();
	}, [metadata, onClick, reportAskAIEvent, testId]);

	return (
		<Button
			color={'gray.1000'}
			leftIcon={icon}
			colorScheme={'gray'}
			borderRadius={'44px'}
			size={'small'}
			onClick={onActionClick}
			testId={`ask-ai-action-chip-${testId}`}
			variant={'ghost'}
		>
			{children}
		</Button>
	);
}

export function ActionChipsGroup({ children }: { children: React.ReactNode }) {
	return (
		<Flex gap={'16px'} padding={'0 16px'}>
			{children}
		</Flex>
	);
}
