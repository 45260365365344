import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import classes from '../../SignalPage.module.scss';
import { ShareSignalModal } from 'src/pages/MetricPage/components/ShareSignalModal/ShareSignalModal';
import Button from 'src/common/components/Button';
import { ChevronDown16, Ok8 } from 'src/common/components/Icons';
import { AccessController } from 'src/common/components/AccessController';
import { Permission } from 'src/types/environment';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

type ButtonType = {
	isDuplicateSignal?: boolean;
	description: string;
	title: string;
	metricNameWithFlavor: string;
	oneOfPermissionsIsMatched: Permission[];
	isParamsChanged: boolean;
	isDuplicateLoading: boolean;
	onSaveClicked: VoidFunction;
	isLoading: boolean;
	onSaveAsNew: VoidFunction;
	onCloseClicked: VoidFunction;
};

export function SaveButton({
	isDuplicateSignal,
	description,
	title,
	metricNameWithFlavor,
	oneOfPermissionsIsMatched,
	isParamsChanged,
	isDuplicateLoading,
	onSaveClicked,
	isLoading,
	onSaveAsNew,
	onCloseClicked,
}: ButtonType) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { errorMessage } = useMetricDerivedState();

	const saveButtonLogic = isParamsChanged ? (
		<Tooltip backgroundColor={'black'} size={'md'} variant="fluid" marginTop="4px" label={`Save changes`}>
			<Button
				isDisabled={isDuplicateLoading || !!errorMessage}
				className={classes.blueButton}
				variant="solid"
				onMouseDown={(e) => {
					e.preventDefault();
					onSaveClicked();
				}}
				size="inline"
				isLoading={isLoading}
			>
				Save
			</Button>
		</Tooltip>
	) : (
		<Button colorScheme="black" isDisabled variant="solid" size="inline" leftIcon={<Ok8 />}>
			Saved
		</Button>
	);

	const saveAsNewButton = (
		<Box marginLeft={'12px'}>
			<Tooltip
				backgroundColor={'black'}
				size={'md'}
				variant="fluid"
				marginTop="4px"
				label={`Save a copy of this signal on this space.`}
			>
				<Button
					isDisabled={!!errorMessage}
					isLoading={isDuplicateLoading}
					colorScheme="gray"
					variant="ghost"
					onClick={onSaveAsNew}
					size="inline"
					color={'gray.1000'}
				>
					Save as new
				</Button>
			</Tooltip>
		</Box>
	);

	const logicalButtons = isDuplicateSignal ? (
		<Tooltip backgroundColor={'black'} size={'md'} variant="fluid" marginTop="4px" label={`Publish this signal.`}>
			<ShareSignalModal
				description={description}
				isModalOpen={isModalOpen}
				onModalClose={() => setIsModalOpen(false)}
				metricTitle={title || metricNameWithFlavor}
				headerName="Signal"
			>
				<Button
					isDisabled={!!errorMessage}
					eventName="start-signal-draft"
					eventMetadata={{ feature: 'Signal header' }}
					className={classes.blueButton}
					variant="solid"
					size="inline"
					onClick={() => setIsModalOpen(true)}
					rightIcon={<ChevronDown16 />}
				>
					Publish
				</Button>
			</ShareSignalModal>
		</Tooltip>
	) : (
		<AccessController
			logic="OR"
			permission={oneOfPermissionsIsMatched}
			noAccessChild={
				<Tooltip
					backgroundColor={'black'}
					size={'md'}
					variant="fluid"
					marginTop="4px"
					label={'Save a duplicate of this signal to another space.'}
				>
					<ShareSignalModal
						description={description}
						isModalOpen={isModalOpen}
						onModalClose={() => setIsModalOpen(false)}
						metricTitle={title || metricNameWithFlavor}
						headerName="Signal"
					>
						<Button
							isDisabled={!!errorMessage}
							eventName="signal-save-as-new-clicked"
							eventMetadata={{ feature: 'Signal header', isViewOnly: false }}
							className={classes.blueButton}
							variant="solid"
							onClick={() => setIsModalOpen(true)}
							size="inline"
							rightIcon={<ChevronDown16 />}
						>
							Save as new
						</Button>
					</ShareSignalModal>
				</Tooltip>
			}
		>
			{saveButtonLogic}
		</AccessController>
	);

	return (
		<>
			<Box marginLeft={'12px'}>{logicalButtons}</Box>

			{!isDuplicateSignal && (
				<>
					<AccessController logic="OR" permission={oneOfPermissionsIsMatched}>
						{saveAsNewButton}
					</AccessController>
				</>
			)}

			<Button color={'gray.1000'} variant="outline" colorScheme="gray" onClick={onCloseClicked} size="inline">
				Close
			</Button>
		</>
	);
}
