import { ParameterValues } from '../../../../../generated/graphql';
import { Option } from '../../../SearchDropdown';

export function getListOptions(values?: ParameterValues | null): Option[] | undefined {
	if (values?.__typename === 'ListValues') {
		return values?.listValues.map((listValue) => ({
			title: listValue.label ?? listValue.value,
			value: listValue.value,
		}));
	}
}
