import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { useAskAIChatMessages } from './hooks/useAskAIChatMessages';
import { useEffect } from 'react';
import { AIAgentsContextBoundary } from './hooks/useAIAgents';
import { AskAIGray24 } from '../Icons';

type AskAIInputAsButtonProps = {
	onClick: () => void;
};

export function AskAIInputAsButton({ onClick }: AskAIInputAsButtonProps) {
	const { resetChatMessages } = useAskAIChatMessages();

	useEffect(() => {
		return () => resetChatMessages();
	}, [resetChatMessages]);

	return (
		<AIAgentsContextBoundary>
			<Flex
				backgroundColor={'natural.100'}
				alignItems={'center'}
				justifyContent={'center'}
				padding={'8px 24px'}
				position={'sticky'}
				top={0}
				left={0}
			>
				<Flex
					flex={1}
					backgroundColor={'white'}
					alignItems={'center'}
					justifyContent={'space-between'}
					padding={'6px 7px 6px 6px'}
					borderRadius={'58px'}
					border={'1px solid'}
					borderColor={'gray.300'}
					onClick={onClick}
					cursor={'pointer'}
					_hover={{
						borderColor: 'gray.500',
					}}
				>
					<Flex alignItems={'center'} gap={'4px'}>
						<AskAIGray24 />
						<Typography variant={'DesktopH8Regular'} color={'gray.600'}>
							Ask Sightfull AI
						</Typography>
					</Flex>
				</Flex>
			</Flex>
		</AIAgentsContextBoundary>
	);
}
