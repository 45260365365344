import Flex from '@components/Flex';
import Box from '@components/Box';
import { SightfullAIBuilderSmall } from '@components/Icons';
import Typography from '@components/Typography';
import { AskAIInput } from 'src/common/components/AskAI/AskAIInput';
import { MetricType } from '../../../../../../common/types/common';
import { MetricBuilderAIAgentSuggestion, useMetricBuilderAIAgent } from '../../../../hooks/useMetricBuilderAIAgent';
import { useCallback } from 'react';
import { useMetricEditorState } from '../../../../hooks/useMetricEditorState';
import { useMetricDerivedState } from '../../../../hooks/useMetricDerivedState';

function getLatestPrompt(latest?: MetricBuilderAIAgentSuggestion): string | undefined {
	if (latest?.op.create) return latest.op.create.prompt;
}

export function AIMetricPromptCard({ metricType }: { metricType: MetricType }) {
	const {
		isMetricBuilderAIAgentEnabled,
		requestExplicitMetricDefinitionSuggestion,
		isBusy,
		latestMetricBuilderSuggetion,
	} = useMetricBuilderAIAgent();

	const { latestEditorValue } = useMetricEditorState();
	const { metricNameWithFlavor } = useMetricDerivedState();

	const onSubmit = useCallback(
		(prompt: string) => {
			// add to the prompt the current metric definition only if we haven't already
			// started a thread and have suggestions available.
			// this is to support going into "edit" mode of an existing metric and asking
			// AI to make changes
			const definition = !latestMetricBuilderSuggetion ? latestEditorValue : undefined;
			requestExplicitMetricDefinitionSuggestion({ prompt, metricType, definition });
		},
		[latestEditorValue, latestMetricBuilderSuggetion, metricType, requestExplicitMetricDefinitionSuggestion]
	);

	if (!isMetricBuilderAIAgentEnabled) return null;

	return (
		<Box borderRadius={'8px'} padding={'16px'} backgroundColor={'natural.100'} marginBottom={'12px'}>
			<Flex direction={'column'} alignItems={'start'} gap={'8px'}>
				<Flex justifyContent={'space-between'} width={'100%'}>
					<SightfullAIBuilderSmall />
				</Flex>
				<Box>
					<Typography variant={'Paragraph14R'} color={'gray.900'} textAlign={'left'}>
						Ask Sightfull AI to help you refine your metric and it will provide you with suggestions.
					</Typography>
				</Box>
				<Box width={'100%'} marginTop={'8px'}>
					<AskAIInput
						placeholder={'Ask AI to refine your metric'}
						value={getLatestPrompt(latestMetricBuilderSuggetion)}
						isDisabled={isBusy}
						hasBorder={true}
						autoFocus={false}
						borderRadius={'32px'}
						submitButtonBorderRadius={'26px'}
						clearInputOnSubmit={false}
						onSubmit={onSubmit}
						size={'sm'}
						reportEvent={'ai-copilot-card-prompt'}
						reportFeature={'AI Copilot'}
						reportMetadata={{ metricType, metricName: metricNameWithFlavor }}
					/>
				</Box>
			</Flex>
		</Box>
	);
}
