import { Box, Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { InputStringEditor } from '../../Editors/InputStringEditor';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterTwoNumbersEditor({ filter, onApply, prefixComponent }: ValueEditorProps) {
	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<Flex flexDir={'row'} alignItems={'center'}>
				<Box flex={1}>
					<InputStringEditor
						value={filter.values[0]?.toString() || ''}
						onApply={(value: string) => {
							onApply([value, filter.values[1] ?? null]);
						}}
						type="number"
						hasLeftPadding={false}
						defaultIsEditing={filter.values.filter((s) => s != '').length === 0}
						key={`filter-input-two-number-editor-1-${filter.key}-${filter.operator}`}
					/>
				</Box>
				<Typography variant={'DesktopH10Regular'} color={colors.gray[600]} px={'8px'}>
					and
				</Typography>

				<Box flex={1}>
					<InputStringEditor
						value={filter.values[1]?.toString() || ''}
						onApply={(value: string) => {
							onApply([filter.values[0] ?? null, value]);
						}}
						type="number"
						hasLeftPadding={false}
						defaultIsEditing={filter.values.filter((s) => s != '').length === 1}
						key={`filter-input-two-number-editor-2-${filter.key}-${filter.operator}`}
					/>
				</Box>
			</Flex>
		</EditableValueContainer>
	);
}
