import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps, Flex } from '@chakra-ui/react';
import { TooltipChevronDirectionType, TooltipLayoutType, TooltipSizeType } from '@components/Tooltip/types';
import Typography from '@components/Typography';
import { TypographyVariant } from '@components/Typography/types';
import { CSSProperties, ReactNode } from 'react';

export type TooltipProps = {
	testId?: string;
	size: TooltipSizeType;
	variant?: TooltipLayoutType;
	placement?: TooltipChevronDirectionType;
	label?: ReactNode;
	title?: string;
	children: React.ReactNode;
	wrapperStyle?: CSSProperties;
} & Omit<ChakraTooltipProps, 'children' | 'label' | 'variant' | 'size' | 'placement'>;

function Tooltip({
	children,
	label,
	title,
	size,
	variant = 'primary',
	hasArrow = true,
	placement = 'bottom',
	testId,
	openDelay = 500,
	closeDelay,
	marginLeft,
	marginTop,
	cursor,
	shouldWrapChildren = true,
	wrapperStyle,
	margin = '8px',
	...props
}: TooltipProps) {
	const typographyVariant: TypographyVariant = size === 'md' && !title ? 'Paragraph14R' : 'Paragraph12R';

	if (!label) {
		return <>{children}</>;
	}

	let LabelContent = <Typography variant={typographyVariant}>{label}</Typography>;
	if (title) {
		LabelContent = (
			<Flex flexDirection="column">
				<Typography variant="Disclaimer12SB">{title}</Typography>
				{LabelContent}
			</Flex>
		);
	}

	return (
		<ChakraTooltip
			openDelay={openDelay}
			closeDelay={closeDelay}
			variant={variant}
			size={size}
			placement={placement}
			data-testid={testId}
			hasArrow={hasArrow}
			marginLeft={marginLeft}
			label={LabelContent}
			marginTop={marginTop}
			margin={margin}
			{...props}
		>
			{shouldWrapChildren ? (
				<Flex cursor={cursor} style={wrapperStyle}>
					{children}
				</Flex>
			) : (
				children
			)}
		</ChakraTooltip>
	);
}

export default Tooltip;
