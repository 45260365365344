import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import Typography from '@components/Typography';
import * as CSS from 'csstype';
import { ReactNode } from 'react';
import { TypographyVariant } from '../Typography/types';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import { CheckboxCheck10, CheckboxSquare16 } from '../Icons';

export type CheckboxVariant = 'primary' | 'rounded';
export type CheckboxSize = 'md' | 'sm' | 'xs';

export interface CheckboxProps {
	testId?: string;
	label?: string | ReactNode;
	value?: string;
	isDisabled?: boolean;
	isChecked?: boolean; // providing this makes this controlled component! amazing
	defaultChecked?: boolean;
	isIntermediate?: boolean;
	isInvalid?: boolean;
	checkboxColor?: CSS.Property.Color;
	onChange?: (isChecked: boolean, value: string) => void;
	size?: CheckboxSize;
	mr?: CSS.Property.MarginRight;
	textAlign?: CSS.Property.TextAlign;
	pointerEvents?: ResponsiveValue<CSS.Property.PointerEvents>;
	isFluid?: boolean;
	labelColor?: CSS.Property.Color;
	intermediateCustomIcon?: ReactNode;
	variant?: CheckboxVariant;
}

function CustomIcon({
	isIntermediate,
	isChecked,
	intermediateCustomIcon,
}: Pick<CheckboxProps, 'isChecked' | 'isIntermediate' | 'intermediateCustomIcon'>) {
	if (!isChecked && !isIntermediate) return <></>;

	if (isIntermediate) {
		if (intermediateCustomIcon) {
			return intermediateCustomIcon;
		}
		return <CheckboxSquare16 />;
	}

	return <CheckboxCheck10 />;
}

export function Checkbox({
	label,
	testId,
	isInvalid,
	isDisabled = false,
	isChecked = false,
	isIntermediate = false,
	onChange,
	checkboxColor = 'white',
	size = 'md',
	mr,
	textAlign,
	pointerEvents = 'auto',
	isFluid,
	labelColor,
	intermediateCustomIcon,
	variant,
	value,
}: CheckboxProps) {
	const textVariantBySize: { [key in CheckboxSize]: TypographyVariant } = {
		md: 'DesktopH7Regular',
		sm: 'DesktopH8Regular',
		xs: 'DesktopH10Regular',
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		e.preventDefault();

		onChange?.(e.target.checked, value ?? label?.toString() ?? '');
	};

	return (
		<ChakraCheckbox
			className={isFluid ? 'isFluid' : ''}
			isChecked={isChecked}
			isDisabled={isDisabled}
			isIndeterminate={isIntermediate}
			isInvalid={isInvalid}
			data-testid={testId}
			variant={variant ?? 'primary'}
			colorScheme={checkboxColor}
			spacing="8px"
			onChange={handleChange}
			size={size}
			iconSize="10px"
			mr={mr}
			textAlign={textAlign}
			pointerEvents={pointerEvents}
			icon={
				<CustomIcon
					isIntermediate={isIntermediate}
					isChecked={isChecked}
					// TODO: remove this prop after redesigning the checkbox'
					intermediateCustomIcon={intermediateCustomIcon}
				/>
			}
		>
			{label && (
				<Typography variant={textVariantBySize[size]} noOfLines={1} textOverflow="ellipsis" color={labelColor}>
					{label}
				</Typography>
			)}
		</ChakraCheckbox>
	);
}
