import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classes from './Markdown.module.scss';

export function Markdown({ message, children }: { message?: string; children?: string }) {
	return (
		<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.markdown}>
			{message || children || ''}
		</ReactMarkdown>
	);
}
