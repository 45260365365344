import { Box, BoxProps } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { isEvent, isScrollEvent } from 'src/common/components/Scrollable/typeguards';

function useAutocompleteAlignmentScrollPosition() {
	const [scrollPosition, setScrollPosition] = useState(0);

	const onScroll = useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		if (isEvent(event) && isScrollEvent(event)) setScrollPosition(event.target.scrollTop);
	}, []);

	return { scrollPosition, onScroll };
}

export function AutocompleteAlignmentProvider({ children, ...boxProps }: BoxProps) {
	const { scrollPosition, onScroll } = useAutocompleteAlignmentScrollPosition();

	return (
		<Box
			{...boxProps}
			onScroll={onScroll}
			style={{ '--suggestTranslate': `-${scrollPosition}px` } as React.CSSProperties}
		>
			{children}
		</Box>
	);
}
