import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonTableLayout.module.scss';

const { mainTitleSkeleton, tableSkeleton } = classes;

function Titles() {
	return (
		<Flex flexDirection="column" justifyContent="flex-start" marginBottom="16px">
			<Skeleton className={mainTitleSkeleton} />
		</Flex>
	);
}

function Table() {
	return <Skeleton className={tableSkeleton} />;
}

export function SkeletonTableLayout() {
	return (
		<Box marginTop={'10px'} height={'100%'} width="100%">
			<Flex justifyContent="space-between" alignItems={'center'} marginBottom="16px" height={'32px'}>
				<Titles />
			</Flex>
			<Flex marginBottom="32px" height={'700px'}>
				<Table />
			</Flex>
		</Box>
	);
}
