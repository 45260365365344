import Box from '@components/Box';
import { ConfirmationModal } from '@components/ConfirmationModal';
import Typography from '@components/Typography';
import useToast from '@hooks/ui/useToast';
import { useReportEvent } from 'src/services/analytics';
import { CategoryV2 } from 'src/types/metric';
import { MetricCatalogV2Query, useDeleteCategoryMutation } from 'src/generated/graphql';
import { useInvalidateCache } from '@services/apollo';
import { MetricCatalogV2 } from 'src/queries/core-reader';

export function DeleteCategoryModal({
	isOpen,
	category,
	onClose,
}: {
	isOpen: boolean;
	category: CategoryV2;
	onClose: () => void;
}) {
	const { name: categoryName, id: categoryId } = category;
	const [deleteCategory, { loading: isDeleteCategoryLoading }] = useDeleteCategoryMutation();
	const { reportEvent } = useReportEvent();
	const toast = useToast();
	const { invalidateCache } = useInvalidateCache();

	const reportModalChoice = ({ action }: { action: 'confirm' | 'cancel' }) => {
		reportEvent({
			event: 'category-delete-modal',
			metaData: { categoryName, action, page: 'Metric catalog' },
		});
	};

	const onDeleteCategory = () => {
		reportModalChoice({ action: 'confirm' });
		deleteCategory({
			variables: {
				category_id: categoryId,
			},
			optimisticResponse: {
				update_metrics_v2: {
					returning: [],
					__typename: 'metrics_v2_mutation_response',
				},
				delete_metric_categories_v2: {
					returning: [
						{
							category: categoryName,
							id: 'someID',
							__typename: 'metric_categories_v2',
						},
					],
					__typename: 'metric_categories_v2_mutation_response',
				},
			},
			update: (cache, { data }) => {
				const catalogFromCache = cache.readQuery<MetricCatalogV2Query>({
					query: MetricCatalogV2,
				});
				const deletedCategory = data?.delete_metric_categories_v2?.returning?.[0];

				if (!catalogFromCache || !deletedCategory) return;

				cache.writeQuery({
					query: MetricCatalogV2,
					data: {
						metricCatalogV2: {
							categories: catalogFromCache.metricCatalogV2.categories.filter(
								(category) => category.categoryId !== deletedCategory?.id
							),
						},
					},
				});
			},
		})
			.then(() => {
				toast({
					variant: 'ok',
					message: (
						<>
							<Typography variant="Paragraph14SB" color="gray.1000">
								{categoryName}&nbsp;
								<Typography variant="Paragraph14R" color="gray.1000">
									was successfully deleted.
								</Typography>
							</Typography>
						</>
					),
				});
			})
			.catch(() => {
				invalidateCache();

				toast({
					variant: 'error',
					message: (
						<>
							<Typography variant="Paragraph14R" color="gray.1000">
								{'Failed to delete '}
								<Typography variant="Paragraph14SB" color="gray.1000">
									{categoryName}
								</Typography>
								.
							</Typography>
						</>
					),
				});
			})
			.finally(() => {
				onClose();
			});
	};

	const onCancelDelete = () => {
		onClose();
		reportModalChoice({ action: 'cancel' });
	};

	return (
		<ConfirmationModal
			primaryButtonLabel={'Delete'}
			isOpen={isOpen}
			modalText={
				<>
					The&nbsp;
					<Box display={'inline-block'} fontWeight={'600'}>
						{categoryName}
					</Box>
					&nbsp;category will be permanently deleted from your catalog and associated dashboards.
				</>
			}
			modalTitle={'Delete category.'}
			onSubmit={onDeleteCategory}
			onClose={onCancelDelete}
			isLoading={isDeleteCategoryLoading}
			submitColorScheme={'red'}
		/>
	);
}
