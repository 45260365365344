import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

export type EditableValueContainerProps = {
	prefixComponent?: React.ReactNode;
	hasLeftPadding?: boolean;
	children: React.ReactNode;
};

export function EditableValueContainer({
	prefixComponent,
	children,
	hasLeftPadding = true,
}: EditableValueContainerProps) {
	const { isLoading: isRulesEngineLoading } = useMetricDerivedState();

	return (
		<Flex color={'gray.1000'} pl={hasLeftPadding ? '26px' : '0'} alignItems={'center'} flexDir={'row'}>
			{prefixComponent}
			<Box flex={1} overflowX={'hidden'}>
				{isRulesEngineLoading ? <Skeleton height={'32px'} /> : children}
			</Box>
		</Flex>
	);
}
