import { Box } from '@chakra-ui/react';
import Center from '@components/Center';
import Typography from '@components/Typography';
import Select from 'src/common/components/Select';
import { useModal } from 'src/common/hooks/ui/useModal';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { supportedOperatorDisplayNames } from './constants';

export const Operator = ({ operator }: { operator: string }) => {
	return (
		<Center padding={'6px 8px'} cursor={'default'}>
			<Typography variant={'DesktopH10Medium'} color={colors.gray[1000]}>
				{operator}
			</Typography>
		</Center>
	);
};

export const OperatorSelect = ({
	operator,
	availableOperators,
	type,
	dimension,
	onSelectOperator,
}: {
	operator: SupportedOperators;
	availableOperators: SupportedOperators[];
	type: SupportedDimensionTypes;
	dimension?: string;
	onSelectOperator: (operator: SupportedOperators) => void;
}) => {
	const { isOpen, onOpen, onClose } = useModal();
	const { reportEvent } = useReportEvent();
	if (availableOperators.length === 1) return <Operator operator={supportedOperatorDisplayNames[operator][type]} />;
	return (
		<Center paddingLeft={'8px'} cursor={'default'}>
			<div data-intercom-target="filter-operator" data-intercom-area={'metric'} data-intercom-type={'main'}>
				<Box
					paddingLeft={'8px'}
					paddingBottom={'6px'}
					paddingTop={'2px'}
					borderRadius={'8px'}
					_hover={{ backgroundColor: colors.blue[100] }}
					backgroundColor={isOpen ? colors.gray[200] : colors.transparent}
				>
					<Select<SupportedOperators>
						options={availableOperators.map((option) => ({
							label: supportedOperatorDisplayNames[option][type],
							value: option,
						}))}
						size={'button'}
						selectedOption={{
							label: supportedOperatorDisplayNames[operator][type],
							value: operator,
						}}
						onMenuOpen={() => {
							onOpen();
							reportEvent({
								event: 'filters-edit-operator',
								metaData: {
									feature: 'filters',
									application: 'metric investigation',
									operator: supportedOperatorDisplayNames[operator][type],
									'dimension-type': type,
									dimension,
								},
							});
						}}
						onMenuClose={onClose}
						menuIsOpen={isOpen}
						onChange={(selectedOption) => {
							reportEvent({
								event: 'filters-operator-selected',
								metaData: {
									feature: 'filters',
									application: 'metric investigation',
									operator: supportedOperatorDisplayNames[selectedOption.value][type],
									'dimension-type': type,
									dimension,
								},
							});
							onSelectOperator(selectedOption.value);
						}}
						color={colors.gray[1000]}
						isGhost={true}
					/>
				</Box>
			</div>
		</Center>
	);
};
