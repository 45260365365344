import { PrimitiveAtom } from 'jotai';
import { Metric, FormulaMetric } from '../../../lib/completions/semanticTypes/metrics.schema';
import { ExplicitDimension, Relationship } from '../../../lib/completions/semanticTypes/normalization.schema';
import { EntityDefinitionState } from '../../../lib/completions/semanticTypes';

export const CREATE_NEW_METRIC_INITIAL_PROMPT_QUERY_PARAM = 'initialPrompt';

export type AskAIChatFieldSuggestion = {
	label: string;
	propertyName: string;
	display?: string;
	value?: any;
	existingValue?: any;
};

export type DiscoverMetricsSuggested = {
	metric: string;
	period: string;
	breakdown?: string;
};
export type DiscoverMetricsResult = { reasoning: string; metrics?: DiscoverMetricsSuggested[]; examples?: string[] };
export type OntologyBuilderEntityResult = { entity: EntityDefinitionState };
export type OntologyBuilderDimensionResult = { dimension: ExplicitDimension };
export type OntologyBuilderRelationshipResult = { relationship: Relationship };

export type AskAIChatVote = 'up' | 'down';
export type AskAIOntologyBuilderSubType = 'entity' | 'dimension' | 'relationship';
export type AskAIChatMessageType =
	| 'metric-discovery'
	| 'ontology-builder-entity'
	| 'ontology-builder-dimension'
	| 'ontology-builder-relationship';

export interface AskAIChatMessage {
	type: AskAIChatMessageType;
	userPrompt: string;
	messageKey: string;
	promptContext?: Record<string, any>;
	onAcceptSuggestions?: (selectedFields: AskAIChatFieldSuggestion[]) => void;
	error?: boolean;
	errorText?: string;
	result?:
		| DiscoverMetricsResult
		| OntologyBuilderEntityResult
		| OntologyBuilderDimensionResult
		| OntologyBuilderRelationshipResult;
	completionTime?: number;
	vote?: AskAIChatVote;
}

export type AskAIChatCardProps = {
	chatMessageAtom: PrimitiveAtom<AskAIChatMessage>;
	chatIndex: number;
	cardMargin?: string;
	isLast?: boolean;
};

// Type guards
export function isAskAIMetricDiscoveryChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: DiscoverMetricsResult } {
	return message.type === 'metric-discovery';
}

export function isAskAIOntologyBuilderEntityChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderEntityResult } {
	return message.type === 'ontology-builder-entity';
}

export function isAskAIOntologyBuilderDimensionChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderDimensionResult } {
	return message.type === 'ontology-builder-dimension';
}

export function isAskAIOntologyBuilderRelationshipChatMessage(
	message: AskAIChatMessage
): message is AskAIChatMessage & { result?: OntologyBuilderRelationshipResult } {
	return message.type === 'ontology-builder-relationship';
}

// Agents
export type AIAgentTriggerType = 'explicit' | 'silent';

export type AIAgentName =
	| 'aggregate-metric-builder'
	| 'formula-metric-builder'
	| 'metric-discovery'
	| 'ontology-builder';

export type CreateAIAgentThreadResponse = {
	agentName: string;
	threadId: string;
};

export type AIAgentOp = {
	create?: { prompt: string };
	edit?: { definition: string; changes: { field: string; value: string }[] };
};

export type AIAgentMetricBuilderOp = AIAgentOp;
export type AIAgentOntologyBuilderEntityOp = AIAgentOp;
export type AIAgentOntologyBuilderDimensionOp = AIAgentOntologyBuilderEntityOp & { entity: string };
export type AIAgentOntologyBuilderRelationshipOp = AIAgentOntologyBuilderEntityOp & { entity: string };
export type AIAgentOntologyBuilderOp =
	| AIAgentOntologyBuilderEntityOp
	| AIAgentOntologyBuilderDimensionOp
	| AIAgentOntologyBuilderRelationshipOp;

export type AIAgentMetricBuilderResponse = {
	status: 'error' | 'complete';
	suggestion?: { metric?: Metric; formula_metric?: FormulaMetric };
	error?: string;
};

export type AIAgentOntologyBuilderResponse = {
	status: 'error' | 'complete';
	suggestion?: OntologyBuilderEntityResult | OntologyBuilderDimensionResult | OntologyBuilderRelationshipResult;
	error?: string;
};

export type AIAgentMetricDiscoveryResponse = {
	status: 'error' | 'complete';
	suggestion?: DiscoverMetricsResult;
	error?: string;
};
