import Flex from '@components/Flex';
import Box from '@components/Box';
import { WarningFluid16 } from '../Icons';
import Typography from '../Typography';
import Button from '../Button';
import { useCallback, useRef } from 'react';
import { useReportAIEvent } from '../AskAI/hooks/useReportAIEvent';
import colors from 'src/style/colors';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { ErrorReadMoreModal } from '../ErrorReadMoreModal';
import { useModal } from 'src/common/hooks/ui/useModal';
import { FixWithAIButton } from './FixWithAIButton';

type ErrorBannerProps = {
	errorMessage: string;
	isFixWithAIAvailable?: boolean;
	isAIBusy: boolean;
	onFixWithAI?: (errorMessage: string) => void;
};

export const ERROR_TITLE = 'An error requires your attention:';

export function ErrorBanner({ errorMessage, isFixWithAIAvailable, isAIBusy, onFixWithAI }: ErrorBannerProps) {
	const { reportAICopilotEvent } = useReportAIEvent();
	const errorMessageRef = useRef<HTMLDivElement | null>(null);

	const { isOpen, onOpen, onClose } = useModal();

	const isOverflowingErrorMessage = useOnOverflow(errorMessageRef, [errorMessage], undefined, false);

	const onFixWithAIClicked = useCallback(() => {
		onFixWithAI?.(errorMessage);
		onClose();
		reportAICopilotEvent({
			event: 'ai-copilot-click-fix-error-with-ai',
			metaData: { errorMessage },
		});
	}, [errorMessage, onFixWithAI, reportAICopilotEvent, onClose]);

	return (
		<Flex
			borderBottom={`1px solid ${colors.gray[300]}`}
			width={'100%'}
			padding={'16px'}
			backgroundColor={colors.gray[100]}
		>
			<WarningFluid16 />
			<Flex
				alignContent={'start'}
				width={'100%'}
				flexWrap={'wrap'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Box
					marginTop={'-2px'}
					marginLeft={'12px'}
					marginRight={'32px'}
					marginBottom={'16px'}
					wordBreak={'break-word'}
					width={'100%'}
					whiteSpace={'break-spaces'}
				>
					<Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={'6px'}>
						<Typography color={colors.gray[1000]} variant={'DesktopH8Medium'}>
							{ERROR_TITLE}
						</Typography>
					</Flex>

					<Typography ref={errorMessageRef} color={colors.gray[1000]} variant={'Paragraph14R'} noOfLines={3}>
						{errorMessage}
					</Typography>
				</Box>
				<Flex marginLeft={'12px'} gap={'12px'}>
					{isFixWithAIAvailable && <FixWithAIButton isAIBusy={isAIBusy} onClick={onFixWithAIClicked} />}
					{isOverflowingErrorMessage && (
						<Button
							onClick={onOpen}
							size="small"
							variant="ghost"
							colorScheme="gray"
							color={colors.gray[1000]}
							borderRadius={'8px'}
						>
							Read more
						</Button>
					)}
				</Flex>
			</Flex>
			<ErrorReadMoreModal
				submitButton={isFixWithAIAvailable && <FixWithAIButton isAIBusy={isAIBusy} onClick={onFixWithAIClicked} />}
				isOpen={isOpen}
				onClose={onClose}
				errorMessage={errorMessage}
			/>
		</Flex>
	);
}
