import Box from '@components/Box';
import Typography from '@components/Typography';

export const Subtitle = ({ subtitle, onClick }: { subtitle: string; onClick?: VoidFunction }) => {
	return (
		<Box textAlign={'left'} height={'20px'} marginTop={'4px'} width={'100%'} onClick={onClick} cursor="pointer">
			<Typography
				variant={'Paragraph12R'}
				color={'gray.700'}
				textOverflow={'ellipsis'}
				whiteSpace={'nowrap'}
				overflow={'hidden'}
				as={'div'}
				width={'100%'}
			>
				{subtitle}
			</Typography>
		</Box>
	);
};
