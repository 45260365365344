import { PopoverContent } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { Search } from '@components/Search';
import Button from 'src/common/components/Button';
import { ChevronDown16, ChevronUp16 } from 'src/common/components/Icons';
import Switch from 'src/common/components/Switch';
import Typography from 'src/common/components/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { SkeletonSearch } from './SkeletonComponents';
import { TestIDs } from 'src/common/types/test-ids';
import Popover from 'src/common/components/Popover';
import { ExploreMenu } from 'src/common/components/TopNavigation/ExploreMenu';

type Props = {
	shouldShowHiddenMetrics: boolean;
	setShouldShowHiddenMetrics: (shouldShowHiddenMetrics: boolean) => void;
	onSearchTermChange: (newSearchTerm: string) => void;
	paddingHorizontal?: string;
	minWidth?: string;
	isLoading?: boolean;
};

export function MetricCatalogHeaderV2({
	shouldShowHiddenMetrics,
	setShouldShowHiddenMetrics,
	onSearchTermChange,
	paddingHorizontal = '0px',
	minWidth = '0px',
	isLoading,
}: Props) {
	const { reportEvent, wrapWithReport } = useReportEvent();

	const { isOpen, onClose, onOpen } = useModal();
	const isMetricPageEnabled = useFeatureFlag('pulse.sightfull2.createNewMetric.enable');

	return (
		<Flex
			justifyContent={'space-between'}
			bg={colors.white}
			minH="60px"
			minWidth={minWidth}
			w="100%"
			alignItems="center"
			pl={paddingHorizontal}
			pr={paddingHorizontal}
			boxShadow={shadows.borderBottom}
			borderRadius={'0 16px 0 0'}
		>
			{isLoading ? (
				<SkeletonSearch />
			) : (
				<Flex
					alignItems={'center'}
					flexDirection="row"
					width="100%"
					marginRight="32px"
					data-testid={TestIDs.METRIC_CATALOG_HEADER}
				>
					<Flex flexGrow={1} alignItems={'center'} h="100%" justifyContent={'center'}>
						<Search isTransparent width="100%" placeholder="Search for metrics" onChange={onSearchTermChange} />
						<Flex
							flexDirection={'row'}
							marginRight={'24px'}
							gap="8px"
							alignItems={'center'}
							justifyContent={'flex-end'}
							width="100%"
						>
							<Switch
								isChecked={shouldShowHiddenMetrics}
								onChange={(e) => {
									reportEvent({
										event: 'metric-catalog-toggle-hidden-metrics',
										metaData: {
											isShowingHiddenMetrics: e.target.checked,
										},
									});
									setShouldShowHiddenMetrics(e.target.checked);
								}}
							/>
							<Typography variant="DesktopH8Regular" color="gray.700" noOfLines={1} textOverflow={'unset'}>
								Show hidden metrics
							</Typography>
						</Flex>
					</Flex>
					{isMetricPageEnabled && (
						<Popover
							triggerElement={
								<Button
									leftIcon={isOpen ? <ChevronUp16 /> : <ChevronDown16 />}
									variant="solid"
									onClick={wrapWithReport(onOpen, 'create-new-metric-clicked')}
									size="small"
									colorScheme="blue"
									data-intercom-area={'main'}
									data-intercom-type={'button'}
									data-intercom-target={'create-new-metric-button'}
									data-testid={TestIDs.NEW_METRIC_BUTTON}
								>
									New metric
								</Button>
							}
							isOpen={isOpen}
							placement={'bottom-start'}
							onClose={onClose}
							popoverContentProps={{
								boxShadow: shadows['a-22'],
							}}
							closeOnBlur={true}
						>
							<PopoverContent>
								<ExploreMenu isCreateNewMetric onClose={onClose} />
							</PopoverContent>
						</Popover>
					)}
				</Flex>
			)}
		</Flex>
	);
}
