import { useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import pluralize from 'pluralize';
import { AccessController } from 'src/common/components/AccessController';
import Button from 'src/common/components/Button';
import { ButtonSizeType } from 'src/common/components/Button/types';
import { BinSolid16, DotsSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import { Permissions } from 'src/types/environment';
import { BuilderTypes } from '../../utils/types';

export default function ThreeDotsButton({
	isEditable,
	type,
	onDelete,
	size = 'xxs',
	testId,
	actionTestId,
}: {
	isEditable: boolean;
	type: BuilderTypes;
	onDelete: VoidFunction;
	size?: ButtonSizeType;
	testId?: string;
	actionTestId?: string;
}) {
	const { isOpen, onClose, onOpen } = useDisclosure();
	return (
		<AccessController permission={Permissions.deleteEntities}>
			<Box marginLeft={'auto'}>
				<Popover
					popoverContentProps={{
						borderRadius: '10px',
					}}
					isOpen={isOpen}
					onClose={onClose}
					placement="bottom-end"
					triggerElement={(isOpen) => (
						<Button
							testId={testId}
							isDisabled={!isEditable}
							onClick={(e) => {
								e.stopPropagation();
								if (isOpen) onClose();
								else onOpen();
							}}
							size={size}
							variant="outline"
							colorScheme="black"
							isIconOnly
							isActive={isOpen}
						>
							<Box paddingX={'4px'} paddingY={'6px'}>
								<DotsSolid16 />
							</Box>
						</Button>
					)}
				>
					<Box borderRadius={'10px'} border={'1px solid'} borderColor={'gray.300'} paddingY="8px" width={'260px'}>
						<ListItem
							testId={actionTestId}
							color="gray.1000"
							size={'sm'}
							label={`Delete ${pluralize(type, 1)}`}
							prefixIcon={<BinSolid16 />}
							onClick={onDelete}
						/>
					</Box>
				</Popover>
			</Box>
		</AccessController>
	);
}
