import { atom, Provider, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

export type PendingAISuggestion = {
	field: string;
	suggestedValue?: string | string[];
	existingValue?: string | string[];
	suggestedValueDisplay?: React.ReactNode;
};

type SuggestionsContext = {
	supportedFields: string[];
	pendingFields: Set<string>;
	activeField?: string;
	autoActivateFirstSuggestion?: boolean;
};

enum ActivationDirection {
	next = 1,
	prev = -1,
	first = 0,
}

export const pendingSuggestionsScope = Symbol();
const PendingSuggestionsStateAtom = atom<SuggestionsContext>({ supportedFields: [], pendingFields: new Set<string>() });

export function PendingAISuggestionsBoundary({
	children,
	supportedFields,
}: {
	children: React.ReactNode | React.ReactNode[];
	supportedFields: string[];
}) {
	return (
		<Provider
			initialValues={[[PendingSuggestionsStateAtom, { supportedFields, pendingFields: new Set<string>() }]]}
			scope={pendingSuggestionsScope}
		>
			{children}
		</Provider>
	);
}

export function useAIPendingSuggestions() {
	const [suggestionsState, setSuggestionsState] = useAtom(PendingSuggestionsStateAtom, pendingSuggestionsScope);
	const { supportedFields, pendingFields, activeField, autoActivateFirstSuggestion } = suggestionsState;

	const pendingFieldsArray = useMemo(() => {
		return supportedFields.filter((field) => pendingFields.has(field));
	}, [pendingFields, supportedFields]);

	const totalPending = useMemo(() => pendingFieldsArray.length, [pendingFieldsArray]);

	const getFieldIndex = useCallback(
		(field?: string) => {
			if (!field) return -1;
			return pendingFieldsArray.indexOf(field);
		},
		[pendingFieldsArray]
	);

	const setActiveSuggestion = useCallback(
		(field: string | undefined, isActive: boolean) => {
			setSuggestionsState((state) => {
				const currentActiveField = state.activeField;
				const currentActiveFieldOrUndefined = currentActiveField !== field ? currentActiveField : undefined;
				const newActiveField = isActive ? field : currentActiveFieldOrUndefined;

				if (newActiveField === currentActiveField) return state;
				return {
					...state,
					activeField: newActiveField,
					autoActivateFirstSuggestion: false,
				};
			});
		},
		[setSuggestionsState]
	);

	const internalActivatePendingSuggestion = useCallback(
		(direction: ActivationDirection) => {
			const activeFieldIndex = getFieldIndex(activeField);
			const newActiveFieldIndex =
				activeFieldIndex >= 0 ? (activeFieldIndex + totalPending + direction) % totalPending : 0;
			const newActiveField = pendingFieldsArray[newActiveFieldIndex];
			setActiveSuggestion(newActiveField, true);
		},
		[getFieldIndex, activeField, pendingFieldsArray, totalPending, setActiveSuggestion]
	);

	const activateNextPendingSuggestion = useCallback(() => {
		internalActivatePendingSuggestion(ActivationDirection.next);
	}, [internalActivatePendingSuggestion]);

	const activatePrevPendingSuggestion = useCallback(() => {
		internalActivatePendingSuggestion(ActivationDirection.prev);
	}, [internalActivatePendingSuggestion]);

	const activateFirstPendingSuggestion = useCallback(() => {
		internalActivatePendingSuggestion(ActivationDirection.first);
	}, [internalActivatePendingSuggestion]);

	const removePendingSuggestion = useCallback(
		(field: string) =>
			setSuggestionsState((state) => {
				if (!state.pendingFields.has(field)) return state;
				state.pendingFields.delete(field);
				return {
					...state,
					pendingFields: new Set(state.pendingFields),
					activeField: state.activeField !== field ? state.activeField : undefined,
				};
			}),
		[setSuggestionsState]
	);

	const addPendingSuggestion = useCallback(
		(field: string) => {
			setSuggestionsState((state) => {
				if (state.pendingFields.has(field)) return state;
				state.pendingFields.add(field);
				return {
					...state,
					pendingFields: new Set(state.pendingFields),
				};
			});
		},
		[setSuggestionsState]
	);

	const clearPendingSuggestions = useCallback(() => {
		setSuggestionsState((state) => {
			return {
				...state,
				pendingFields: new Set(),
				activeField: undefined,
				autoActivateFirstSuggestion: false,
			};
		});
	}, [setSuggestionsState]);

	const setAutoActivateFirstSuggestion = useCallback(() => {
		setSuggestionsState((state) => {
			if (state.autoActivateFirstSuggestion) return state;
			return {
				...state,
				autoActivateFirstSuggestion: true,
			};
		});
	}, [setSuggestionsState]);

	useEffect(() => {
		if (autoActivateFirstSuggestion) {
			activateFirstPendingSuggestion();
		}
	}, [activateFirstPendingSuggestion, autoActivateFirstSuggestion]);

	return {
		pendingFields,
		totalPending,
		activeField,
		getFieldIndex,
		removePendingSuggestion,
		addPendingSuggestion,
		clearPendingSuggestions,
		setActiveSuggestion,
		setAutoActivateFirstSuggestion,
		activateNextPendingSuggestion,
		activatePrevPendingSuggestion,
	};
}
