import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'src/pages/DashboardPage/NotFoundPage';
import { EntityProfilePage, EntityProfilePagePath } from 'src/pages/EntityProfilePage/EntityProfilePage';
import ExportMetricPage, { ExportMetricPath } from 'src/pages/ExportMetricPage';
import MetricPage from 'src/pages/MetricPage';
import { AddTemplatePath, MetricCatalogPath, MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import WorkspacePage, { WorkspacePath } from 'src/pages/WorkspacePage';
import ConfigurationPage, { ConfigurationPath } from './ConfigurationPage';
import {
	CreateNewMetricPage,
	CreateNewMetricPagePath,
	ExploreNewMetricPagePath,
} from './CreateNewMetricPage/CreateNewMetricPage';
import { METRIC_COPIES } from './CreateNewMetricPage/copy';
import DashboardPage, { DashboardPath } from './DashboardPage';
import DataHygienePage, { DataHygienePath } from './DataHygiene';
import { FeedPage, FeedPagePath } from './FeedPage/FeedPage';
import { MetricCatalogGuard } from './MetricCatalog/MetricCatalog';
import { OntologyPage, OntologyPagePath } from './OntologyPage/OntologyBuilderPage';
import SearchResultsPage, { SearchResultsPath } from './SearchResultsPage';
import { SignalPage } from './SignalPage';
import { SignalPath } from 'src/pages/SignalPage/pageRoutePaths';
import { SubscriptionPage, SubscriptionPagePath } from './SubscriptionPage/SubscriptionPage';
import { RedirectSignalPage } from './WorkspacePage/RedirectSignalPage';
import { ConnectorsPage, ConnectorsPagePath } from './ConnectorsPage';

export const RootPath = '/';

// TAF: Wrap every route natively in a 'AnalyticsPageContext'
export default (
	<Routes>
		<Route path={RootPath} element={<DashboardPage />} />
		<Route path={ConnectorsPagePath} element={<ConnectorsPage />} />
		<Route path={`${ConnectorsPagePath}/thanks`} element={<ConnectorsPage isThankYouModalActive />} />
		<Route path={SubscriptionPagePath} element={<SubscriptionPage />} />
		<Route path={MetricCatalogPath} element={<MetricCatalogGuard />} />
		<Route path={AddTemplatePath} element={<MetricCatalogGuard />} />
		<Route path={FeedPagePath} element={<FeedPage />} />
		<Route path={OntologyPagePath} element={<OntologyPage />} />
		<Route path={`${OntologyPagePath}/:ontologyType`} element={<OntologyPage />} />
		<Route path={`${OntologyPagePath}/:ontologyType/:ontologyName`} element={<OntologyPage />} />
		<Route path={`${OntologyPagePath}/:ontologyType/:ontologyName/:propertyName`} element={<OntologyPage />} />
		<Route path={`${EntityProfilePagePath}/:entityId`} element={<EntityProfilePage />} />
		<Route path={`${MetricPagePath}/:metricName`} element={<MetricPage />} />
		{METRIC_COPIES.map((metric) => {
			const metricKind = metric.type;
			return (
				<Route
					// TODO: remove when AI page is enabled
					key={metricKind || 'ai'}
					path={`${CreateNewMetricPagePath}/${metricKind}`}
					element={<CreateNewMetricPage metricKind={metricKind} />}
				/>
			);
		})}
		{METRIC_COPIES.map((metric) => {
			const metricKind = metric.type;
			return (
				<Route
					// TODO: remove when AI page is enabled
					key={metricKind || 'ai'}
					path={`${ExploreNewMetricPagePath}/${metricKind}`}
					element={<CreateNewMetricPage metricKind={metricKind} />}
				/>
			);
		})}
		<Route path={`${ConfigurationPath}`} element={<ConfigurationPage />} />
		<Route path={`${DataHygienePath}`} element={<DataHygienePage />} />
		<Route
			path={`${DashboardPath}/:dashboardId/${SignalPath}/:signalId`}
			element={<SignalPage collectionType="dashboard" />}
		/>
		<Route path={`${DashboardPath}/:dashboardId`} element={<DashboardPage />} />
		<Route path={DashboardPath} element={<DashboardPage />} />

		<Route path={`${SignalPath}/:workspaceId/:signalId`} element={<RedirectSignalPage />} />
		<Route
			path={`${WorkspacePath}/:workspaceId/${SignalPath}/:signalId`}
			element={<SignalPage collectionType="workspace" />}
		/>
		<Route path={`${WorkspacePath}/:workspaceId`} element={<WorkspacePage />} />
		<Route path={WorkspacePath} element={<WorkspacePage />} />

		<Route path={`${ExportMetricPath}/:metricName`} element={<ExportMetricPage />} />
		<Route path={`${SearchResultsPath}/:searchText/:objects`} element={<SearchResultsPage />} />
		<Route path="*" element={<NotFoundPage title={"We couldn't find the page."} />} />
	</Routes>
);
