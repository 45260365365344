import Flex from '@components/Flex';
import classes from '@components/LeftExpandCollapsePanel/LeftExpandCollapsePanel.module.scss';
import Typography from '@components/Typography';
import colors from 'src/style/colors';

export const DisabledBreakdownCard = () => {
	return (
		<Flex className={classes.disabledCard} p={'11px 11px 11px 15px'} alignItems={'center'} cursor={'default'}>
			<Typography variant={'Paragraph12R'} color={colors.gray['600']}>
				This metric doesn&apos;t provide breakdowns.
			</Typography>
		</Flex>
	);
};
