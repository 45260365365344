import { QueryResult } from '@apollo/client/react/types/types';
import useRequest from '@hooks/fetching/useRequest';
import { Options } from 'axios-hooks';
import { useCallback } from 'react';
import useLazyQuery from 'src/common/hooks/fetching/useLazyQuery';
import {
	CalculateMetricMultiQuery,
	CalculateMetricMultiQueryVariables,
	GetDimensionValuesQuery,
	GetDimensionValuesQueryVariables,
	GetFiltersBreakdownsOptionsQuery,
	GetFiltersBreakdownsOptionsQueryVariables,
	GetMetricByNameQuery,
	GetMetricByNameQueryVariables,
	MetricNodeTypesQuery,
	MetricNodeTypesQueryVariables,
} from 'src/generated/graphql';
import { DETAILS_TABLE_LIMIT } from 'src/lib/api/constants';
import {
	CalculateMetricMulti,
	GetDimensionValues,
	GetFiltersBreakdownsOptions,
	GetMetricByName,
	MetricNodeTypes,
} from 'src/queries/metrics';
import { MetricCalcMultiResult, MetricPostBody } from 'src/types/metric';

export const ManualFetchingNoAutoCancel: Options = { manual: true, autoCancel: false, useCache: true };

export type CalcMetricCallback = (
	name: string,
	periods: string[],
	params?: MetricPostBody
) => Promise<MetricCalcMultiResult>;

function useInternalCalcMetric(): CalcMetricCallback {
	const fetchMetricGraphQL = useInternalCalcMetricGQL();

	return fetchMetricGraphQL;
}

function useInternalCalcMetricGQL(): CalcMetricCallback {
	const [lazyCallApi] = useLazyQuery<CalculateMetricMultiQuery, CalculateMetricMultiQueryVariables>(
		CalculateMetricMulti
	);

	const callApi = useCallback(
		(metricName: string, periods: string[], params?: MetricPostBody): Promise<MetricCalcMultiResult> => {
			return lazyCallApi({
				variables: {
					metricName,
					periods,
					filterBy: JSON.stringify(params?.filter_by ?? {}),
					groupBy: params?.group_by?.map((v) => v),
					collectProps: params?.collect_props?.map((v) => v),
					verbose: false,
					limit: DETAILS_TABLE_LIMIT,
				},
			}).then((res) => res.data?.calculateMetricMulti.result as MetricCalcMultiResult);
		},
		[lazyCallApi]
	);

	return callApi;
}

function useInternalCalcMetricStatistics() {
	const [, { refetch: fetchAxios }] = useRequest({
		method: 'POST',
		options: ManualFetchingNoAutoCancel,
	});

	return useCallback(
		(name: string, period: string, statisticsOperation: string, body?: MetricPostBody) =>
			fetchAxios({
				url: `/metric/statistics/${encodeURIComponent(name)}/${encodeURIComponent(period)}/${encodeURIComponent(
					statisticsOperation
				)}`,
				data: body,
			}),
		[fetchAxios]
	);
}

function useGetMetricMetadata(): [
	Pick<QueryResult<GetMetricByNameQuery, GetMetricByNameQueryVariables>, 'data' | 'loading' | 'error'>,
	(name: string, flavor: string) => Promise<QueryResult<GetMetricByNameQuery, GetMetricByNameQueryVariables>>
] {
	const [executeQuery, { data, loading, error }] = useLazyQuery<GetMetricByNameQuery, GetMetricByNameQueryVariables>(
		GetMetricByName,
		{}
	);

	const callApi = useCallback(
		(name: string) =>
			executeQuery({
				variables: {
					name,
				},
			}),
		[executeQuery]
	);

	return [{ data, loading, error }, callApi];
}

function useGetFiltersBreakdownsOptions(): [
	Pick<
		QueryResult<GetFiltersBreakdownsOptionsQuery, GetFiltersBreakdownsOptionsQueryVariables>,
		'data' | 'loading' | 'error'
	>,
	(
		metricNameWithFlavor: string,
		periods: string[] | string,
		prefixPath?: string,
		verbose?: boolean,
		shouldIncludeNotNormalizedProps?: boolean
	) => Promise<QueryResult<GetFiltersBreakdownsOptionsQuery, GetFiltersBreakdownsOptionsQueryVariables>>
] {
	const [executeQuery, { data, loading, error }] = useLazyQuery<
		GetFiltersBreakdownsOptionsQuery,
		GetFiltersBreakdownsOptionsQueryVariables
	>(GetFiltersBreakdownsOptions);

	const callApi = useCallback(
		(
			name: string,
			periods: string[] | string,
			prefixPath?: string,
			verbose?: boolean,
			shouldIncludeNotNormalizedProps?: boolean
		) =>
			executeQuery({
				variables: {
					metric: name,
					periods,
					prefixPath,
					verbose,
					shouldIncludeNotNormalizedProps,
				},
			}),
		[executeQuery]
	);

	return [{ data, loading, error }, callApi];
}

function useGetDimensionValues(): [
	Pick<QueryResult<GetDimensionValuesQuery, GetDimensionValuesQueryVariables>, 'data' | 'loading' | 'error'>,
	(
		name: string,
		periods: string[] | string,
		dimension: string,
		prefixPath?: string,
		verbose?: boolean
	) => Promise<QueryResult<GetDimensionValuesQuery, GetDimensionValuesQueryVariables>>
] {
	const [executeQuery, { data, loading, error }] = useLazyQuery<
		GetDimensionValuesQuery,
		GetDimensionValuesQueryVariables
	>(GetDimensionValues);

	const callApi = useCallback(
		(name: string, periods: string[] | string, dimension: string, prefixPath?: string, verbose?: boolean) =>
			executeQuery({
				variables: {
					metric: name,
					periods,
					dimension,
					prefixPath,
					verbose,
				},
			}),
		[executeQuery]
	);

	return [{ data, loading, error }, callApi];
}

function useGetMetricObjectsTypes(): [
	Pick<QueryResult<MetricNodeTypesQuery, MetricNodeTypesQueryVariables>, 'data' | 'loading' | 'error'>,
	(
		metricNameWithFlavor: string,
		verbose?: boolean
	) => Promise<QueryResult<MetricNodeTypesQuery, MetricNodeTypesQueryVariables>>
] {
	const [executeQuery, { data, loading, error }] = useLazyQuery<MetricNodeTypesQuery, MetricNodeTypesQueryVariables>(
		MetricNodeTypes
	);

	const callApi = useCallback(
		(name: string, verbose?: boolean) =>
			executeQuery({
				variables: {
					metric: name,
					verbose,
				},
			}),
		[executeQuery]
	);

	return [{ data, loading, error }, callApi];
}

function useMetricApi() {
	const getMetricMetadata = useGetMetricMetadata();
	const getFiltersBreakdownsOptions = useGetFiltersBreakdownsOptions();
	const getDimensionValues = useGetDimensionValues();
	const getMetricObjectsTypes = useGetMetricObjectsTypes();

	return {
		getMetricMetadata,
		getFiltersBreakdownsOptions,
		getDimensionValues,
		getMetricObjectsTypes,
	};
}

export { useInternalCalcMetric, useInternalCalcMetricStatistics };

export default useMetricApi;
