import { DataConnectorsModalSecurityInfo } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModalSecurityInfo';
import Flex from '@components/Flex';
import { DataConnectorsModalSearch } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModalSearch';
import React, { useCallback, useRef, useState } from 'react';
import { DataConnectorsCardsDisplay } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsCardsDisplay';
import Scrollable from '@components/Scrollable';
import Box from '@components/Box';
import { onScrollEvent } from '@components/Scrollable/types';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';

const BODY_HEIGHT = '545px';
const SCROLL_TOP_HIDDEN_SECURITY_INFO = 115;

export function DataConnectorsModalBody({
	selectedConnector,
	setSelectedConnector,
}: {
	selectedConnector: DataConnectorInfo | null;
	setSelectedConnector: React.Dispatch<React.SetStateAction<DataConnectorInfo | null>>;
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const hasSearchTerm = searchTerm !== '';

	const [isSearchWithBottomShadow, setIsSearchWithBottomShadow] = useState(false);

	const onSearchTermChange = useCallback((newVal: string) => {
		if (scrollableRef.current && scrollableRef.current.scrollTop != 0) scrollableRef.current.scrollTop = 0;
		setSearchTerm(newVal);
	}, []);

	const onScroll = useCallback(
		(event: onScrollEvent) => {
			if (event.scrollTop === undefined) {
				return;
			}

			const shouldHideSearchBottomShadow =
				isSearchWithBottomShadow && event.scrollTop < SCROLL_TOP_HIDDEN_SECURITY_INFO;
			const shouldShowSearchBottomShadow =
				!isSearchWithBottomShadow && event.scrollTop > SCROLL_TOP_HIDDEN_SECURITY_INFO;

			const shouldUpdateSearchBottomShadow = shouldHideSearchBottomShadow || shouldShowSearchBottomShadow;

			if (shouldUpdateSearchBottomShadow) {
				setIsSearchWithBottomShadow(!isSearchWithBottomShadow);
			}
		},
		[isSearchWithBottomShadow]
	);

	const scrollableRef = useRef<HTMLDivElement>(null);
	return (
		<Box height={BODY_HEIGHT} maxHeight={BODY_HEIGHT} overflowY={'auto'}>
			<Scrollable onScroll={onScroll} scrollableRef={scrollableRef}>
				<Flex padding={`${hasSearchTerm ? '12px' : '24px'} 32px 24px 32px`} flexDirection={'column'} flex={1}>
					{!hasSearchTerm && <DataConnectorsModalSecurityInfo />}
					<DataConnectorsModalSearch
						onSearchTermChange={onSearchTermChange}
						value={searchTerm}
						hasSearchTerm={hasSearchTerm}
						isSearchWithBottomShadow={isSearchWithBottomShadow}
					/>
					<DataConnectorsCardsDisplay
						searchTerm={searchTerm}
						selectedConnector={selectedConnector}
						setSelectedConnector={setSelectedConnector}
						hasSearchTerm={hasSearchTerm}
					/>
				</Flex>
			</Scrollable>
		</Box>
	);
}
