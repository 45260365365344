import { As } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { Icon } from 'src/common/components/Icons';

export default function ResizableIcon({
	icon,
	size,
	className,
	color,
	width,
	height,
}: {
	icon: As;
	size?: string;
	className?: string;
	color?: string;
	width?: string;
	height?: string;
}) {
	return (
		<Flex alignItems={'center'} className={className}>
			<Icon boxSize={size} width={width} height={height} as={icon} color={color} />
		</Flex>
	);
}
