import Button from '../Button';
import { SparksFullWhite } from '../Icons';
import ResizableIcon from '../ResizableIcon';

export function FixWithAIButton({ isAIBusy = false, onClick }: { isAIBusy?: boolean; onClick?: VoidFunction }) {
	return (
		<Button
			minWidth={'126px'}
			isDisabled={isAIBusy}
			isLoading={isAIBusy}
			onClick={onClick}
			size="small"
			variant="solid"
			colorScheme="blue"
			borderRadius={'8px'}
			leftIcon={<ResizableIcon icon={SparksFullWhite} size={'12px'} />}
		>
			Fix with AI
		</Button>
	);
}
