import { useMemo } from 'react';
import { AskAIChatCardProps, AskAIChatFieldSuggestion, isAskAIOntologyBuilderDimensionChatMessage } from '../../types';
import { useOntologyBuilderAIAgent } from '../../hooks/useOntologyBuilderAIAgent';
import { AskAIChatFieldSuggestions } from '../AskAIChatFieldSuggestions';
import { AskAILoadingResults } from '../AskAILoadingResults';

export function AskAIOntologyBuilderDimensionCardContent({ chatMessageAtom, isLast }: AskAIChatCardProps) {
	const { chatMessage } = useOntologyBuilderAIAgent({ chatMessageAtom });
	const isDimensionMessage = isAskAIOntologyBuilderDimensionChatMessage(chatMessage);
	const result = isDimensionMessage ? chatMessage.result : undefined;
	const dimension = result?.dimension;
	const existingDimension: typeof dimension = chatMessage.promptContext?.parsedYaml;

	const fields = useMemo(() => {
		if (!dimension) return [];

		const fields: AskAIChatFieldSuggestion[] = [];

		if (dimension.name && (!existingDimension || existingDimension.name === 'untitled')) {
			fields.push({
				label: '',
				propertyName: 'name',
				value: dimension.name,
			});
		}

		if (dimension.meta?.display_name) {
			fields.push({
				label: 'Name',
				propertyName: 'display_name',
				display: dimension.meta.display_name,
				value: dimension.meta.display_name,
				existingValue: existingDimension?.meta?.display_name,
			});
		}

		if (dimension.sources) {
			fields.push({
				label: 'Formula',
				propertyName: 'sources',
				display: dimension.sources?.map((source) => source.sql).join(' **and** '),
				value: dimension.sources,
				existingValue: existingDimension?.sources,
			});
		}

		if (dimension.type) {
			fields.push({
				label: 'Type',
				propertyName: 'type',
				display: dimension.type,
				value: dimension.type,
				existingValue: existingDimension?.type,
			});
		}

		if (dimension.meta?.description) {
			fields.push({
				label: 'Description',
				propertyName: 'description',
				display: dimension.meta?.description,
				value: dimension.meta?.description,
				existingValue: existingDimension?.meta?.description,
			});
		}

		return fields;
	}, [dimension, existingDimension]);

	if (!isDimensionMessage) return;

	return dimension ? (
		<AskAIChatFieldSuggestions
			fields={fields}
			onAcceptSuggestions={chatMessage.onAcceptSuggestions}
			isLastChatCard={isLast}
		/>
	) : (
		<AskAILoadingResults />
	);
}
