import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import {
	AIAgentName,
	AIAgentOntologyBuilderOp,
	AIAgentOntologyBuilderResponse,
	AskAIChatCardProps,
	AskAIOntologyBuilderSubType,
	isAskAIOntologyBuilderDimensionChatMessage,
	isAskAIOntologyBuilderEntityChatMessage,
	isAskAIOntologyBuilderRelationshipChatMessage,
} from '../types';
import { isAIAgentAbortError, useAIAgents } from './useAIAgents';

type OntologyBuilderAgentState = {
	running: boolean;
};

const discoveryAgentState: OntologyBuilderAgentState = {
	running: false,
};

export function useOntologyBuilderAIAgent({ chatMessageAtom }: Pick<AskAIChatCardProps, 'chatMessageAtom'>) {
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { promptContext, userPrompt: originalUserPrompt, result: cachedResult, error: cachedError } = chatMessage;

	const userPrompt = useMemo(() => {
		if (promptContext?.existingYaml) {
			return `in the context for this definition:\n${promptContext.existingYaml}\n\n${originalUserPrompt}`;
		}
		return originalUserPrompt;
	}, [originalUserPrompt, promptContext]);

	const { getOrCreateAIAgentThread, callAIAgent } = useAIAgents();

	const callOntologyBuilderAIAgent = useCallback(
		async ({
			type,
			op,
			entity,
		}: {
			type: AskAIOntologyBuilderSubType;
			op: AIAgentOntologyBuilderOp;
			entity?: string;
		}) => {
			if (discoveryAgentState.running) return;
			try {
				discoveryAgentState.running = true;
				const agentName: AIAgentName = `ontology-builder`;
				const threadId = await getOrCreateAIAgentThread({ agentName });
				const data = await callAIAgent<AIAgentOntologyBuilderResponse>({
					agentName,
					threadId,
					body: { [type]: { op, entity } },
				});
				setChatMessage((prev) => ({
					...prev,
					type: `ontology-builder-${type}`,
					result: data?.suggestion,
					error: data?.status === 'error',
					completionTime: Date.now(),
				}));
			} catch (error: any) {
				if (isAIAgentAbortError(error)) {
					setChatMessage((prev) => ({
						...prev,
						type: `ontology-builder-${type}`,
						error: true,
						errorText: error.message,
						completionTime: Date.now(),
					}));
				} else {
					throw error;
				}
			} finally {
				discoveryAgentState.running = false;
			}
		},
		[getOrCreateAIAgentThread, callAIAgent, setChatMessage]
	);

	const requestExplicitEntityDefinitionSuggestion = useCallback(
		async ({ prompt }: { prompt: string }) => {
			await callOntologyBuilderAIAgent({ type: 'entity', op: { create: { prompt } } });
		},
		[callOntologyBuilderAIAgent]
	);

	const requestExplicitDimensionDefinitionSuggestion = useCallback(
		async ({ prompt, entity }: { prompt: string; entity: string }) => {
			await callOntologyBuilderAIAgent({ type: 'dimension', entity, op: { create: { prompt } } });
		},
		[callOntologyBuilderAIAgent]
	);

	const requestExplicitRelationshipDefinitionSuggestion = useCallback(
		async ({ prompt, entity }: { prompt: string; entity: string }) => {
			await callOntologyBuilderAIAgent({ type: 'relationship', entity, op: { create: { prompt } } });
		},
		[callOntologyBuilderAIAgent]
	);

	useEffect(() => {
		if (cachedResult || cachedError) return;
		if (isAskAIOntologyBuilderEntityChatMessage(chatMessage)) {
			requestExplicitEntityDefinitionSuggestion({ prompt: userPrompt }).catch((error) =>
				setChatMessage((prev) => ({ ...prev, error: !!error }))
			);
		}
		if (isAskAIOntologyBuilderDimensionChatMessage(chatMessage)) {
			requestExplicitDimensionDefinitionSuggestion({ prompt: userPrompt, entity: promptContext?.entityName }).catch(
				(error) => setChatMessage((prev) => ({ ...prev, error: !!error }))
			);
		}
		if (isAskAIOntologyBuilderRelationshipChatMessage(chatMessage)) {
			requestExplicitRelationshipDefinitionSuggestion({ prompt: userPrompt, entity: promptContext?.entityName }).catch(
				(error) => setChatMessage((prev) => ({ ...prev, error: !!error }))
			);
		}
	}, [
		cachedError,
		cachedResult,
		callOntologyBuilderAIAgent,
		promptContext,
		requestExplicitEntityDefinitionSuggestion,
		requestExplicitDimensionDefinitionSuggestion,
		requestExplicitRelationshipDefinitionSuggestion,
		setChatMessage,
		userPrompt,
		chatMessage,
	]);

	return {
		chatMessage,
		requestExplicitEntityDefinitionSuggestion,
		requestExplicitDimensionDefinitionSuggestion,
		requestExplicitRelationshipDefinitionSuggestion,
	};
}
