export const clickOutsideChameleonPopover = (onClose: VoidFunction) => {
	const chameleonPopover = document.getElementsByClassName('chmln-inner');
	const doesChameleonExist = chameleonPopover.length > 0;
	if (!doesChameleonExist) return onClose();
	const chameleonSpans = chameleonPopover[0].getElementsByTagName('span');
	if (chameleonSpans.length === 0) return onClose();
	for (let i = 0; i < chameleonSpans.length; i++) {
		if (chameleonSpans[i].textContent === 'Choose metric type') return;
	}
	onClose();
};
