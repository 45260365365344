import Box from '@components/Box';
import Flex from '@components/Flex';
import { formatMetricFilterLabel } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useParameters } from '@services/useParameters';
import pluralize from 'pluralize';
import { Fragment, useMemo } from 'react';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { Breakdown, Filter, FilterV2 } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Parameter } from 'src/types/parameter';

const ITEMS_MAX_LENGTH = 2;

function AppliedTooltipBody({ children }: { children: React.ReactNode | React.ReactNode[] }) {
	return <Flex gap={'4px'}>{children}</Flex>;
}

function FiltersTooltipContent({ filters }: { filters: (Filter | FilterV2)[] }) {
	return (
		<AppliedTooltipBody>
			{filters.map((filter) => {
				const formattedValuesLabels = filter.values.map((value) => formatMetricFilterLabel(value?.toString()));
				const showElipsis = formattedValuesLabels?.length > ITEMS_MAX_LENGTH;
				const textDots = showElipsis ? '...' : '.';
				const valuesText = `${formattedValuesLabels.slice(0, ITEMS_MAX_LENGTH).join(', ')}${textDots}`;
				return (
					<Fragment key={filter.key}>
						<Typography color={'gray.700'} variant="Paragraph12R">
							{filter?.label} {'>'}
						</Typography>
						<Typography maxWidth={'200px'} color={'gray.1000'} variant="Paragraph12M">
							<Box wordBreak={'break-all'} noOfLines={1}>
								{valuesText}
							</Box>{' '}
						</Typography>
						{showElipsis && formattedValuesLabels?.length > ITEMS_MAX_LENGTH && (
							<Typography color={'gray.700'} variant="Paragraph12R">
								+{filter?.values.length - ITEMS_MAX_LENGTH} more
							</Typography>
						)}
					</Fragment>
				);
			})}
		</AppliedTooltipBody>
	);
}

function BreakdownsTooltipContent({ breakdowns }: { breakdowns: Breakdown[] }) {
	const showElipsis = breakdowns.length > ITEMS_MAX_LENGTH;
	const textDots = showElipsis ? '...' : '.';
	const breakdownsText = `${breakdowns
		?.slice(0, ITEMS_MAX_LENGTH)
		?.map((el) => el.label)
		.join(', ')}${textDots}`;

	return (
		<AppliedTooltipBody>
			<Typography maxWidth={'250px'} color={'gray.1000'} variant="Paragraph12M">
				<Box wordBreak={'break-all'} noOfLines={1}>
					{breakdownsText}
				</Box>
			</Typography>
			{showElipsis && (
				<Typography color={'gray.700'} variant="Paragraph12R">
					+{breakdowns.length - ITEMS_MAX_LENGTH} more
				</Typography>
			)}
		</AppliedTooltipBody>
	);
}

function ParametersTooltipContent({ parameters }: { parameters: Parameter[] }) {
	const { getParameterDisplayValue } = useParameters();

	const parametersText = parameters.map((parameter) => (
		<Flex key={parameter.definition.name}>
			<Box color={'gray.700'}>{`${parameter.definition.label ?? parameter.definition.name} >`}</Box>
			<Box marginLeft={'4px'}>{getParameterDisplayValue(parameter.definition.name)}</Box>
		</Flex>
	));
	return (
		<AppliedTooltipBody>
			<Typography maxWidth={'250px'} color={'gray.1000'} variant="Paragraph12M">
				{parametersText}
			</Typography>
		</AppliedTooltipBody>
	);
}

function MetricModifierTag({
	data,
	name,
	tooltipContent,
}: {
	data: Breakdown[] | (Filter | FilterV2)[] | Parameter[];
	name: string;
	tooltipContent: React.ReactNode;
}) {
	return (
		<Tooltip
			boxShadow={shadows.switcherButton}
			backgroundColor={'white'}
			hasArrow={false}
			label={tooltipContent}
			placement="bottom"
			size="md"
		>
			<Flex alignItems={'center'} borderRadius={'32px'} padding={'2px 8px'} backgroundColor={colors.gray[200]}>
				<Typography variant="Paragraph12M">
					{data?.length} {pluralize(name, data?.length)}{' '}
				</Typography>
			</Flex>
		</Tooltip>
	);
}

function Separator({ text }: { text: string }) {
	return (
		<Typography variant="Paragraph12R" textAlign="start">
			{text}
		</Typography>
	);
}

export function MetricFiltersAndBreakdowns() {
	const { filters, breakdowns, parameters } = useMetricDerivedState();
	const filtersWithValues = filters?.filter((filter) => filter.values?.length);

	const modifierTags: React.ReactNode[] = useMemo(() => {
		const tags = [];
		if (filtersWithValues?.length) {
			tags.push(
				<MetricModifierTag
					name={'filter'}
					data={filtersWithValues}
					tooltipContent={<FiltersTooltipContent filters={filtersWithValues} />}
				/>
			);
		}

		if (breakdowns?.values?.length) {
			tags.push(
				<MetricModifierTag
					name={'breakdown'}
					data={breakdowns.values}
					tooltipContent={<BreakdownsTooltipContent breakdowns={breakdowns.values} />}
				/>
			);
		}

		if (parameters?.length) {
			tags.push(
				<MetricModifierTag
					name={'parameter'}
					data={parameters}
					tooltipContent={<ParametersTooltipContent parameters={parameters} />}
				/>
			);
		}
		return tags;
	}, [breakdowns.values, filtersWithValues, parameters]);

	const modifierSeparators = useMemo(() => {
		const separators = [];
		if (modifierTags.length > 1) separators.push(<Separator text={'and'} key="and" />);
		if (modifierTags.length > 2) separators.unshift(<Separator text={', '} key="comma" />);
		if (modifierTags.length > 0) separators.unshift(<Separator text={'Applied with'} key="applied" />);
		return separators;
	}, [modifierTags]);

	if (modifierTags.length === 0) return null;

	return (
		<Flex gap={'6px'} alignItems={'center'} color={'gray.1000'} marginLeft={'8px'}>
			{modifierSeparators[0]}
			{modifierTags[0]}
			{modifierSeparators[1]}
			{modifierTags[1]}
			{modifierSeparators[2]}
			{modifierTags[2]}
		</Flex>
	);
}
