import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { AddSmall10 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';

export function CreateNewEntityContent({ onCreateNewOpen }: { onCreateNewOpen: VoidFunction }) {
	return (
		<Flex
			flexDirection={'column'}
			alignContent={'center'}
			alignItems={'center'}
			justifyContent={'center'}
			width={'100%'}
		>
			<Typography marginBottom={'8px'} variant="DesktopH5S" color="gray.1000">
				No entities yet
			</Typography>
			<Typography marginBottom={'24px'} variant="DesktopH7Regular" color="gray.1000">
				Start by defining your first entity.
			</Typography>
			<Button
				leftIcon={<AddSmall10 />}
				variant="solid"
				onClick={onCreateNewOpen}
				size="small"
				colorScheme="blue"
				borderRadius={'8px'}
			>
				Create Entity
			</Button>
		</Flex>
	);
}
