import { ReactNode, useCallback, useMemo } from 'react';

import { AIAgentsContextBoundary, useAIAgents } from '../../../common/components/AskAI/hooks/useAIAgents';
import { MetricType } from '../../../common/types/common';
import { FormulaMetric, Metric, MetricMeta } from '../../../lib/completions/semanticTypes';
import { DeepPartial } from '@chakra-ui/react';
import {
	AIAgentMetricBuilderOp,
	AIAgentMetricBuilderResponse,
	AIAgentName,
	AIAgentTriggerType,
} from '../../../common/components/AskAI/types';
import useFeatureFlag from '../../../common/hooks/stores/useFeatureFlag';
import {
	PendingAISuggestionsBoundary,
	useAIPendingSuggestions,
} from '../../../common/components/AskAI/hooks/useAIPendingSuggestions';
import { atom, Provider, useAtom } from 'jotai';

export type MetricBuilderAIAgentSuggestion = {
	op: AIAgentMetricBuilderOp;
	metricType: MetricType;
	triggerType: AIAgentTriggerType;
	suggestedMetric?: {
		name?: string;
		entity?: string;
		meta?: MetricMeta;
		aggregate?: DeepPartial<Metric>;
		formula?: DeepPartial<FormulaMetric>;
	};
};

type MetricBuilderAIAgentState = {
	isEnabled: boolean;
};

const metricBuilderAgentScope = Symbol();
const LatestMetricBuilderSuggetionAtom = atom<MetricBuilderAIAgentSuggestion | undefined>(undefined);

const globalScope = Symbol();
const MetricBuilderAgentStateAtom = atom<MetricBuilderAIAgentState>({ isEnabled: true });

const MetricBuilderSuggestionFields = [
	'display_name',
	'entity',
	'filters',
	'operation',
	'measure',
	'period',
	'formula',
	'description',
];

export function MetricBuilderAIAgentSuggestionsBoundary({ children }: { children: ReactNode }) {
	return (
		<Provider initialValues={[[LatestMetricBuilderSuggetionAtom, undefined]]} scope={metricBuilderAgentScope}>
			<AIAgentsContextBoundary>
				<PendingAISuggestionsBoundary supportedFields={MetricBuilderSuggestionFields}>
					{children}
				</PendingAISuggestionsBoundary>
			</AIAgentsContextBoundary>
		</Provider>
	);
}

export function useMetricBuilderAIAgent() {
	const isSightfull2Enabled = useFeatureFlag('pulse.sightfull2.enable');
	const isAIMetricSuggestionsEnabledFF = useFeatureFlag('pulse.sightfull2.metricBuilderAIAgent.enable');
	const [latestMetricBuilderSuggetion, setLatestMetricBuilderSuggetion] = useAtom(
		LatestMetricBuilderSuggetionAtom,
		metricBuilderAgentScope
	);

	const [metricBuilderAIAgentState, setMetricBuilderAIAgentState] = useAtom(MetricBuilderAgentStateAtom, globalScope);

	const { isBusy, getOrCreateAIAgentThread, callAIAgent, handleAIAgentError } = useAIAgents();
	const { setAutoActivateFirstSuggestion, clearPendingSuggestions } = useAIPendingSuggestions();

	const { isBusySilent, isBusyExplicit } = useMemo(
		() => ({
			isBusySilent: isBusy && latestMetricBuilderSuggetion?.triggerType === 'silent',
			isBusyExplicit: isBusy && latestMetricBuilderSuggetion?.triggerType === 'explicit',
		}),
		[isBusy, latestMetricBuilderSuggetion?.triggerType]
	);

	const isMetricBuilderAIAgentEnabled = useMemo(() => {
		return isSightfull2Enabled && isAIMetricSuggestionsEnabledFF && metricBuilderAIAgentState.isEnabled;
	}, [isAIMetricSuggestionsEnabledFF, isSightfull2Enabled, metricBuilderAIAgentState.isEnabled]);

	const clearLatestSuggestion = useCallback(
		() => setLatestMetricBuilderSuggetion(undefined),
		[setLatestMetricBuilderSuggetion]
	);

	const enableMetricBuilderAIAgent = useCallback(
		(enable: boolean) => {
			setMetricBuilderAIAgentState((cur) => ({ ...cur, isEnabled: enable }));
		},
		[setMetricBuilderAIAgentState]
	);

	const callMetricBuilderAIAgent = useCallback(
		async ({ op, metricType }: { op: AIAgentMetricBuilderOp; metricType: MetricType }) => {
			const agentName: AIAgentName = `${metricType}-metric-builder`;
			const threadId = await getOrCreateAIAgentThread({ agentName });
			const data = await callAIAgent<AIAgentMetricBuilderResponse>({
				agentName,
				threadId,
				body: { op },
			});
			if (data?.status === 'error') {
				throw new Error(data.error);
			}
			return { data, threadId };
		},
		[getOrCreateAIAgentThread, callAIAgent]
	);

	const innerRequestMetricSuggestion = useCallback(
		async ({
			op,
			triggerType,
			metricType,
			abortController,
		}: {
			op: AIAgentMetricBuilderOp;
			triggerType: AIAgentTriggerType;
			metricType: MetricType;
			abortController?: AbortController;
		}) => {
			if (!isMetricBuilderAIAgentEnabled) return;

			clearPendingSuggestions();
			setLatestMetricBuilderSuggetion({ triggerType: triggerType, op, metricType });

			try {
				const { data } = await callMetricBuilderAIAgent({ op, metricType });
				if (abortController?.signal?.aborted) {
					return;
				}

				setLatestMetricBuilderSuggetion({
					triggerType,
					op,
					metricType,
					suggestedMetric: {
						name: data?.suggestion?.metric?.name ?? data?.suggestion?.formula_metric?.name,
						entity: data?.suggestion?.metric?.entity ?? data?.suggestion?.formula_metric?.entity,
						meta: data?.suggestion?.metric?.meta ?? data?.suggestion?.formula_metric?.meta,
						aggregate: data?.suggestion?.metric,
						formula: data?.suggestion?.formula_metric,
					},
				});
			} catch (error) {
				if (error instanceof Error) handleAIAgentError(error.message);
			}
		},
		[
			isMetricBuilderAIAgentEnabled,
			clearPendingSuggestions,
			setLatestMetricBuilderSuggetion,
			callMetricBuilderAIAgent,
			handleAIAgentError,
		]
	);

	const requestExplicitMetricDefinitionSuggestion = useCallback(
		async ({
			prompt,
			metricType,
			definition,
			abortController,
		}: {
			prompt: string;
			metricType: MetricType;
			definition?: string;
			abortController?: AbortController;
		}) => {
			let creationPrompt = prompt;
			if (definition) {
				creationPrompt = `${prompt} in the following metric definition:\n${definition}`;
			}
			await innerRequestMetricSuggestion({
				op: { create: { prompt: creationPrompt } },
				triggerType: 'explicit',
				metricType,
				abortController,
			});
			setAutoActivateFirstSuggestion();
		},
		[innerRequestMetricSuggestion, setAutoActivateFirstSuggestion]
	);

	const requestSilentMetricErrorFixingSuggestion = useCallback(
		async ({
			errorMessage,
			metricType,
			definition,
			abortController,
		}: {
			errorMessage: string;
			metricType: MetricType;
			definition?: string;
			abortController?: AbortController;
		}) => {
			let creationPrompt = `fix this error: "${errorMessage}"`;
			if (definition) {
				creationPrompt = `${creationPrompt}, in this metric definition:\n${definition}`;
			}
			await innerRequestMetricSuggestion({
				op: { create: { prompt: creationPrompt } },
				triggerType: 'silent',
				metricType,
				abortController,
			});
			setAutoActivateFirstSuggestion();
		},
		[innerRequestMetricSuggestion, setAutoActivateFirstSuggestion]
	);

	const requestSilentMetricChangesSuggestion = useCallback(
		async (
			{
				definition,
				changes,
				abortController,
			}: { definition: string; changes: { field: string; value: string }[]; abortController?: AbortController },
			metricType: MetricType
		) => {
			await innerRequestMetricSuggestion({
				op: { edit: { definition, changes } },
				triggerType: 'silent',
				metricType,
				abortController,
			});
		},
		[innerRequestMetricSuggestion]
	);

	return {
		isMetricBuilderAIAgentEnabled,
		isBusy,
		isBusySilent,
		isBusyExplicit,
		latestMetricBuilderSuggetion,
		clearLatestSuggestion,
		requestExplicitMetricDefinitionSuggestion,
		requestSilentMetricErrorFixingSuggestion,
		requestSilentMetricChangesSuggestion,
		enableMetricBuilderAIAgent,
	};
}
