import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { ArrowUpRightThin16, BarChartEmoji16, Hammer16, Sparkles16 } from '@components/Icons';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { Provider } from 'jotai';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import Link from '../../../Link';
import Spacer from '../../../Spacer';
import { useAskAIChatMessages } from '../../hooks/useAskAIChatMessages';
import { useCachedPerformDiscovery } from '../../hooks/useCachedPerformDiscovery';
import { useOpenInNewTab } from '../../hooks/useOpenInNewTab';
import { useReportAIEvent } from '../../hooks/useReportAIEvent';
import { AskAIChatCardProps, AskAIChatMessage, DiscoverMetricsSuggested } from '../../types';
import { AskAIMetricChartPreview } from './AskAIChatMetricPreview';
import { ActionChip, ActionChipsGroup } from '../AskAIActionChips';
import { AskAICreateNewMetricPopover } from '../../AskAICreateNewMetricPopover';
import { useModal } from '@hooks/ui/useModal';
import {
	useSemanticDefinitions,
	useSemanticsGetMetricDisplayName,
} from '../../../../hooks/stores/useSemanticDefinitions';

function MetricPreview({
	metric,
	testId,
	chatMessageAtom,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'> & {
	metric: DiscoverMetricsSuggested;
	testId: string;
}) {
	const { onOpenInNewTab } = useOpenInNewTab({ metric, chatMessageAtom });
	return (
		<Box padding={'0 16px'}>
			<AskAIMetricChartPreview metric={metric} onClick={onOpenInNewTab} testId={testId} />
		</Box>
	);
}

function AdditionalActionClickable({
	index,
	icon,
	onClick,
	onHover,
	children,
	testId,
}: {
	index: number;
	onClick: () => void;
	onHover?: (hover: boolean) => void;
	icon?: JSX.Element;
	children: React.ReactNode;
	testId: string;
}) {
	const withBorder = useMemo(() => index > 0, [index]);

	const onLinkClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
		(event) => {
			event.preventDefault();
			onClick();
		},
		[onClick]
	);

	const onMouseOver = useCallback(() => {
		onHover?.(true);
	}, [onHover]);

	const onMouseOut = useCallback(() => {
		onHover?.(false);
	}, [onHover]);

	return (
		<Link
			_hover={{ textDecoration: 'none' }}
			_active={{ textDecoration: 'none' }}
			data-testid={testId}
			width={'100%'}
			alignItems={'center'}
			onClick={onLinkClick}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseOut}
		>
			{withBorder && <Box margin={'0 44px'} borderTop={'1px'} borderColor={'gray.300'} />}
			<Flex
				_hover={{ backgroundColor: 'gray.200' }}
				gap={'12px'}
				alignItems={'center'}
				padding={'0 44px 0 16px'}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			>
				<Flex alignSelf={'start'} marginTop={'16px'}>
					{icon}
				</Flex>
				<Flex padding={'12px 0'} gap={'12px'} width={'100%'} alignItems={'center'}>
					{children}
				</Flex>
			</Flex>
		</Link>
	);
}

function AdditionalMetricAction({
	index,
	metrics,
	testId,
	chatMessageAtom,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'> & {
	index: number;
	metrics: DiscoverMetricsSuggested[];
	testId: string;
}) {
	const { semanticDefinitions } = useSemanticDefinitions();
	const getMetricDisplayName = useSemanticsGetMetricDisplayName();
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();
	const metric = metrics[index];
	const { onOpenInNewTab } = useOpenInNewTab({ metric, chatMessageAtom });
	const metricDisplay = getMetricDisplayName(metric.metric);
	const breakdownDisplayName = useMemo(() => {
		if (!metric.breakdown) return;
		const entityName = semanticDefinitions?.metrics.find((metricDef) => metricDef.name === metric.metric)?.entity;
		if (!entityName) return;
		const entity = semanticDefinitions.entities.find((entity) => entity.name === entityName);
		const dimension = entity?.dimensions.find((dimension) => dimension.name === metric.breakdown);
		return dimension?.meta?.display_name ?? metric.breakdown;
	}, [metric.breakdown, metric.metric, semanticDefinitions]);

	const [showActions, setShowActions] = useState(false);

	const onHover = useCallback((hover: boolean) => {
		setShowActions(hover);
	}, []);

	const previewMetricInCard = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-discovery-suggestion-click',
			metaData: {
				metric,
			},
		});
		addChatMessage({
			type: 'metric-discovery',
			userPrompt: metricDisplay,
			result: {
				reasoning: `Here is the preview of the requested metric`,
				metrics: [metric],
				examples: [],
			},
		});
	}, [addChatMessage, metric, metricDisplay, reportAskAIEvent]);

	return (
		<AdditionalActionClickable
			index={index}
			onClick={previewMetricInCard}
			onHover={onHover}
			testId={testId}
			icon={<BarChartEmoji16 />}
		>
			<Flex width={'100%'} alignItems={'center'}>
				{metricDisplay}
				{breakdownDisplayName && ` by ${breakdownDisplayName}`}
				<Spacer />
				{showActions && (
					<Box maxHeight={'24px'}>
						<Tooltip
							label={'Open in new tab'}
							size={'md'}
							variant={'fluid'}
							background={'black'}
							placement={'top'}
							marginBottom="8px"
						>
							<Button
								onClick={onOpenInNewTab}
								variant={'outline'}
								colorScheme={'gray'}
								size={'xxs'}
								isIconOnly={true}
								textColor={'gray.900'}
								blendMode={'multiply'}
								borderRadius={'4px'}
							>
								<ArrowUpRightThin16 />
							</Button>
						</Tooltip>
					</Box>
				)}
			</Flex>
		</AdditionalActionClickable>
	);
}

function AdditionalActionAskExample({ index, example, testId }: { index: number; example: string; testId: string }) {
	const { reportAskAIEvent } = useReportAIEvent();
	const { addChatMessage } = useAskAIChatMessages();

	const askExample = useCallback(() => {
		reportAskAIEvent({
			event: 'ask-ai-discovery-example-click',
			metaData: {
				example,
			},
		});
		addChatMessage({ userPrompt: example, type: 'metric-discovery' });
	}, [addChatMessage, example, reportAskAIEvent]);

	return (
		<AdditionalActionClickable index={index} onClick={askExample} testId={testId} icon={<Sparkles16 />}>
			<Flex alignItems={'center'}>{example}</Flex>
		</AdditionalActionClickable>
	);
}

function MetricPreviewGuard({ metricName, children }: { metricName: string; children: React.ReactNode }) {
	return (
		<Provider
			initialValues={[
				[
					MetricPageSearchParamsAtom,
					{
						metricName: metricName,
						searchParams: new URLSearchParams(),
					},
				],
				[IsMetricPageURLBasedAtom, false],
			]}
		>
			{children}
		</Provider>
	);
}

function AdditionalActionsHeader({ children }: { children: React.ReactNode }) {
	return (
		<Box padding={'12px 16px 8px 16px'}>
			<Typography variant="Paragraph14M" color={'gray.1000'}>
				{children}
			</Typography>
		</Box>
	);
}

function AdditionalActionsContent({ children }: { children: React.ReactNode }) {
	return <Flex direction={'column'}>{children}</Flex>;
}

function AdditionalMetricActions({
	metrics,
	chatMessageAtom,
	chatIndex,
}: AskAIChatCardProps & { metrics: DiscoverMetricsSuggested[] }) {
	return metrics.map((metric, index) => (
		<MetricPreviewGuard metricName={metric.metric} key={index}>
			<AdditionalMetricAction
				index={index}
				metrics={metrics}
				chatMessageAtom={chatMessageAtom}
				testId={`ask-ai-chat-discovery-${chatIndex}-additional-metric-action-${index}`}
			/>
		</MetricPreviewGuard>
	));
}

function AdditionalExampleActions({ examples, chatIndex }: AskAIChatCardProps & { examples: string[] }) {
	return examples.map((example, index) => (
		<AdditionalActionAskExample
			key={index}
			index={index}
			example={example}
			testId={`ask-ai-chat-discovery-${chatIndex}-additional-example-action-${index}`}
		/>
	));
}

function AdditionalActions({
	additionalMetrics,
	examples,
	createNewMetricPrompt,
	chatMessageAtom,
	chatIndex,
}: AskAIChatCardProps & {
	additionalMetrics: DiscoverMetricsSuggested[];
	createNewMetricPrompt?: string;
	examples: string[];
}) {
	const hasMetrics = additionalMetrics.length > 0;
	const pluralMetrics = additionalMetrics.length > 1;
	const hasExamples = examples.length > 0;
	if (!hasMetrics && !hasExamples && !createNewMetricPrompt) return <Box />;

	return (
		<Flex
			paddingBottom={'8px'}
			direction={'column'}
			backgroundColor={'gray.100'}
			borderTop={'1px solid'}
			borderColor={'gray.300'}
			borderRadius={'0 0 8px 8px'}
		>
			{hasMetrics && (
				<>
					<AdditionalActionsHeader>
						{`Here ${pluralMetrics ? 'are' : 'is'} ${additionalMetrics.length} more relevant metric${
							pluralMetrics ? 's' : ''
						}:`}
					</AdditionalActionsHeader>
					<AdditionalActionsContent>
						<AdditionalMetricActions
							metrics={additionalMetrics}
							chatMessageAtom={chatMessageAtom}
							chatIndex={chatIndex}
						/>
					</AdditionalActionsContent>
				</>
			)}
			{hasExamples && (
				<>
					<AdditionalActionsHeader>{`Here are a few examples of questions you can ask:`}</AdditionalActionsHeader>
					<AdditionalActionsContent>
						<AdditionalExampleActions examples={examples} chatMessageAtom={chatMessageAtom} chatIndex={chatIndex} />
					</AdditionalActionsContent>
				</>
			)}
		</Flex>
	);
}

function CreateNewMetricActionChip({ prompt }: { prompt: string }) {
	const { isOpen, onOpen, onClose } = useModal();

	return (
		<AskAICreateNewMetricPopover
			prompt={prompt}
			key={'create-new-metric'}
			createInNewTab={true}
			isSelectMetricTypeOpen={isOpen}
			onSelectMetricTypeClose={onClose}
		>
			<ActionChip onClick={onOpen} icon={<Hammer16 />} testId={'create-new-metric'} metadata={{ prompt }}>
				Build a new metric
			</ActionChip>
		</AskAICreateNewMetricPopover>
	);
}

function ChatActionChips({
	metrics = [],
	chatMessage,
}: {
	metrics?: DiscoverMetricsSuggested[];
	chatMessage: AskAIChatMessage;
}) {
	const createNewMetricPrompt = metrics.length === 0 ? chatMessage.userPrompt : undefined;

	return (
		<ActionChipsGroup>
			{createNewMetricPrompt && <CreateNewMetricActionChip prompt={createNewMetricPrompt} />}
		</ActionChipsGroup>
	);
}

export function AskAIMetricDiscoveryChatSuggestions({
	metrics = [],
	examples = [],
	chatMessageAtom,
	chatIndex,
}: AskAIChatCardProps & { metrics?: DiscoverMetricsSuggested[]; examples?: string[] }) {
	const { chatMessage } = useCachedPerformDiscovery({ chatMessageAtom });
	if (!chatMessage.completionTime) return null;

	const allMetrics = [...metrics];
	const previewMetric = allMetrics.shift();
	const additionalMetrics = allMetrics;

	return (
		<Flex gap={'16px'} direction={'column'}>
			{previewMetric && (
				<MetricPreviewGuard metricName={previewMetric.metric}>
					<MetricPreview
						metric={previewMetric}
						chatMessageAtom={chatMessageAtom}
						testId={`ask-ai-chat-discovery-${chatIndex}-metric-preview`}
					/>
				</MetricPreviewGuard>
			)}
			<ChatActionChips metrics={metrics} chatMessage={chatMessage} />
			<AdditionalActions
				additionalMetrics={additionalMetrics}
				examples={examples}
				createNewMetricPrompt={!metrics || metrics.length === 0 ? chatMessage.userPrompt : undefined}
				chatMessageAtom={chatMessageAtom}
				chatIndex={chatIndex}
			/>
		</Flex>
	);
}
