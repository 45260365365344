import Flex from '@components/Flex';
import { formatMetricFilterLabel } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import pluralize from 'pluralize';
import { Fragment } from 'react';
import Badge from 'src/common/components/Badge';
import { ArrowTo16, FilterTool12 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { getBreadcrumbs } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { Filter, FilterV2 } from 'src/pages/MetricPage/utils/state.types';

export function FiltersBadge({ filters }: { filters: (Filter | FilterV2)[] }) {
	const filterTitle = `${pluralize('Filter', filters.length)} (${filters.length}):`;
	return (
		<Popover
			triggerElement={
				<Badge
					icon={<FilterTool12 />}
					iconColor="gray.700"
					color="transparent"
					hoverColor="blue.200"
					hoverIconColor="blue.700"
				/>
			}
			trigger="hover"
		>
			<Flex paddingX={'16px'} paddingY="10px" direction="column" gap="4px">
				<Typography variant="Paragraph12R" color={'gray.600'}>
					{filterTitle}
				</Typography>
				<Flex direction={'column'} gap="2px">
					{filters.map((f) => (
						<FilterTooltipItem filter={f} key={f.key} />
					))}
				</Flex>
			</Flex>
		</Popover>
	);
}
export function FilterTooltipItem({
	filter,
	includeValues = true,
}: {
	filter: Filter | FilterV2;
	includeValues?: boolean;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const filterValueLabel =
		filter.values.length > 1 ? `+${filter.values.length} values` : formatMetricFilterLabel(filter.values[0]);

	const renderBreadcrumbs = (breadcrumbs: string[]) => {
		return breadcrumbs.map((breadcrumb, index) => (
			<Fragment key={index}>
				{index !== 0 && <ArrowTo16 />}
				<Typography variant="Paragraph12R" color="gray.700">
					{breadcrumb}
				</Typography>
			</Fragment>
		));
	};

	return (
		<Flex direction={'row'} gap="4px" alignItems={'center'}>
			{isSightfull2 ? renderBreadcrumbs([filter.label]) : renderBreadcrumbs(getBreadcrumbs(filter.key))}
			{includeValues && (
				<>
					<ArrowTo16 />
					<Typography variant="Disclaimer12SB" color="gray.800">
						{filterValueLabel}
					</Typography>
				</>
			)}
		</Flex>
	);
}
