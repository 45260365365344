import { Box } from '@chakra-ui/react';
import * as CSS from 'csstype';
import { AccessController } from 'src/common/components/AccessController';
import Divider from 'src/common/components/Divider';
import { EditFull16, InvestigateMetric16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import useUser from 'src/common/hooks/stores/useUser';
import { SignalWidgetHeader } from 'src/pages/DashboardPage/components/Widget/SignalWidget/SignalWidgetHeader';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { MetricThumbnail } from 'src/pages/MetricPage/MetricThumbnail';
import { Permission, Permissions } from 'src/types/environment';
import './WorkspaceAttachment.scss';

export function WorkspaceAttachment({
	authorId,
	onClick,
	onEdit,
	chartHeight = '250px',
	headerOnClickHandler,
}: {
	authorId?: string;
	onClick?: VoidFunction;
	onEdit?: VoidFunction;
	headerOnClickHandler?: VoidFunction;
	chartHeight?: CSS.Property.Height;
}) {
	const { isLoading: isMetricLoading, metricDisplayName, doesMetricExist } = useMetricDerivedState();
	const hasActions = onClick && onEdit;

	return (
		<Box className={'workspace-attachment'}>
			<SignalWidgetHeader
				isClickable={doesMetricExist}
				onClick={headerOnClickHandler}
				menu={hasActions && <AttachmentMenu onEdit={onEdit} onExplore={onClick} authorId={authorId} />}
				isEditMode={false}
				isLoading={isMetricLoading}
				metricTitle={metricDisplayName}
			/>
			<Box h={chartHeight}>
				<MetricThumbnail />
			</Box>
		</Box>
	);
}

function AttachmentMenu({
	authorId,
	onExplore,
	onEdit,
}: {
	authorId?: string;
	onExplore: VoidFunction;
	onEdit: VoidFunction;
}) {
	const [{ id: userId }] = useUser();

	const permissionsForCrud: Permission[] = [Permissions.updateAllSignals];
	if (authorId == userId) permissionsForCrud.push(Permissions.crudMySignals);

	return (
		<>
			<ListItem label="Explore" size="sm" prefixIcon={<InvestigateMetric16 />} onClick={onExplore} />
			<AccessController permission={permissionsForCrud} logic="OR">
				<Divider direction={'horizontal'} marginY="8px" />
				<ListItem label="Edit" size="sm" prefixIcon={<EditFull16 />} onClick={onEdit} />
			</AccessController>
		</>
	);
}
