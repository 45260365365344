import Popover from '@components/Popover';
import { useState } from 'react';
import { KeySensitiveError } from 'src/pages/OntologyPage/components';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import Button from '../Button';
import { WarningFluid16 } from '../Icons';

type ErrorButtonProps = {
	errorMessage?: string;
	isFixWithAIAvailable?: boolean;
	isAIBusy?: boolean;
	onFixWithAI?: (errorMessage: string) => void;
	reportEventData: {
		event: string;
		feature: string;
		extraMetadata?: Record<string, any>;
	};
};

export function ErrorButton({
	errorMessage,
	isFixWithAIAvailable,
	isAIBusy,
	onFixWithAI,
	reportEventData: { event, feature, extraMetadata },
}: ErrorButtonProps) {
	const [isHovered, setIsHovered] = useState(false);
	const [wasClicked, setWasClicked] = useState(true);
	const isOpen = isHovered || wasClicked;
	const { reportEvent } = useReportEvent(extraMetadata);

	const onClickHandler = () => {
		setWasClicked(!wasClicked);
		setIsHovered(false);

		reportEvent({
			event,
			metaData: { action: 'click', feature, error: errorMessage },
		});
	};

	const onMouseEnter = () => {
		setIsHovered(true);

		reportEvent({
			event,
			metaData: { action: 'hover', feature, error: errorMessage },
		});
	};

	const onMouseLeave = () => {
		setIsHovered(false);
	};

	const onClose = () => {
		setIsHovered(false);
		setWasClicked(false);
	};

	return (
		<Popover
			popoverContentProps={{
				borderRadius: '8px',
				backgroundColor: 'gray.1000',
			}}
			autoFocus={false}
			isWithPortal={false}
			isOpen={isOpen}
			triggerElement={
				<Button
					onClick={onClickHandler}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					boxShadow={isOpen ? shadows.redButtonShadow : 'none'}
					variant="outline"
					isIconOnly
					colorScheme="red"
					size={'small'}
				>
					<WarningFluid16 />
				</Button>
			}
			placement={'left-start'}
		>
			<KeySensitiveError
				onClose={onClose}
				errorMessage={errorMessage}
				isFixWithAIAvailable={isFixWithAIAvailable}
				isAIBusy={isAIBusy}
				onFixWithAI={onFixWithAI}
				reportMetadata={extraMetadata}
			/>
		</Popover>
	);
}
