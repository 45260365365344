import { allPossiblePresetsByType, PeriodRange } from '@sightfull/period-ranges';
import omit from 'lodash/omit';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import PeriodPicker from 'src/pages/MetricPage/components/PeriodPicker';
import { QuarterPeriodPickerOption } from 'src/pages/MetricPage/components/PeriodPicker/types';
import { getAvailablePeriodRange } from './config';

export function GlobalPeriodPicker({
	selectedPeriodRange,
	onSelectPeriodRange,
}: {
	selectedPeriodRange?: PeriodRange;
	onSelectPeriodRange: (periodRange?: PeriodRange) => void;
}) {
	return (
		<PeriodPicker
			size="sm"
			selectedPeriodRange={selectedPeriodRange}
			onSelectPeriodRange={onSelectPeriodRange}
			availableRangePresets={omit(allPossiblePresetsByType(fiscalYearOffset()), 'fweek')}
			availablePeriodRanges={getAvailablePeriodRange()}
			defaultPeriodPickerOption={QuarterPeriodPickerOption}
			hasDefaultOption
		/>
	);
}
