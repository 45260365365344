import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PulseApolloProvider, createClient } from '@services/apollo';
import { ConfigProvider as AntDesignConfigProvider } from 'antd';
import { StrictMode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { initializeUserInformation } from 'src/services/user';
import AppLayout from './layout/AppLayout';

import { configureAxios } from 'src/services/axios';
import { normalizedEnvConfig, useEnvironment, useEnvironmentStore } from 'src/stores/environment';
import './style/global.scss';

import Center from '@components/Center';
import SomethingWentWrongError from '@components/ErrorWentWrong/SomethingWentWrongError';
import { PopupLoginSuccess } from '@components/PopupLoginSuccess/PopupLoginSuccess';
import { TenantPickerPage } from '@components/TenantPicker/TenantPickerPage';
import { FronteggProvider } from '@frontegg/react';
import { useIntercomFromSearchParams } from '@hooks/useIntercomFromSearchParams';
import { initAnalytics } from '@services/analytics';
import { parseHashLinks } from '@services/parseHashLinks';
import SightfullLoadingGif from 'src/assets/images/Sightfull_loading-gif.gif';
import { getBearerToken, getFronteggOptions, useAuthEnv } from 'src/services/auth';
import Chakra from 'src/style/Chakra';
import Button from './common/components/Button';
import useUrlTenant from './common/hooks/navigation/useUrlTenant';
import useGraceTimeout from './common/hooks/ui/useGraceTimeout';
import useFronteggLogin, { isInPopupLoggedIn, useFronteggAuth } from './common/hooks/useFronteggLogin';
import { isTenantIDAutoGenerated } from './common/utils/tenantId';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';
import { PulseAbilityProviderGuard } from './services/entitlements';
import colors from './style/colors';
import { SightfullFronteggThemeOptions } from './style/fronteggTheme';

AntDesignConfigProvider.config({
	theme: {
		primaryColor: colors.blue[700],
	},
});
initAnalytics();
parseHashLinks();

function Pulse({ apolloClient }: { apolloClient: ApolloClient<NormalizedCacheObject> }) {
	const [isUserInitialized, setIsUserInitialized] = useState(false);

	useEffect(() => {
		initializeUserInformation(apolloClient).then(() => setIsUserInitialized(true));
	}, [apolloClient]);

	if (!isUserInitialized) return null;

	return (
		<StrictMode>
			<ErrorBoundary fallback={<SomethingWentWrongError />}>
				<PulseApolloProvider>
					<PulseAbilityProviderGuard>
						<AppLayout />
					</PulseAbilityProviderGuard>
				</PulseApolloProvider>
			</ErrorBoundary>
		</StrictMode>
	);
}

function PreApp() {
	const selectedEnv = useEnvironment();
	const [loading, setLoading] = useState(true);
	const { user, isAuthenticated } = useFronteggAuth();
	const accessToken = user?.accessToken;
	const authEnv = useAuthEnv();
	const setEnv = useEnvironmentStore((state) => state.setEnvironment);
	const [urlTenant] = useUrlTenant();
	const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
	const isGraceDone = useGraceTimeout();
	useIntercomFromSearchParams();

	const { openLoginPopup, shouldLoginWithPopup } = useFronteggLogin();

	useEffect(() => {
		if (isAuthenticated && accessToken && authEnv) {
			const isTenantSwitched = selectedEnv.tenant && selectedEnv.tenant != authEnv.tenant;
			if (isTenantSwitched) {
				location.reload();
			}

			setEnv(authEnv);
			configureAxios(authEnv, getBearerToken(accessToken));
			setApolloClient(createClient({ selectedEnv: normalizedEnvConfig(authEnv), accessToken, isSightfull2: false }));
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [authEnv, isAuthenticated, selectedEnv.tenant, setEnv, accessToken, selectedEnv]);

	if (isTenantIDAutoGenerated(user?.tenantId)) {
		return <ThankYouPage />;
	} else if (!isAuthenticated || !accessToken || !apolloClient || loading) {
		return (
			<Center height="calc(100vh)" width="calc(100vw)" display={'flex'}>
				{shouldLoginWithPopup && isGraceDone ? (
					<Button onClick={openLoginPopup} colorScheme="blue" size="large" variant="solid">
						Login
					</Button>
				) : (
					<img src={SightfullLoadingGif} style={{ width: '300px' }} alt="Loading..." />
				)}
			</Center>
		);
	} else if (isInPopupLoggedIn()) {
		return <PopupLoginSuccess />;
	} else if (selectedEnv.isMultiTenant && !urlTenant) {
		return <TenantPickerPage />;
	}

	return <Pulse apolloClient={apolloClient} />;
}

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="/*"
			errorElement={
				<Chakra>
					<SomethingWentWrongError />
				</Chakra>
			}
			element={
				<ErrorBoundary
					fallback={
						<Chakra>
							<SomethingWentWrongError
								title="Oh no, login error."
								subtitle="This is probably due to a wrong tenant ID. Go back and try again,"
							/>
						</Chakra>
					}
				>
					<FronteggProvider
						contextOptions={getFronteggOptions()}
						themeOptions={SightfullFronteggThemeOptions}
						hostedLoginBox={true}
						urlStrategy={'path'}
						authOptions={{ keepSessionAlive: true }}
					>
						<Chakra>
							<PreApp />
						</Chakra>
					</FronteggProvider>
				</ErrorBoundary>
			}
		/>
	)
);

function App() {
	return (
		<ErrorBoundary
			fallback={
				<Chakra>
					<SomethingWentWrongError />
				</Chakra>
			}
		>
			<RouterProvider router={router} />
		</ErrorBoundary>
	);
}
export default App;
