import Modal from '@components/Modal';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16 } from '@icons/index';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import { showIntercom } from '@services/intercom';
import Skeleton from '@components/Skeleton';

export function DataConnectorsResponseModal({
	isOpen,
	onClose,
	isSuccess,
	isLoading,
}: {
	isLoading: boolean;
	isOpen: boolean;
	onClose: () => void;
	isSuccess: boolean;
}) {
	const openIntercom = () => {
		showIntercom();
	};

	const title = isSuccess ? 'We got your request.' : "We couldn't connect your data source.";
	const subtitle = isSuccess
		? 'One of our experts will be in touch shortly. This may take up to 24 hours.'
		: "Something went wrong, but we're here to help. Please reach out to us, and we'll get things sorted out quickly.";
	return isLoading ? (
		<Modal
			isOpen={isLoading}
			onClose={() => {}}
			closeOnOverlayClick={false}
			maxWidth={'492px'}
			isCentered={true}
			borderRadius={'8px'}
			closeOnEsc={false}
		>
			<Flex height={'48px'} alignSelf={'stretch'} />
			<Flex
				padding={'24px 32px 32px 32px'}
				alignSelf={'stretch'}
				alignItems={'center'}
				flexDirection={'column'}
				gap={'32px'}
			>
				<Flex
					padding={'0px 20px 16px 20px'}
					flexDirection={'column'}
					alignItems={'center'}
					gap={'24px'}
					alignSelf={'stretch'}
				>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'36px'} alignSelf={'stretch'}>
						<Flex flexDirection={'column'} alignItems={'center'} gap={'6px'} alignSelf={'stretch'}>
							<Skeleton>
								<Flex width={'200px'} height={'24px'}></Flex>
							</Skeleton>
							<Skeleton>
								<Flex width={'400px'} height={'48px'}></Flex>
							</Skeleton>
						</Flex>
						<Flex flexDirection={'column'} alignItems={'center'} gap={'6px'} alignSelf={'stretch'}>
							<Skeleton>
								<Flex width={'400px'} height={'48px'}></Flex>
							</Skeleton>
							<Skeleton>
								<Flex width={'400px'} height={'48px'}></Flex>
							</Skeleton>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Modal>
	) : (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			maxWidth={'492px'}
			isCentered={true}
			borderRadius={'8px'}
			closeOnEsc={true}
		>
			<Flex padding={'16px 24px 0px 16px'} gap={'16px'} alignSelf={'stretch'}>
				<Button isIconOnly={true} onClick={onClose} variant="outline" size={'small'} colorScheme={'gray'}>
					<CloseLarge16 color={colors.gray['900']} />
				</Button>
			</Flex>
			<Flex
				padding={'24px 32px 32px 32px'}
				flexDirection={'column'}
				alignItems={'center'}
				gap={'36px'}
				alignSelf={'stretch'}
			>
				<Flex
					padding={'0px 20px 16px 20px'}
					flexDirection={'column'}
					alignItems={'center'}
					gap={'24px'}
					alignSelf={'stretch'}
				>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'36px'} alignSelf={'stretch'}>
						<Flex flexDirection={'column'} gap={'6px'} alignItems={'center'} alignSelf={'stretch'}>
							<Typography variant={'DesktopH5SemiBold'} textAlign={'center'} color={colors.gray['1000']}>
								{title}
							</Typography>
							<Typography
								maxWidth={isSuccess ? '300px' : '100%'}
								variant={'Paragraph14R'}
								textAlign={'center'}
								color={colors.gray['900']}
							>
								{subtitle}
							</Typography>
						</Flex>
						<Flex width={'100%'} justifyContent={'center'} alignItems={'center'} gap={'12px'} alignSelf={'stretch'}>
							<Button
								colorScheme={'blue'}
								variant={'solid'}
								alignSelf={'stretch'}
								borderRadius={'8px'}
								onClick={onClose}
							>
								Got it
							</Button>
							<Button
								colorScheme={'black'}
								variant={'ghost'}
								alignSelf={'stretch'}
								borderRadius={'8px'}
								border={`1px solid ${colors.gray['300']}`}
								onClick={() => {
									onClose();
									openIntercom();
								}}
							>
								Contact us
							</Button>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Modal>
	);
}
