import { Box, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { ChevronLeft16, ChevronRight16, FilterOutline16 } from 'src/common/components/Icons';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { useScrollableFiltersBar } from 'src/pages/DashboardPage/Header/GlobalFilters/useScrollableFiltersBar';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { FilterChip } from './FilterChip';
import classes from './GlobalFiltersBar.module.scss';

export function GlobalFiltersBar({
	onDialogOpen,
	isDialogOpen,
	onRemoveFilter,
	filterObjects: availableFilterObjects,
	globalFilters,
}: {
	onDialogOpen: VoidFunction;
	isDialogOpen: boolean;
	onRemoveFilter: (key: string) => void;
	filterObjects: string[];
	globalFilters: Filter[];
}) {
	const { reportEvent } = useReportEvent();
	const {
		scrollState: { isStartShadowShown, isEndShadowShown, isScrolling },
		calculateScrollState,
		scrollComponentRef,
	} = useScrollableFiltersBar();
	const scrollOnClickPixels = 250;

	useEffect(calculateScrollState, [calculateScrollState, globalFilters.length]);

	const globalFiltersWithNullLabels = globalFilters.map((filter) => ({
		...filter,
		label: filter.label ?? NOT_AVAILABLE_VALUE_STRING,
	}));

	return (
		<Flex dir="row" width={0} flex={1} alignItems={'center'} gap="12px" transition={'all 150ms'}>
			<Button
				isDisabled={!availableFilterObjects.length}
				onClick={() => {
					reportEvent({
						event: 'dashboard-global-filters-click',
						metaData: { objects: availableFilterObjects, activeFiltersCount: globalFilters.length },
					});
					onDialogOpen();
				}}
				variant="ghost"
				size="small"
				colorScheme={'gray'}
				leftIcon={<FilterOutline16 />}
				isActive={isDialogOpen}
			>
				Filters
			</Button>
			{!!globalFilters.length && (
				<Box height={'36px'}>
					<Divider direction={'vertical'} />
				</Box>
			)}

			<Flex direction={'row'} flex={1} width={0} justifyContent="center">
				{isScrolling && (
					<Flex
						height={'36px'}
						minWidth={'32px'}
						boxShadow={isStartShadowShown ? shadows['a-22'] : undefined}
						zIndex={1}
						clipPath="inset(0px -25px 0px 0px)"
						alignItems="center"
						onClick={() => {
							scrollComponentRef.current?.scrollBy({ left: -scrollOnClickPixels, behavior: 'smooth' });
						}}
					>
						<IconButton icon={<ChevronLeft16 />} size="xxs" colorScheme="black" isDisabled={!isStartShadowShown} />
					</Flex>
				)}
				<Flex
					direction={'row'}
					alignItems={'center'}
					gap="8px"
					overflowX="auto"
					flexGrow={1}
					className={classes.globalFiltersBar}
					onScroll={calculateScrollState}
					ref={scrollComponentRef}
				>
					{globalFiltersWithNullLabels.map((filter) => (
						<Box key={filter.key + filter.values.join('')}>
							<FilterChip
								filter={filter}
								onRemove={() => {
									reportEvent({
										event: 'dashboard-global-filters-chip-remove',
										metaData: { filterKey: filter.key, activeFiltersCount: globalFilters.length },
									});
									onRemoveFilter(filter.key);
								}}
							/>
						</Box>
					))}
				</Flex>
				{isScrolling && (
					<Flex
						height={'36px'}
						minWidth={'32px'}
						boxShadow={isEndShadowShown ? shadows['a-22'] : undefined}
						zIndex={1}
						clipPath="inset(0px 0px 0px -25px)"
						alignItems="center"
						justifyContent={'end'}
					>
						<IconButton
							icon={<ChevronRight16 />}
							size="xxs"
							colorScheme="black"
							isDisabled={!isEndShadowShown}
							onClick={() => {
								scrollComponentRef.current?.scrollBy({ left: scrollOnClickPixels, behavior: 'smooth' });
							}}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}
