import Flex from '@components/Flex';
import Box from '@components/Box';
import Typography from '@components/Typography';
import classes from '@components/LeftExpandCollapsePanel/LeftExpandCollapsePanel.module.scss';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { CloseMedium16 } from '@icons/index';

export const Title = ({
	title,
	onRemove,
	removeTooltip,
	icon,
}: {
	title: string;
	onRemove?: VoidFunction;
	removeTooltip?: string;
	icon: React.ReactNode;
}) => {
	return (
		<Flex justifyContent={'space-between'} alignItems={'center'}>
			<Flex
				alignItems={'center'}
				color={'gray.1000'}
				padding={'6px 8px'}
				gap={'10px'}
				borderRadius={'6px'}
				width={'100%'}
				cursor={'default'}
			>
				<Box>{icon}</Box>
				<Typography variant={'DesktopH8Medium'} textOverflow="ellipsis" noOfLines={1} className={classes.titleWordWrap}>
					{title}
				</Typography>
			</Flex>
			{onRemove && (
				<Flex alignItems={'center'}>
					<Tooltip
						size={'md'}
						label={removeTooltip}
						variant="fluid"
						placement={'top'}
						as={'div'}
						shouldWrapChildren={false}
					>
						<Box>
							<Button
								className={classes.closeButton}
								size="inline"
								variant="outline"
								colorScheme="lightGray"
								onClick={(event) => {
									event.stopPropagation();
									onRemove?.();
								}}
								isIconOnly={true}
								blendMode={'multiply'}
							>
								<CloseMedium16 />
							</Button>
						</Box>
					</Tooltip>
				</Flex>
			)}
		</Flex>
	);
};
